<template>
  <div class="container">
    <customer-new ref="customerNew"></customer-new>
    <!-- HEADER -->
    <div class="row">
      <div class="col-sm-6">
        <h1 class="page-title">LISTA DE PACIENTES</h1>
      </div>
      <div class="col-sm-2 text-center">
        <button
          @click="addNewCustomer"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          <i class="fas fa-plus mr-1"></i>
          Nuevo paciente
        </button>
      </div>
      <div class="col-sm-2 text-center">
        <button
          @click="downloadCustomers"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          <i class="fas fa-download mr-1"></i>
          Descargar
        </button>
      </div>
      <div class="col-sm-2 text-center">
        <button
          @click="downloadUnpaidCustomers"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          <i class="fas fa-download mr-1"></i>
          Pend. Pago
        </button>
      </div>
    </div>
    <!-- SEARCH  INPUT-->
    <div class="row form-group">
      <div class="col-sm-4">
        <input
          v-model="search"
          class="form-control icon"
          type="search"
          name="search"
          id="customer_index_search"
          placeholder="Nombre, DNI o Nº de historia"
        />
        <label class="wabident-info" for="customer_index_search"
          >Búsqueda</label
        >
      </div>
      <div class="col-sm-2">
        <select
          v-model="search_clinic"
          id="select_clinic"
          class="custom-select"
        >
          <option :value="null">Todas</option>
          <option v-for="(name, index) in clinics" :key="index" :value="index">
            {{ name }}
          </option>
        </select>
        <label class="wabident-info" for="select_clinic">Clínica</label>
      </div>
      <div class="col-sm-2">
        <input
          v-model="date_from"
          type="date"
          class="form-control"
          id="select_date_from"
        />
        <label class="wabident-info" for="select_date_from">F.Nac. Desde</label>
      </div>
      <div class="col-sm-2">
        <input
          v-model="date_to"
          type="date"
          class="form-control"
          id="select_date_to"
        />
        <label class="wabident-info" for="select_date_to">F.Nac. Hasta</label>
      </div>
      <div class="col-sm-2">
        <select
          v-model="is_consent_signed"
          id="select_data_signed"
          class="custom-select"
        >
          <option :value="null">Todos</option>
          <option :value="1">Sí</option>
          <option :value="0">No</option>
        </select>
        <label class="wabident-info" for="select_data_signed"
          >P.Datos Firmado</label
        >
      </div>
    </div>
    <!-- RESULTS -->
    <div class="row">
      <div class="col-sm-12">
        <div v-show="is_searching" class="text-center">
          <h2 class="customer-search-empty-message my-4">
            Buscando
            <i class="fas fa-spin fa-circle-notch"></i>
          </h2>
        </div>
        <div v-show="!has_results_available" class="text-center">
          <h2 class="customer-search-empty-message my-4">
            {{ empty_message }}
          </h2>
        </div>
        <div v-show="has_results_available" class="customer-search-wrapper">
          <table class="table customer-search-results">
            <thead>
              <tr>
                <th colspan="1"></th>
                <th colspan="7">PACIENTE</th>
                <th colspan="2">TELÉFONO</th>
                <th colspan="2">CLÍNICA REF.</th>
                <th colspan="2">Nº HISTORIA</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="customer-search-results__row"
                v-for="(customer, index) in search_customers"
                :key="index"
                @click="customerClicked(customer)"
              >
                <td
                  class="customer-search-results__photo text-center"
                  colspan="1"
                >
                  <img :src="customer.photo" />
                </td>
                <td colspan="7">
                  {{ customer.name }}
                  <span v-if="!customer.has_paid">
                    - <i class="fas fa-money-bill-alt"></i
                  ></span>
                </td>
                <td colspan="2">{{ customer.phone }}</td>
                <td colspan="2">{{ showClinic(customer) }}</td>
                <td colspan="2">{{ customer.history_number }}</td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- Pagination -->
    <div v-if="has_results_available" class="row">
      <div class="col text-center customer-search-pagination my-2">
        <button
          :disabled="is_first_page"
          @click="goFirst"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-double-left"></i>
        </button>
        <button
          :disabled="is_first_page"
          @click="goPrev"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <label class="customer-search-pagination-locator mx-2">
          Página
          {{ current_page }} de {{ total_pages }}
        </label>
        <button
          :disabled="is_last_page"
          @click="goNext"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-right"></i>
        </button>
        <button
          :disabled="is_last_page"
          @click="goLast"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      search_results: [],
      search_clinic: null,
      date_from: null,
      date_to: null,
      is_consent_signed: null,
      page: 1,
      is_searching: false,
      selected_customer: null,
    };
  },
  watch: {
    search(value) {
      this.page = 1;
      this.searchCustomers(value);
    },
    search_clinic(v) {
      this.page = 1;
      this.searchCustomers(this.search);
    },
    date_from(v) {
      this.page = 1;
      this.searchCustomers(this.search);
    },
    date_to(v) {
      this.page = 1;
      this.searchCustomers(this.search);
    },
    is_consent_signed(v) {
      this.page = 1;
      this.searchCustomers(this.search);
    },
  },
  props: {
    clinic_id: { required: true },
  },
  computed: {
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == this.search_results.last_page;
    },
    search_customers() {
      if (this.is_searching) return [];
      if (!this.search_results.data) return [];
      return this.search_results.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.search_results.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.search_results.last_page;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names || [];
    },
    has_results_available() {
      return (
        this.search_results &&
        this.search_results.data &&
        this.search_results.data.length != 0
      );
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningún paciente con ese nombre";
      }
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    ...mapGetters(["clinic"]),
  },
  methods: {
    goFirst() {
      this.page = 1;
      this.searchCustomers(this.search);
    },
    goPrev() {
      this.page -= 1;
      this.searchCustomers(this.search);
    },
    goNext() {
      this.page += 1;
      this.searchCustomers(this.search);
    },
    goLast() {
      this.page = this.total_pages;
      this.searchCustomers(this.search);
    },
    showClinic(customer) {
      return this.clinics[customer.clinic_id] || "";
    },
    addNewCustomer() {
      this.$refs.customerNew.show();
    },
    downloadCustomers() {
      const params = this.getSearchParams();
      let sparams = new URLSearchParams();
      for (const key in params) {
        let value = params[key];
        if (value != null && value != "") {
          sparams.append(key, value);
        }
      }

      window.location = `/customer/csv/?` + sparams.toString();
    },
    downloadUnpaidCustomers() {
      window.location = `/customer/unpaid`;
    },
    customerClicked(customer) {
      window.location = `/customer/${customer.id}/edit`;
    },
    getSearchParams() {
      return {
        search: this.search,
        paged: true,
        page: this.page,
        clinic_id: this.search_clinic,
        date_from: this.date_from,
        date_to: this.date_to,
        is_consent_signed: this.is_consent_signed,
      };
    },
    searchCustomers(search, page = 1) {
      if (this.is_searching) return;
      this.is_searching = true;
      axios
        .get("/customer/search", {
          params: this.getSearchParams(),
        })
        .then((response) => {
          this.search_results = response.data;
        })
        .catch((error) => {
          console.log("[ERR] searchCustomers:", error);
        })
        .then(() => {
          this.is_searching = false;
        });
    },
    loadClinicData() {
      if (!this.clinic) {
        axios
          .get("/agenda/clinic_info", {
            params: {
              clinic_id: this.clinic_id,
            },
          })
          .then((response) => {
            let data = response.data;
            if (data.error) {
              console.log("[ERR] loadClinicData:", data.error);
            } else {
              this.setClinic(data);
            }
          })
          .catch((error) => {
            console.log("[ERR] loadClinicData:", error);
          });
      }
    },
    ...mapMutations(["setClinic"]),
  },
  mounted() {
    this.loadClinicData();
    this.searchCustomers("");
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.customer-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
  vertical-align: middle;
}

.customer-search-wrapper {
  margin-top: 30px;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 65vh;
}

.customer-search-results {
  width: 100%;
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
  }

  &__photo > img {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    text-align: center;
    margin: auto 0;
    display: inline-block;
    background-color: var(--theme-grey);
    border: none;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);
      vertical-align: baseline;
    }
  }
}
</style>
