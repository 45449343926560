<template>
  <tr class="clinic-treatment">
    <td class="clinic-treatment__date">{{ pretty_date }}</td>
    <td class="clinic-treatment__clinic">{{ clinic_name }}</td>
    <td class="clinic-treatment__doctor">{{ doctor_name }}</td>
    <td class="clinic-treatment__treatment">
      {{ treatment_name }}
    </td>
    <td class="clinic-treatment__type">
      {{ product_type }}
    </td>
    <td class="clinic-treatment__category">
      {{ category_name }}
    </td>
    <td class="clinic-treatment__customer">
      <a target="_blank" :href="customer_link">{{ customer_name }}</a>
    </td>
    <td class="clinic-treatment__done">{{ done }}</td>
    <td class="clinic-treatment__paid">{{ paid }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    treatment: { required: true },
    clinics: { default: null },
    doctors: { default: null },
    showpaid: { default: null },
    categories: { default: null },
    orderby: { default: null },
  },
  computed: {
    product_type() {
      if (!this.treatment.product) return "";
      return this.treatment.product.type == "product" ? "Producto" : "Ttto";
    },
    category_name() {
      if (!this.categories || !this.treatment.product.product_category_id)
        return "";

      return this.categories[this.treatment.product.product_category_id];
    },
    customer_name() {
      if (!this.treatment.customer) return "";
      return this.customer.name;
    },
    customer_link() {
      if (!this.treatment.customer) return "";
      return `/customer/${this.treatment.customer.id}/edit`;
    },
    pretty_date() {
      let d = this.treatment.created_at;
      switch (this.orderby) {
        case "done_at":
          d = this.treatment.done_at;
          break;
        case "paid_at":
          d = this.treatment.paid_at;
          break;
      }
      if (d) {
        return moment(d).toDate().toLocaleDateString();
      }
      return "-";
    },
    doctor_name() {
      if (!this.doctors) return "";
      let doc = this.doctors.find((doc) => {
        return doc.doctor.id == this.treatment.doctor_id;
      });

      if (!doc) return "";
      return doc.name;
    },
    treatment_name() {
      let teeth = "";
      if (this.treatment.teeth && this.treatment.teeth.length > 0) {
        teeth = " (" + this.treatment.teeth.join(",") + ")";
      }

      return this.treatment.product.name + teeth;
    },
    customer_name() {
      if (!this.treatment.customer) return "";
      return this.treatment.customer.name;
    },
    clinic_name() {
      if (!this.clinics) return "";
      return this.clinics[this.treatment.clinic_id] || "";
    },
    done() {
      if (this.treatment.status == "done") return "Sí";
      return "No";
    },
    paid() {
      if (this.treatment.is_paid) return "Sí";
      return "No";
    },
    price() {
      if (this.treatment.price) {
        return window.WabiDent.fmtMoney(this.treatment.price);
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
</style>
