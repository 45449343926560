<template>
  <b-form class="customer-edit-data" @submit="onSubmit">
    <!-- Photo an Main Data -->
    <show-qr-code
      @close="onCloseFillDialog"
      ref="filldialog"
      id="customer-data-fill"
      title="Rellenar Datos"
      :url="customer_fill_url"
    ></show-qr-code>

    <div class="row">
      <div class="col-sm-2 d-flex justify-content-center align-items-top">
        <div
          class="
            customer-photo-edit
            d-flex
            justify-content-center
            align-items-center
          "
          @click="selectFile"
          :style="photo_style"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="23"
            viewBox="0 0 20 23"
          >
            <path
              d="M9.996 22.624h.006c2.746-.017 5.123-1.002 7.067-2.93C19.014 17.749 20 15.37 20 12.623c0-1.747-.397-3.35-1.174-4.77a.568.568 0 0 0-.992.55c.686 1.247 1.033 2.67 1.033 4.22 0 2.47-.851 4.52-2.596 6.266-1.748 1.733-3.799 2.584-6.27 2.6-2.485-.017-4.536-.866-6.268-2.598a9.611 9.611 0 0 1-1.41-1.79 8.079 8.079 0 0 1-1.127-3.352v-.008a9.086 9.086 0 0 1-.06-1.119c0-2.484.852-4.534 2.6-6.27 1.732-1.747 3.782-2.596 6.267-2.596a.568.568 0 1 0 0-1.136c-2.764 0-5.144.987-7.07 2.93C.987 7.48.001 9.857.001 12.621c-.005.42.016.84.062 1.256a9.32 9.32 0 0 0 1.284 3.813c.446.73.976 1.402 1.581 2.006 1.928 1.924 4.305 2.91 7.068 2.927z"
            />
            <path
              d="M6.29 14.26a.567.567 0 0 0-.092.294l-.12 4.156a.568.568 0 0 0 .852.506l3.667-2.146a.568.568 0 0 0 .258-.33.546.546 0 0 0 .134-.248l6.127-10.804a.567.567 0 0 0 .053-.128.565.565 0 0 0 .06-.085l.792-1.396c.368-.634.489-1.38.34-2.098A2.493 2.493 0 0 0 17.17.312c-.613-.347-1.337-.407-2.039-.167a2.998 2.998 0 0 0-1.625 1.373l-.791 1.397a.562.562 0 0 0-.037.08L6.29 14.261zm9.677-8.848L10.21 15.57 7.67 14.13 13.427 3.97l2.54 1.442zm-8.652 9.822l2.12 1.202-2.192 1.283.072-2.485zm7.177-13.156c.22-.4.577-.705 1.006-.859a1.3 1.3 0 0 1 1.754.999c.09.445.012.908-.218 1.3l-.511.903-2.54-1.44.51-.903z"
            />
          </svg>
        </div>
        <input
          ref="photofile"
          class="customer-photo-file"
          type="file"
          accept="image/*"
          @change="onFileChanged"
        />
      </div>
      <!-- Inner Rows -->
      <div class="col-sm-10">
        <!-- First Inner Row -->
        <div class="form-group row">
          <div v-if="qr_fill_enabled" class="col-sm-2 mt-2">
            <button
              type="button"
              role="button"
              class="btn btn-primary btn-wabident-round btn-block"
              :disabled="is_updating"
              @click="showFillDialog"
            >
              Escanear QR
            </button>
          </div>

          <div class="col">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="name"
              id="edit_customer_name"
              required
            />
            <label for="edit_customer_name" class="wabident-info"
              >Nombre y apellidos</label
            >
          </div>

          <div class="col-sm-3">
            <input
              v-model="form.history_number"
              type="text"
              class="form-control"
              name="history_number"
              id="edit_customer_history_number"
              required
            />
            <label for="edit_customer_history_number" class="wabident-info"
              >Nº historial</label
            >
          </div>
        </div>
        <!-- Second Inner Rows -->
        <div class="form-group row">
          <div class="col-sm-5">
            <input
              v-model="form.created_at"
              type="date"
              name="created_at"
              id="customer_edit_created_at"
              class="form-control"
              required
            />
            <label for="customer_edit_created_at" class="wabident-info"
              >Fecha de alta</label
            >
          </div>
          <div class="col-sm-5">
            <input
              v-model="form.birth_date"
              type="date"
              name="birth_date"
              id="customer_edit_birth_date"
              class="form-control"
              required
            />
            <label for="customer_edit_birth_date" class="wabident-info"
              >Fecha de nacimiento</label
            >
          </div>
          <div class="col-sm-2">
            <input
              type="text"
              id="customer_edit_age"
              class="form-control"
              disabled
              :value="customer_age"
            />
            <label for="customer_edit_age" class="wabident-info">Edad</label>
          </div>
        </div>
      </div>
    </div>
    <!-- Phone and Email -->
    <div class="form-group row">
      <div class="col-sm-3">
        <input
          v-model="form.phone"
          type="tel"
          name="phone"
          id="customer_edit_phone"
          class="form-control"
          required
        />
        <label for="customer_edit_phone" class="wabident-info"
          >Teléfono móvil</label
        >
      </div>
      <div class="col-sm-3">
        <input
          v-model="form.phone_alt"
          type="tel"
          name="phone_alt"
          id="customer_edit_phone_alt"
          class="form-control"
        />
        <label for="customer_edit_phone_alt" class="wabident-info"
          >Teléfono secundario</label
        >
      </div>
      <div class="col-sm-6">
        <input
          v-model="form.email"
          type="email"
          name="email"
          id="customer_edit_email"
          class="form-control"
        />
        <label for="customer_edit_email" class="wabident-info">Email</label>
      </div>
    </div>
    <!-- NIF and Country -->
    <div class="form-group row">
      <div class="col-sm-3">
        <input
          v-model="form.nif"
          type="text"
          name="nif"
          id="customer_edit_nif"
          class="form-control"
        />
        <label for="customer_edit_nif" class="wabident-info"
          >DNI / Pasaporte</label
        >
      </div>
      <div class="col-sm-9">
        <select
          v-model="form.country"
          name="country"
          id="customer_edit_country"
          class="form-control custom-select"
        >
          <option value="AF">Afganistán</option>
          <option value="AL">Albania</option>
          <option value="DE">Alemania</option>
          <option value="AD">Andorra</option>
          <option value="AO">Angola</option>
          <option value="AI">Anguilla</option>
          <option value="AQ">Antártida</option>
          <option value="AG">Antigua y Barbuda</option>
          <option value="AN">Antillas Holandesas</option>
          <option value="SA">Arabia Saudí</option>
          <option value="DZ">Argelia</option>
          <option value="AR">Argentina</option>
          <option value="AM">Armenia</option>
          <option value="AW">Aruba</option>
          <option value="AU">Australia</option>
          <option value="AT">Austria</option>
          <option value="AZ">Azerbaiyán</option>
          <option value="BS">Bahamas</option>
          <option value="BH">Bahrein</option>
          <option value="BD">Bangladesh</option>
          <option value="BB">Barbados</option>
          <option value="BE">Bélgica</option>
          <option value="BZ">Belice</option>
          <option value="BJ">Benin</option>
          <option value="BM">Bermudas</option>
          <option value="BY">Bielorrusia</option>
          <option value="MM">Birmania</option>
          <option value="BO">Bolivia</option>
          <option value="BA">Bosnia y Herzegovina</option>
          <option value="BW">Botswana</option>
          <option value="BR">Brasil</option>
          <option value="BN">Brunei</option>
          <option value="BG">Bulgaria</option>
          <option value="BF">Burkina Faso</option>
          <option value="BI">Burundi</option>
          <option value="BT">Bután</option>
          <option value="CV">Cabo Verde</option>
          <option value="KH">Camboya</option>
          <option value="CM">Camerún</option>
          <option value="CA">Canadá</option>
          <option value="TD">Chad</option>
          <option value="CL">Chile</option>
          <option value="CN">China</option>
          <option value="CY">Chipre</option>
          <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
          <option value="CO">Colombia</option>
          <option value="KM">Comores</option>
          <option value="CG">Congo</option>
          <option value="CD">Congo, República Democrática del</option>
          <option value="KR">Corea</option>
          <option value="KP">Corea del Norte</option>
          <option value="CI">Costa de Marfíl</option>
          <option value="CR">Costa Rica</option>
          <option value="HR">Croacia (Hrvatska)</option>
          <option value="CU">Cuba</option>
          <option value="DK">Dinamarca</option>
          <option value="DJ">Djibouti</option>
          <option value="DM">Dominica</option>
          <option value="EC">Ecuador</option>
          <option value="EG">Egipto</option>
          <option value="SV">El Salvador</option>
          <option value="AE">Emiratos Árabes Unidos</option>
          <option value="ER">Eritrea</option>
          <option value="SI">Eslovenia</option>
          <option value="ES" selected>España</option>
          <option value="US">Estados Unidos</option>
          <option value="EE">Estonia</option>
          <option value="ET">Etiopía</option>
          <option value="FJ">Fiji</option>
          <option value="PH">Filipinas</option>
          <option value="FI">Finlandia</option>
          <option value="FR">Francia</option>
          <option value="GA">Gabón</option>
          <option value="GM">Gambia</option>
          <option value="GE">Georgia</option>
          <option value="GH">Ghana</option>
          <option value="GI">Gibraltar</option>
          <option value="GD">Granada</option>
          <option value="GR">Grecia</option>
          <option value="GL">Groenlandia</option>
          <option value="GP">Guadalupe</option>
          <option value="GU">Guam</option>
          <option value="GT">Guatemala</option>
          <option value="GY">Guayana</option>
          <option value="GF">Guayana Francesa</option>
          <option value="GN">Guinea</option>
          <option value="GQ">Guinea Ecuatorial</option>
          <option value="GW">Guinea-Bissau</option>
          <option value="HT">Haití</option>
          <option value="HN">Honduras</option>
          <option value="HU">Hungría</option>
          <option value="IN">India</option>
          <option value="ID">Indonesia</option>
          <option value="IQ">Irak</option>
          <option value="IR">Irán</option>
          <option value="IE">Irlanda</option>
          <option value="BV">Isla Bouvet</option>
          <option value="CX">Isla de Christmas</option>
          <option value="IS">Islandia</option>
          <option value="KY">Islas Caimán</option>
          <option value="CK">Islas Cook</option>
          <option value="CC">Islas de Cocos o Keeling</option>
          <option value="FO">Islas Faroe</option>
          <option value="HM">Islas Heard y McDonald</option>
          <option value="FK">Islas Malvinas</option>
          <option value="MP">Islas Marianas del Norte</option>
          <option value="MH">Islas Marshall</option>
          <option value="UM">Islas menores de Estados Unidos</option>
          <option value="PW">Islas Palau</option>
          <option value="SB">Islas Salomón</option>
          <option value="SJ">Islas Svalbard y Jan Mayen</option>
          <option value="TK">Islas Tokelau</option>
          <option value="TC">Islas Turks y Caicos</option>
          <option value="VI">Islas Vírgenes (EEUU)</option>
          <option value="VG">Islas Vírgenes (Reino Unido)</option>
          <option value="WF">Islas Wallis y Futuna</option>
          <option value="IL">Israel</option>
          <option value="IT">Italia</option>
          <option value="JM">Jamaica</option>
          <option value="JP">Japón</option>
          <option value="JO">Jordania</option>
          <option value="KZ">Kazajistán</option>
          <option value="KE">Kenia</option>
          <option value="KG">Kirguizistán</option>
          <option value="KI">Kiribati</option>
          <option value="KW">Kuwait</option>
          <option value="LA">Laos</option>
          <option value="LS">Lesotho</option>
          <option value="LV">Letonia</option>
          <option value="LB">Líbano</option>
          <option value="LR">Liberia</option>
          <option value="LY">Libia</option>
          <option value="LI">Liechtenstein</option>
          <option value="LT">Lituania</option>
          <option value="LU">Luxemburgo</option>
          <option value="MK">Macedonia, Ex-República Yugoslava de</option>
          <option value="MG">Madagascar</option>
          <option value="MY">Malasia</option>
          <option value="MW">Malawi</option>
          <option value="MV">Maldivas</option>
          <option value="ML">Malí</option>
          <option value="MT">Malta</option>
          <option value="MA">Marruecos</option>
          <option value="MQ">Martinica</option>
          <option value="MU">Mauricio</option>
          <option value="MR">Mauritania</option>
          <option value="YT">Mayotte</option>
          <option value="MX">México</option>
          <option value="FM">Micronesia</option>
          <option value="MD">Moldavia</option>
          <option value="MC">Mónaco</option>
          <option value="MN">Mongolia</option>
          <option value="MS">Montserrat</option>
          <option value="MZ">Mozambique</option>
          <option value="NA">Namibia</option>
          <option value="NR">Nauru</option>
          <option value="NP">Nepal</option>
          <option value="NI">Nicaragua</option>
          <option value="NE">Níger</option>
          <option value="NG">Nigeria</option>
          <option value="NU">Niue</option>
          <option value="NF">Norfolk</option>
          <option value="NO">Noruega</option>
          <option value="NC">Nueva Caledonia</option>
          <option value="NZ">Nueva Zelanda</option>
          <option value="OM">Omán</option>
          <option value="NL">Países Bajos</option>
          <option value="PA">Panamá</option>
          <option value="PG">Papúa Nueva Guinea</option>
          <option value="PK">Paquistán</option>
          <option value="PY">Paraguay</option>
          <option value="PE">Perú</option>
          <option value="PN">Pitcairn</option>
          <option value="PF">Polinesia Francesa</option>
          <option value="PL">Polonia</option>
          <option value="PT">Portugal</option>
          <option value="PR">Puerto Rico</option>
          <option value="QA">Qatar</option>
          <option value="UK">Reino Unido</option>
          <option value="CF">República Centroafricana</option>
          <option value="CZ">República Checa</option>
          <option value="ZA">República de Sudáfrica</option>
          <option value="DO">República Dominicana</option>
          <option value="SK">República Eslovaca</option>
          <option value="RE">Reunión</option>
          <option value="RW">Ruanda</option>
          <option value="RO">Rumania</option>
          <option value="RU">Rusia</option>
          <option value="EH">Sahara Occidental</option>
          <option value="KN">Saint Kitts y Nevis</option>
          <option value="WS">Samoa</option>
          <option value="AS">Samoa Americana</option>
          <option value="SM">San Marino</option>
          <option value="VC">San Vicente y Granadinas</option>
          <option value="SH">Santa Helena</option>
          <option value="LC">Santa Lucía</option>
          <option value="ST">Santo Tomé y Príncipe</option>
          <option value="SN">Senegal</option>
          <option value="SC">Seychelles</option>
          <option value="SL">Sierra Leona</option>
          <option value="SG">Singapur</option>
          <option value="SY">Siria</option>
          <option value="SO">Somalia</option>
          <option value="LK">Sri Lanka</option>
          <option value="PM">St Pierre y Miquelon</option>
          <option value="SZ">Suazilandia</option>
          <option value="SD">Sudán</option>
          <option value="SE">Suecia</option>
          <option value="CH">Suiza</option>
          <option value="SR">Surinam</option>
          <option value="TH">Tailandia</option>
          <option value="TW">Taiwán</option>
          <option value="TZ">Tanzania</option>
          <option value="TJ">Tayikistán</option>
          <option value="TF">Territorios franceses del Sur</option>
          <option value="TP">Timor Oriental</option>
          <option value="TG">Togo</option>
          <option value="TO">Tonga</option>
          <option value="TT">Trinidad y Tobago</option>
          <option value="TN">Túnez</option>
          <option value="TM">Turkmenistán</option>
          <option value="TR">Turquía</option>
          <option value="TV">Tuvalu</option>
          <option value="UA">Ucrania</option>
          <option value="UG">Uganda</option>
          <option value="UY">Uruguay</option>
          <option value="UZ">Uzbekistán</option>
          <option value="VU">Vanuatu</option>
          <option value="VE">Venezuela</option>
          <option value="VN">Vietnam</option>
          <option value="YE">Yemen</option>
          <option value="YU">Yugoslavia</option>
          <option value="ZM">Zambia</option>
          <option value="ZW">Zimbabue</option>
        </select>
        <label for="customer_edit_country" class="wabident-info"
          >Nacionalidad</label
        >
      </div>
    </div>
    <!-- Child Info -->
    <div v-if="!is_adult" class="form-group row">
      <div class="col-sm-3">
        <select
          v-model="form.adult_type"
          name="adult_type"
          id="customer_edit_adult_type"
          class="form-control custom-select"
        >
          <option
            v-for="(type, index) in adult_types"
            :value="index"
            :key="index"
          >
            {{ type }}
          </option>
        </select>
        <label for="customer_edit_adult_type" class="wabident-info"
          >Adulto a cargo</label
        >
      </div>
      <div class="col-sm-3">
        <input
          v-model="form.adult_nif"
          type="text"
          class="form-control"
          name="adult_nif"
          id="edit_customer_adult_nif"
        />
        <label for="edit_customer_adult_nif" class="wabident-info"
          >NIF Adulto</label
        >
      </div>
      <div class="col-sm-6">
        <input
          v-model="form.adult_name"
          type="text"
          class="form-control"
          name="name"
          id="edit_customer_adult_name"
        />
        <label for="edit_customer_adult_name" class="wabident-info"
          >Nombre del adulto</label
        >
      </div>
    </div>
    <!-- Address -->
    <div class="form-group row">
      <div class="col-sm-6">
        <input
          v-model="form.address"
          type="text"
          name="address"
          id="customer_edit_address"
          class="form-control"
        />
        <label for="customer_edit_address" class="wabident-info"
          >Dirección</label
        >
      </div>
      <div class="col-sm-3">
        <input
          v-model="form.city"
          type="text"
          name="city"
          id="customer_edit_city"
          class="form-control"
        />
        <label for="customer_edit_city" class="wabident-info">Población</label>
      </div>
      <div class="col-sm-3">
        <input
          v-model="form.zipcode"
          type="text"
          name="zipcode"
          id="customer_edit_zipcode"
          class="form-control"
        />
        <label for="customer_edit_zipcode" class="wabident-info"
          >Código postal</label
        >
      </div>
    </div>
    <!-- Notes -->
    <div class="form-group row">
      <div class="col-sm-12">
        <textarea
          v-model="form.notes"
          name="notes"
          id="customer_edit_notes"
          class="form-control"
        />
        <label for="customer_edit_notes" class="wabident-info"
          >Anotaciones</label
        >
      </div>
    </div>
    <!-- Reason -->
    <div class="form-group row">
      <div class="col-sm-12">
        <select
          v-model="form.reason"
          name="reason"
          id="customer_edit_reason"
          class="form-control custom-select"
        >
          <option
            v-for="(name, value) in customer_reasons"
            :value="value"
            :key="value"
          >
            {{ name }}
          </option>
        </select>
        <label for="customer_edit_reason" class="wabident-info"
          >¿Cómo nos conociste?</label
        >
      </div>
    </div>
    <!-- -->
    <div class="form-group row">
      <div class="col-sm-3">
        <div class="form-check form-check-inline">
          <input
            v-model="form.is_consent_signed"
            class="form-check-input"
            type="checkbox"
            id="customer_edit_signed_consent"
            value="true"
            name="is_consent_signed"
          />
          <label class="form-check-label" for="customer_edit_signed_consent"
            >Ley de protección de datos firmada</label
          >
        </div>
      </div>
      <div class="col-sm-3">
        <div class="form-check form-check-inline">
          <input
            v-model="form.is_incapacitated"
            class="form-check-input"
            type="checkbox"
            id="customer_edit_is_incapacitated"
            value="true"
            name="is_incapacitated"
          />
          <label class="form-check-label" for="customer_edit_is_incapacitated"
            >Incapacitado</label
          >
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col text-center">
        <button
          type="button"
          role="button"
          @click="onDelete"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px; border-radius: 26.8px"
        ></button>
        <button
          type="submit"
          role="button"
          class="btn btn-primary btn-wabident-round big"
          style="min-width: 316px"
          :disabled="is_updating"
        >
          <span class="btn-icon float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
            >
              <path
                d="M14.343.3a1.027 1.027 0 0 0-.726-.3h-1.489v5.873a.41.41 0 0 1-.41.41H3.058a.408.408 0 0 1-.408-.41V0H1.024A1.023 1.023 0 0 0 0 1.024v16.95A1.025 1.025 0 0 0 1.024 19h16.95A1.028 1.028 0 0 0 19 17.974V5.381a1.025 1.025 0 0 0-.302-.724L14.343.3zm1.967 16.342a.53.53 0 0 1-.532.53H3.22a.53.53 0 0 1-.532-.53V9.557a.53.53 0 0 1 .532-.53h12.56a.53.53 0 0 1 .531.53v7.085z"
              />
              <path d="M11.073 0H3.705v5.227h7.368V0z" />
              <path
                d="M13.31 13.875H5.69a.528.528 0 1 0 0 1.056h7.62a.528.528 0 1 0 0-1.056z"
              />
            </svg>
          </span>

          <span v-if="is_updating">Guardando ...</span>
          <span v-else>Guardar cambios</span>
          <i
            v-if="is_updating"
            class="fas fa-circle-notch fa-spin float-right"
            style="margin-top: 5px"
          />
        </button>
      </div>
    </div>
  </b-form>
</template>

<script>
export default {
  props: {
    customer: { required: true },
  },
  data() {
    return {
      form: {
        photo: null,
        name: "",
        history_number: "",
        created_at: "",
        birth_date: "",
        phone: "",
        phone_alt: "",
        email: "",
        nif: "",
        country: "",
        address: "",
        city: "",
        zipcode: "",
        notes: "",
        reason: "",
        is_consent_signed: false,
        is_incapacitated: false,
        adult_type: "other",
        adult_name: "",
        adult_nif: "",
      },
      photo_data: null,
      customer_fill_url: "",
      is_updating: false,
    };
  },
  computed: {
    customer_age() {
      var created_at = moment(this.form.created_at);
      var birth = moment(this.form.birth_date);
      return created_at.diff(birth, "years");
    },
    is_adult() {
      return !this.is_incapacitated && this.customer_age >= 18;
    },
    adult_type() {
      return WabiDent.AdultType[this.customer.adult_type];
    },
    adult_types() {
      return WabiDent.AdultType;
    },
    photo_style() {
      let bgdata = "url('" + this.customer.photo + "')";
      if (this.photo_data) {
        bgdata = "url('" + this.photo_data + "')";
      }
      return {
        "background-image": bgdata,
      };
    },
    qr_fill_enabled() {
      if(!this.clinic) return false;

      return this.custom_history_enabled || this.clinic.type == "dental";
    },
    custom_history_enabled() {
      return this.clinic && this.clinic.config["custom_customer_history"];
    },
    customer_reasons() {
      if (!this.clinic) return {};
      return this.clinic.customer_reasons;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    onSubmit(ev) {
      ev.preventDefault();
      if (!this.is_updating) {
        this.is_updating = true;

        let formData = new FormData();
        for (let field in this.form) {
          formData.append(field, this.form[field]);
        }

        axios
          .post(`/customer/${this.customer.id}/edit`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.$emit("customerUpdated", response.data.customer);
            this.msgwindow.addMessage("success", "Paciente guardado con éxito");
          })
          .catch((error) => {
            console.log("[ERR] customerUpdated: ", error);
          })
          .then(() => {
            this.is_updating = false;
          });
      }
    },
    onDelete() {
      if (!this.is_updating) {
        this.errorwindow.show(
          "Confirmación",
          "<p>¿Desea borrar este paciente?</p><p><strong>ATENCIÓN: Si borra el Paciente, perderá su historial, facturación y pagos.</strong></p><p>Haga una copia de sus facturas y recibos</p>",
          "Borrar",
          "Cancelar",
          this.onDeleteConfirm,
          null
        );
      }
    },
    onDeleteConfirm() {
      if (!this.is_updating) {
        this.is_updating = true;
        axios
          .post(`/customer/${this.customer.id}/edit`, { _method: "delete" })
          .then((response) => {
            this.msgwindow.addMessage("success", "Paciente borrado con éxito");
            window.setTimeout(() => {
              window.location.href = "/customer";
            }, 1000);
          })
          .catch((error) => {
            this.errorwindow.show("Error", "Error borrando cliente");
            this.is_updating = false;
          });
      }
    },
    selectFile() {
      this.$refs.photofile.click();
    },
    onFileChanged() {
      console.log("onFilechanged");
      let files = this.$refs.photofile.files;
      if (files.length) {
        this.form.photo = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
          this.photo_data = e.target.result;
        };
        reader.readAsDataURL(this.form.photo);
      } else {
        this.form.photo = null;
        this.photo_data = null;
      }
    },
    showFillDialog() {
      if (!this.is_updating) {
        this.is_updating = true;
        axios
          .get(`/customer/${this.customer.id}/fillkey`)
          .then((response) => {
            let url = response.data.url;
            if (url) {
              this.customer_fill_url = url;
              this.$refs.filldialog.show();
            } else {
              this.msgwindow.error(
                "Error rellenando datos: " + (response.data.error || "")
              );
            }
          })
          .catch((error) => {
            console.log("[ERR] showFillDialog", error);
            this.msgwindow.error("Error rellenando  datos !");
          })
          .then(() => {
            this.is_updating = false;
          });
      }
    },
    onCloseFillDialog() {},
  },
  mounted() {
    this.form.name = this.customer.name || "";
    this.form.history_number = this.customer.history_number || "";
    this.form.created_at =
      moment(this.customer.created_at).toDate().toISODate() || "";
    this.form.birth_date = this.customer.birth_date || "";
    this.form.phone = this.customer.phone || "";
    this.form.phone_alt = this.customer.phone_alt || "";
    this.form.email = this.customer.email || "";
    this.form.nif = this.customer.nif || "";
    this.form.country = this.customer.country || "";
    this.form.address = this.customer.address || "";
    this.form.city = this.customer.city || "";
    this.form.zipcode = this.customer.zipcode || "";
    this.form.reason = this.customer.reason || "";
    this.form.notes = this.customer.notes || "";
    this.form.adult_type = this.customer.adult_type || "";
    this.form.adult_name = this.customer.adult_name || "";
    this.form.adult_nif = this.customer.adult_nif || "";
    this.form.is_consent_signed = this.customer.is_consent_signed || false;
    this.form.is_incapacitated = this.customer.is_incapacitated || false;
    this.is_updating = false;
  },
};
</script>

<style lang="scss" scoped>
.customer-edit-data {
  margin-top: 25px;

  .customer-photo-file {
    display: none !important;
  }

  .customer-photo-edit {
    cursor: pointer;
    position: relative;
    background-color: var(--theme-grey-bg);
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-size: cover;
    background-position: center center;
    overflow: hidden;

    svg {
      color: white;
      opacity: 1;
      z-index: 2;
      fill: currentColor;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--theme-color);
      opacity: 0.5;
      z-index: 1;
    }
  }
}
</style>
