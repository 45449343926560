<template>
  <div class="customer-edit-treatments">
    <customer-show-treatment
      ref="showtreatment"
      :customer="customer"
      :clinic="clinic"
      @submit="updateTreatments"
    ></customer-show-treatment>
    <customer-show-quotation
      ref="showquotation"
      :customer="customer"
      :clinic="clinic"
      @submit="updateTreatments"
    ></customer-show-quotation>
    <customer-show-history-entry
      ref="showentry"
      :customer="customer"
      :readonly="!can_edit_history"
      @submit="updateHistoryEntries"
    ></customer-show-history-entry>
    <customer-show-payment
      ref="showpayment"
      :customer="customer"
      @submit="updateTreatments"
    ></customer-show-payment>

    <!-- Odontgram View -->
    <div v-if="is_dental">
      <!-- Odontogram control -->
      <div class="row" style="margin-top: 16px">
        <div class="col-sm-12 text-center">
          <div class="wabident-switcher float-right">
            <input
              @click="switchTeeth"
              id="customer_show_child_teeth"
              type="checkbox"
              :checked="show_child_teeth"
            />
            <label for="customer_show_child_teeth">Niños</label>
          </div>
          <span v-b-toggle.teeth_main class="hide-odontogram"
            >Ocultar Odontograma</span
          >
        </div>
      </div>
      <!-- Odontogram view -->
      <div class="row edit-odontogram-teeth">
        <b-collapse id="teeth_main" class="col teeth-main" visible>
          <div class="teeth-row">
            <template v-for="t in teeth_quadrants[0]">
              <odontogram-tooth
                :key="t"
                @selected="toothSelected"
                @treatmentClicked="editTreatment"
                :code="t"
                :selected="selected_teeth[t]"
                :treatments="teeth_treatments[t]"
              ></odontogram-tooth>
            </template>
            <template v-for="t in teeth_quadrants[1]">
              <odontogram-tooth
                :key="t"
                @selected="toothSelected"
                @treatmentClicked="editTreatment"
                :code="t"
                :selected="selected_teeth[t]"
                :treatments="teeth_treatments[t]"
              ></odontogram-tooth>
            </template>
          </div>
          <div class="teeth-row bottom">
            <template v-for="t in teeth_quadrants[3]">
              <odontogram-tooth
                :key="t"
                @selected="toothSelected"
                @treatmentClicked="editTreatment"
                :code="t"
                :selected="selected_teeth[t]"
                :treatments="teeth_treatments[t]"
                :bottom="true"
              ></odontogram-tooth>
            </template>
            <template v-for="t in teeth_quadrants[2]">
              <odontogram-tooth
                :key="t"
                @selected="toothSelected"
                @treatmentClicked="editTreatment"
                :code="t"
                :selected="selected_teeth[t]"
                :treatments="teeth_treatments[t]"
                :bottom="true"
              ></odontogram-tooth>
            </template>
          </div>
        </b-collapse>
      </div>
    </div>
    <div v-else class="my-4"></div>
    <!-- End Odontogram View -->

    <div class="row history-selector">
      <div class="col">
        <div
          class="history-selector__tab"
          :class="{ active: current_tab == 'treatments' }"
          @click="current_tab = 'treatments'"
        >
          <span class="history-selector__title"> Tratamientos </span>
          <transition name="fade">
            <button
              v-if="current_tab == 'treatments'"
              type="button"
              @click="addTreatment"
              class="btn btn-primary btn-wabident-round float-right"
            >
              Añadir tratamiento
            </button>
          </transition>
        </div>
      </div>

      <div class="col">
        <div
          class="history-selector__tab"
          :class="{ active: current_tab == 'history' }"
          @click="current_tab = 'history'"
        >
          <span class="history-selector__title">Historia Clínica</span>
          <transition name="fade">
            <button
              v-if="current_tab == 'history' && can_edit_history"
              type="button"
              @click="addHistory"
              class="btn btn-primary btn-wabident-round float-right"
            >
              Añadir historia
            </button>
          </transition>
        </div>
      </div>
    </div>

    <!-- Treatments view -->
    <div
      v-if="current_tab == 'treatments'"
      key="treatments"
      class="row edit-odontogram-treatments"
    >
      <div class="col-sm-12 d-flex align-items-center">
        <div class="form-inline mt-3 mr-3" >
          <label class="mr-3">Filtro:</label>
          <select
            v-model="treatments_filter"
            class="custom-select wabident-xsmall"
          >
            <option :value="null">Todos</option>
            <option value="pending">Pendientes</option>
            <option value="done">Realizados</option>
            <option value="done_prior">Realizado otros</option>
            <option value="paid">Pagados</option>
            <option value="not_paid">Sin pagar</option>
            <option value="unquoted">Sin presupuestar</option>
            <option value="quoted">Presupuestados</option>
            <option value="approved">Aprobados</option>
          </select>
        </div>

        <div class="form-inline mt-3">
          <label class="mr-3">Ordenar:</label>
          <select
            v-model="treatments_order"
            class="custom-select wabident-xsmall"
          >
            <option value="desc">Recientes</option>
            <option value="asc">Antiguos</option>
          </select>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="treatment-list">
          <template v-for="(treatment, index) in filtered_treatments">
            <treatment-item
              :key="index"
              :treatment="treatment"
              :clinics="clinics"
              :selected="isTreatmentSelected(treatment)"
              :clinic_type="clinic_type"
              @edit="editTreatment"
              @select="selectedTreatment"
              @done="doneTreatment"
              @paid="paidTreatment"
            ></treatment-item>
          </template>
        </div>
      </div>
      <div
        class="
          col-sm-12
          text-center
          d-flex
          flex-row
          justify-content-between
          align-items-center
        "
      >
        <button
          :disabled="!can_delete"
          @click="onDeleteTreatments"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>

        <div class="flex-spacer"></div>
        <button
          @click="addQuotation"
          type="button"
          role="button"
          class="btn btn-primary btn-wabident-round big"
          style="min-width: 316px; margin: 30px 0 30px 0"
        >
          <div class="btn-icon float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                d="M7 0a.757.757 0 0 0-.757.757v5.486H.757a.757.757 0 1 0 0 1.514h5.486v5.486a.757.757 0 1 0 1.514 0V7.757h5.486a.757.757 0 1 0 0-1.514H7.757V.757A.757.757 0 0 0 7 0z"
              />
            </svg>
          </div>
          <span>Crear presupuesto</span>
        </button>
        <div class="flex-spacer"></div>
      </div>
    </div>
    <!-- End Treatments View -->

    <!-- Clinic History View view -->
    <div
      v-if="current_tab == 'history'"
      key="history"
      class="row edit-odontogram-histories"
    >
      <div class="col-sm-12 d-flex align-items-center">
        <div class="form-inline mt-3">
          <label class="mr-3">Ordenar:</label>
          <select
            v-model="treatments_order"
            class="custom-select wabident-xsmall"
          >
            <option value="desc">Recientes</option>
            <option value="asc">Antiguos</option>
          </select>
        </div>
      </div>

      <div class="col-sm-12">
        <div class="history-list">
          <template v-for="(history, index) in filtered_history_entries">
            <odontogram-history-entry
              :key="index"
              :history="history"
              :clinics="clinics"
              @edit="editHistory"
              :readonly="!can_edit_history"
            ></odontogram-history-entry>
          </template>
        </div>
      </div>
      <div class="col-sm-12 text-center">
        <button
          @click="printHistory"
          type="button"
          role="button"
          class="btn btn-primary btn-wabident-round big"
          style="min-width: 316px; margin: 30px 0 30px 0"
        >
          <div class="btn-icon float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="23"
              fill="none"
              viewBox="0 0 24 23"
            >
              <path
                fill="white"
                d="M5.053 3.79h13.894V1.262H5.053V3.79zm-1.264 0V.631c0-.35.283-.632.632-.632H19.58c.349 0 .631.283.631.632v3.157h3.158c.35 0 .632.283.632.632v8.842a.632.632 0 0 1-.632.632h-3.157v3.158a.631.631 0 0 1-.185.446l-5.053 5.053a.632.632 0 0 1-.447.185H4.421a.632.632 0 0 1-.632-.632v-8.21H.632A.632.632 0 0 1 0 13.263V4.421c0-.349.283-.632.632-.632h3.157zm0 8.842V12c0-.349.283-.632.632-.632H19.58c.349 0 .631.283.631.632v.632h2.527v-7.58H1.263v7.58H3.79zm11.369 7.949l2.896-2.897h-2.896v2.896zm-1.263.893v-4.421c0-.35.283-.632.631-.632h4.421v-3.79H5.053v8.843h8.842zm-6.948-6.316a.632.632 0 0 1 0-1.263h8.843a.632.632 0 0 1 0 1.263H6.947zm0 2.526a.632.632 0 0 1 0-1.263h3.79a.631.631 0 1 1 0 1.263h-3.79zm0 2.526a.632.632 0 1 1 0-1.263h3.79a.631.631 0 1 1 0 1.264h-3.79zM4.421 7.58a.632.632 0 0 1 0-1.263h2.526a.632.632 0 0 1 0 1.263H4.421z"
              />
            </svg>
          </div>
          <span>Imprimir historia clínica completa</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer", "age"],
  data() {
    return {
      is_loading: false,
      show_odontogram: true,
      show_child_teeth: false,
      adult_teeth_quadrants: [
        // Quadrant 0
        [18, 17, 16, 15, 14, 13, 12, 11],
        // Quadrant 1
        [21, 22, 23, 24, 25, 26, 27, 28],
        // Quadrant 2
        [31, 32, 33, 34, 35, 36, 37, 38],
        // Quadrant 3
        [48, 47, 46, 45, 44, 43, 42, 41],
      ],
      child_teeth_quadrants: [
        // Quadrant 0
        [55, 54, 53, 52, 51],
        // Quadrant 1
        [61, 62, 63, 64, 65],
        // Quadrant 2
        [71, 72, 73, 74, 75],
        // Quadrant 3
        [85, 84, 83, 82, 81],
      ],
      selected_teeth: {},
      treatments: [],
      treatments_filter: null,
      treatments_order: "desc",
      selected_treatments: [],
      history_entries: [],
      current_tab: "treatments",
      invoices: [],
    };
  },
  computed: {
    is_dental() {
      return this.clinic_type == "dental";
    },
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names || [];
    },
    can_edit_history() {
      if (!this.clinic) return false;
      return this.clinic.capabilities["edit-history-entries"] || false;
    },
    can_delete() {
      return (
        !this.is_loading &&
        this.selected_treatments &&
        this.selected_treatments.length > 0
      );
    },
    teeth_quadrants() {
      return this.show_child_teeth
        ? this.child_teeth_quadrants
        : this.adult_teeth_quadrants;
    },
    available_treatments() {
      return this.treatments.filter((t) => {
        return !(Boolean(t.quotation_id) || t.status == "done_prior");
      });
    },
    filtered_history_entries() {
      return this.history_entries.sort((a, b) => {
        if (this.treatments_order == "desc") {
          return a.created_at < b.created_at;
        } else {
          return a.created_at > b.created_at;
        }
      });
    },
    filtered_treatments() {
      let treatments = this.treatments
        .filter((t) => {
          switch (this.treatments_filter) {
            case "pending":
              return t.status == "pending";
            case "done":
              return t.status == "done";
            case "done_prior":
              return t.status == "done_prior";
            case "paid":
              return t.status != "done_prior" && t.is_paid == true;
            case "not_paid":
              return t.status != "done_prior" && t.is_paid == false;
            case "quoted":
              return Boolean(t.quotation_id);
            case "unquoted":
              return !Boolean(t.quotation_id);
            case "approved":
              return t.is_approved;
            default:
              return true;
          }
        })
        .sort((a, b) => {
          if (this.treatments_order == "desc") {
            return a.created_at < b.created_at;
          } else {
            return a.created_at > b.created_at;
          }
        });

      return treatments;
    },
    quoted_treatments() {
      let treatments = this.treatments
        .filter((t) => {
          if (!t.is_approved) return false;

          switch (this.treatments_filter) {
            case "pending":
              return t.status == "pending";
              break;
            case "done":
              return t.status == "done";
              break;
            case "done_prior":
              return t.status == "done_prior";
              break;
            case "paid":
              return t.status != "done_prior" && t.is_paid == true;
              break;
            case "not_paid":
              return t.status != "done_prior" && t.is_paid == false;
              break;
            default:
              return true;
          }
        })
        .sort((a, b) => {
          if (this.treatments_order == "desc") {
            return a.created_at < b.created_at;
          } else {
            return a.created_at > b.created_at;
          }
        });

      return treatments;
    },
    teeth_treatments() {
      let ret = {};
      if (this.treatments) {
        this.treatments.forEach((treatment) => {
          treatment.teeth.forEach((tooth) => {
            ret[tooth] = ret[tooth] || [];
            ret[tooth].push(treatment);
          });
        });
      }
      return ret;
    },
    done_warning() {
      if (this.clinic && this.clinic.type == "dental") {
        return "¡El tratamiento viene hecho de otra clínica!";
      } else return "¡El tratamiento es una revisión!";
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    isTreatmentSelected(treatment) {
      return Boolean(
        this.selected_treatments.find((t) => {
          return t.id == treatment.id;
        })
      );
    },
    toothSelected(code) {
      this.$set(
        this.selected_teeth,
        code,
        !(this.selected_teeth[code] || false)
      );
    },
    clearSelection() {
      this.selected_teeth = {};
    },
    selectedTreatment(treatment, selected) {
      if (selected) {
        this.selected_treatments.push(treatment);
      } else {
        this.selected_treatments = this.selected_treatments.filter((item) => {
          return item.id != treatment.id;
        });
      }
    },
    doneTreatment(treatment) {
      if (this.is_loading) return;

      if (treatment.status == "done_prior" && this.clinic.type == "dental") {
        this.msgwindow.warning(this.done_warning);
        return;
      }

      this.is_loading = true;

      axios
        .post(`/customer/${this.customer.id}/treatment/${treatment.id}/done`)
        .then((response) => {
          this.msgwindow.success("Tratamiento actualizado");
        })
        .catch((error) => {
          console.log("[ERR] doneTreatment ", error);
          this.msgwindow.error("Error actualizando tratamiento ");
        })
        .then(() => {
          this.is_loading = false;
          this.updateTreatments();
        });
    },
    confirmPaidTreatment(treatment) {
      this.msgwindow.info("Buscando precio del tratamiento ...");
      axios
        .get(`/customer/${this.customer.id}/treatment/${treatment.id}/price`)
        .then((response) => {
          let price = response.data.price;
          this.invoices = response.data.invoices;
          this.$refs.showpayment.showNewTreatment(
            treatment,
            price,
            response.data.invoices
          );
        })
        .catch((error) => {
          console.log("[ERR] confirmPaidTreatment ", error);
          this.msgwindow.error("Error actualizando tratamiento ");
        })
        .then(() => {
          this.is_loading = false;
        });
    },
    paidTreatment(treatment) {
      if (this.is_loading) return;

      if (treatment.status == "done_prior") {
        this.msgwindow.warning(this.done_warning);
        return;
      }

      if (!treatment.quotation_id) {
        this.msgwindow.warning("¡ El tratamiento no se ha presupuestado !");
        return;
      }

      if (treatment.is_paid) {
        this.msgwindow.info("El tratamiento ya está pagado");
        return;
      }

      this.is_loading = true;
      this.confirmPaidTreatment(treatment);
    },
    switchTeeth() {
      this.clearSelection();
      this.show_child_teeth = !this.show_child_teeth;
    },
    addTreatment() {
      this.$refs.showtreatment.showNew(this.selected_teeth);
    },
    editTreatment(treatment) {
      this.$refs.showtreatment.showEdit(treatment);
    },
    addQuotation() {
      if (this.available_treatments.length <= 0) {
        this.errorwindow.show(
          "Error",
          "No hay tratamientos sin presupuestar. Por favor cree al menos uno primero"
        );
        return;
      }

      this.$refs.showquotation.showNew(
        this.selected_treatments,
        this.available_treatments
      );
    },
    updateTreatments() {
      axios
        .get(`/customer/${this.customer.id}/treatment`)
        .then((response) => {
          this.treatments = response.data;
          this.selected_treatments = [];
          this.selected_teeth = {};
        })
        .catch((error) => {
          this.msgwindow.error("Error cargando tratamientos");
        });
    },
    updateHistoryEntries() {
      axios
        .get(`/customer/${this.customer.id}/history_entries`)
        .then((response) => {
          this.$set(this, "history_entries", response.data);
        })
        .catch((error) => {
          this.msgwindow.error("Error cargando historia clínica");
        });
    },
    addHistory() {
      this.$refs.showentry.showNew();
    },
    editHistory(entry) {
      this.$refs.showentry.showEdit(entry);
    },
    printHistory() {
      window.open(`/customer/${this.customer.id}/history_pdf`, "_blank");
    },
    onDeleteTreatments() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea borrar todos estos tratamientos?",
        "Borrar",
        "Cancelar",
        () => {
          if (!this.is_loading) {
            this.is_loading = true;
            axios
              .post(`/customer/${this.customer.id}/delete_treatments`, {
                treatments: this.selected_treatments.map((x) => x.id),
              })
              .then((response) => {
                this.msgwindow.success("Tratamientos borrados");
                this.selected_treatments = [];
                this.updateTreatments();
              })
              .catch((err) => {
                console.log("[ERR] deleteTreatments", err);
                this.msgwindow.error("Error borrando tratamientos!");
                this.updateTreatments();
              })
              .then(() => {
                this.is_loading = false;
              });
          }
        }
      );
    },
  },
  mounted() {
    this.show_child_teeth = this.age < 12;
    this.updateTreatments();
    this.updateHistoryEntries();
  },
};
</script>

<style lang="scss" scoped>
.customer-edit-treatments {
  .hide-odontogram {
    cursor: pointer;
    font-size: 10px;
    color: var(--theme-grey);
    text-transform: uppercase;
  }

  .edit-odontogram-teeth {
    .teeth-main {
      position: relative;
      &::before,
      &::after {
        content: "";
        background-color: var(--theme-highlight);
        display: inline-block;
        position: absolute;
        pointer-events: none;
      }

      &::before {
        width: calc(100% - 30px);
        height: 1px;
        top: 50%;
        left: 0;
        z-index: -1;
        margin: 0 15px 0 15px;
      }

      &::after {
        width: 1px;
        height: calc(100% - 30px);
        top: 0;
        left: 50%;
        z-index: -1;
        margin: 15px 0 15px 0;
      }

      .teeth-row {
        margin: 21px 0 21px 0;
        width: 100%;
        display: flex;
        flex-direction: row nowrap;
        align-items: center;
        justify-content: center;
        position: relative;

        &::before,
        &::after {
          position: absolute;
          left: 0;
          width: 100%;
          height: 80px;
          border: 2px var(--theme-grey-disabled) dashed;
          pointer-events: none;
          display: block;
          content: "";
          z-index: 2;
        }

        &:before {
          top: 20px;
        }

        &:after {
          bottom: 0;
        }

        &.bottom:before {
          top: 0;
        }

        &.bottom:after {
          bottom: 20px;
        }
      }
    }
  }

  .edit-odontogram-treatments .treatment-list,
  .edit-odontogram-histories .history-list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .history-selector {
    &__tab {
      cursor: pointer;
      border-bottom: 2px transparent solid;
      transition: all 0.2s;
      padding: 3px 0;

      .history-selector__title {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.36px;
        color: var(--theme-bg-color);
        padding-left: 24px;
        transition: color 0.2s;
        line-height: 35px;
      }

      &.active {
        border-bottom: 2px var(--theme-color) solid;
        .history-selector__title {
          color: var(--theme-color);
        }
      }
    }
  }
}
</style>
