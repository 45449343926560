<template>
  <div class="customer-edit-appointments">
    <agenda-show-appointment
      @onSubmit="loadAppointments"
      ref="showapp"
      locked="true"
    ></agenda-show-appointment>
    <div class="row customer-edit-appointments__header">
      <div
        class="
          col
          text-center
          d-flex
          justify-content-between
          align-items-center
        "
      >
        <button
          @click="newAppointment"
          type="button"
          class="btn btn-primary btn-wabident"
        >
          Nueva Cita <i class="fa fa-plus ml-3"></i>
        </button>
        <button
          @click="sendPending"
          type="button"
          class="btn btn-primary btn-wabident"
        >
          Enviar Pendientes <i class="fab fa-whatsapp ml-3"></i>
        </button>
      </div>
    </div>
    <div class="customer-edit-appointments-list">
      <div v-for="(appoint, index) in appointments" :key="index" class="row">
        <div class="col">
          <div :class="appointmentClass(appoint)">
            <button
              type="button"
              class="btn btn-wabident-status float-right"
              :disabled="!isCurrentClinic(appoint)"
              @click="editAppointment(appoint)"
              :class="appointmentBtnClass(appoint)"
              style="margin-top: 10px; min-width: 136px"
            >
              {{ appointmentBtnText(appoint) }}
            </button>
            <label>{{ appointmentDate(appoint) }}</label>
            <label>{{ appointmentTime(appoint) }}</label>
            <label>{{ appointmentClinic(appoint) }}</label>
            <p>{{ appoint.notes }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: { required: true },
  },
  data() {
    return {
      appointments: [],
      clinics: [],
      box_to_clinic: {},
      is_loading: false,
    };
  },
  computed: {
    pending_msg_header() {
      if (!this.clinic) return "";

      if (this.clinic.config["custom_pending_message_header"]) {
        return this.clinic.config["custom_pending_message_header"];
      }

      return `Recordatorio de citas programadas en *${this.clinic.name}*:\n`;
    },
    pending_msg_footer() {
      if (!this.clinic) return "";

      if (this.clinic.config["custom_pending_message_footer"]) {
        return this.clinic.config["custom_pending_message_footer"];
      }

      return "\nReciba un cordial saludo.";
    },
    ...mapGetters(["errorwindow", "msgwindow", "clinic"]),
  },
  methods: {
    isCurrentClinic(app) {
      if (!this.clinic) return false;
      let box = this.clinic.boxes.find((x) => x.id == app.box_id);
      return Boolean(box);
    },
    appointmentClass(appoint) {
      let ret = {
        "customer-edit-appointment": true,
      };

      let status = appoint.status;
      if (!status || status.length == 0) status = "pending";

      ret[status] = true;
      ret["other-clinic"] = !this.isCurrentClinic(appoint);
      return ret;
    },
    appointmentDate(app) {
      return moment(app.date_from).toDate().toLocaleDateString();
    },
    appointmentTime(app) {
      return moment(app.date_from).format("HH:mm");
    },
    appointmentClinic(app) {
      let clinic = this.box_to_clinic[app.box_id];
      if (!clinic) return "Sin clínica!";
      return clinic.name;
    },
    appointmentBtnClass(app) {
      let ret = {};
      let status = app.status;
      if (!status || status.length == 0) status = "pending";
      ret[status] = true;
      return ret;
    },
    appointmentBtnText(app) {
      var now = moment();
      var then = moment(app.date_from);
      var diff = then.diff(now, "days");

      if (diff >= 0) {
        return `FALTAN ${diff} DÍAS`;
      }

      let status = app.status;
      if (!status || status.length == 0) status = "pending";

      return WabiDent.AppointmentStatus[status];
    },
    mapBoxesToClinics(clinics) {
      let ret = {};
      clinics.forEach((clinic) => {
        clinic.boxes.forEach((box) => {
          ret[box.id] = clinic;
        });
      });
      return ret;
    },
    loadAppointments() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .get(`/customer/${this.customer.id}/appointments`)
          .then((response) => {
            this.appointments = response.data.appointments;
            this.clinics = response.data.clinics;
            this.box_to_clinic = this.mapBoxesToClinics(this.clinics);
          })
          .catch((error) => {
            console.log("[ERR] loadAppointments ", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    newAppointment() {
      this.$refs.showapp.showCustomerNew(this.customer);
    },
    getAppointmentBox(app) {
      let clinic = this.box_to_clinic[app.box_id];
      if (!clinic) return null;
      let found = clinic.boxes.filter((box) => {
        return box.id == app.box_id;
      });
      console.log(found);
      return found.length > 0 ? found[0] : null;
    },
    editAppointment(app) {
      if (!this.isCurrentClinic(app)) return;

      app.customer = this.customer;
      this.$refs.showapp.showEdit(
        app,
        undefined,
        undefined,
        this.getAppointmentBox(app)
      );
    },
    sendPending() {
      let lines = [];
      lines.push(this.pending_msg_header);
      this.appointments.forEach((app) => {
        if (app.status == "pending") {
          let line = moment(app.date_from).format(
            "[El] dddd DD/MM/YYYY [a las] HH:mm"
          );
          lines.push(line);
        }
      });

      lines.push(this.pending_msg_footer);
      let message = lines.join("\n");

      let number = String(this.customer.phone)
        .replace(" ", "")
        .replace("-", "")
        .replace("+", "")
        .replace("(", "")
        .replace(")", "");

      let text = encodeURIComponent(message);

      let url = `https://wa.me/34${number}?text=${text}`;
      if (this.clinic.config.use_wa_app) {
        url = `whatsapp://send?phone=34${number}&text=${text}`;
      }

      window.open(url, "_blank");
    },
  },
  mounted() {
    this.loadAppointments();
  },
};
</script>

<style lang="scss" scoped>
.customer-edit-appointments {
  margin-top: 19px;

  &__header {
    margin-bottom: 30px;
  }

  .customer-edit-appointments-list {
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
    .customer-edit-appointment {
      border: 2px var(--theme-grey-disabled) solid;
      border-radius: 5px;
      padding: 19px;
      margin-bottom: 10px;

      label {
        color: var(--theme-grey);
        font-size: 15px;
        font-weight: 500;
      }

      label + label {
        margin-left: 15px;
      }

      p {
        margin-bottom: 0;
        min-height: 24px;
      }

      &.pending {
        background-color: var(--theme-grey-bg);
      }
    }
  }
}
</style>
