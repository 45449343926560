<template>
  <div class="container-fluid wabident-clinic-main">
    <b-tabs class="clinic-main-tabs" justified lazy>
      <b-tab index="products" title="Tratamientos">
        <clinic-products></clinic-products>
      </b-tab>
      <b-tab index="schedule" title="Horario">
        <clinic-schedule></clinic-schedule>
      </b-tab>
      <b-tab v-if="userCan('admin')" index="users" title="Personal">
        <clinic-users></clinic-users>
      </b-tab>
      <b-tab
        v-if="userCan('admin') && enable_doctor_availability"
        index="availability"
        title="Disponibles"
      >
        <clinic-availability></clinic-availability>
      </b-tab>
      <b-tab
        v-if="userCan('edit-templates')"
        index="templates"
        title="Plantillas"
      >
        <clinic-templates></clinic-templates>
      </b-tab>
      <b-tab
        v-if="userCan('edit-templates')"
        index="log"
        title="Registro"
      >
        <clinic-log></clinic-log>
      </b-tab>
 
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: ["clinic_id"],
  mounted() {
    this.loadClinicData();
  },
  computed: {
    enable_doctor_availability() {
      if (!this.clinic) return false;
      if (!this.clinic.config) return false;
      return this.clinic.config.enable_doctor_availability || false;
    },
    ...mapGetters(["clinic"]),
  },
  methods: {
    userCan(capability) {
      if (!this.clinic) return false;
      return this.clinic.capabilities[capability] || false;
    },
    loadClinicData(clinic_id) {
      axios
        .get("/agenda/clinic_info", {
          params: {
            clinic_id: clinic_id,
          },
        })
        .then((response) => {
          let data = response.data;
          if (data.error) {
            console.log("[ERR] loadClinicData:", data.error);
          } else {
            this.setClinic(data);
          }
        })
        .catch((error) => {
          console.log("[ERR] loadClinicData:", error);
        });
    },
    ...mapMutations(["setClinic"]),
  },
};
</script>

<style lang="scss">
.clinic-main-tabs {
  position: relative;
  height: 53px;

  & > div:not(.tab-content) {
    background-color: var(--theme-grey-bg);
  }

  .nav-tabs {
    max-width: 1140px;
    margin: auto;

    .nav-link {
      font-size: 14px;
    }

    &::before,
    &::after {
      position: absolute;
      background-color: var(--theme-grey-bg);
      top: 0;
      left: -99em;
      width: 99em;
      height: 52px;
      display: block;
      content: "";
    }

    &::before {
      left: -99em;
    }

    &::after {
      left: 100%;
    }
  }
}
</style>
