<template>
  <b-modal
    class="clinic-show-product"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="form-group row">
      <div class="col-sm-2">
        <input
          v-model="code"
          type="text"
          class="form-control"
          id="show_product_code"
          readonly
        />
        <label for="show_product_code" class="wabident-info">Código</label>
      </div>

      <div class="col-sm-8">
        <input
          placeholder="Nombre del tratamiento"
          v-model="name"
          type="text"
          id="show_product_name"
          class="form-control"
          required
        />
        <label class="wabident-info" for="show_product_name"
          >Nombre del tratamiento</label
        >
      </div>
      <div class="col-sm-2">
        <select
          v-model="disabled"
          name="icon"
          id="show_product_disabled"
          class="custom-select"
        >
          <option :value="0">Activo</option>
          <option :value="1">Inactivo</option>
        </select>
        <label class="wabident-info" for="show_product_disabled">Estado</label>
      </div>
    </div>
    <div class="form-group row">
      <div v-if="clinic_type == 'dental'" class="col-sm-3">
        <select
          v-model="icon"
          name="icon"
          id="show_product_icon"
          class="custom-select"
        >
          <option value>Sin icono</option>
          <option
            v-for="(icon, index) in product_icons"
            :key="index"
            :value="index"
          >
            {{ icon.name }}
          </option>
        </select>
        <label for="show_product_icon" class="wabident-info">Icono</label>
      </div>
      <div v-if="clinic_type == 'dental'" class="col-sm-4">
        <select
          v-model="position"
          name="position"
          id="show_product_position"
          class="custom-select"
        >
          <option value>Sin definir</option>
          <option value="up">Oclusal</option>
          <option value="inside">Palatino/Lingual</option>
          <option value="outside">Vestibular</option>
          <option value="both">Ambos</option>
          <option value="inside_root">Raíz Palatino/Lingual</option>
          <option value="outside_root">Raíz Vestibular</option>
          <option value="both_root">Raíz Ambos</option>
        </select>
        <label for="show_product_position" class="wabident-info"
          >Posición icono</label
        >
      </div>
      <div class="col">
        <input
          v-model="price"
          id="show_product_price"
          type="number"
          class="form-control"
        />
        <label for="show_product_price" class="wabident-info">Precio</label>
      </div>

      <div class="col">
        <select
          name="vat"
          id="show_vat"
          v-model="vat"
          class="custom-select"
          required
        >
          <option :value="null">Elige</option>
          <option :value="0">Exento</option>
          <option :value="21">21%</option>
          <option :value="10">10%</option>
          <option :value="4">4%</option>
        </select>
        <label class="wabident-info" for="show_vat">IVA Aplicado</label>
      </div>
    </div>

    <div class="form-group row">
      <div class="col">
        <select
          name="type"
          id="show_type"
          v-model="type"
          class="custom-select"
          required
        >
          <option :value="null">Elige</option>
          <option value="treatment">Tratamiento</option>
          <option value="product">Producto</option>
        </select>
        <label class="wabident-info" for="show_type">Tipo</label>
      </div>
      <div class="col">
        <select
          name="type"
          id="show_type"
          v-model="category_id"
          class="custom-select"
          required
        >
          <option :value="null">Ninguna</option>
          <option v-for="(c, i) in categories" :key="i" :value="c.id">
            {{ c.name }}
          </option>
        </select>
        <label class="wabident-info" for="show_type"
          >Familia / Categoría / Unidad Médica</label
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          :disabled="is_loading"
          type="button"
          @click="onSubmit"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_loading">Guardando ...</span>
          <span v-else-if="is_editing">Modificar Tratamiento</span>
          <span v-else>Crear Tratamiento</span>
          <i v-if="is_loading" class="fas fa-circle-notch float-right"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      code: "",
      icon: "",
      position: "",
      disabled: 0,
      price: 0,
      vat: 0,
      type: null,
      category_id: null,
      is_editing: false,
      is_loading: false,
      product: null,
      categories: [],
      product_icons: {
        absent: {
          name: "Ausente",
          icon: "icon-wabident-absent",
        },
        brackets: {
          name: "Brackets",
          icon: "icon-wabident-brackets",
        },
        cavity: {
          name: "Caries",
          icon: "icon-wabident-caries",
        },
        carilles: {
          name: "Carillas",
          icon: "icon-wabident-carillas",
        },
        corona: {
          name: "Corona",
          icon: "icon-wabident-corona",
        },
        endodoncy: {
          name: "Endodoncia",
          icon: "icon-wabident-endodoncia",
        },
        extraction: {
          name: "Extracción",
          icon: "icon-wabident-extraccion",
        },
        fracture: {
          name: "Fractura",
          icon: "icon-wabident-fractura",
        },
        implant: {
          name: "Implante",
          icon: "icon-wabident-implante",
        },
        prothesis: {
          name: "Prótesis",
          icon: "icon-wabident-protesis",
        },
        restore: {
          name: "Restauración",
          icon: "icon-wabident-restauracion",
        },
        multiple: {
          name: "Múltiple",
          icon: "icon-wabident-multiple",
        },
      },
    };
  },
  computed: {
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    dialog_title() {
      return this.is_editing ? "Modificar Tratamiento" : "Nuevo Tratamiento";
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  methods: {
    showNew() {
      this.is_editing = false;
      this.product = null;
      this.position = "";
      this.disabled = 0;
      this.name = "";
      this.icon = "";
      this.price = 0;
      this.vat = null;
      this.type = null;
      this.category_id = null;
      this.getNewProductCode();
      this.getCategories();
      this.$refs.modal.show();
    },
    showEdit(product) {
      this.is_editing = true;
      this.position = product.position || "";
      this.name = product.name || "";
      this.icon = product.icon || "";
      this.price = product.price || "";
      this.code = product.code;
      this.vat = Number.parseFloat(product.vat) || 0;
      this.type = product.type;
      this.disabled = product.disabled;
      this.product = product;
      this.category_id = product.product_category_id;
      this.getCategories();
      this.$refs.modal.show();
    },
    onSubmit() {
      if (this.is_editing) {
        this.modifyProduct();
      } else {
        this.createNewProduct();
      }
    },
    modifyProduct() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          name: this.name,
          code: this.code,
          position: this.position,
          icon: this.icon,
          price: this.price,
          disabled: this.disabled,
          vat: this.vat,
          type: this.type,
          product_category_id: this.category_id,
        };
        axios
          .post(`/product/${this.product.id}`, params)
          .then((response) => {
            this.$emit("submit", response.data);
            this.msgwindow.success("Tratamiento modificado con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] modifyProduct ", err);
            this.msgwindow.error("Error modificando tratamiento!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    createNewProduct() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          name: this.name,
          code: this.code,
          position: this.position,
          icon: this.icon,
          price: this.price,
          disabled: this.disabled,
          vat: this.vat,
          type: this.type,
          product_category_id: this.category_id,
        };
        axios
          .post("/product", params)
          .then((response) => {
            this.$emit("submit", response.data);
            this.msgwindow.success("Tratamiento creado con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] createNewProduct ", err);
            this.msgwindow.error("Error creando tratamiento!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    onDelete() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/product/${this.product.id}`, { _method: "delete" })
          .then((response) => {
            this.msgwindow.success("Tratamiento borrado con éxito");
            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] onDelete", err);
            this.msgwindow.error("Error borrando tratamiento");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    mounted() {},
    getCategories() {
      axios
        .get("/product/categories")
        .then((response) => {
          this.categories = response.data;
        })
        .catch((err) => {
          console.log("[ERR] getCategories", err);
          this.msgwindow.error("Error buscando categorías de productos");
        });
    },
    getNewProductCode() {
      axios
        .get("/product/code")
        .then((response) => {
          this.code = response.data;
        })
        .catch((err) => {
          console.log("[ERR] getNewProductcode", err);
          this.msgwindow.error("Error obteniendo el código de tratamiento");
        });
    },
  },
};
</script>

<style lang="scss">
</style>
