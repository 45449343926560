<template>
  <div class="container-fluid clinic-invoices">
    <div class="row">
      <div class="col-2">
        <div class="row" v-if="has_results_available">
          <div class="col-12 mb-2">
            <h1 class="page-title">RESUMEN</h1>
          </div>
          <!-- VAT Exploded -->
          <div
            class="col-12 vat-summary"
            v-for="(vat, index) in summary.by_vat"
            :key="index"
          >
            <h5>IVA {{ index }}%</h5>
            <ul>
              <li><b>Base:</b> {{ fmtMoney(vat.base) }}</li>
              <li><b>IVA:</b> {{ fmtMoney(vat.vat) }}</li>
              <li>
                <b>Total:</b>
                {{ fmtMoney(vat.total) }}
              </li>
            </ul>
          </div>
          <!-- VAT Summary -->
          <div class="col-12 vat-summary">
            <h5>Total</h5>
            <ul>
              <li><b>Base: </b> {{ fmtMoney(summary.total.base) }}</li>
              <li><b>IVA:</b> {{ fmtMoney(summary.total.vat) }}</li>
              <li>
                <b>Total:</b>
                {{ fmtMoney(summary.total.total) }}
              </li>
            </ul>
          </div>
          <!-- Payment Summary -->
          <div class="col-12 vat-summary">
            <h5>Pagos</h5>
            <ul>
              <li><b>Efectivo: </b> {{ fmtMoney(summary.by_payment.cash) }}</li>
              <li>
                <b>Tarjeta: </b> {{ fmtMoney(summary.by_payment.credit_card) }}
              </li>
              <li>
                <b>Financ.: </b> {{ fmtMoney(summary.by_payment.direct_debit) }}
              </li>
              <li><b>Bizum: </b> {{ fmtMoney(summary.by_payment.mobile) }}</li>
              <li>
                <b>Transf.: </b> {{ fmtMoney(summary.by_payment.transf) }}
              </li>
              <li><b>Otros: </b> {{ fmtMoney(summary.by_payment.others) }}</li>
              <li><b>Total: </b> {{ fmtMoney(summary.by_payment.total) }}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col">
        <!-- HEADER -->
        <div class="row">
          <div class="col-sm-9">
            <h1 class="page-title">LISTADO DE FACTURAS</h1>
          </div>
        </div>
        <!-- INPUT -->
        <div class="row form-group">
          <div class="col-sm-12">
            <input
              v-model="search"
              class="form-control icon"
              type="search"
              name="search"
              id="invoice_index_search"
              placeholder="Escribe el código de factura o datos del paciente"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-2">
            <select
              v-model="period"
              id="invoice_select_period"
              class="custom-select"
            >
              <option value="day">Hoy</option>
              <option value="day_prior">Ayer</option>
              <option value="week">Esta semana</option>
              <option value="week_prior">Semana pasada</option>
              <option value="month">Este mes</option>
              <option value="month_prior">Mes pasado</option>
              <option value="trimester">Este trimestre</option>
              <option value="tri_prior">Trimestre pasado</option>
              <option value="year">Este año</option>
              <option value="year_prior">Año pasado</option>
              <option value="custom">Personalizado</option>
            </select>
            <label for="invoice_select_period" class="wabident-info"
              >Periodo</label
            >
          </div>
          <div v-show="period == 'custom'" class="col-sm-2">
            <input
              v-model="date_from"
              type="date"
              class="form-control"
              id="invoice_select_date_from"
            />
            <label class="wabident-info" for="invoice_select_date_from"
              >Desde</label
            >
          </div>
          <div v-show="period == 'custom'" class="col-sm-2">
            <input
              v-model="date_to"
              type="date"
              class="form-control"
              id="invoice_select_date_to"
            />
            <label class="wabident-info" for="invoice_select_date_to"
              >Hasta</label
            >
          </div>
          <div class="col-sm-2">
            <select
              v-model="clinic_id"
              id="invoice_select_clinic"
              class="custom-select"
            >
              <option :value="null">Todas</option>
              <option
                v-for="(name, index) in clinics"
                :key="index"
                :value="index"
              >
                {{ name }}
              </option>
            </select>
            <label class="wabident-info" for="invoice_select_clinic"
              >Clínica</label
            >
          </div>
          <div class="col-sm-2">
            <select
              v-model="paid"
              id="invoice_select_paid"
              class="custom-select"
            >
              <option :value="null">Todas</option>
              <option :value="0">Sin pagar</option>
              <option :value="1">Pagadas</option>
            </select>
            <label class="wabident-info" for="invoice_select_paid"
              >Pagadas</label
            >
          </div>
        </div>
        <!-- RESULTS -->
        <div class="row">
          <div class="col-12 mt-4 mb-2">
            <h4 class="summary-title">FACTURAS</h4>
          </div>
          <div class="col-sm-12">
            <div v-show="is_searching" class="text-center">
              <h2 class="invoice-search-empty-message my-4">
                Buscando
                <i class="fas fa-spin fa-circle-notch"></i>
              </h2>
            </div>
            <div v-show="!has_results_available" class="text-center">
              <h2 class="invoice-search-empty-message my-4">
                {{ empty_message }}
              </h2>
            </div>
            <div v-show="has_results_available" class="invoice-search-wrapper">
              <clinic-invoice
                v-for="(invoice, index) in search_invoices"
                :key="index"
                :clinics="clinics"
                :invoice="invoice"
                @submit="onSubmit(true)"
              ></clinic-invoice>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <div v-if="has_results_available" class="row">
          <div class="col text-center customer-search-pagination my-2">
            <button
              :disabled="is_first_page"
              @click="goFirst"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-left"></i>
            </button>
            <button
              :disabled="is_first_page"
              @click="goPrev"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-left"></i>
            </button>
            <label class="customer-search-pagination-locator mx-2">
              Página
              {{ current_page }} de {{ total_pages }}
            </label>
            <button
              :disabled="is_last_page"
              @click="goNext"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-right"></i>
            </button>
            <button
              :disabled="is_last_page"
              @click="goLast"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-2">
        <h1 class="page-title">ACCIONES</h1>
        <div
          class="d-flex flex-column align-items-center justify-content-center"
        >
          <button
            :disabled="is_loading"
            @click="generateReport('customer_report')"
            class="
              btn btn-primary btn-block btn-wabident
              float-right
              d-flex
              flex-row
              justify-content-center
              align-items-center
              my-2
            "
          >
            <i
              class="fas fa-user"
              style="margin-right: 24px; font-size: 24px"
            ></i>
            Informe Pacientes
          </button>
          <button
            :disabled="is_loading"
            @click="generateReport('zip')"
            class="
              btn btn-primary btn-block btn-wabident
              float-right
              d-flex
              flex-row
              justify-content-center
              align-items-center
              my-2
            "
          >
            <i
              class="fas fa-download"
              style="margin-right: 24px; font-size: 24px"
            ></i>
            ZIP Facturas
          </button>

          <button
            :disabled="is_loading"
            @click="generateReport('csv')"
            class="
              btn btn-primary btn-block btn-wabident
              float-right
              d-flex
              flex-row
              justify-content-center
              align-items-center
              my-2
            "
          >
            <i
              class="fas fa-download"
              style="margin-right: 24px; font-size: 24px"
            ></i>
            Excel Facturas
          </button>

          <button
            :disabled="is_loading"
            @click="emailInvoices"
            class="
              btn btn-primary btn-block btn-wabident
              float-right
              d-flex
              flex-row
              justify-content-center
              align-items-center
              my-2
            "
          >
            <i
              class="wabident icon-wabident-mail"
              style="margin-right: 24px; font-size: 24px"
            ></i>
            Enviar ZIP
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      period: "week",
      date_from: new Date().toISODate(),
      date_to: new Date().toISODate(),
      clinic_id: null,
      search_results: [],
      summary: [],
      selected_customer: null,
      selected_invoice: null,
      is_searching: false,
      is_loading: false,
      paid: null,
      page: 1,
      clinic_data: {
        name: "",
        cif: "",
        phone: "",
        address: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        iban: "",
      },
    };
  },
  computed: {
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == this.search_results.last_page;
    },
    search_invoices() {
      if (this.is_searching) return [];
      if (!this.search_results.data) return [];
      return this.search_results.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.search_results.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.search_results.last_page;
    },
    clinic_logo() {
      if (!this.clinic) return "";
      return this.clinic.invoice_logo;
    },
    clinic_name() {
      if (!this.clinic) return "";
      return this.clinic.name;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    has_results_available() {
      return (
        this.search_results &&
        this.search_results.data &&
        this.search_results.length != 0
      );
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningúna factura";
      }
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  watch: {
    search(val) {
      this.page = 1;
      this.searchInvoices(val);
    },
    clinic(val) {
      this.loadClinicData();
    },
    clinic_id(val) {
      this.page = 1;
      this.searchInvoices(this.search);
    },
    period(val) {
      this.page = 1;
      this.searchInvoices(this.search);
    },
    date_from(val) {
      this.page = 1;
      this.searchInvoices(this.search);
    },
    date_to(val) {
      this.page = 1;
      this.searchInvoices(this.search);
    },
    paid(val) {
      this.page = 1;
      this.searchInvoices(this.search);
    },
  },
  methods: {
    goFirst() {
      this.page = 1;
      this.searchInvoices(this.search);
    },
    goPrev() {
      this.page -= 1;
      this.searchInvoices(this.search);
    },
    goNext() {
      this.page += 1;
      this.searchInvoices(this.search);
    },
    goLast() {
      this.page = this.total_pages;
      this.searchInvoices(this.search);
    },
    invoiceClicked(prod) {
      this.$refs.showinvoice.showEdit(prod);
    },
    onSubmit(force = false) {
      if ((this.search && this.search !== "") || force) {
        this.searchInvoices();
      }
    },
    getSearchParams(search = "") {
      return {
        search: search,
        page: this.page,
        period: this.period,
        date_from: this.date_from,
        date_to: this.date_to,
        clinic_id: this.clinic_id,
        paid: this.paid,
      };
    },
    searchInvoices(search) {
      if (!this.is_searching) {
        this.is_searching = true;
        let params = this.getSearchParams(search);
        axios
          .get("/invoice/search", { params })
          .then((response) => {
            this.search_results = response.data.results;
            this.summary = response.data.summary;
            this.date_from = response.data.params.date_from;
            this.date_to = response.data.params.date_to;
          })
          .catch((err) => {
            this.msgwindow.error("Error buscando facturas");
          })
          .then(() => {
            this.is_searching = false;
          });
      }
    },
    emailInvoices() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = this.getSearchParams(this.search);
        params.email = true;
        this.msgwindow.info("Enviando correo ..");
        axios
          .get("/invoice/search", { params })
          .then((response) => {
            if (!response.data.error) {
              this.msgwindow.success("Correo enviado con éxito");
            } else {
              this.msgwindow.error(
                "Error enviando correo : " + response.data.error
              );
            }
          })
          .catch((err) => {
            this.msgwindow.error("Error enviando correo");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    generateReport(type) {
      let params = this.getSearchParams(this.search);
      params[type] = true;

      let query_params = [];
      for (let field in params) {
        let val = params[field];
        if (val) {
          query_params.push(`${field}=${params[field]}`);
        }
      }

      window.open("/invoice/search?" + query_params.join("&"), "_blank");
    },
    fmtMoney(val) {
      return window.WabiDent.fmtMoney(val);
    },
  },
  mounted() {
    this.searchInvoices();
  },
};
</script>

<style lang="scss" scoped>
.clinic-invoices {
  margin-top: 54px;
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.summary-title {
  color: var(--theme-text-color);
  font-weight: 800;
}

.invoice-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
}

.invoice-search-wrapper {
  margin-top: 15px;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 55vh;
}

.invoice-search-results {
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
    position: sticky;
    background-color: white;
    top: 0;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);

      &.invoice-code,
      &.invoice-icon {
        color: var(--theme-grey);
        text-transform: uppercase;
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
