<template>
  <b-modal
    class="customer-show-treatment"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row">
      <div class="col text-center">
        <h3 class="customer-name">{{ customer.name }}</h3>
      </div>
    </div>
    <div v-if="clinic_type == 'dental'" class="form-group row">
      <div class="col">
        <select @change="addTooth" class="form-control custom-select">
          <option value>Selecciona pieza(s)</option>
          <option v-for="t in teeth" :key="t" :value="t">{{ t }}</option>
        </select>
        <div class="selected-teeth">
          <a
            @click="removeTooth(tooth)"
            role="button"
            class="btn wabident-badge wabident-badge__clear"
            v-for="(tooth, index) in selected_teeth_array"
            style="margin: 4px"
            :key="index"
            >{{ tooth }}</a
          >
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <v-select
          ref="productselector"
          v-model="selected_product"
          id="treatment_select_product"
          class="wabident-selector"
          placeholder="Selecciona o escribe el nombre del Tratamiento"
          :options="product_options"
        ></v-select>

        <div v-if="!is_editing" class="selected-teeth">
          <a
            @click="removeProduct(index)"
            role="button"
            class="btn wabident-badge wabident-badge__clear"
            v-for="(product, index) in selected_products"
            style="margin: 4px"
            :key="index"
            >{{ product.product.name }} - {{ product.product.price }}€
          </a>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-md-4" style="padding: 0 6px">
        <input
          type="date"
          id="treatment_show_date"
          class="form-control"
          v-model="date"
        />
        <label for="treatment_show_date" class="wabident-info">Fecha</label>
      </div>
      <div class="col-md-8" style="padding: 0 6px">
        <select
          v-model="doctor_id"
          id="treatment_select_doctor"
          class="custom-select"
        >
          <option :value="null">Selecciona Doctor</option>
          <option
            v-for="(doctor, index) in doctors"
            :key="index"
            :value="doctor.doctor.id"
          >
            {{ doctor.name }}
          </option>
        </select>
        <label for="treatment_select_doctor" class="wabident-info"
          >Doctor</label
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col-md-6" style="padding: 0 6px">
        <textarea
          id="treatment_notes"
          v-model="notes"
          class="form-control"
          placeholder="Notas"
        />
        <label for="treatment_notes" class="wabident-info"Notas</label>
      </div>
      <div class="col-md-6 pl-4" >
        <input
          v-model="is_custom_color"
          class="form-check-input"
          type="checkbox"
          id="treatment_color"
          value="true"
        />
        <label class="form-check-label" for="treatment_color"
          >Color Personalizado</label
        >
        <div v-if="is_custom_color" class="color-container">
          <compact-picker v-model="colors" :palette="palette"> </compact-picker>
        </div>
      </div>
    </div>
    <div class="form-group row" style="margin-bottom: 40px">
      <div
        class="
          col
          d-flex
          flex-row
          justify-content-around
          align-items-center
          status-buttons
        "
      >
        <button
          type="button"
          @click="is_priority = !is_priority"
          :class="{ unmarked: !is_priority }"
          class="btn btn-wabident-status absent status-check"
        >
          Prioritario
        </button>
        <button
          type="button"
          @click="setStatus('done_prior')"
          :class="{ unmarked: status != 'done_prior' }"
          class="btn btn-wabident-status cancelled status-check"
        >
          <span v-if="is_dental"> Ya venía hecho </span>
          <span v-else> Revisión </span>
        </button>
        <button
          type="button"
          @click="setStatus('done')"
          :class="{ unmarked: status != 'done' }"
          class="btn btn-wabident-status presented status-check"
        >
          Realizado
        </button>
        <button
          type="button"
          @click="is_paid = !is_paid"
          :class="{ unmarked: !is_paid }"
          class="btn btn-wabident-status attended status-check"
        >
          Pagado
        </button>
      </div>
    </div>
    <div class="form-group row">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing && !quotation_id"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          :disabled="!is_valid"
          type="button"
          @click="onSubmit"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_loading">Guardando ...</span>
          <span v-else-if="is_editing">Modificar Tratamiento</span>
          <span v-else>Crear Tratamiento</span>
          <i v-if="is_loading" class="fas fa-circle-notch float-right"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    customer: { required: true },
  },
  data() {
    return {
      date: new Date().toISODate(),
      teeth: [
        11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32,
        33, 34, 35, 36, 37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 51, 52, 53, 54,
        55, 61, 62, 63, 64, 65, 71, 72, 73, 74, 75, 81, 82, 83, 84, 85,
      ],
      treatment_id: 0,
      selected_teeth: {},
      is_editing: false,
      is_loading: false,
      products: [],
      selected_product: null,
      selected_products: [],
      notes: "",
      status: "pending",
      is_paid: false,
      is_priority: false,
      quotation_id: 0,
      doctor_id: null,
      is_custom_color: false,
      colors: {
        hex: "#000000",
      },
      palette: window.WabiDent.ColorPalette,
    };
  },
  watch: {
    selected_product(val) {
      if (!this.is_editing && val) {
        this.selected_products.push(val);
        this.selected_product = null;
      }
    },
  },
  computed: {
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    is_valid() {
      let selected_products = this.is_editing
        ? this.selected_product != null
        : this.selected_products.length > 0;

      return !this.is_loading && selected_products;
    },
    selected_teeth_array() {
      let ret = [];
      for (let code in this.selected_teeth) {
        if (this.selected_teeth[code]) {
          ret.push(code);
        }
      }
      return ret;
    },
    dialog_title() {
      return this.is_editing ? "Editar Tratamiento" : "Asignar Tratamiento(s)";
    },
    search_placeholder() {
      return this.is_editing
        ? "Selecciona o busca Tratamiento"
        : "Selecciona o busca Tratamientos ..";
    },
    product_options() {
      return this.products.map((product) => {
        return {
          label: `${product.name} - ${product.price}€`,
          product: product,
        };
      });
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    is_dental() {
      return this.clinic && this.clinic.type == "dental";
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  methods: {
    showNew(selected_teeth) {
      this.treatment_id = 0;
      this.is_editing = false;
      this.selected_teeth = selected_teeth || [];
      this.selected_product = null;
      this.selected_products = [];
      this.notes = "";
      this.updateProducts();
      this.quotation_id = 0;
      this.doctor_id = null;
      this.is_paid = false;
      this.is_priority = false;
      this.date = new Date().toISODate();
      this.status = "pending";
      this.is_custom_color = false;
      this.colors = {
        hex: "#000000",
      };
      this.$refs.modal.show();
    },
    showEdit(treatment) {
      this.treatment_id = treatment.id;
      this.is_editing = true;
      this.selected_teeth = this.makeTeethDict(treatment.teeth);
      this.notes = treatment.notes;
      this.status = treatment.status;
      this.is_paid = treatment.is_paid;
      this.is_priority = treatment.is_priority;
      this.quotation_id = treatment.quotation_id;
      this.doctor_id = treatment.doctor_id;
      this.date = moment(treatment.created_at).format("YYYY-MM-DD");

      this.is_custom_color = treatment.color != null;
      if (this.is_custom_color) {
        this.colors = { hex: treatment.color };
      } else {
        this.colors = { hex: "#000000" };
      }

      this.updateProducts(treatment);
      this.$refs.modal.show();
    },
    makeTeethDict(selected_teeth) {
      let ret = {};
      if (selected_teeth) {
        selected_teeth.forEach((element) => {
          ret[element] = true;
        });
      }
      return ret;
    },
    removeTooth(tooth) {
      this.selected_teeth[tooth] = false;
    },
    removeProduct(index) {
      this.selected_products.splice(index, 1);
    },
    addTooth(ev) {
      let tooth = ev.target.value;
      if (tooth) {
        this.$set(this.selected_teeth, tooth, true);
      }
      ev.target.value = "";
    },
    setStatus(status) {
      if (status == this.status) {
        this.status = "pending";
      } else {
        this.status = status;
      }
    },
    updateProducts(treatment) {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .get("/product")
          .then((response) => {
            this.products = response.data;
            if (treatment && this.is_editing) {
              this.product_options.forEach((option) => {
                if (option.product.id == treatment.product_id) {
                  if (!this.is_editing) {
                    this.selected_products = [option];
                    this.selected_product = null;
                  } else {
                    this.selected_products = [];
                    this.selected_product = option;
                  }
                }
              });
            }
          })
          .catch((error) => {
            console.log("[ERR] updateProducts", products);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    onSubmit() {
      if (this.is_editing) {
        this.editTreatment();
      } else {
        this.addTreatment();
      }
    },
    addTreatment() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          teeth: this.selected_teeth_array,
          notes: this.notes,
          product_ids: this.selected_products.map((x) => x.product.id),
          is_paid: this.is_paid,
          status: this.status,
          is_priority: this.is_priority,
          doctor_id: this.doctor_id,
          clinic_id: this.clinic ? this.clinic.id : null,
          created_at: this.date,
          color: this.is_custom_color ? this.colors.hex : null,
        };
        axios
          .post(`/customer/${this.customer.id}/treatment`, params)
          .then((response) => {
            this.msgwindow.success("Tratamiento asignado con éxito");
            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error asignando tratamiento");
            console.log("[ERR] addTreatment ", err);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    editTreatment() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          teeth: this.selected_teeth_array,
          notes: this.notes,
          product_id: this.selected_product.product.id,
          is_paid: this.is_paid,
          status: this.status,
          is_priority: this.is_priority,
          doctor_id: this.doctor_id,
          created_at: this.date,
          color: this.is_custom_color ? this.colors.hex : null,
        };
        axios
          .post(
            `/customer/${this.customer.id}/treatment/${this.treatment_id}`,
            params
          )
          .then((response) => {
            
            if (response.data.error) {
              this.msgwindow.error(response.data.error);
            } else {
              this.msgwindow.success("Tratamiento modificado con éxito");
            }

            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error modificando tratamiento");
            console.log("[ERR] editTreatment ", err);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    onDelete() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea borrar este tratamiento?",
        "Borrar",
        "Cancelar",
        this.onDeleteConfirm,
        null
      );
    },
    onDeleteConfirm() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/treatment/${this.treatment_id}/delete`
          )
          .then((response) => {
            if (response.data.error) {
              this.msgwindow.error(response.data.error);
            } else {
              this.msgwindow.success("Tratamiento borrado con éxito");
            }
            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((error) => {
            console.log("[ERR] onDeleteTreatment", error);
            this.msgwindow.error("Error borrando tratamiento!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-teeth {
  margin-top: 7px;
}
.modal-header {
  padding-bottom: 0 !important;
}

.modal-body {
  padding-top: 0 !important;
}

.customer-name {
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.25;
  color: var(--theme-text-color);
}

.status-check {
  flex: 0 0 120px;
}
</style>
