<template>
  <b-modal ref="modal" :title="title" hide-footer>
    <div class="row">
      <div class="col text-center mb-4">
        <span class="wabident-error" v-html="message"></span>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <button
          v-if="cancelMsg"
          type="button"
          @click="onCancel"
          class="btn btn-primary btn-wabident mx-3"
        >
          {{ cancelMsg }}
        </button>
        <button
          v-if="okMsg"
          type="button"
          @click="onAccept"
          class="btn btn-primary btn-wabident mx-3"
        >
          {{ okMsg }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "Error",
      message: "Mensaje",
      okMsg: null,
      cancelMsg: null,
      okFunc: null,
      cancelFunc: null,
    };
  },
  computed: {
    ...mapGetters(["errorwindow"]),
  },
  methods: {
    show(
      title,
      message,
      okMsg = "Aceptar",
      cancelMsg = null,
      okFunc = null,
      cancelFunc = null
    ) {
      this.okMsg = okMsg;
      this.cancelMsg = cancelMsg;
      this.okFunc = okFunc;
      this.cancelFunc = cancelFunc;
      this.title = title || "Error";
      this.message = message || "";
      this.$refs.modal.show();
    },
    onAccept() {
      this.$emit("onAccept");
      if (this.okFunc) this.okFunc();
      this.$refs.modal.hide();
    },
    onCancel() {
      this.$emit("onCancel");
      if (this.cancelFunc) this.cancelFunc();
      this.$refs.modal.hide();
    },
    ...mapMutations(["setErrorWindow"]),
  },
  mounted() {
    if (!this.errorwindow) {
      this.setErrorWindow(this);
    }
  },
};
</script>
