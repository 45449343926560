<template>
  <b-modal
    id="customer-show-document-gallery"
    ref="modal"
    :title="document_title"
    hide-footer
  >
    <div class="container-fluid">
      <div class="row mb-4">
        <div class="col">
          <h1 class="customer-show-document-gallery__title">
            {{ document_title }}
          </h1>
        </div>
        <div class="col">
          <a
            @click="onEdit"
            href="#"
            class="customer-show-document-gallery__link float-right"
          >
            <i class="fas fa-edit"></i>
          </a>
          <a
            :href="download_link"
            class="customer-show-document-gallery__link float-right"
          >
            <i class="fas fa-download"></i>
          </a>
          <a
            @click="onMail"
            href="#"
            class="customer-show-document-gallery__link float-right"
          >
            <i class="fas fa-envelope"></i>
          </a>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <img
            class="customer-show-document-gallery__image"
            :src="image_link"
            :alt="document_title"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    locked: { default: true },
  },
  data() {
    return {
      is_loading: false,
      is_editing: false,
      document: null,
      hour: null,
      selected_customer: null,
      created_at: new Date().toISODate(),
      revoke_date: "",
      doctor_id: null,
      template_id: null,
      title: "",
      content: "",
      type: "consent",
      file: null,
      templates: [],
    };
  },
  computed: {
    document_title() {
      if (!this.document) return "";
      return this.document.title;
    },
    image_link() {
      if (!this.document || !this.selected_customer) return "";
      return `/customer/${this.selected_customer.id}/document/${this.document.id}/view`;
    },
    download_link() {
      if (!this.document || !this.selected_customer) return "";
      return `/customer/${this.selected_customer.id}/document/${this.document.id}/download?action=download`;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    onEdit() {
      this.hide();
      this.$emit("edit", this.document);
    },
    onDownload() {
      this.hide();
      this.$emit("download", this.document);
    },
    onMail() {
      this.hide();
      this.$emit("email", this.document);
    },
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    showEdit(customer, document) {
      this.is_editing = true;
      this.document = document;
      this.selected_customer = customer;

      this.type = document.type;
      this.created_at = moment(document.created_at).toDate().toISODate();
      this.doctor_id = document.doctor_id;
      this.title = document.title;
      this.content = document.content;
      this.template_id = document.template_id;

      if (document.revoke_date && document.revoke_date != "") {
        this.revoke_date = moment(document.revoke_date).toDate().toISODate();
      } else {
        this.revoke_date = "";
      }

      this.show();
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#customer-show-document-gallery {
  .modal-dialog {
    max-width: 70vw;
    text-align: center;
  }

  .modal-title {
    display: none;
  }

  .modal-content {
    background: none !important;
  }

  .close {
    color: white;
  }

  .customer-show-document-gallery__title {
    color: white;
    text-align: left;
  }

  .customer-show-document-gallery__link {
    color: white;
    font-size: 30px;
    margin-left: 30px;
  }

  .customer-show-document-gallery__image {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
  }
}
</style>
