<template>
  <div class="container-fluid clinic-log">
    <div class="row">
      <div class="col-2"></div>
      <div class="col">
        <!-- HEADER -->
        <div class="row">
          <div class="col-sm-9">
            <h1 class="page-title">REGISTRO DE ACCIONES</h1>
          </div>
        </div>
        <!-- INPUT -->
        <div class="row form-group">
          <div class="col-sm-2">
            <select
              v-model="period"
              id="log_select_period"
              class="custom-select"
            >
              <option value="day">Hoy</option>
              <option value="day_prior">Ayer</option>
              <option value="week">Esta semana</option>
              <option value="week_prior">Semana pasada</option>
              <option value="month">Este mes</option>
              <option value="month_prior">Mes pasado</option>
              <option value="trimester">Este trimestre</option>
              <option value="tri_prior">Trimestre pasado</option>
              <option value="year">Este año</option>
              <option value="year_prior">Año pasado</option>
              <option value="custom">Personalizado</option>
            </select>
            <label for="log_select_period" class="wabident-info">Periodo</label>
          </div>
          <div v-show="period == 'custom'" class="col-sm-2">
            <input
              v-model="date_from"
              type="date"
              class="form-control"
              id="log_select_date_from"
            />
            <label class="wabident-info" for="log_select_date_from"
              >Desde</label
            >
          </div>
          <div v-show="period == 'custom'" class="col-sm-2">
            <input
              v-model="date_to"
              type="date"
              class="form-control"
              id="log_select_date_to"
            />
            <label class="wabident-info" for="log_select_date_to">Hasta</label>
          </div>
          <div class="col-sm-2">
            <select
              v-model="clinic_id"
              id="log_select_clinic"
              class="custom-select"
            >
              <option :value="null">Todas</option>
              <option
                v-for="(name, index) in clinics"
                :key="index"
                :value="index"
              >
                {{ name }}
              </option>
            </select>
            <label class="wabident-info" for="log_select_clinic">Clínica</label>
          </div>

          <div class="col-sm-2">
            <select
              v-model="user_id"
              id="log_select_user"
              class="custom-select"
            >
              <option :value="null">Todxs</option>
              <option
                v-for="(name, index) in users"
                :key="index"
                :value="index"
              >
                {{ name }}
              </option>
            </select>
            <label class="wabident-info" for="log_select_user">Usuario</label>
          </div>
        </div>
        <!-- RESULTS -->
        <div class="row clinic-log__header">
          <div class="col-sm-2"><strong>FECHA - HORA</strong></div>
          <div class="col-sm-1"><strong>IP</strong></div>
          <div class="col-sm-2"><strong>USUARIO</strong></div>
          <div class="col-sm-2"><strong>CLINICA</strong></div>
          <div class="col-sm-1"><strong>SECCION</strong></div>
          <div class="col-sm-2"><strong>ACCIÓN</strong></div>
          <div class="col"><strong>DATOS</strong></div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div v-show="is_searching" class="text-center">
              <h2 class="log-search-empty-message my-4">
                Buscando
                <i class="fas fa-spin fa-circle-notch"></i>
              </h2>
            </div>
            <div v-show="!has_results_available" class="text-center">
              <h2 class="log-search-empty-message my-4">
                {{ empty_message }}
              </h2>
            </div>
            <div v-show="has_results_available" class="log-search-wrapper">
              <clinic-log-entry
                v-for="(log, index) in search_logs"
                :key="index"
                :clinics="clinics"
                :users="users"
                :log="log"
              ></clinic-log-entry>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <div v-if="has_results_available" class="row">
          <div class="col text-center customer-search-pagination my-2">
            <button
              :disabled="is_first_page"
              @click="goFirst"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-left"></i>
            </button>
            <button
              :disabled="is_first_page"
              @click="goPrev"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-left"></i>
            </button>
            <label class="customer-search-pagination-locator mx-2">
              Página
              {{ current_page }} de {{ total_pages }}
            </label>
            <button
              :disabled="is_last_page"
              @click="goNext"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-right"></i>
            </button>
            <button
              :disabled="is_last_page"
              @click="goLast"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      period: "week",
      date_from: new Date().toISODate(),
      date_to: new Date().toISODate(),
      clinic_id: null,
      user_id: null,
      search_results: [],
      is_searching: false,
      is_loading: false,
      page: 1,
      users: [],
    };
  },
  computed: {
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.search_results.current_page == this.search_results.last_page;
    },
    search_logs() {
      if (this.is_searching) return [];
      if (!this.search_results.data) return [];
      return this.search_results.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.search_results.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.search_results.last_page;
    },
    clinic_logo() {
      if (!this.clinic) return "";
      return this.clinic.log_logo;
    },
    clinic_name() {
      if (!this.clinic) return "";
      return this.clinic.name;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    has_results_available() {
      return (
        this.search_results &&
        this.search_results.data &&
        this.search_results.length != 0
      );
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningúna entrada";
      }
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  watch: {
    clinic(val) {
      this.loadClinicData();
    },
    clinic_id(val) {
      this.page = 1;
      this.searchLogs(this.search);
    },
    user_id(val) {
      this.page = 1;
      this.searchLogs(this.search);
    },
    period(val) {
      this.page = 1;
      this.searchLogs(this.search);
    },
    date_from(val) {
      this.page = 1;
      this.searchLogs(this.search);
    },
    date_to(val) {
      this.page = 1;
      this.searchLogs(this.search);
    },
  },
  methods: {
    goFirst() {
      this.page = 1;
      this.searchLogs(this.search);
    },
    goPrev() {
      this.page -= 1;
      this.searchLogs(this.search);
    },
    goNext() {
      this.page += 1;
      this.searchLogs(this.search);
    },
    goLast() {
      this.page = this.total_pages;
      this.searchLogs(this.search);
    },
    onSubmit(force = false) {
      if ((this.search && this.search !== "") || force) {
        this.searchLogs();
      }
    },
    getSearchParams(search = "") {
      return {
        page: this.page,
        period: this.period,
        date_from: this.date_from,
        date_to: this.date_to,
        clinic_id: this.clinic_id,
        user_id: this.user_id,
      };
    },
    searchLogs(search) {
      if (!this.is_searching) {
        this.is_searching = true;
        let params = this.getSearchParams(search);
        axios
          .get("/log/search", { params })
          .then((response) => {
            this.search_results = response.data.results;
            this.users = response.data.users;
            this.date_from = response.data.params.date_from;
            this.date_to = response.data.params.date_to;
          })
          .catch((err) => {
            this.msgwindow.error("Error buscando facturas");
          })
          .then(() => {
            this.is_searching = false;
          });
      }
    },
  },
  mounted() {
    this.searchLogs();
  },
};
</script>

<style lang="scss" scoped>
.clinic-log {
  margin-top: 54px;
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.summary-title {
  color: var(--theme-text-color);
  font-weight: 800;
}

.log-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
}

.log-search-wrapper {
  padding-bottom: 15px;
}

.clinic-log__header {
  color: var(--theme-grey);
  border-bottom: 1px var(--theme-grey) solid;
  padding-bottom: 15px;
}

.log-search-results {
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
    position: sticky;
    background-color: white;
    top: 0;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);

      &.log-code,
      &.log-icon {
        color: var(--theme-grey);
        text-transform: uppercase;
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
