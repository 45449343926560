<template>
  <div class="row clinic-log-entry">
    <div class="col-sm-2">{{created_at}}</div>
    <div class="col-sm-1">{{log.ip}}</div>
    <div class="col-sm-2">{{user_name}}</div>
    <div class="col-sm-2">{{clinic_name}}</div>
    <div class="col-sm-1">{{log.section}}</div>
    <div class="col-sm-2">{{log.action}}</div>
    <div class="col clinic-log-entry__code"><code data-trigger="click" :title="log_data" >{{log_data_summary}}</code></div>
  </div>
</template>

<script>
export default {
  props: ["log", "clinics", "users"],
  computed: {
      created_at(){
          return moment(this.log.created_at).format('DD/MM/YY HH:mm:ss');
      },
      user_name() {
          return this.users[this.log.user_id] || 'N/D'
      },
      clinic_name() {
          return this.clinics[this.log.clinic_id] || 'N/D';
      },
      log_data_summary() {
          if(this.log.summary) {
              return this.log.summary;
          } else {
            let str = JSON.stringify(this.log.data);
            return str.substring(0,20) + ' ...';
          }
      },
      log_data() {
          let str = JSON.stringify(this.log.data,null,2);
          return str;
      },
    ...mapGetters(["msgwindow"]),
  },
  methods:{
  }
};
</script>

<style lang="scss">
.clinic-log-entry {
    border-bottom: 1px #e0e0e0 solid;
    border-top: 1px #e0e0e0 solid;
    padding: 5px 0;
}

.clinic-log-entry:nth-child(even) {
    background-color: #e0e0e0;
}

.clinic-log-entry__code code{
    font-size: 0.75rem;
    cursor: pointer;
    color: var(--theme-text);

    &:hover {
        color: var(--theme-color);
    }
}

</style>