<template>
  <div class="container clinic-users">
    <clinic-show-user ref="showuser" @submit="onSubmit"></clinic-show-user>
    <!-- HEADER -->
    <div class="row">
      <div class="col-sm-9">
        <h1 class="page-title">LISTADO DE PERSONAL</h1>
      </div>
      <div class="col-sm-3 text-center">
        <button
          @click="addNewUser"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          Añadir usuario
        </button>
      </div>
    </div>
    <!-- RESULTS -->
    <div class="row">
      <div class="col-sm-12">
        <div v-show="!has_results_available" class="text-center">
          <h2 class="template-search-empty-message my-4">
            {{ empty_message }}
          </h2>
        </div>
        <div v-show="has_results_available" class="template-search-wrapper">
          <table class="table template-search-results">
            <thead>
              <tr>
                <th class="text-center" >ACTIV@</th>
                <th>NOMBRE</th>
                <th>USUARI@</th>
                <th>PERFIL</th>
                <th>CLINICA REF.</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="template-search-results__row"
                v-for="(user, index) in search_results"
                :key="index"
                @click="userClicked(user)"
              >
                <td class="text-center">{{ user.is_active ? "Sí" : "No" }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ getRole(user) }}</td>
                <td>{{ getClinic(user.clinic_id) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      search_results: [],
      is_searching: false,
      selected_template: null,
    };
  },
  computed: {
    has_results_available() {
      return this.search_results && this.search_results.length != 0;
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningún usuario con ese nombre";
      }
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  watch: {
    search(val) {
      this.searchUsers(val);
    },
  },
  methods: {
    getRole(user) {
      switch (user.role) {
        case "admin":
          return "Administración";
        case "reception":
          return "Recepción";
        case "doctor":
          switch (user.doctor.type) {
            case "nurse":
              return "Enfermero/a";
            case "auxiliary":
              return "Auxiliar";
            default:
              return "Doctor/a";
          }
      }
    },
    getClinic(clinic_id) {
      if (!this.clinic || !clinic_id) return "";
      return this.clinic.clinic_names[clinic_id] || "";
    },
    addNewUser() {
      this.$refs.showuser.showNew();
    },
    userClicked(user) {
      this.$refs.showuser.showEdit(user);
    },
    onSubmit() {
      this.searchUsers();
    },
    searchUsers() {
      if (!this.is_searching) {
        this.is_searching = true;
        axios
          .get("/users")
          .then((response) => {
            this.search_results = response.data;
          })
          .catch((err) => {
            console.log("[ERR] searchTemplates", err);
            this.msgwindow.error("Error buscando usuarios");
          })
          .then(() => {
            this.is_searching = false;
          });
      }
    },
  },
  mounted() {
    this.searchUsers();
  },
};
</script>

<style lang="scss">
.clinic-users {
  margin-top: 54px;
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.template-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
}

.template-search-wrapper {
  margin-top: 30px;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
}

.template-search-results {
  width: 100%;
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
    position: sticky;
    background-color: white;
    top: 0;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);

      &.template-code,
      &.template-icon {
        color: var(--theme-grey);
        text-transform: uppercase;
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
