import { render, staticRenderFns } from "./CustomerNew.vue?vue&type=template&id=53cfc403&"
import script from "./CustomerNew.vue?vue&type=script&lang=js&"
export * from "./CustomerNew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports