<template>
  <div class="container">
    <div v-if="is_ok" class="row">
      <div class="col text-center">
        <h2>DATOS ENVIADOS</h2>
        <h3>Puede cerrar esta ventana</h3>
      </div>
    </div>
    <b-form v-else class="customer-fill-data" @submit="onSubmit">
      <div class="row form-group">
        <div class="col-sm-12">
          <h2>DATOS PERSONALES</h2>
        </div>
        <div class="col-sm-9">
          <label for="edit_customer_name" class="wabident-info"
            >Nombre y apellidos (Obligatorio)
          </label>

          <input
            v-model="form.name"
            type="text"
            class="form-control"
            name="name"
            id="edit_customer_name"
            required
          />
        </div>

        <div class="col-sm-3">
          <label for="customer_edit_birth_date" class="wabident-info"
            >Fecha de nacimiento (Obligatorio)
          </label>

          <input
            v-model="form.birth_date"
            type="date"
            name="birth_date"
            id="customer_edit_birth_date"
            class="form-control"
            required
          />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-sm-3">
          <label for="customer_edit_nif" class="wabident-info"
            >DNI / Pasaporte</label
          >
          <input
            v-model="form.nif"
            type="text"
            name="nif"
            id="customer_edit_nif"
            class="form-control"
          />
        </div>

        <div class="col-sm-3">
          <label for="customer_edit_phone" class="wabident-info"
            >Teléfono móvil (Obligatorio)
          </label>

          <input
            v-model="form.phone"
            type="tel"
            name="phone"
            id="customer_edit_phone"
            class="form-control"
            required
          />
        </div>
        <div class="col-sm-3">
          <label for="customer_edit_phone_alt" class="wabident-info"
            >Teléfono secundario (Opcional)</label
          >
          <input
            v-model="form.phone_alt"
            type="tel"
            name="phone_alt"
            id="customer_edit_phone_alt"
            class="form-control"
          />
        </div>
        <div class="col-sm-3">
          <label for="customer_edit_email" class="wabident-info">Email</label>
          <input
            v-model="form.email"
            type="email"
            name="email"
            id="customer_edit_email"
            class="form-control"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">
          <legend>DIRECCIÓN</legend>
        </div>
        <div class="col-sm-6">
          <label for="customer_edit_address" class="wabident-info"
            >Dirección</label
          >
          <input
            v-model="form.address"
            type="text"
            name="address"
            id="customer_edit_address"
            class="form-control"
          />
        </div>
        <div class="col-sm-3">
          <label for="customer_edit_city" class="wabident-info"
            >Población</label
          >
          <input
            v-model="form.city"
            type="text"
            name="city"
            id="customer_edit_city"
            class="form-control"
          />
        </div>
        <div class="col-sm-3">
          <label for="customer_edit_zipcode" class="wabident-info"
            >Código postal</label
          >
          <input
            v-model="form.zipcode"
            type="text"
            name="zipcode"
            id="customer_edit_zipcode"
            class="form-control"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-2">
          <legend>OTROS DATOS</legend>
        </div>
        <div class="col-sm-12">
          <label for="customer_edit_reason" class="wabident-info"
            >¿Cómo nos conociste?</label
          >
          <select
            v-model="form.reason"
            name="reason"
            id="customer_edit_reason"
            class="form-control custom-select"
          >
            <option
              v-for="(name, value) in customer_reasons"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </select>
        </div>
      </div>

      <template v-if="show_history">
        <div class="row form-group mt-2">
          <div class="col">
            <h2>HISTORIA CLÍNICA</h2>
          </div>
        </div>

        <div class="form-group row">
          <div class="col customer-edit-history-section">
            <legend>Alergias</legend>
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.allergies['medicines']"
                  class="form-check-input"
                  type="checkbox"
                  id="customer_edit_history_medicine_allergies"
                />
                <label
                  for="customer_edit_history_medicine_allergies"
                  class="form-check-label"
                >
                  ¿Tiene alergias a algún medicamento?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.allergies['medicines']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.allergies['other_medicines']"
                />
              </div>
            </transition>
            <!-- SUBSTANCE ALLERGIES -->
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.allergies['substances']"
                  class="form-check-input"
                  type="checkbox"
                  id="customer_edit_history_substance_allergies"
                />
                <label
                  for="customer_edit_history_substance_allergies"
                  class="form-check-label"
                >
                  ¿Tiene alergias a alguna sustancia?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.allergies['substances']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.allergies['other_substances']"
                />
              </div>
            </transition>
          </div>
        </div>
        <!-- DISEASES CUSTOM -->
        <div class="row">
          <div class="col customer-edit-history-section">
            <legend>Enfermedades</legend>
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.diseases['diabetes']"
                  class="form-check-input"
                  type="checkbox"
                  name="diseases[diabetes]"
                  id="customer_edit_history_diabetes"
                />
                <label
                  for="customer_edit_history_diabetes"
                  class="form-check-label"
                >
                  Diabetes
                </label>
              </div>
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.diseases['hypertension']"
                  class="form-check-input"
                  type="checkbox"
                  name="diseases[hypertension]"
                  id="customer_edit_history_hypertension"
                />
                <label
                  for="customer_edit_history_hypertension"
                  class="form-check-label"
                >
                  Hipertensión
                </label>
              </div>
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.diseases['autoinmune']"
                  class="form-check-input"
                  type="checkbox"
                  name="diseases[autoinmune]"
                  id="customer_edit_history_autoinmune"
                />
                <label
                  for="customer_edit_history_autoinmune"
                  class="form-check-label"
                >
                  Enf. Autoinmune
                </label>
              </div>
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.diseases['herpes']"
                  class="form-check-input"
                  type="checkbox"
                  name="diseases[herpes]"
                  id="customer_edit_history_herpes"
                />
                <label
                  for="customer_edit_history_herpes"
                  class="form-check-label"
                >
                  Herpes simple
                </label>
              </div>
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.diseases['other_diseases']"
                  class="form-check-input"
                  type="checkbox"
                  name="diseases[other_diseases]"
                  id="customer_edit_history_other_diseases"
                />
                <label
                  for="customer_edit_history_other_diseases"
                  class="form-check-label"
                >
                  ¿Tiene otras enfermedades
                  cardíacas/hepáticas/digestivas/óseas?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.diseases['other_diseases']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.diseases['other_diseases_content']"
                />
              </div>
            </transition>
          </div>
        </div>

        <!-- MEDICINES  -->
        <div class="row">
          <div class="col customer-edit-history-section">
            <legend>Medicación</legend>
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.medicines['other_medicines']"
                  class="form-check-input"
                  type="checkbox"
                  id="customer_edit_history_other_medicines"
                />
                <label
                  for="customer_edit_history_other_medicines"
                  class="form-check-label"
                >
                  ¿Toma algún tipo de medicación?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.medicines['other_medicines']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.medicines['other_medicines_content']"
                />
              </div>
            </transition>
          </div>
        </div>

        <!-- MEDICAL INTERVENTIONS -->
        <div class="row">
          <div class="col customer-edit-history-section">
            <legend>Intervenciones quirúrgicas</legend>
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.background['surgical_interventions']"
                  class="form-check-input"
                  type="checkbox"
                  id="customer_edit_history_surgical_interventions"
                />
                <label
                  for="customer_edit_history_surgical_interventions"
                  class="form-check-label"
                >
                  ¿Se ha realizado algún tipo de intervención quirúrgica?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.background['surgical_interventions']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.background['surgical_interventions_content']"
                />
              </div>
            </transition>
          </div>
        </div>

        <!-- MEDICAL INTERVENTIONS -->
        <div class="row">
          <div class="col customer-edit-history-section">
            <legend>Tratamientos estéticos previos</legend>
            <div class="customer-edit-history-checks">
              <div class="form-check form-check-inline form-check-history">
                <input
                  v-model="history.background['previous_treatments']"
                  class="form-check-input"
                  type="checkbox"
                  id="customer_edit_history_previous_treatments"
                />
                <label
                  for="customer_edit_history_previous_treatments"
                  class="form-check-label"
                >
                  ¿Se ha realizado algún tratamiento estético en otra clínica?
                </label>
              </div>
            </div>
            <transition name="fade">
              <div v-if="history.background['previous_treatments']">
                <legend>Indique cuales:</legend>
                <input
                  type="text"
                  class="form-control"
                  v-model="history.background['previous_treatments_content']"
                />
              </div>
            </transition>
          </div>
        </div>
      </template>

      <div class="row form-group">
        <div class="col">
          <button
            type="submit"
            role="button"
            class="btn btn-primary btn-wabident-round big btn-block mt-4"
            :disabled="is_updating"
          >
            <span class="btn-icon float-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
              >
                <path
                  d="M14.343.3a1.027 1.027 0 0 0-.726-.3h-1.489v5.873a.41.41 0 0 1-.41.41H3.058a.408.408 0 0 1-.408-.41V0H1.024A1.023 1.023 0 0 0 0 1.024v16.95A1.025 1.025 0 0 0 1.024 19h16.95A1.028 1.028 0 0 0 19 17.974V5.381a1.025 1.025 0 0 0-.302-.724L14.343.3zm1.967 16.342a.53.53 0 0 1-.532.53H3.22a.53.53 0 0 1-.532-.53V9.557a.53.53 0 0 1 .532-.53h12.56a.53.53 0 0 1 .531.53v7.085z"
                />
                <path d="M11.073 0H3.705v5.227h7.368V0z" />
                <path
                  d="M13.31 13.875H5.69a.528.528 0 1 0 0 1.056h7.62a.528.528 0 1 0 0-1.056z"
                />
              </svg>
            </span>

            <span v-if="is_updating">ENVIANDO ...</span>
            <span v-else>ENVIAR DATOS</span>
            <i
              v-if="is_updating"
              class="fas fa-circle-notch fa-spin float-right"
              style="margin-top: 5px"
            />
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  props: ["customer", "fillkey", "clinic"],
  data() {
    return {
      show_history: false,
      is_updating: false,
      is_ok: false,
      history: {
        allergies: {
          penicillin: false,
          latex: false,
          others: [],
          medicines: false,
          other_medicines: "",
          substances: false,
          other_substances: "",
        },
        diseases: {
          hepatitis: false,
          diabetes: false,
          heart_condition: false,
          hiv: false,
          others: [],
          hypertension: false,
          autoinmune: false,
          other_diseases: false,
          other_diseases_content: "",
        },
        medicines: {
          anticoagulants: false,
          inmunosuppresasnts: false,
          others: [],
          other_medicines: false,
          other_medicines_content: "",
        },
        background: {
          headache: false,
          neuralgia: false,
          bruxism: false,
          others: [],
          surgical_interventions: false,
          surgical_interventions_content: "",
          previous_treatments: false,
          previous_treatments_content: "",
        },
        habits: {
          tobacco: false,
          drugs: false,
          others: [],
        },
      },
      form: {
        name: "",
        birth_date: "",
        phone: "",
        phone_alt: "",
        email: "",
        nif: "",
        address: "",
        city: "",
        zipcode: "",
        reason: "",
      },
    };
  },
  computed: {
    customer_reasons() {
      if (!this.clinic) return {};
      return this.clinic.customer_reasons;
    },
    ...mapGetters(["errorwindow", "msgwindow"]),
  },
  methods: {
    confirmSubmit() {
      if (!this.is_updating) {
        this.is_updating = true;

        let formData = new FormData();
        for (let field in this.form) {
          formData.append(field, this.form[field]);
        }

        formData.append("key", this.fillkey);
        formData.append("history", JSON.stringify(this.history));

        axios
          .post(`/customer/${this.customer.id}/fill`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.msgwindow.success("Datos guardados con éxito");
            this.is_ok = true;
            if (response.data.redirect) {
              window.location = response.data.redirect;
            }
          })
          .catch((error) => {
            this.errorwindow.show(
              "Error",
              `Error guardando datos: ${error.response.data.message}.<br> Por favor consulte en Recepción`
            );
          })
          .then(() => {
            this.is_updating = false;
          });
      }
    },
    onSubmit(ev) {
      ev.preventDefault();
      this.errorwindow.show(
        "Confirmar",
        "¿Desea enviar esta información?",
        "Sí",
        "No",
        this.confirmSubmit
      );
    },
  },
  mounted() {
    this.form.name = this.customer.name;
    this.form.birth_date = moment(this.customer.birth_date)
      .toDate()
      .toISODate();
    this.form.phone = this.customer.phone;
    this.form.phone_alt = this.customer.phone_alt;
    this.form.email = this.customer.email;
    this.form.nif = this.customer.nif;
    this.form.address = this.customer.address;
    this.form.city = this.customer.city;
    this.form.zipcode = this.customer.zipcode;
    this.form.reason = this.customer.reason;
    this.history.allergies = this.customer.history.allergies;
    this.history.medicines = this.customer.history.medicines;
    this.history.diseases = this.customer.history.diseases;
    this.history.habits = this.customer.history.habits;
    this.history.background = this.customer.history.background;
  },
};
</script>

<style lang="scss">
.customer-fill-data {
  .customer-edit-history-section {
    .btn-plus {
      float: right;
    }

    margin-top: 30px;
    &::after {
      margin-top: 26px;
      width: 100%;
      height: 1px;
      background-color: var(--theme-grey-disabled);
      content: "";
      display: block;
    }

    legend {
      color: var(--theme-text-color);
      font-size: 18px;
      letter-spacing: 0.36px;
      font-weight: 500;
    }

    .customer-edit-history-checks {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 23px 0 18px 0;
      .form-check + .form-check {
        margin-left: 30px;
      }
    }

    .customer-edit-history-others {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      margin: -5px;

      .other-entry {
        margin: 5px;
        display: flex;
        flex-direction: row nowrap;
        align-items: center;
        border: 1px var(--theme-color-absent) solid;
        border-radius: 70px;
        height: 32px;
        padding: 7px 11px;
        input {
          border: none !important;
          color: var(--theme-color-absent);
          font-size: 13px;
          line-height: 1.54;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          appearance: none;
          width: 20px;
          height: 20px;
          color: white;
          border-radius: 10px;
          background-color: var(--theme-color-absent);
          border: none;
          i {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .customer-fill-data
    .customer-edit-history-section
    .customer-edit-history-checks {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 23px 0 18px 0;
  }

  .customer-fill-data
    .customer-edit-history-section
    .customer-edit-history-checks
    .form-check
    + .form-check {
    margin-left: 0;
    margin-top: 15px;
  }
}
</style>
