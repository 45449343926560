<template>
  <div class="container clinic-schedule">
    <!-- HOURS / MINUTES -->
    <div class="row">
      <div class="col-sm-6">
        <h2 class="clinic-schedule__title">HORARIO COMERCIAL</h2>
        <div
          v-for="(day,index) in daily_hours"
          class="row form-group clinic-schedule__day"
          :key="index"
        >
          <div class="col-sm-4">
            <div class="form-check form-check-inline">
              <input
                v-model="day.checked"
                class="form-check-input"
                type="checkbox"
                :id="'day_checked_'+index"
                :value="true"
              />
              <label class="form-check-label" :for="'day_checked_'+index">{{day.name}}</label>
            </div>
          </div>

          <div class="col-sm-4 form-inline clinic-schedule__hours">
            <input
              @change="setHourValue(day,'morning_start',$event.target.value)"
              :value="getHourValue(day,day.morning_start)"
              class="form-control wabident-small"
              type="text"
              :readonly="!day.checked"
              placeholder="No"
            />
            —
            <input
              @change="setHourValue(day,'morning_end',$event.target.value)"
              :value="getHourValue(day,day.morning_end)"
              class="form-control wabident-small"
              type="text"
              :readonly="!day.checked"
              placeholder="No"
            />
          </div>

          <div class="col-sm-4 form-inline clinic-schedule__hours">
            <input
              @change="setHourValue(day,'afternoon_start',$event.target.value)"
              :value="getHourValue(day,day.afternoon_start)"
              class="form-control wabident-small"
              type="text"
              :readonly="!day.checked"
              placeholder="No"
            />
            —
            <input
              @change="setHourValue(day,'afternoon_end',$event.target.value)"
              :value="getHourValue(day,day.afternoon_end)"
              class="form-control wabident-small"
              type="text"
              :readonly="!day.checked"
              placeholder="No"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <h2 class="clinic-schedule__title">FESTIVOS</h2>
        <div class="form-group row">
          <div
            v-for="(day,index) in vacations"
            :key="index"
            class="col-sm-6 form-inline flex-nowrap"
            style="margin-bottom: 15px"
          >
            <input
              :value="day"
              @change="setVacationDay(index,$event.target.value)"
              type="date"
              class="form-control wabident-small"
            />
            <a
              type="button"
              @click="removeVacation(index)"
              role="button"
              class="clinic-schedule__button-link"
            >
              <i class="wabident icon-wabident-trash"></i>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <a
              type="button"
              @click="addVacation"
              role="button"
              class="clinic-schedule__button-link"
            >
              <i class="fas fa-plus"></i> Añadir día festivo
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- INTERVAL/ SAVE -->
    <div class="row">
      <div class="col-sm-6">
        <h2 class="clinic-schedule__title">INTERVALO ENTRE CITAS</h2>
        <div class="row form-group">
          <div class="col">
            <select v-model.number="slot_minutes" class="form-control custom-select">
              <option value="15">Cada 15 minutos</option>
              <option value="30">Cada 30 minutos</option>
              <option value="60">Cada 60 minutos</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-sm-6 text-center" style="padding-top:110px">
        <button
          type="button"
          role="button"
          class="btn btn-primary btn-wabident-round big"
          style="min-width:316px"
          :disabled="is_updating"
          @click="onSave"
        >
          <span class="btn-icon float-left">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
              <path
                d="M14.343.3a1.027 1.027 0 0 0-.726-.3h-1.489v5.873a.41.41 0 0 1-.41.41H3.058a.408.408 0 0 1-.408-.41V0H1.024A1.023 1.023 0 0 0 0 1.024v16.95A1.025 1.025 0 0 0 1.024 19h16.95A1.028 1.028 0 0 0 19 17.974V5.381a1.025 1.025 0 0 0-.302-.724L14.343.3zm1.967 16.342a.53.53 0 0 1-.532.53H3.22a.53.53 0 0 1-.532-.53V9.557a.53.53 0 0 1 .532-.53h12.56a.53.53 0 0 1 .531.53v7.085z"
              />
              <path d="M11.073 0H3.705v5.227h7.368V0z" />
              <path d="M13.31 13.875H5.69a.528.528 0 1 0 0 1.056h7.62a.528.528 0 1 0 0-1.056z" />
            </svg>
          </span>

          <span v-if="is_updating">Guardando ...</span>
          <span v-else>Guardar cambios</span>
          <i
            v-if="is_updating"
            class="fas fa-circle-notch fa-spin float-right"
            style="margin-top:5px"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_updating: false,
      vacations: [],
      slot_minutes: 15,
      daily_hours: []
    };
  },
  computed: {
    ...mapGetters(["msgwindow"])
  },
  methods: {
    getHourValue(day, value) {
      return day.checked ? value : "";
    },
    setHourValue(day, value_code, value) {
      let d = moment(value, "HH:mm");
      if (value != "" && (!d.isValid() || d.minutes() > 59 || d.hours() > 23)) {
        this.msgwindow.error("La hora introducida no es válida");
      } else {
        day[value_code] = value;
      }
    },
    setVacationDay(index, value) {
      this.vacations[index] = value;
    },
    onSave() {
      if (!this.is_updating) {
        this.is_updating = true;
        let data = {
          slot_minutes: this.slot_minutes,
          daily_hours: this.daily_hours,
          vacations: this.vacations
        };
        axios
          .post("/clinic/schedule", data)
          .then(response => {
            console.log(response.data);
            this.msgwindow.success("Horarios guardados");
          })
          .catch(error => {
            console.log("[ERR] saveSchedule", error);
            this.msgwindow.error("¡Error guardando horarios!");
          })
          .then(() => {
            this.is_updating = false;
          });
      }
    },
    addVacation() {
      this.vacations.push(new Date().toISODate());
    },
    removeVacation(index) {
      this.vacations.splice(index, 1);
    },
    getSchedule() {
      this.is_updating = true;
      axios
        .get("/clinic/schedule")
        .then(response => {
          let hours = response.data.hours_config;
          this.slot_minutes = hours.slot_minutes;
          this.daily_hours = hours.daily_hours;
          this.vacations = hours.vacations;
        })
        .catch(err => {
          msgwindow.error("Error cargando horario de Clínica");
          console.log("[ERR] getSchedule ", err);
        })
        .then(() => {
          this.is_updating = false;
        });
    }
  },
  mounted() {
    this.getSchedule();
  }
};
</script>

<style lang="scss" scoped>
.clinic-schedule {
  &__title {
    margin-top: 40px;
    font-size: 32px;
    color: var(--theme-text-color);
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 1.31;
    margin-bottom: 25px;
  }

  &__hours {
    input {
      width: 40%;
    }
  }

  &__button-link {
    text-decoration: none;
    font-weight: bold;
    font-size: 13px;
    transition: color 0.2s;
    &:hover {
      color: var(--theme-color) !important;
    }

    .wabident {
      margin: 0 5px;
      font-size: 28px;
    }
  }
}
</style>
