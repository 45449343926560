<template>
  <div class="wabident-attended-customers">
    <h2>Orden de llegada</h2>
    <ul class="list-unstyled">
      <li
        class="d-flex flex-row align-items-center"
        v-for="(app, index) in appointments"
        :key="index"
      >
        <div class="wabident-attended-customers__order">
          <span v-if="app.status == 'attended'">✓</span>
          <span v-else>{{ index + 1 }}</span>
        </div>
        <div class="wabident-attended-customers__content">
          <strong>{{ app.customer.name }}</strong>
          <br />
          {{ appTime(app) }} - {{ app.box }}
        </div>
        <div class="wabident-attended-customers__switcher">
          <div class="wabident-switcher">
            <input
              @change="appChecked($event, app)"
              type="checkbox"
              :id="'app_' + app.id + '_switcher'"
              :checked="app.status == 'attended' || app.status == 'treated'"
            />
            <label :for="'app_' + app.id + '_switcher'">Atendido</label>
          </div>
        </div>
        <transition name="fade">
          <div v-if="app.status == 'attended' || app.status == 'treated'">
            <div
              class="wabident-attended-customers__gone-btn"
              :id="'btn-gone-' + app.id"
              @click="appGone(app)"
            >
              <i class="fas fa-door-open"></i>
            </div>
            <b-tooltip :target="'btn-gone-' + app.id">Salió</b-tooltip>
          </div>
        </transition>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["appointments"],
  data() {
    return {};
  },
  computed: {},
  methods: {
    appTime(app) {
      return moment(app.arrival_time).format("hh:mm");
    },
    appChecked(event, app) {
      this.$emit("appointmentChecked", event.target.checked, app);
    },
    appGone(app) {
      this.$emit("appointmentGone", app);
    },
  },
};
</script>

<style lang="scss" scoped>
.wabident-attended-customers {
  h2 {
    margin-top: 22px;
    margin-bottom: 22px;
    color: var(--theme-color-attended);
    font-size: 21px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }

  &__order {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-color-attended);
    font-size: 13px;
    font-weight: bold;
    width: 24px;
    height: 24px;
    border: 1px var(--theme-color-attended) solid;
    border-radius: 12px;
  }

  &__content {
    flex-grow: 1;
    margin-left: 13px;
    font-size: 12px;
    color: var(--theme-grey);
    strong {
      font-size: 13px;
      color: var(--theme-text-color);
      font-weight: bold;
    }
  }

  &__gone-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin: 0 0 4px 10px;
    border-radius: 12px;
    border: 1px var(--theme-color-gone) solid;
    color: var(--theme-color-gone);
    cursor: pointer;
    transition: all 0.2s;

    i {
      color: inherit;
      font-size: 10px;
    }

    &:hover {
      border-color: var(--theme-text-color);
      color: var(--theme-text-color);
    }
  }

  ul {
    li {
      border-bottom: 1px var(--theme-grey-disabled) solid;
      padding: 13px 0 13px 3px;
    }
  }
}
</style>
