<template>
  <div
    :id="identifier"
    class="odontogram-tooth"
    @click="clicked"
    :class="tooth_class"
    :style="tooth_style"
  >
    <b-popover
      :show.sync="show_popup"
      :disabled="treatments.length == 0"
      :target="identifier"
      custom-class="tooth-popover"
      placement="auto"
      :title="popover_title"
      triggers="hover"
    >
      <div class="mini-treatment-list">
        <div
          @click="treatmentClicked(treatment)"
          class="mini-treatment"
          v-for="(treatment, index) in treatments"
          :key="index"
        >
          <div class="mini-name">{{ treatmentName(treatment) }}</div>
        </div>
      </div>
    </b-popover>
    <div v-if="is_absent" class="absent-tooth" :style="tooth_style"></div>
    <label>{{ code }}</label>
    <treatment-icon
      v-if="filtered_treatments.outside.length"
      class="outside"
      :icon="outside_icon"
      :color="outside_color"
    ></treatment-icon>
    <treatment-icon
      v-if="filtered_treatments.root_outside.length"
      class="root-outside"
      :icon="root_outside_icon"
      :color="root_outside_color"
    ></treatment-icon>
    <treatment-icon
      v-if="filtered_treatments.center.length"
      class="center"
      :icon="center_icon"
      :color="center_color"
    ></treatment-icon>
    <treatment-icon
      v-if="filtered_treatments.inside.length"
      class="inside"
      :icon="inside_icon"
      :color="inside_color"
    ></treatment-icon>
    <treatment-icon
      v-if="filtered_treatments.root_inside.length"
      class="root-inside"
      :icon="root_inside_icon"
      :color="root_inside_color"
    ></treatment-icon>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_popup: false,
    };
  },
  props: {
    code: { required: true },
    bottom: { default: false },
    selected: { default: false },
    treatments: {
      default() {
        return [];
      },
    },
  },
  computed: {
    popover_title() {
      return `Tratamientos (${this.code})`;
    },
    identifier() {
      return "odontogram-tooth-" + this.code;
    },
    filtered_treatments() {
      let ret = {
        outside: [],
        root_outside: [],
        inside: [],
        root_inside: [],
        center: [],
      };

      if (this.treatments && this.treatments.length) {
        this.treatments.forEach((treatment) => {
          if (!treatment.product) {
            return "both";
          }

          switch (treatment.product.position) {
            case "inside":
              ret.inside.push(treatment);
              break;
            case "outside":
              ret.outside.push(treatment);
              break;
            case "both":
              ret.inside.push(treatment);
              ret.outside.push(treatment);
              break;
            case "inside_root":
              ret.root_inside.push(treatment);
              break;
            case "outside_root":
              ret.root_outside.push(treatment);
              break;
            case "both_root":
              ret.root_inside.push(treatment);
              ret.root_outside.push(treatment);
              break;
            case "up":
              ret.center.push(treatment);
              break;
          }
        });
      }
      return ret;
    },
    outside_icon() {
      return this.getIcon(this.filtered_treatments.outside);
    },
    root_outside_icon() {
      return this.getIcon(this.filtered_treatments.root_outside);
    },
    center_icon() {
      return this.getIcon(this.filtered_treatments.center);
    },
    inside_icon() {
      return this.getIcon(this.filtered_treatments.inside);
    },
    root_inside_icon() {
      return this.getIcon(this.filtered_treatments.root_inside);
    },
    root_outside_color() {
      return this.getColor(this.filtered_treatments.root_outside);
    },
    outside_color() {
      return this.getColor(this.filtered_treatments.outside);
    },
    inside_color() {
      return this.getColor(this.filtered_treatments.inside);
    },
    root_inside_color() {
      return this.getColor(this.filtered_treatments.root_inside);
    },
    center_color() {
      return this.getColor(this.filtered_treatments.center);
    },
    tooth_style() {
      let ret = {
        "background-image": `url('/odontogram/${this.code}.svg')`,
        "background-position-y": this.bottom ? "top" : "bottom",
      };
      return ret;
    },
    is_absent() {
      return this.treatments.reduce((cur, treatment) => {
        let icon = treatment.product ? treatment.product.icon : null;
        return cur || icon == "absent";
      }, false);
    },
    tooth_class() {
      let ret = {};
      // Tooth class
      let cl = `tooth-${this.code}`;
      ret[cl] = true;
      // Tooth position
      let pos = this.bottom ? "bottom" : "top";
      ret[pos] = true;
      // Tooth selection
      ret["selected"] = this.selected;
      // Tooth absent
      ret["absent"] = this.is_absent;
      return ret;
    },
  },
  methods: {
    treatmentName(treatment) {
      if (treatment.product) {
        return treatment.product.name;
      }
      return "";
    },
    contextmenu(ev) {
      this.show_popup = !this.show_popup;
      ev.preventDefault();
    },
    clicked(ev) {
      this.show_popup = false;
      this.$emit("selected", this.code);
    },
    treatmentClicked(treatment) {
      this.show_popup = false;
      this.$emit("treatmentClicked", treatment);
    },
    getIcon(treatments) {
      if (!treatments || !treatments.length) return "multiple";
      if (treatments.length == 1) {
        let product = treatments[0].product;
        if (product) {
          return product.icon || "multiple";
        }
      }
      return "multiple";
    },
    getColor(treatments) {
      if (!treatments || !treatments.length) return "black";
      if (treatments.length == 1) return WabiDent.getIconColor(treatments[0]);
      return "var(--theme-line-color)";
    },
  },
};
</script>

<style lang="scss">
.tooth-popover {
  .popover-header {
    color: white;
    background-color: var(--theme-color);
  }

  .popover-body {
    color: var(--theme-text-color);
  }

  .mini-treatment-list {
    .mini-treatment {
      padding: 2px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: white;
        background-color: var(--theme-color);
      }
    }
  }
}

.odontogram-tooth {
  box-sizing: border-box;
  position: relative;
  flex: 1 1 37px;
  height: 220px;
  background-repeat: no-repeat;
  background-position-x: center;
  background-size: 27px;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px transparent solid;

  &.absent {
    background-image: none !important;

    .absent-tooth {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position-x: center;
      opacity: 0;
    }
  }

  &:hover {
    border: 1px var(--theme-color) solid;
  }

  &.selected {
    background-color: var(--theme-highlight);
    label {
      font-weight: 600;
    }
  }

  label {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 13px;
    height: 20px;
    left: 0;
    font-weight: 500;
    display: block;
    color: var(--theme-line-color);
    pointer-events: none;
    margin: 0;
    padding: 0;
  }

  .wabident-treatment-icon {
    position: absolute;
    left: calc(50% - 10px);
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &.top {
    label {
      top: 0;
    }

    .wabident-treatment-icon {
      &.root-outside {
        top: 21%;
      }

      &.outside {
        top: 32.5%;
      }

      &.center {
        top: 50%;
      }

      &.inside {
        top: 67.5%;
      }

      &.root-inside {
        top: 79%;
      }
    }
  }

  &.bottom {
    label {
      bottom: 0;
    }

    .wabident-treatment-icon {
      &.root-inside {
        top: calc(21% - 20px);
      }

      &.inside {
        top: calc(32.5% - 20px);
      }

      &.center {
        top: calc(50% - 20px);
      }

      &.outside {
        top: calc(67.5% - 20px);
      }

      &.root-outside {
        top: calc(79% - 20px);
      }
    }
  }

  .wabident-treatment-icon {
    font-size: 36px;
  }
}
</style>
