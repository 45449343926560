<template>
  <div class="container-fluid clinic-treatments">
    <div class="row" style="margin-top: 40px">
      <div class="col">
        <h1 class="clinic-treatments__title">TRATAMIENTOS REALIZADOS</h1>
      </div>
    </div>

    <!-- Treatment Selector -->
    <div class="clinic-treatment-customers__inputs row form-group">
      <div class="col-sm-6">
        <v-select
          class="wabident-selector"
          @search="searchProducts"
          :options="products"
          placeholder="Busca el tratamiento/producto aquí ..."
          v-model="selected_product"
        ></v-select>
        <label class="wabident-info">Tratamiento</label>
      </div>
      <div class="col-sm-6">
        <v-select
          class="wabident-selector"
          @search="searchCustomers"
          :options="customers"
          placeholder="Busca el paciente aquí"
          v-model="selected_customer"
        ></v-select>
        <label class="wabident-info">Paciente</label>
      </div>
    </div>

    <legend style="font-weight: bold; font-size: 0.7rem">FILTROS</legend>

    <div class="clinic-treatment-customers__inputs row form-group">
      <!-- ClinicSelector -->
      <div v-if="show_clinic_selector" class="col-sm-2">
        <select
          id="clinic_treatments_clinic_id"
          v-model="clinic_id"
          class="custom-select"
        >
          <option :value="null">Todas</option>
          <option v-for="(name, id) in clinics" :key="id" :value="id">
            {{ name }}
          </option>
        </select>
        <label for="clinic_treatments_clinic_id" class="wabident-info"
          >Clínica</label
        >
      </div>
      <!-- DoctorSelector -->
      <div class="col-sm-2">
        <select
          id="clinic_treatments_doctor_id"
          v-model="doctor_id"
          class="custom-select"
        >
          <option :value="null">Todos</option>
          <option
            v-for="(doctor, index) in doctors"
            :key="index"
            :value="doctor.doctor.id"
          >
            {{ doctor.name }}
          </option>
        </select>
        <label for="clinic_treatments_doctor_id" class="wabident-info"
          >Doctor</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_show_done"
          v-model="show_done"
          class="custom-select"
        >
          <option :value="null">Todos</option>
          <option value="done">Realizado</option>
          <option value="not_done">Sin realizar</option>
        </select>
        <label for="clinic_treatments_show_done" class="wabident-info"
          >¿Realizado?</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_show_paid"
          v-model="show_paid"
          class="custom-select"
        >
          <option :value="null">Todos</option>
          <option value="paid">Pagados</option>
          <option value="not_paid">Sin pagar</option>
        </select>
        <label for="clinic_treatments_show_paid" class="wabident-info"
          >¿Pagado?</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_show_type"
          v-model="product_type"
          class="custom-select"
        >
          <option :value="null">Todos</option>
          <option value="treatment">Tratamientos</option>
          <option value="product">Productos</option>
        </select>
        <label for="clinic_treatments_show_type" class="wabident-info"
          >Tipo</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_show_category"
          v-model="product_category_id"
          class="custom-select"
        >
          <option :value="null">Todas</option>
          <option
            v-for="(name, id) in product_categories"
            :value="id"
            :key="id"
          >
            {{ name }}
          </option>
        </select>
        <label for="clinic_treatments_show_category" class="wabident-info"
          >Unidad</label
        >
      </div>
    </div>

    <legend style="font-weight: bold; font-size: 0.7rem">FECHAS</legend>

    <!-- Period Selector -->
    <div class="clinic-treatments__inputs row form-group">
      <div class="col-sm-2">
        <select
          id="clinic_treatments_order_by"
          v-model="order_by"
          class="custom-select"
        >
          <option value="done_at">F. Realización</option>
          <option value="paid_at">F. Pago</option>
          <option value="created_at">F. Creación</option>
        </select>
        <label for="clinic_treatments_order_by" class="wabident-info"
          >Ordenar por ...</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_order_by_dir"
          v-model="order_by_dir"
          class="custom-select"
        >
          <option value="desc">Recientes</option>
          <option value="asc">Antiguos</option>
        </select>
        <label for="clinic_treatments_order_by" class="wabident-info"
          >Sentido</label
        >
      </div>

      <div class="col-sm-2">
        <select
          id="clinic_treatments_period"
          v-model="period"
          class="custom-select"
        >
          <option value="day">Hoy</option>
          <option value="day_prior">Ayer</option>
          <option value="week">Esta semana</option>
          <option value="week_prior">Semana pasada</option>
          <option value="month">Este mes</option>
          <option value="month_prior">Mes pasado</option>
          <option value="trimester">Este trimestre</option>
          <option value="trimester_prior">Trimestre pasado</option>
          <option value="year">Este año</option>
          <option value="past_year">Año pasado</option>
          <option value="custom">Personalizado</option>
        </select>
        <label for="clinic_treatments_period" class="wabident-info"
          >Periodo</label
        >
      </div>
      <!-- Custom Dates Selector -->
      <transition name="fade">
        <div class="col-sm-2" v-if="period == 'custom'">
          <input
            v-model="date_from"
            id="clinic_treatments_date_from"
            type="date"
            class="form-control"
          />
          <label class="wabident-info" for="clinic_treatments_date_from"
            >Desde:</label
          >
        </div>
      </transition>
      <transition name="fade">
        <div class="col-sm-2" v-if="period == 'custom'">
          <input
            v-model="date_to"
            id="clinic_treatments_date_to"
            type="date"
            class="form-control"
          />
          <label class="wabident-info" for="clinic_treatments_date_to"
            >Hasta:</label
          >
        </div>
      </transition>
      <!-- TreatmentSelector -->
    </div>

    <div v-if="!is_loading" class="row my-4">
      <div class="col-md-2" v-if="total_treatments">
        <h5>TRATAMIENTOS:</h5>
        <b>{{ total_treatments }}</b>
      </div>

      <div class="col-md-2" v-if="show_paid != 'paid'">
        <h5>PRECIO:</h5>
        <b>{{ total }}</b>
      </div>

      <div class="col-md-2" v-if="show_paid != 'not_paid'">
        <h5>PAGADO:</h5>
        <b>{{ total_paid }}</b>
      </div>

      <div
        class="
          col
          clinic-treatments__summary
          d-flex
          flex-row
          justify-content-end
          align-items-start
        "
      >
        <div class="mx-3" style="margin-top: 2px">
          <select
            id="clinic_report_type"
            v-model="report_type"
            class="custom-select"
          >
            <option value="pdf">Listado Ttos. PDF</option>
            <option value="xls">Listado Excel</option>
            <option value="grouped">Ttos. Agregados PDF</option>
          </select>
          <label for="clinic_report_type" class="wabident-info"
            >Tipo de Informe</label
          >
        </div>

        <button
          :disabled="is_loading"
          @click="generateReportPDF"
          class="
            btn btn-primary btn-wabident
            float-right
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <i
            style="font-size: 24px; margin-right: 20px"
            class="wabident icon-wabident-print"
          ></i>
          Descargar
        </button>
      </div>
    </div>

    <div v-if="!is_loading" class="clinic-treatments__list">
      <table class="table">
        <thead>
          <tr>
            <th>{{ date_header }}</th>
            <th>CLINICA</th>
            <th>DOCTOR</th>
            <th>TTO.</th>
            <th>TIPO</th>
            <th>UNIDAD</th>
            <th>PACIENTE</th>
            <th>REALIZADO</th>
            <th>PAGADO</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(treatment, index) in search_treatments">
            <clinic-treatment
              class="clinic-treatments__row"
              :treatment="treatment"
              :key="index"
              :clinics="clinics"
              :categories="product_categories"
              :doctors="doctors"
              :showpaid="show_paid"
              :orderby="order_by"
            ></clinic-treatment>
          </template>
        </tbody>
      </table>

      <!-- PAGINATION -->
      <div v-if="has_results_available" class="row">
        <div class="col text-center customer-search-pagination my-2">
          <button
            :disabled="is_first_page"
            @click="goFirst"
            type="button"
            class="btn btn-primary btn-wabident-round mx-1"
          >
            <i class="fas fa-angle-double-left"></i>
          </button>
          <button
            :disabled="is_first_page"
            @click="goPrev"
            type="button"
            class="btn btn-primary btn-wabident-round mx-1"
          >
            <i class="fas fa-angle-left"></i>
          </button>
          <label class="customer-search-pagination-locator mx-2">
            Página
            {{ current_page }} de {{ total_pages }}
          </label>
          <button
            :disabled="is_last_page"
            @click="goNext"
            type="button"
            class="btn btn-primary btn-wabident-round mx-1"
          >
            <i class="fas fa-angle-right"></i>
          </button>
          <button
            :disabled="is_last_page"
            @click="goLast"
            type="button"
            class="btn btn-primary btn-wabident-round mx-1"
          >
            <i class="fas fa-angle-double-right"></i>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="clinic-treatments__loading text-center">
      <h2>
        Buscando
        <i class="fas fa-spin fa-circle-notch"></i>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show_paid: null,
      show_done: "done",
      order_by: "done_at",
      order_by_dir: "desc",
      is_loading: false,
      period: "day",
      date_from: new Date().toISODate(),
      date_to: new Date().toISODate(),
      doctor_id: null,
      clinic_id: null,
      product_type: null,
      product_category_id: null,
      treatments: [],
      summary: [],
      page: 1,
      selected_product: null,
      products: [],
      product_categories: [],
      customers: [],
      selected_customer: null,
      report_type: "pdf",
    };
  },
  computed: {
    total() {
      if (!this.summary.total) return "";
      return WabiDent.fmtMoney(this.summary.total, 2);
    },
    total_paid() {
      if (!this.summary.total_paid) return "";
      return WabiDent.fmtMoney(this.summary.total_paid, 2);
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == this.treatments.last_page;
    },
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == this.treatments.last_page;
    },
    search_treatments() {
      if (this.is_loading) return [];
      if (!this.treatments) return [];
      if (!this.treatments.data) return [];
      return this.treatments.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.treatments.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.treatments.last_page;
    },
    total_treatments() {
      if (!this.has_results_available) return 0;
      return this.treatments.total;
    },
    has_results_available() {
      if (this.is_loading) {
        return false;
      }

      if (!this.treatments) {
        return false;
      }

      if (!this.treatments.data) {
        return false;
      }

      return this.treatments.data.length > 0;
    },
    show_clinic_selector() {
      return this.clinics.length > 1;
    },
    date_header() {
      let labels = {
        done_at: "F. REALIZADO",
        paid_at: "F. PAGO",
        created_at: "F. ALTA",
      };

      return labels[this.order_by] || "FECHA";
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  watch: {
    doctor_id(val) {
      this.reloadTreatments();
    },
    product_category_id(val) {
      this.reloadTreatments();
    },
    product_type(val) {
      this.reloadTreatments();
    },
    period(val) {
      this.reloadTreatments();
    },
    date_from(val) {
      this.reloadTreatments();
    },
    date_to(val) {
      this.reloadTreatments();
    },
    clinic_id(val) {
      this.reloadTreatments();
    },
    show_paid(val) {
      this.reloadTreatments();
    },
    show_done(val) {
      this.reloadTreatments();
    },
    order_by(val) {
      this.reloadTreatments();
    },
    order_by_dir(val) {
      this.reloadTreatments();
    },
    selected_product(val) {
      this.reloadTreatments();
    },
    selected_customer(val) {
      this.reloadTreatments();
    },
  },
  methods: {
    reloadTreatments() {
      this.page = 1;
      this.loadTreatments();
    },
    goFirst() {
      this.page = 1;
      this.loadTreatments();
    },
    goPrev() {
      this.page -= 1;
      this.loadTreatments();
    },
    goNext() {
      this.page += 1;
      this.loadTreatments();
    },
    goLast() {
      this.page = this.total_pages;
      this.loadTreatments();
    },
    fillSearchedProducts(products) {
      this.searched_products = products;
      this.products = products.map((p) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
    },
    fillSearchedCustomers(customers) {
      this.searched_customers = customers;
      this.customers = customers.map((p) => {
        return {
          label: `${p.name} - #${p.history_number}`,
          value: p.id,
        };
      });
    },

    searchProducts(search, loading) {
      loading(true);
      axios
        .get("/product", {
          params: {
            search: search,
          },
        })
        .then((response) => {
          this.fillSearchedProducts(response.data);
        })
        .catch((error) => {
          console.log("[ERR] searchProducts:", error);
        })
        .then(() => {
          loading(false);
        });
    },

    searchCustomers(search, loading) {
      loading(true);
      axios
        .get("/customer/search", {
          params: {
            search: search,
          },
        })
        .then((response) => {
          this.fillSearchedCustomers(response.data);
        })
        .catch((error) => {
          console.log("[ERR] searchCustomers:", error);
        })
        .then(() => {
          loading(false);
        });
    },

    userCan(cap) {
      if (!this.clinic) return false;
      if (!this.clinic.capabilities) return false;
      return this.clinic.capabilities[cap] || false;
    },
    generateReportPDF() {
      let params = {
        period: this.period,
        date_from: this.date_from,
        date_to: this.date_to,
        doctor_id: this.doctor_id,
        clinic_id: this.clinic_id,
        report_type: this.report_type,
        show_paid: this.show_paid,
        show_paid: this.show_done,
        order_by: this.order_by,
        order_by_dir: this.order_by_dir,
        product_id: this.getSelectedProductId(),
        customer_id: this.getSelectedCustomerId(),
        product_type: this.product_type,
        product_category_id: this.product_category_id,
      };

      let query_params = [];
      for (let field in params) {
        let val = params[field];
        if (val) {
          query_params.push(`${field}=${params[field]}`);
        }
      }

      window.open("/clinic/treatments?" + query_params.join("&"), "_blank");
    },
    getSelectedProductId() {
      if (!this.selected_product) return null;
      return this.selected_product.value;
    },
    getSelectedCustomerId() {
      if (!this.selected_customer) return null;
      return this.selected_customer.value;
    },
    loadTreatments() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          period: this.period,
          date_from: this.date_from,
          date_to: this.date_to,
          doctor_id: this.doctor_id,
          clinic_id: this.clinic_id,
          show_paid: this.show_paid,
          show_done: this.show_done,
          order_by: this.order_by,
          order_by_dir: this.order_by_dir,
          page: this.page,
          product_id: this.getSelectedProductId(),
          customer_id: this.getSelectedCustomerId(),
          product_type: this.product_type,
          product_category_id: this.product_category_id,
        };

        axios
          .get("/clinic/treatments", { params })
          .then((response) => {
            this.treatments = response.data.results;
            this.product_categories = response.data.product_categories;
            this.page = response.data.results.current_page;
            this.summary = response.data.summary;
            this.date_from = response.data.params.date_from;
            this.date_to = response.data.params.date_to;
          })
          .catch((error) => {
            console.log("[ERR] loadTreatments ", error);
            this.msgwindow.error("Error cargando tratamientos realizados");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
  mounted() {
    this.loadTreatments();
  },
};
</script>

<style lang="scss">
.clinic-treatments {
  max-width: 1440px;

  &__title {
    font-size: 32px;
  }

  &__list {
    table {
      th {
        font-size: 15px;
        letter-spacing: 0.3px;
        color: var(--theme-grey);
        border: none;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        border-bottom: 1px #e0e0e0 solid;
      }

      &__row {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.2s;
        border-bottom: 1px #e0e0e0 solid;
        border-top: 1px #e0e0e0 solid;

        &:hover {
          background-color: var(--theme-highlight);
        }

        td {
          font-size: 16px;
          line-height: 20px;
          color: var(--theme-text-color);
        }
      }
    }
  }
}
</style>
