<template>
  <div class="clinic-invoice">
    <div class="clinic-invoice__info">
      <div class="clinic-invoice__header">
        <span class="clinic-invoice__date">{{ invoice_date }}</span>
        <span class="clinic-invoice__code"
          >Factura #{{ invoice.code }}
          <span v-if="invoice.is_paid">
            - Pagada
            <span v-if="invoice.paid_amount - invoice.total_amount >= 0.5">
              <i class="fas fa-exclamation-triangle"></i>
            </span>
          </span>
          <strong v-else> - Sin pagar </strong>
        </span>
      </div>
      <div class="clinic-invoice__body">
        <span class="clinic-invoice__customer"
          ><a :href="customer_link" target="_blank">{{
            customer_name
          }}</a></span
        >
        - <span class="clinic-invoice__clinic">{{ clinic_name }}</span> -
        <span class="clinic-invoice__notes">{{ invoice.notes }}</span>
      </div>
    </div>
    <div class="flex-spacer"></div>
    <div class="clinic-invoice__print fcenter">
      <a :href="invoice_pdf_link" target="_blank">
        <i class="wabident icon-wabident-print"></i>
      </a>
    </div>
    <div @click="sendMail" class="clinic-invoice__email fcenter">
      <i class="wabident icon-wabident-mail"></i>
    </div>
    <div @click="deleteInvoice" class="clinic-invoice__email fcenter">
      <i class="wabident icon-wabident-trash"></i>
    </div>
    <div class="clinic-invoice__total">{{ pretty_total }}</div>
  </div>
</template>

<script>
export default {
  props: ["invoice"],
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    customer_link() {
      if (!this.invoice.customer) return "";
      return `/customer/${this.invoice.customer.id}/edit`;
    },
    invoice_pdf_link() {
      return `/invoice/${this.invoice.id}/pdf`;
    },
    pretty_total() {
      let value = this.invoice.invoicelines.reduce((total, line) => {
        return (
          total +
          Number(line.price) *
            Number(line.quantity) *
            (1 - Number(line.discount) / 100)
        );
      }, 0);
      return WabiDent.fmtMoney(value, 0);
    },
    invoice_date() {
      return moment(this.invoice.created_at).toDate().toLocaleDateString();
    },
    customer_name() {
      return this.invoice.customer ? this.invoice.customer.name : "";
    },
    clinic_name() {
      if (!this.clinic) return "";
      return this.clinic.clinic_names[this.invoice.clinic_id] || "";
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  methods: {
    deleteInvoice() {
      if (this.sending) return;
      this.errorwindow.show(
        "Confirmación",
        "¿Está seguro/a? Esta acción no se puede deshacer",
        "Borrar",
        "Cancelar",
        () => {
          this.sending = true;
          axios
            .post(`/invoice/${this.invoice.id}`, { _method: "delete" })
            .then((response) => {
              this.$emit("submit");
              this.msgwindow.success("Factura borrada");
            })
            .catch((err) => {
              console.log("[ERR] deleteInvoice", err);
              this.msgwindow.error("Factura borrada");
            })
            .then(() => {
              this.sending = false;
            });
        }
      );
    },
    sendMail() {
      if (this.sending) return;
      this.sending = true;
      this.msgwindow.info("Enviando factura ...");
      axios
        .get(`/invoice/${this.invoice.id}/email`)
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.msgwindow.error(data.message);
          } else {
            this.msgwindow.success(data.message);
          }
        })
        .catch((error) => {
          console.log("[ERR] sendInvoiceEmail ", error);
          this.msgwindow.error("Error enviando factura !");
        })
        .catch(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss">
.clinic-invoice {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  padding: 9px 11px;
  border-bottom: 1px var(--theme-line-color) solid;

  &__info {
    flex-grow: 1;
  }

  &__date {
    font-size: 14px;
    font-weight: 500;
    color: var(--theme-line-color);
  }

  &__code {
    font-size: 14px;
    margin-left: 15px;
  }

  &__customer,
  &__clinic,
  &__notes {
    font-size: 13px;
    font-weight: 500;
  }

  &__total {
    font-size: 18px;
    color: var(--theme-color-attended);
    letter-spacing: 0.36px;
    flex: 0 0 120px;
    text-align: right;
  }

  &__email,
  &__print {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 0 35px;
    cursor: pointer;
    transition: all 0.2s;

    a {
      color: inherit;
      text-decoration: none;
    }

    i {
      font-size: 24px;
    }

    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
