<style lang="scss" scoped>
</style>
<template>
  <b-modal ref="modal" title="Nueva cita" hide-footer>
    <p>This is a sample</p>
  </b-modal>
</template>
<script>
export default {
  methods: {
    show() {
      this.$refs.modal.show();
    }
  }
};
</script>
