<style lang="scss" scoped>
.mb4px {
  margin-bottom: 4px !important;
}

.status-check {
  flex-grow: 1;
}

.status-check + .status-check {
  margin-left: 23px;
}

.whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  color: var(--theme-grey);
  height: 53.6px;
  text-decoration: none;
  &:hover {
    color: var(--theme-color);
  }
  i {
    font-size: 32px;
  }
}

.color-container {
  position: absolute;
  bottom: 30px;
  left: 0;
}
</style>
<template>
  <div>
    <customer-new
      v-if="!locked"
      @customerCreated="onCustomerCreated"
      ref="customerNew"
    ></customer-new>
    <b-modal ref="modal" :title="dialog_title" hide-footer>
      <!-- Customer Search Selector -->
      <div class="form-group row">
        <div v-if="is_edit" class="col-sm-12 text-center mb-2">
          <div
            class="d-flex align-items-center justify-content-between text-muted"
            style="font-size: 0.8rem"
          >
            <div><b>Creada:</b> {{ created_at }}</div>
            <div><b>Usuario:</b> {{ user_name }}</div>
            <div><b>Modificada:</b> {{ updated_at }}</div>
          </div>
        </div>
        <div class="col-sm-12">
          <customer-search
            ref="search"
            :locked="locked"
            :clinics="clinics"
            @customerSelected="customerSelected"
            @customerDeselected="customerDeselected"
          ></customer-search>
        </div>

        <div v-if="selected_customer" class="col-sm-12">
          <a :href="customer_edit_link" target="_blank" class="btn-link"
            >Ver ficha de paciente</a
          >
        </div>
        <div v-else-if="!locked" class="col-sm-12">
          <span role="button" class="btn-link" @click="onNewCustomer"
            >Añadir un nuevo paciente</span
          >
        </div>
      </div>
      <div class="form-group row">
        <div v-if="is_aesthetic && is_edit" class="col">
          <select
            id="app_changed_reason"
            v-model="changed_reason"
            class="custom-select"
          >
            <option :value="null">Elige una opción</option>
            <option value="clinic">Clínica</option>
            <option value="customer">Paciente</option>
          </select>
          <label for="app_select_doctor" class="wabident-info"
            >Motivo del cambio</label
          >
        </div>

        <div v-if="is_aesthetic" class="col">
          <select
            id="app_select_origin"
            v-model="app_origin"
            class="custom-select"
          >
            <option :value="null">Elige una opción</option>
            <option value="mobile">Whatsapp</option>
            <option value="hone">Teléfono</option>
            <option value="email">Email</option>
            <option value="door">Puerta</option>
          </select>
          <label for="app_select_doctor" class="wabident-info"
            >¿Cómo se tomó la cita?</label
          >
        </div>
        <div class="col">
          <select
            id="app_select_doctor"
            v-model="doctor_id"
            class="custom-select"
          >
            <option :value="null">Selecciona Doctor(a)</option>
            <option
              v-for="(doctor, i) in doctors"
              :key="i"
              :value="doctor.doctor.id"
            >
              {{ doctor.name }}
            </option>
          </select>
          <label for="app_select_doctor" class="wabident-info"
            >Doctor(a)</label
          >
        </div>
      </div>
      <!-- Customer Treatment Selector -->
      <div
        v-show="selected_customer"
        class="form-group row"
        :class="{ mb4px: selected_treatment_ids }"
      >
        <div class="col-sm-12">
          <select
            v-model="selected_treatment"
            class="custom-select wabident-small"
          >
            <option value="0">Selecciona tratamiento</option>
            <option
              v-for="treatment in customer_treatments"
              :key="treatment.id"
              :value="treatment.id"
            >
              {{ getTreatmentName(treatment) }}
            </option>
          </select>
        </div>
      </div>
      <!-- Treatment List -->
      <div v-show="selected_treatment_ids" class="form-group row no-gutters">
        <div class="col-sm-12">
          <a
            @click="removeTreatment(treatment.id)"
            role="button"
            class="
              btn
              wabident-badge wabident-badge__clear wabident-badge__ellipsis
            "
            v-for="treatment in selected_customer_treatments"
            style="margin: 4px"
            :key="treatment.id"
            >{{ treatment.product.name.limit(20) }}</a
          >
        </div>
      </div>
      <!-- Date/Time Selector -->
      <div class="form-group row">
        <div class="col-sm-5">
          <input
            v-model="selected_date"
            type="date"
            class="form-control"
            id="app_new_date"
          />
          <label for="app_new_date" class="wabident-info">Día</label>
        </div>
        <div v-show="!is_vacation" class="col-sm-2">
          <select
            v-model="selected_time_start"
            id="app_time_start"
            class="form-control custom-select"
          >
            <template v-if="clinic">
              <optgroup label="Mañana">
                <option
                  v-for="(slot, i) in day_slots.morning.start"
                  :key="i"
                  :value="slot"
                >
                  {{ slot }}
                </option>
              </optgroup>
              <optgroup label="Tarde">
                <option
                  v-for="(slot, i) in day_slots.afternoon.start"
                  :key="i"
                  :value="slot"
                >
                  {{ slot }}
                </option>
              </optgroup>
            </template>
          </select>
          <label for="app_new_time_start" class="wabident-info"
            >Hora de Inicio</label
          >
        </div>
        <div v-show="!is_vacation" class="col-sm-2">
          <select
            v-model="selected_time_end"
            id="app_time_end"
            class="form-control custom-select"
          >
            <template v-if="clinic">
              <optgroup label="Mañana">
                <option
                  v-for="(slot, i) in day_slots.morning.end"
                  :key="i"
                  :value="slot"
                >
                  {{ slot }}
                </option>
              </optgroup>
              <optgroup label="Tarde">
                <option
                  v-for="(slot, i) in day_slots.afternoon.end"
                  :key="i"
                  :value="slot"
                >
                  {{ slot }}
                </option>
              </optgroup>
            </template>
          </select>
          <label for="app_new_time_end" class="wabident-info"
            >Hora de Fin</label
          >
        </div>
        <div v-show="!is_vacation" class="col-sm-3">
          <select
            v-model.number="selected_box"
            id="app_new_box"
            class="form-control custom-select"
          >
            <template v-if="clinic">
              <option v-for="box in clinic.boxes" :key="box.id" :value="box.id">
                {{ box.name }}
              </option>
            </template>
          </select>
          <label for="app_new_box" class="wabident-info">Box</label>
        </div>
      </div>
      <!-- Notes -->
      <div class="form-group row">
        <div class="col-sm-12">
          <textarea
            v-model.lazy="app_notes"
            class="form-control"
            id="app_new_notes"
            rows="4"
          ></textarea>
          <label for="app_new_box" class="wabident-info">Info</label>
        </div>
      </div>
      <!-- Checks -->
      <div class="form-group row">
        <div class="col-sm-12">
          <div class="form-check form-check-inline">
            <input
              v-model="is_urgent"
              class="form-check-input"
              type="checkbox"
              id="app_new_urgent"
              value="true"
            />
            <label class="form-check-label" for="app_new_urgent"
              >Urgencia</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="is_insurance"
              class="form-check-input"
              type="checkbox"
              id="app_new_insurance"
              value="true"
            />
            <label class="form-check-label" for="app_new_insurance">
              <span v-if="is_aesthetic">Coolsculpting</span>
              <span v-else>Seguro</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="is_revision"
              class="form-check-input"
              type="checkbox"
              id="app_new_revision"
              value="true"
            />
            <label class="form-check-label" for="app_new_revision">
              <span v-if="is_aesthetic">Nota</span>
              <span v-else>Revisión</span>
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input
              v-model="is_marked"
              class="form-check-input"
              type="checkbox"
              id="app_new_marked"
              value="true"
            />
            <label class="form-check-label" for="app_new_marked">
              <span v-if="is_aesthetic">Láser/Qx</span>
              <span v-else>Trabajo en clínica</span>
            </label>
          </div>

          <div class="form-check form-check-inline">
            <input
              v-model="is_custom_color"
              class="form-check-input"
              type="checkbox"
              id="app_new_color"
              value="true"
            />
            <label class="form-check-label" for="app_new_color"
              >Color Personalizado</label
            >
            <div v-if="is_custom_color" class="color-container">
              <compact-picker v-model="colors" :palette="palette">
              </compact-picker>
            </div>
          </div>
        </div>
      </div>
      <!-- Buttons -->
      <div v-show="is_edit" class="form-group row">
        <div class="col d-flex flex-row justify-content-center status-buttons">
          <button
            type="button"
            @click="setAppStatus('arrived')"
            class="btn btn-wabident-status presented status-check"
            :class="{
              unmarked: !(
                app_status == 'arrived' ||
                app_status == 'attended' ||
                app_status == 'treated' ||
                app_status == 'gone'
              ),
            }"
          >
            Se presentó
          </button>
          <button
            type="button"
            @click="setAppStatus('attended')"
            class="btn btn-wabident-status attended status-check"
            :class="{
              unmarked: !(
                app_status == 'attended' ||
                app_status == 'treated' ||
                app_status == 'gone'
              ),
            }"
          >
            Atendido
          </button>

          <button
            v-if="clinic_type == 'aesthetic'"
            type="button"
            @click="setAppStatus('treated')"
            class="btn btn-wabident-status treated status-check"
            :class="{
              unmarked: !(app_status == 'treated' || app_status == 'gone'),
            }"
          >
            Tratado
          </button>

          <button
            type="button"
            @click="setAppStatus('gone')"
            class="btn btn-wabident-status gone status-check"
            :class="{ unmarked: !(app_status == 'gone') }"
          >
            Se fue
          </button>
          <button
            type="button"
            @click="setAppStatus('cancel')"
            class="btn btn-wabident-status cancelled status-check"
            :class="{ unmarked: app_status != 'cancel' }"
          >
            Cancelada
          </button>
          <button
            v-if="clinic_type == 'dental'"
            type="button"
            @click="setAppStatus('absent')"
            class="btn btn-wabident-status absent status-check"
            :class="{ unmarked: app_status != 'absent' }"
          >
            No Acude/No Avisa
          </button>
        </div>
      </div>
      <!-- Create Appointment -->
      <div class="form-group row">
        <div class="col text-center">
          <button
            :disabled="is_loading"
            @click="onDelete"
            v-show="is_edit"
            class="btn btn-wabident-delete float-left"
            style="width: 53.6px; height: 53.6px"
          ></button>
          <button
            :disabled="save_disabled"
            @click="submit"
            role="button"
            class="btn btn-primary btn-wabident"
            style="min-width: 316px"
          >
            <span v-if="is_edit">Guardar cambios</span>
            <span v-else>Crear cita</span>
            <i
              style="margin-top: 5px"
              v-if="is_loading"
              class="fas fa-circle-notch fa-spin float-right"
            ></i>
          </button>
          <a
            class="whatsapp float-right"
            @click="onReminderSent"
            target="_blank"
            :href="whatsapp_url"
            v-if="show_whatsapp"
          >
            <i class="fab fa-whatsapp" :style="ws_button_style"></i>
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    locked: { default: false },
    clinics: { default: null },
  },
  data() {
    return {
      is_edit: false,
      appointment: null,
      app_status: "pending",
      selected_customer: 0,
      selected_box: 0,
      selected_date: new Date().toISODate(),
      selected_time_start: "09:00",
      selected_time_end: "09:30",
      is_insurance: false,
      is_urgent: false,
      is_revision: false,
      is_marked: false,
      reminder_sent: false,
      app_notes: "",
      is_loading: false,
      selected_treatment_ids: [],
      selected_treatment: 0,
      customer_treatments: [],
      doctor_id: null,
      app_origin: null,
      changed_reason: null,
      is_custom_color: false,
      current_appointments: [],
      colors: {
        hex: "#000000",
      },
      palette: [
        "#E57373",
        "#F06292",
        "#BA68C8",
        "#9575CD",
        "#7986CB",
        "#64B5F6",
        "#4FC3F7",
        "#4DD0E1",
        "#4DB6AC",
        "#81C784",
        "#AED581",
        "#DCE775",
        "#FFF176",
        "#FFD54F",
        "#FFB74D",
        "#FF8A65",
        "#A1887F",
        "#E0E0E0",
        "#90A4AE",
      ],
    };
  },
  watch: {
    selected_treatment(value) {
      if (value) {
        if (this.selected_treatment_ids.indexOf(value) < 0) {
          this.selected_treatment_ids.push(value);
        }
        this.selected_treatment = 0;
      }
    },
  },
  methods: {
    onReminderSent() {
      this.reminder_sent = true;
    },
    showCustomerNew(customer) {
      this.is_edit = false;
      this.selected_box = this.clinic.boxes[0].id;
      this.selected_date = new Date().toISODate();
      this.app_status = "pending";
      this.is_insurance = false;
      this.is_urgent = false;
      this.is_revision = false;
      this.is_marked = false;
      this.doctor_id = null;
      this.app_origin = null;
      this.changed_reason = null;
      this.reminder_sent = false;
      this.is_custom_color = false;
      this.colors = { hex: "#000000" };

      setTimeout(() => {
        this.$refs.search.select(customer);
      }, 100);
      this.$refs.modal.show();
    },
    show(date, timeslot, box) {
      this.is_edit = false;
      this.selected_box = box ? box.id || 0 : 0;
      this.selected_date = date.toISODate();
      let hour = Number(timeslot.hour);
      let minute = Number(timeslot.minute);
      this.selected_treatment_ids = [];
      this.selected_treatment = 0;
      this.appointment = null;
      this.app_status = "pending";
      this.selected_customer = null;
      this.doctor_id = null;
      this.reminder_sent = false;
      this.is_custom_color = false;
      this.colors = { hex: "#000000" };
      this.app_origin = null;
      this.changed_reason = null;

      this.selected_time_start = pad(hour, 2) + ":" + pad(minute, 2);
      minute = minute + 30;
      if (minute >= 60) {
        minute = minute - 60;
        hour += 1;
      }

      this.is_insurance =
        this.is_urgent =
        this.is_revision =
        this.is_marked =
          false;
      this.app_notes = "";

      this.selected_time_end = pad(hour, 2) + ":" + pad(minute, 2);
      this.$refs.modal.show();
    },
    showEdit(appointment, date, timeslot, box) {
      this.is_edit = true;
      this.appointment = appointment;
      this.selected_box = box ? box.id || 0 : 0;

      let date_from = moment(appointment.date_from).toDate();
      let date_to = moment(appointment.date_to).toDate();

      this.selected_date = date_from.toISODate();
      this.selected_time_start =
        pad(date_from.getHours(), 2) + ":" + pad(date_from.getMinutes(), 2);

      this.selected_time_end =
        pad(date_to.getHours(), 2) + ":" + pad(date_to.getMinutes(), 2);

      this.app_notes = appointment.notes;
      this.is_insurance = appointment.is_insurance;
      this.is_revision = appointment.is_revision;
      this.is_urgent = appointment.is_urgent;
      this.is_marked = appointment.is_marked;
      this.app_status = appointment.status || "pending";
      this.doctor_id = appointment.doctor_id;
      this.app_origin = appointment.app_origin;
      this.changed_reason = appointment.changed_reason;

      this.is_custom_color = !(
        appointment.color == null ||
        appointment.color == "#000000" ||
        appointment.color == "null"
      );

      if (this.is_custom_color) {
        this.colors = { hex: appointment.color };
      } else {
        this.colors = { hex: "#000000" };
      }

      this.reminder_sent = appointment.reminder_sent || false;

      setTimeout(() => {
        this.$refs.search.select(appointment.customer);
      }, 100);
      this.$refs.modal.show();
    },
    customerSelected(customer) {
      if (customer) {
        this.selected_customer = customer;
        this.selected_treatment_ids = [];
        this.selected_treatment = 0;
        this.getCustomerTreatments(customer);
      }
    },
    onNewCustomer() {
      this.$refs.customerNew.show();
    },
    onCustomerCreated(customer) {
      setTimeout(() => {
        this.$refs.search.select(customer);
      }, 100);
    },
    getCustomerTreatments(customer) {
      this.customer_treatments = [];
      axios
        .get(`/customer/${customer.id}/curr_treatments`)
        .then((response) => {
          let ret = {};
          response.data.forEach((treatment) => {
            ret[treatment.id] = treatment;
          });
          this.customer_treatments = ret;
          if (this.is_edit && this.appointment) {
            this.selected_treatment_ids = this.appointment.treatments.map(
              (t) => t.id
            );
          }
        })
        .catch((error) => {
          console.log("[ERR] getCustomerTreatments", error);
        });
    },
    customerDeselected() {
      this.selected_customer = null;
      this.selected_treatment_ids = [];
      this.selected_treatment = 0;
      this.customer_treatments = [];
      this.app_status = "pending;";
    },
    removeTreatment(id) {
      this.selected_treatment_ids = this.selected_treatment_ids.filter(
        (treatment) => {
          return id != treatment;
        }
      );
    },
    setAppStatus(status) {
      if (status == this.app_status) {
        this.app_status = "pending";
      } else {
        this.app_status = status;
      }
    },
    submit() {
      if (this.is_loading) {
        return;
      }

      if (!this.validate()) {
        return;
      }

      if (this.is_edit) {
        this.modifyAppointment();
      } else {
        this.createAppointment();
      }
    },
    validate() {
      let ret = true;
      return ret;
    },
    getRequestParameters() {
      let from_moment = moment.utc(
        this.selected_date + " " + this.selected_time_start
      );

      let to_moment = moment.utc(
        this.selected_date + " " + this.selected_time_end
      );

      return {
        customer_id: this.selected_customer ? this.selected_customer.id : 0,
        treatment_ids: this.selected_treatment_ids
          ? this.selected_treatment_ids
          : [],
        box_id: this.selected_box || 0,
        date_from: from_moment.toISOString(),
        date_to: to_moment.toISOString(),
        is_insurance: this.is_insurance,
        is_revision: this.is_revision,
        is_urgent: this.is_urgent,
        is_marked: this.is_marked,
        notes: this.app_notes,
        status: this.app_status,
        doctor_id: this.doctor_id,
        reminder_sent: this.reminder_sent,
        color: this.is_custom_color ? this.colors.hex : null,
        app_origin: this.app_origin,
        changed_reason: this.changed_reason,
      };
    },
    createAppointment() {
      this.is_loading = true;
      axios
        .post(
          `/clinic/${this.clinic.id}/appointments`,
          this.getRequestParameters()
        )
        .then((response) => {
          if (response.data.error) {
            this.errorwindow.show("Error", response.data.error);
          } else {
            this.$emit("onSubmit");
            this.msgwindow.addMessage("success", "Cita creada con éxito");
            this.$refs.modal.hide();
          }
          this.is_loading = false;
        })
        .catch((error) => {
          console.log("[ERR] createAppoinment:", error.response.data);
          this.is_loading = false;
        });
    },
    modifyAppointment() {
      if (this.appointment) {
        this.is_loading = true;
        axios
          .post(
            `/clinic/${this.clinic.id}/appointments/${this.appointment.id}`,
            this.getRequestParameters()
          )
          .then((response) => {
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error);
            } else {
              this.$emit("onSubmit");
              this.msgwindow.addMessage("success", "Cita modificada con éxito");
              this.$refs.modal.hide();
            }
            this.is_loading = false;
          })
          .catch((error) => {
            console.log("[ERR] modifyAppointment:", error.response.data);
            this.is_loading = false;
          });
      }
    },
    onDelete() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea borrar esta cita?",
        "Borrar",
        "Cancelar",
        this.onDeleteConfirm,
        null
      );
    },
    onDeleteConfirm() {
      if (this.is_edit && !this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/clinic/${this.clinic.id}/appointments/${this.appointment.id}/delete`,
            { '_method':'delete'}
          )
          .then((response) => {
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error);
            } else {
              this.$emit("onSubmit");
              this.msgwindow.addMessage("success", "Cita borrada con éxito");
              this.$refs.modal.hide();
            }
            this.is_loading = false;
          })
          .catch((error) => {
            console.log("[ERR] deleteAppointment:", error.response.data);
            this.is_loading = false;
          });
      }
    },
    getTreatmentName(treatment) {
      let ret = treatment.product ? treatment.product.name : treatment.notes;
      if (treatment.teeth && treatment.teeth.length > 0) {
        ret += "(" + treatment.teeth.join(",") + ")";
      }
      return ret;
    },
    getWhatsappMessage(date) {
      if (!this.clinic) return "";

      if (this.clinic.config && this.clinic.config.whatsapp_msg) {
        let msg = this.clinic.config.whatsapp_msg
          .replace("@clinica@", this.clinic.name)
          .replace("@fecha@", date)
          .replace("@hora@", this.selected_time_start);
        return msg;
      }

      return `Le recordamos que tiene cita en *${this.clinic.name}* el *${date} a las ${this.selected_time_start}*`;
    },
  },
  computed: {
    created_at() {
      if (!this.appointment) return "";
      return moment(this.appointment.created_at).format("DD/MM/YYYY hh:mm");
    },
    updated_at() {
      if (!this.appointment) return "";
      return moment(this.appointment.updated_at).format("DD/MM/YYYY hh:mm");
    },
    user_name() {
      if (!this.appointment || !this.appointment.user) return "";
      return this.appointment.user.name;
    },
    ws_button_style() {
      if (!this.reminder_sent) return {};
      return {
        color: "var(--theme-color-attended) !important",
      };
    },
    customer_edit_link() {
      if (!this.selected_customer) return null;
      return `/customer/${this.selected_customer.id}/edit`;
    },
    save_disabled() {
      return (
        this.is_loading ||
        this.is_vacation ||
        this.selected_customer == null
      );
    },
    dayindex() {
      let d = moment(this.selected_date).toDate().getDay();
      if (d == 0) return 6;
      return d - 1;
    },
    is_aesthetic() {
      if (!this.clinic) return false;
      return this.clinic.type == "aesthetic";
    },
    is_vacation() {
      if (!this.clinic) return true;

      let slots = this.day_slots;
      if (!slots.checked) return true;

      let found = this.clinic.schedule.vacations.find((vacation) => {
        return vacation == this.date.toISODate();
      });

      if (found) return true;

      return false;
    },
    date() {
      return moment.utc(this.selected_date).toDate();
    },
    day_slots() {
      if (!this.clinic) return [];
      return this.clinic.schedule.daily_timeslots[this.dayindex];
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    show_whatsapp() {
      return (
        this.is_edit &&
        this.app_status == "pending" &&
        this.selected_customer != null
      );
    },
    whatsapp_url() {
      let from_moment = moment.utc(
        this.selected_date + " " + this.selected_time_start
      );
      let date = from_moment.format("LL");

      let number = String(this.selected_customer.phone)
        .replace(" ", "")
        .replace("-", "")
        .replace("+", "")
        .replace("(", "")
        .replace(")", "");

      let text = encodeURIComponent(this.getWhatsappMessage(date));

      if (this.clinic.config.use_wa_app) {
        return `whatsapp://send?phone=34${number}&text=${text}`;
      }

      return `https://wa.me/34${number}?text=${text}`;
    },
    dialog_title() {
      return this.is_edit ? "Editar Cita" : "Nueva Cita";
    },
    selected_customer_treatments() {
      if (!this.customer_treatments) return [];
      return Object.values(this.customer_treatments).filter((treatment) => {
        return this.selected_treatment_ids.indexOf(treatment.id) >= 0;
      });
    },
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    ...mapGetters(["clinic", "msgwindow", "errorwindow"]),
  },
};
</script>
