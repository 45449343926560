<template>
  <div class="customer-quotation">
    <div class="customer-quotation__info">
      <span class="date" v-text="pretty_date" />
      <span class="name" v-text="pretty_name" />
      <div class="desc" v-text="pretty_desc" />
    </div>
    <div class="customer-quotation__edit fcenter">
      <button
        v-if="!quotation.is_approved"
        type="button"
        class="btn btn-wabident-round transparent"
        @click="$emit('edit', quotation)"
      >
        Editar
      </button>
    </div>
    <div
      class="customer-quotation__done fcenter"
      :class="{ done: quotation.is_approved }"
    >
      <i
        class="wabident icon-wabident-icon-check"
        @click="$emit('approve', quotation)"
      ></i>
    </div>
    <div class="customer-quotation__print fcenter">
      <a :href="quotation_pdf_link" target="_blank">
        <i class="wabident icon-wabident-print"></i>
      </a>
    </div>
    <div @click="sendMail" class="customer-quotation__email fcenter">
      <i class="wabident icon-wabident-mail"></i>
    </div>
    <div class="customer-quotation__total">{{ pretty_total }}</div>
  </div>
</template>
<script>
export default {
  props: ["quotation", "customer"],
  data() {
    return {};
  },
  computed: {
    quotation_pdf_link() {
      return `/quotation/${this.quotation.id}/pdf`;
    },
    pretty_date() {
      return moment(this.quotation.created_at).toDate().toLocaleDateString();
    },
    pretty_name() {
      return `Presupuesto #${this.quotation.code}`;
    },
    total() {
      return this.quotation.quotationlines.reduce((x, t) => {
        return (
          x +
          Number(t.price) * Number(t.quantity) * (1 - Number(t.discount) / 100)
        );
      }, 0);
    },
    pretty_total() {
      return WabiDent.fmtMoney(this.total, 0);
    },
    pretty_desc() {
      if (this.quotation.notes && this.quotation.notes != "")
        return this.quotation.notes;

      let ret = this.quotation.quotationlines
        .map((t) => {
          if (t.product) {
            return t.product.name;
          } else {
            return "";
          }
        })
        .reduce((cur, val) => {
          return cur + " " + val;
        }, "");

      if (ret.lenght > 30) {
        ret = ret.substr(0, 30) + "...";
      }

      return ret;
    },
    ...mapGetters(["msgwindow"]),
  },
  methods: {
    sendMail() {
      this.msgwindow.info("Enviando presupuesto ...");
      axios
        .get(`/quotation/${this.quotation.id}/email`)
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.msgwindow.error(data.message);
          } else {
            this.msgwindow.success(
              "Presupuesto enviado a " + this.customer.email
            );
          }
        })
        .catch((error) => {
          console.log("[ERR] sendQuotationEmail ", error);
          this.msgwindow.error("Error enviando presupuesto !");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.customer-quotation {
  padding: 9px;
  background-color: var(--theme-grey-bg);
  border-bottom: 1px var(--theme-grey) solid;
  margin-bottom: 9px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  .fcenter {
    justify-content: center;
  }

  &__info {
    flex: 1 1 auto;
    .date {
      font-size: 14px;
      color: var(--theme-grey);
      font-weight: 500;
      margin-right: 10px;
    }
    .name {
      font-size: 16px;
      color: var(--theme-text-color);
      font-weight: normal;
      line-height: 1.25;
    }
    .desc {
      font-size: 12px;
      color: var(--theme-text-color);
      font-weight: 500;
    }
  }

  &__price {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex: 0 1 auto;
  }

  &__edit {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    flex: 0 0 120px;
  }

  &__done,
  &__email,
  &__print {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex: 0 0 35px;
    cursor: pointer;
    transition: all 0.2s;

    a {
      color: inherit;
      text-decoration: none;
    }

    i {
      font-size: 24px;
    }

    &.done {
      color: var(--theme-color-attended);
    }

    &:hover {
      color: var(--theme-color);
    }
  }

  &__total {
    font-size: 18px;
    color: var(--theme-color-attended);
    letter-spacing: 0.36px;
    margin-left: 30px;
  }
}
</style>
