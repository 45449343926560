<template>
  <b-modal
    :id="id"
    @close="onClose"
    @dismiss="onClose"
    ref="signdocument"
    :title="title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col text-center">
        <p>
          Escanea este código con tu móvil o tablet para abrir la página de
          firma
        </p>
        <qr-code
          class="show-qr-code__qrcode"
          :text="url"
          :size="400"
          color="black"
          bg-color="white"
          error-level="L"
        ></qr-code>
      </div>
    </div>

    <div class="row form-group">
      <div class="col text-center">
        <a :href="url" target="_blank"
          >Comparte este enlace por WhatsApp si no puedes abrir el QR Code</a
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col text-center">
        <button @click="onClose" class="btn btn-primary btn-wabident">
          Cerrar
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    id: { required: true },
    url: { required: true },
    title: { default: "Firmar Documento" },
  },
  data() {
    return {};
  },
  methods: {
    onClose() {
      this.$refs.signdocument.hide();
      this.$emit("close");
    },
    show() {
      this.$refs.signdocument.show();
    },
  },
};
</script>

<style lang="scss">
.show-qr-code {
  &__qrcode {
    img {
      margin: auto;
    }
  }
}
</style>
