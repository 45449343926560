<template>
  <div class="container clinic-invoices">
    <div class="row">
      <!-- INVOICE DATA -->
      <div class="col clinic-data">
        <div class="row">
          <div
            class="col d-flex justify-content-center align-items-center mt-4"
          >
            <img
              :src="clinic_logo"
              :alt="clinic_name"
              style="height: 78px"
              class="clinic-data__logo"
            />
          </div>
        </div>

        <div class="row form-group">
          <div class="col">
            <h2 class="clinic-data__title">Datos de facturacion</h2>
          </div>
        </div>
        <!-- NAME -->
        <div class="row form-group">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_name"
              v-model="clinic_data.name"
            />
            <label for="clinic_data_name" class="wabident-info"
              >Razón social</label
            >
          </div>
        </div>
        <!-- NIF / PHONE -->
        <div class="row form-group">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_cif"
              v-model="clinic_data.cif"
            />
            <label for="clinic_data_cif" class="wabident-info">NIF/CIF</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_name"
              v-model="clinic_data.phone"
            />
            <label for="clinic_data_phone" class="wabident-info"
              >Teléfono</label
            >
          </div>
        </div>
        <!-- ADDRESS / CITY -->
        <div class="row form-group">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_address"
              v-model="clinic_data.address"
            />
            <label for="clinic_data_address" class="wabident-info"
              >Dirección</label
            >
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_name"
              v-model="clinic_data.city"
            />
            <label for="clinic_data_city" class="wabident-info">Ciudad</label>
          </div>
        </div>
        <!-- ZIPCODE / PROVINCE -->
        <div class="row form-group">
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_province"
              v-model="clinic_data.province"
            />
            <label for="clinic_data_province" class="wabident-info"
              >Provincia</label
            >
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_name"
              v-model="clinic_data.zipcode"
            />
            <label for="clinic_data_zipcode" class="wabident-info">C.P.</label>
          </div>
        </div>
        <!-- EMAIL / IBAN -->
        <div class="row form-group">
          <div class="col">
            <input
              type="email"
              class="form-control"
              id="clinic_data_email"
              v-model="clinic_data.email"
            />
            <label for="clinic_data_email" class="wabident-info">Email</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              id="clinic_data_name"
              v-model="clinic_data.iban"
            />
            <label for="clinic_data_iban" class="wabident-info">IBAN</label>
          </div>
        </div>
        <!-- SUBMIT -->
        <div class="row form-group">
          <div
            class="col text-center d-flex flex-row align-items-center justify-content-center"
          >
            <button
              @click="saveClinicData"
              :disable="is_loading"
              class="btn btn-primary btn-wabident-round d-flex flex-row align-items-center justify-content-center"
            >
              <i
                class="wabident icon-wabident-save float-left"
                style="margin-right: 15px"
              ></i>
              Guardar cambios
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      clinic_id: null,
      is_loading: false,
      clinic_data: {
        name: "",
        cif: "",
        phone: "",
        address: "",
        city: "",
        province: "",
        zipcode: "",
        email: "",
        iban: "",
      },
    };
  },
  computed: {
    clinic_logo() {
      if (!this.clinic) return "";
      return this.clinic.invoice_logo;
    },
    clinic_name() {
      if (!this.clinic) return "";
      return this.clinic.name;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  watch: {
    clinic(val) {
      this.loadClinicData();
    },
  },
  methods: {
    saveClinicData() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/clinic/${this.clinic.id}`, this.clinic_data)
          .then((response) => {
            this.$emit("clinicUpdated");
            this.msgwindow.success("Clínica actualizada con éxito");
          })
          .catch((err) => {
            this.msgwindow.error("Error actualizando clinica");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    loadClinicData() {
      this.clinic_data.name = this.clinic.name;
      this.clinic_data.cif = this.clinic.cif;
      this.clinic_data.phone = this.clinic.phone;
      this.clinic_data.email = this.clinic.email;
      this.clinic_data.address = this.clinic.address;
      this.clinic_data.city = this.clinic.city;
      this.clinic_data.province = this.clinic.province;
      this.clinic_data.zipcode = this.clinic.zipcode;
      this.clinic_data.iban = this.clinic.iban;
    },
  },
  mounted() {
    this.loadClinicData();
  },
};
</script>

<style lang="scss">
.clinic-data {
  &__title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2.1px;
    text-transform: uppercase;
  }

  &__logo {
    margin: 4px auto 44px auto;
  }
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}
</style>
