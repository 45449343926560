<template>
  <b-modal
    id="clinic-show-availability"
    class="clinic-show-availability"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <!-- Información Doctor y Clinica-->
    <div class="row form-group">
      <div class="col-md-6">
        <label for="" class="wabident-info">Doctor</label>
        <h4>{{ doctor_name }}</h4>
      </div>

      <div class="col-md-6">
        <label for="" class="wabident-info">Clínica</label>
        <h4>{{ clinic_name }}</h4>
      </div>
    </div>

    <!-- Datos Cita -->
    <div class="row form-group">
      <!-- Fecha -->
      <div class="col-md-3">
        <input
          class="form-control"
          type="date"
          v-model="selected_date"
          id="show_avail_date"
        />
        <label class="wabident-info" for="show_avail_date">Fecha</label>
      </div>
      <!-- Hora Inicio -->
      <div v-show="!is_vacation" class="col-sm-3">
        <select
          v-model="selected_time_start"
          id="selected_time_start"
          class="form-control custom-select"
        >
          <template v-if="clinic">
            <optgroup label="Mañana">
              <option
                v-for="(slot, i) in day_slots.morning.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
            <optgroup label="Tarde">
              <option
                v-for="(slot, i) in day_slots.afternoon.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
          </template>
        </select>
        <label for="selected_time_start" class="wabident-info"
          >Hora de Inicio</label
        >
      </div>
      <!-- Hora Fin -->
      <div v-show="!is_vacation" class="col-sm-3">
        <select
          v-model="selected_time_end"
          id="selected_time_end"
          class="form-control custom-select"
        >
          <template v-if="clinic">
            <optgroup label="Mañana">
              <option
                v-for="(slot, i) in day_slots.morning.end"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
            <optgroup label="Tarde">
              <option
                v-for="(slot, i) in day_slots.afternoon.end"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
          </template>
        </select>
        <label for="selected_time_end" class="wabident-info">Hora de Fin</label>
      </div>

      <div v-show="!is_vacation" class="col-sm-3">
        <select
          id="is_available"
          class="form-control custom-select"
          v-model="is_available"
        >
          <option :value="null">Elige</option>
          <option :value="1">Disponible</option>
          <option :value="0">No disponible</option>
        </select>
        <label for="is_available" class="wabident-info">Disponibilidad</label>
      </div>
    </div>

    <div v-if="!is_editing" class="row form-group">
      <div class="col">
        <h4>Repetir</h4>
      </div>
    </div>

    <!-- Repetición -->
    <div v-if="!is_editing" class="row form-group">
      <div class="col-md-3">
        <select
          id="repeat_mode"
          class="form-control custom-select"
          v-model="repeat_mode"
        >
          <option :value="null">No repetir</option>
          <option value="week">El resto de la semana</option>
          <option value="month">El resto del mes</option>
          <option value="year">El resto del año</option>
        </select>
      </div>
      <div class="col" v-if="repeat_mode">
        <b>¡Atención!</b><br />
        Se crearán entradas desde el <b>{{ pretty_selected_date }}</b> hasta el
        <b>{{ pretty_repeat_date }}</b
        >. <small>(Excl. festivos)</small>
      </div>
    </div>

    <!-- Fila de Botones -->
    <div class="form-group row mt-5">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          :disabled="save_disabled"
          @click="onSubmit"
          role="button"
          class="btn btn-primary btn-wabident"
          style="min-width: 316px"
        >
          <span v-if="is_editing">Modificar</span>
          <span v-else>Crear</span>
          <i
            style="margin-top: 5px"
            v-if="is_loading"
            class="fas fa-circle-notch fa-spin float-right"
          ></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from "moment";
export default {
  props: ["doctor", "selectedclinic"],
  data() {
    return {
      availability_id: null,
      is_editing: false,
      is_loading: false,
      selected_date: new Date().toISODate(),
      selected_time_start: null,
      selected_time_end: null,
      is_available: null,
      repeat_mode: null,
    };
  },
  computed: {
    date() {
      return moment.utc(this.selected_date).toDate();
    },
    pretty_selected_date() {
      return moment.utc(this.selected_date).format("DD/MM/YYYY");
    },
    pretty_repeat_date() {
      let d = moment.utc(this.selected_date);

      switch (this.repeat_mode) {
        case "week":
          d.endOf("week");
          break;
        case "month":
          d.endOf("month");
          break;
        case "year":
          d.endOf("year");
          break;
      }

      return d.format("DD/MM/YYYY");
    },
    dayindex() {
      let d = moment.utc(this.selected_date).toDate().getDay();
      if (d == 0) return 6;
      return d - 1;
    },
    day_slots() {
      if (!this.clinic) return [];
      let day = this.dayindex;
      console.log("Day is " + day);
      return this.clinic.schedule.daily_timeslots[day];
    },
    is_vacation() {
      if (!this.clinic) return true;

      let slots = this.day_slots;
      if (!slots || !slots.checked) return true;

      let found = this.clinic.schedule.vacations.find((vacation) => {
        return vacation == this.date.toISODate();
      });

      if (found) return true;

      return false;
    },
    doctor_name() {
      if (!this.doctor) return "";
      return this.doctor.name;
    },
    clinic_name() {
      if (!this.clinic) return "";
      return this.clinic.clinic_names[this.selectedclinic];
    },
    dialog_title() {
      if (!this.is_editing) return "Crear Disponibibilidad";
      return "Modificar Disponibilidad";
    },
    save_disabled() {
      return (
        this.is_loading ||
        this.selected_date == null ||
        this.selected_time_start == null ||
        this.selected_time_end == null ||
        this.is_available == null
      );
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  methods: {
    showNew() {
      this.availability_id = null;
      this.is_editing = false;
      this.is_loading = false;
      this.is_available = null;
      this.selected_date = moment().toDate().toISODate();
      this.selected_time_start = null;
      this.selected_time_end = null;
      this.repeat_mode = null;
      this.$refs.modal.show();
    },
    showNewDate(date) {
      this.availability_id = null;
      this.is_editing = false;
      this.is_loading = false;
      this.is_available = null;
      this.repeat_mode = null;

      this.selected_date = date.toISODate();

      let hours = date.getHours();
      let minutes = Math.floor(date.getMinutes() / 15) * 15;

      this.selected_time_start = pad(hours, 2) + ":" + pad(minutes, 2);
      this.selected_time_end = null;

      console.log(this.selected_time_start);

      this.$refs.modal.show();
    },
    showEdit(availability) {
      this.is_editing = true;
      this.is_loading = false;

      this.availability_id = availability.id;
      this.clinic_id = availability.clinic_id;
      this.doctor_id = availability.doctor_id;
      this.is_available = availability.is_available;

      let date_from = moment(availability.date_from).toDate();
      let date_to = moment(availability.date_to).toDate();

      this.selected_date = date_from.toISODate();
      this.selected_time_start =
        pad(date_from.getHours(), 2) + ":" + pad(date_from.getMinutes(), 2);

      this.selected_time_end =
        pad(date_to.getHours(), 2) + ":" + pad(date_to.getMinutes(), 2);

      this.$refs.modal.show();
    },
    onDelete() {
      this.errorwindow.show(
        "Confirmar",
        "¿Desea borrar la asignación?. No se podrá recuperar.",
        "Borrar",
        "Cancelar",
        () => {
          this.reallyDelete();
        }
      );
    },
    reallyDelete() {
      if (this.is_loading) return;
      this.is_loading = true;
      let url = `/availability/${this.availability_id}`;
      let params = {
        _method: "delete",
      };

      axios
        .post(url, params)
        .then((response) => {
          if (response.data.error) {
            this.errorwindow.show("Error", response.data.error);
          } else {
            this.msgwindow.success("Borrado con éxito");
            this.$refs.modal.hide();
          }
        })
        .catch((error) => {
          this.msgwindow.error("Error borrando ...");
          this.$refs.modal.hide();
        })
        .then(() => {
          this.is_loading = false;
          this.$emit("submit");
        });
    },
    onSubmit() {
      if (this.is_loading) return;
      this.is_loading = true;

      let from_moment = moment.utc(
        this.selected_date + " " + this.selected_time_start
      );

      let to_moment = moment.utc(
        this.selected_date + " " + this.selected_time_end
      );

      let params = {
        clinic_id: this.selectedclinic,
        doctor_id: this.doctor.doctor.id,
        date_from: from_moment.toISOString(),
        date_to: to_moment.toISOString(),
        is_available: this.is_available,
        repeat: this.repeat_mode,
      };

      let url = "/availability";
      if (this.is_editing) {
        url = `/availability/${this.availability_id}`;
      }

      axios
        .post(url, params)
        .then((response) => {
          if (response.data.error) {
            this.errorwindow.show("Error", response.data.error);
          } else {
            this.msgwindow.success("Guardado con éxito");
            this.$refs.modal.hide();
          }
        })
        .catch((error) => {
          this.msgwindow.error("Error guardando ...");
          this.$refs.modal.hide();
        })
        .then(() => {
          this.is_loading = false;
          this.$emit("submit");
        });
    },
  },
};
</script>

<style lang="scss">
#clinic-show-availability {
  .modal-dialog {
    max-width: 50%;
  }
}
</style>
