<template>
  <b-modal
    id="customer-show-document"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col-sm-12">
        <customer-search
          id="show_document_customer"
          ref="search"
          :locked="locked"
          @customerSelected="customerSelected"
          @customerDeselected="customerDeselected"
        ></customer-search>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <select
          v-model="type"
          @change="template_id = null"
          id="show_document_type"
          class="custom-select form-control"
          :disabled="is_editing"
        >
          <option value="consent">Consentimiento</option>
          <option value="proof">Justificante</option>
          <option value="report">Informe</option>
          <option value="prescription">Receta</option>
          <option value="document">Archivo</option>
        </select>
        <label for="show_document_type" class="wabident-info"
          >Tipo de Documento</label
        >
      </div>
      <div class="col" v-if="is_templatable">
        <select
          v-model="template_id"
          id="show_document_template"
          class="custom-select form-control"
          :disabled="is_editing"
        >
          <option :value="null">Sin plantilla</option>
          <option
            :key="index"
            :value="template.id"
            v-for="(template, index) in templates"
          >
            {{ template.title }}
          </option>
        </select>
        <label for="show_document_template" class="wabident-info"
          >Plantilla</label
        >
      </div>
      <div class="col">
        <input
          class="form-control"
          type="date"
          id="show_document_date"
          v-model="created_at"
        />
        <label for="show_document_date" class="wabident-info">Fecha</label>
      </div>

      <div class="col">
        <select
          v-model="hour"
          id="show_document_hour"
          class="custom-select form-control"
        >
          <template v-if="clinic">
            <optgroup label="Mañana">
              <option
                v-for="(slot, i) in day_slots.morning.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
            <optgroup label="Tarde">
              <option
                v-for="(slot, i) in day_slots.afternoon.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
          </template>
        </select>
        <label for="show_document_hour" class="wabident-info">Hora</label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-3">
        <select
          v-model="doctor_id"
          id="show_document_doctor"
          class="custom-select form-control"
        >
          <option :value="null">Selecciona Doctor</option>
          <option
            v-for="(doctor, index) in doctors"
            :key="index"
            :value="doctor.doctor.id"
          >
            {{ doctor.name }}
          </option>
        </select>
        <label for="show_document_doctor" class="wabident-info">Doctor</label>
      </div>

      <div class="col">
        <input
          id="show_document_title"
          type="text"
          class="form-control"
          v-model="title"
        />
        <label for="show_document_title" class="wabident-info"
          >Cabecera del documento</label
        >
      </div>

      <div v-if="is_revokable" class="col-sm-3">
        <input
          type="date"
          v-model="revoke_date"
          id="show_document_revoke_date"
          class="form-control"
        />
        <label for="show_document_revoke_date" class="wabident-info"
          >Fecha de Revocación</label
        >
      </div>
    </div>

    <div class="row form-group">
      <div v-if="is_downloadable" class="col">
        <div class="mb-2" v-if="is_uploaded">
          Archivo subido
          <a class="btn-link" :href="file_link">{{ document.file_name }}</a>
        </div>

        <b-form-file
          v-model="file"
          :state="Boolean(file)"
          :placeholder="file_placeholder"
          class="wabident-file-input"
          :required="!is_editing"
          :disabled="is_loading"
        ></b-form-file>
      </div>
      <div v-else-if="!template_id" class="col">
        <quill-editor
          id="show_document_content"
          :options="options"
          v-model="content"
        ></quill-editor>
        <label for="show_document_content" class="wabident-info"
          >Texto del documento</label
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>

        <button
          @click="submitDocument"
          class="btn btn-primary btn-wabident vertical-align-middle"
          :disabled="!is_valid || is_loading"
        >
          <span v-if="is_loading">
            Guardando
            <i class="fas fa-spin fa-circle-notch"></i>
          </span>
          <span v-else-if="is_editing">Modificar Documento</span>
          <span v-else>Guardar Documento</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    locked: { default: true },
  },
  data() {
    return {
      is_loading: false,
      is_editing: false,
      document: null,
      hour: null,
      selected_customer: null,
      created_at: new Date().toISODate(),
      revoke_date: "",
      doctor_id: null,
      template_id: null,
      title: "",
      content: "",
      type: "consent",
      file: null,
      templates: [],
      options: {
        placeholder: "Introduce texto ...",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["blockquote", "code-block", "link"],
            ["clean"],
          ],
        },
      },
    };
  },
  watch: {
    type(val) {
      this.getTemplates();
    },
    template_id(val) {
      if (this.selected_template) {
        this.title = this.selected_template.title;
        this.content = this.selected_template.content;
      }
    },
  },
  computed: {
    selected_template() {
      return this.templates.find((template) => {
        return template.id == this.template_id;
      });
    },
    is_valid() {
      if (this.is_loading) return false;
      if (!this.doctor_id) return false;
      if (!this.type) return false;
      if (this.type == "document" && !this.file) return false;
      return true;
    },
    is_downloadable() {
      return this.type && this.type == "document";
    },
    is_templatable() {
      return (
        this.type && this.type !== "document" && this.type !== "prescription"
      );
    },
    is_uploaded() {
      return (
        this.is_editing &&
        this.is_downloadable &&
        this.document &&
        this.document.file_name &&
        this.document.file_name != ""
      );
    },
    file_link() {
      if (!this.is_uploaded) return "";
      return `/customer/${this.selected_customer.id}/document/${this.document.id}/download?action=download`;
    },
    file_placeholder() {
      if (this.is_uploaded) return "Elige un nuevo archivo para subir";

      return "Elige un archivo";
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    dialog_title() {
      return this.is_editing ? "Editar Documento" : "Crear Documento";
    },
    is_revokable() {
      return this.is_editing && this.type == "consent";
    },
    day_slots() {
      if (!this.clinic) return [];
      return this.clinic.schedule.daily_timeslots[this.dayindex];
    },
    dayindex() {
      let d = moment(this.created_at).toDate().getDay();
      if (d == 0) return 6;
      return d - 1;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    customerSelected(customer) {
      if (customer) {
        this.selected_customer = customer;
      }
    },
    customerDeselected() {
      this.selected_customer = null;
    },
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    showNew(customer) {
      this.is_editing = false;
      this.document = null;
      this.type = "consent";
      this.created_at = new Date().toISODate();
      this.doctor_id = null;
      this.title = "";
      this.content = "";
      this.revoke_date = "";
      this.template_id = null;
      this.hour = this.getFirstHour();

      this.selected_customer = customer;
      setTimeout(() => {
        this.$refs.search.select(customer);
      }, 100);

      this.show();
    },
    showEdit(customer, document) {
      this.is_editing = true;
      this.document = document;
      this.selected_customer = customer;

      this.type = document.type;
      this.created_at = moment(document.created_at).toDate().toISODate();
      this.doctor_id = document.doctor_id;
      this.title = document.title;
      this.content = document.content;
      this.template_id = document.template_id;
      this.hour = this.getHour(document.created_at);

      if (document.revoke_date && document.revoke_date != "") {
        this.revoke_date = moment(document.revoke_date).toDate().toISODate();
      } else {
        this.revoke_date = "";
      }

      setTimeout(() => {
        this.$refs.search.select(customer);
      }, 100);

      this.show();
    },
    getFirstHour() {
      return this.day_slots.morning.start[0];
    },
    getHour(date) {
      return moment(date).format("HH:mm");
    },
    getFormData() {
      let ret = new FormData();

      ret.append("created_at", this.created_at);
      ret.append("doctor_id", this.doctor_id);
      ret.append("customer_id", this.selected_customer.id);
      ret.append("title", this.title);
      ret.append("content", this.content);
      ret.append("type", this.type);
      ret.append("revoke_date", this.revoke_date);
      ret.append("template_id", this.template_id);
      ret.append("hour", this.hour);

      if (this.clinic) {
        ret.append("clinic_id", this.clinic.id);
      }

      if (this.type == "document") {
        ret.append("file", this.file);
      }

      return ret;
    },
    submitDocument() {
      if (this.is_editing) {
        this.editDocument();
      } else {
        this.createDocument();
      }
    },
    createDocument() {
      if (!this.is_loading) {
        this.is_loading = true;
        let formData = this.getFormData();
        axios
          .post("/document", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.msgwindow.success("Documento creado con éxito");
            this.$emit("submit");
            this.hide();
          })
          .catch((err) => {
            console.log("[ERR] createDocument", err);
            this.errorwindow.show("Error", "Error creando documento");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    editDocument() {
      if (!this.is_loading) {
        this.is_loading = true;
        let formData = this.getFormData();
        axios
          .post(`/document/${this.document.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            this.msgwindow.success("Documento modificado con éxito");
            this.$emit("submit");
            this.hide();
          })
          .catch((err) => {
            console.log("[ERR] editDocument", err);
            this.errorwindow.show("Error", "Error edidanto documento");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    getTemplates() {
      axios
        .get("/document_templates", {
          params: {
            type: this.type,
          },
        })
        .then((resp) => {
          this.templates = resp.data;
        })
        .catch((err) => {
          this.templates = [];
          this.msgwindow.error("Error cargando plantillas de documento");
        });
    },
    onDelete() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea borrar el documento?",
        "Borrar",
        "Cancelar",
        () => {
          if (!this.is_loading) {
            this.is_loading = true;
            axios
              .post(`/document/${this.document.id}`, { _method: "delete" })
              .then((resp) => {
                this.msgwindow.success("Documento borrado con éxito");
                this.$emit("submit");
                this.hide();
              })
              .catch((err) => {
                console.log("[ERR] deleteDocument", err);
                this.errorwindow.show("Error", "Error borrando el documento");
              })
              .then(() => {
                this.is_loading = false;
              });
          }
        }
      );
    },
  },
  mounted() {
    this.getTemplates();
  },
};
</script>

<style lang="scss">
#customer-show-document {
  .modal-dialog {
    max-width: 50vw;
  }

  #show_document_content .ql-container {
    height: 250px;
    overflow-y: scroll;
  }
}
</style>
