<template>
  <div class="customer-document">
    <div class="customer-document__info">
      <div class="customer-document__info__header">
        {{ date_time }}
        <span v-if="document.doctor_id">- {{ doctor_name }}</span>
      </div>
      {{ document_summary }}
    </div>
    <div class="flex-spacer"></div>
    <div v-if="is_signable" class="customer-document__sign">
      <button
        @click="trySign()"
        class="btn btn-primary btn-wabident-round"
        :class="{ signed: is_signed }"
      >
        <span v-if="is_signed">
          Firmado
          <i class="wabident icon-wabident-icon-check"></i>
        </span>
        <span v-else>Firmar</span>
      </button>
    </div>
    <div class="customer-document__edit">
      <button
        @click="$emit('edit')"
        class="btn btn-primary btn-wabident-round mx-1"
      >
        Editar
      </button>
    </div>
    <div class="customer-document__print">
      <a v-if="is_downloadable" :href="document_print_url">
        <i class="fas fa-download"></i>
      </a>
      <a v-else :href="document_print_url">
        <i class="wabident icon-wabident-print"></i>
      </a>
    </div>
    <div v-if="is_downloadable" class="customer-document__view">
      <a :href="document_view_url" target="_blank">
        <i class="wabident icon-wabident-search"></i>
      </a>
    </div>
    <div class="customer-document__email" @click="$emit('email')">
      <i class="wabident icon-wabident-mail"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer", "document", "clinic"],
  data() {
    return {};
  },
  computed: {
    is_signable() {
      return this.document.type == "consent";
    },
    is_downloadable() {
      return this.document.type == "document";
    },
    is_signed() {
      return Boolean(this.document.is_signed);
    },
    document_print_url() {
      if (this.is_downloadable) {
        return `/customer/${this.customer.id}/document/${this.document.id}/download?action=download`;
      } else {
        return `/customer/${this.customer.id}/document/${this.document.id}/pdf`;
      }
    },
    document_view_url() {
      if (this.is_downloadable) {
        return `/customer/${this.customer.id}/document/${this.document.id}/view`;
      }
      return "";
    },
    date_time() {
      return moment(this.document.created_at).format("DD/MM/YYYY HH:mm");
    },
    doctor_name() {
      if (!this.clinic || !this.document.doctor_id) return "";
      let doc = this.clinic.doctors.find((d) => {
        return d.doctor.id == this.document.doctor_id;
      });
      return doc ? doc.name : "";
    },
    document_summary() {
      let title = {
        proof: "Justificante",
        document: "Archivo",
        consent: "Consentimiento",
        prescription: "Receta",
        report: "Informe",
      };

      let title_text = title[this.document.type] || "Otros";
      return `${title_text} - ${this.document.title}`;
    },
  },
  methods: {
    trySign() {
      if (this.is_signable) {
        if (this.is_signed) {
          this.$emit("resign");
        } else {
          this.$emit("sign");
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.customer-document {
  border: 2px var(--theme-grey-disabled) solid;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 19px 14px;
  margin-bottom: 14px;

  &__info {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    &__header {
      font-size: 14px;
      font-weight: 500;
      color: var(--theme-line-color);
      margin-bottom: 8px;
    }
  }

  .btn-wabident-round.signed {
    background-color: var(--theme-color-attended) !important;
    border-color: var(--theme-color-attended) !important;
  }

  &__print,
  &__edit,
  &__email,
  &__view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex: 0 0 35px;
    cursor: pointer;
    transition: all 0.2s;
    color: var(--theme-line-color);

    a {
      color: inherit;
      text-decoration: none;
    }

    i.wabident {
      font-size: 20px;
    }

    i.fas {
      font-size: 20px;
    }

    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
