<template>
  <b-modal
    id="clinic-show-template"
    class="clinic-show-template"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="form-group row">
      <div class="col-sm-6">
        <input
          placeholder="Introduce el nombre del plantilla"
          v-model="title"
          type="text"
          id="show_template_title"
          class="form-control"
          required
        />
        <label class="wabident-info" for="show_template_title"
          >Título de la Plantilla</label
        >
      </div>

      <div class="col-sm-3">
        <select
          v-model="format"
          name="format"
          id="show_template_format"
          class="custom-select"
        >
          <option value="html">HTML5</option>
          <option value="rich">Visual</option>
          <option value="markdown">Markdown</option>
        </select>
        <label for="show_template_format" class="wabident-info">Formato</label>
      </div>

      <div class="col-sm-3">
        <select
          v-model="type"
          name="type"
          id="show_template_type"
          class="custom-select"
        >
          <option value="consent">Consentimiento</option>
          <option value="proof">Justificante</option>
          <option value="report">Informe</option>
        </select>
        <label for="show_template_type" class="wabident-info"
          >Tipo de Plantilla</label
        >
      </div>
    </div>
    <div class="form-group row">
      <div class="col">
        <quill-editor
          v-if="format == 'rich'"
          id="show_template_content"
          :options="options"
          v-model="content"
        ></quill-editor>
        <prism-editor
          v-else
          class="content-editor"
          v-model="content"
          :language="lang"
        ></prism-editor>
      </div>
    </div>
    <div class="form-group row">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          :disabled="is_loading"
          type="button"
          @click="onSubmit"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_loading">Guardando ...</span>
          <span v-else-if="is_editing">Modificar Plantilla</span>
          <span v-else>Crear Plantilla</span>
          <i v-if="is_loading" class="fas fa-circle-notch float-right"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      type: "consent",
      content: "",
      position: "",
      format: "html",
      is_editing: false,
      is_loading: false,
      template: null,
      options: {
        placeholder: "Introduce texto ...",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ["bold", "italic", "underline", "strike"],
              [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              ["blockquote", "code-block", "link"],
              ["clean"],
            ],
            handlers: {
              template: function(value) {
                console.log(value);
              }
            }
          },
        },
      },
    };
  },
  computed: {
    lang() {
      switch (this.format) {
        case "markdown":
          return "md";
        default:
          return "html";
      }
    },
    dialog_title() {
      return this.is_editing ? "Modificar Plantilla" : "Nueva Plantilla";
    },
    ...mapGetters(["msgwindow"]),
  },
  methods: {
    showNew() {
      this.is_editing = false;
      this.template = null;
      this.title = "";
      this.type = "consent";
      this.content = "";
      this.format = "html";
      this.$refs.modal.show();
    },
    showEdit(template) {
      this.is_editing = true;
      this.title = template.title;
      this.type = template.type;
      this.content = template.content;
      this.format = template.format;
      this.template = template;
      this.$refs.modal.show();
    },
    onSubmit() {
      if (this.is_editing) {
        this.modifyTemplate();
      } else {
        this.createNewTemplate();
      }
    },
    modifyTemplate() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          title: this.title,
          type: this.type,
          content: this.content,
          format: this.format == "rich" ? "html" : this.format,
        };
        axios
          .post(`/document_template/${this.template.id}`, params)
          .then((response) => {
            this.$emit("submit", response.data);
            this.msgwindow.success("Plantilla modificado con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] modifyTemplate ", err);
            this.msgwindow.error("Error modificando plantilla!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    createNewTemplate() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          title: this.title,
          type: this.type,
          content: this.content,
          format: this.format == "rich" ? "html" : this.format,
        };
        axios
          .post("/document_template", params)
          .then((response) => {
            this.$emit("submit", response.data);
            this.msgwindow.success("Plantilla creada con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] createNewTemplate ", err);
            this.msgwindow.error("Error creando plantilla!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    onDelete() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/document_template/${this.template.id}`, { _method: "delete" })
          .then((response) => {
            this.msgwindow.success("Plantilla borrada con éxito");
            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] onDelete", err);
            this.msgwindow.error("Error borrando plantilla");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
#clinic-show-template {
  .modal-dialog {
    max-width: 75%;
  }

  .content-editor {
    height: 55vh;
  }

  #show_template_content .ql-container {
    height: 55vh;
    overflow-y: scroll;
  }
}
</style>
