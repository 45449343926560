<template>
  <tr class="clinic-treatment-customer">
    <td class="clinic-treatment-customer__date">{{ pretty_date }}</td>
    <td class="clinic-treatment-customer__customer">
      <a target="_blank" :href="customer_link">{{ customer_name }}</a>
    </td>
    <td class="clinic-treatment-customer__clinic">{{ clinic_name }}</td>
    <td class="clinic-treatment-customer__doctor">{{ doctor_name }}</td>
    <td class="clinic-treatment-customer__done">{{ done }}</td>
    <td class="clinic-treatment-customer__paid">{{ paid }}</td>
  </tr>
</template>

<script>
export default {
  props: {
    treatment: { required: true },
    clinics: { default: null },
    doctors: { default: null },
  },
  computed: {
    customer_link() {
      if (!this.treatment.customer) return "";
      return `/customer/${this.treatment.customer.id}/edit`;
    },
    pretty_date() {
      return moment(this.treatment.created_at).toDate().toLocaleDateString();
    },
    doctor_name() {
      if (!this.doctors) return "";
      let doc = this.doctors.find((doc) => {
        return doc.doctor.id == this.treatment.doctor_id;
      });

      if (!doc) return "";
      return doc.name;
    },
    customer_name() {
      if (!this.treatment.customer) return "";
      return this.treatment.customer.name;
    },
    clinic_name() {
      if (!this.clinics) return "";
      return this.clinics[this.treatment.clinic_id] || "";
    },
    done() {
      if (this.treatment.status == "done") return "Sí";
      return "No";
    },
    paid() {
      if (this.treatment.is_paid) return "Sí";
      return "No";
    },
  },
};
</script>

<style lang="scss">
</style>
