<template>
  <b-modal
    class="customer-show-payment"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col-sm-3">
        <input
          type="date"
          v-model="created_at"
          class="form-control"
          id="customer-show-payment-created-at"
          required
          readonly
        />
        <label for="customer-show-payment-created-at" class="wabident-info"
          >Fecha</label
        >
      </div>

      <div class="col-sm-9">
        <select
          v-model="invoice_id"
          id="customer-show-payment-invoice"
          class="form-control custom-select"
        >
          <option :value="null">Sin factura</option>
          <option
            v-for="(invoice, index) in available_invoices"
            :key="index"
            :value="invoice.id"
          >
            {{ invoice.code }}
            <span v-if="invoice.notes"> - {{ invoice.notes }}</span>
          </option>
        </select>
        <label for="customer-show-payment-invoice" class="wabident-info">
          Factura asociada
        </label>
      </div>
    </div>
    <div class="row form-group">
      <div class="col-sm-4">
        <input
          id="customer-show-payment-amount"
          type="number"
          v-model.number="amount"
          class="form-control"
          :readonly="restrict_editing"
        />
        <label for="customer-show-payment-amount" class="wabident-info"
          >Cantidad</label
        >
      </div>
      <div class="col-sm-4">
        <select
          name="vat"
          id="show_vat"
          v-model="vat"
          class="custom-select"
          required
          :disabled="restrict_editing"
        >
          <option :value="null">Seleccionar</option>
          <option :value="0">Exento</option>
          <option :value="21">21%</option>
          <option :value="10">10%</option>
          <option :value="4">4%</option>
        </select>
        <label class="wabident-info" for="show_vat">IVA Aplicado</label>
      </div>
      <div class="col-sm-4">
        <select
          v-model="type"
          id="customer-show-payment-type"
          class="form-control custom-select"
          required
          :disabled="restrict_editing"
        >
          <option :value="null">Seleccionar</option>
          <option value="cash">Efectivo</option>
          <option value="credit_card">Tarjeta</option>
          <option value="transfer">Transferencia</option>
          <option value="mobile">Bizum/Móvil</option>
          <option value="direct_debit">Financiación</option>
          <option value="other">Otros</option>
        </select>
        <label for="customer-show-payment-type" class="wabident-info"
          >Tipo</label
        >
      </div>
    </div>
    <div v-if="is_dental || !selected_treatments.length" class="row form-group">
      <div class="col">
        <select
          v-model="selected_treatment"
          @change="selectedTreatment($event.target.value)"
          id="customer-pay-treatment"
          class="form-control custom-select"
          :disabled="restrict_editing"
        >
          <option :value="null">Seleccionar tratamiento(s)</option>
          <option
            v-for="(treatment, index) in available_treatments"
            :key="index"
            :value="treatment.id"
          >
            {{ treatment.product.name }}
            <span v-if="treatment.quoted_price">
              - {{ getQuotedPrice(treatment) }}
            </span>
          </option>
        </select>
        <label for="customer-pay-treatment" class="wabident-info"
          >Tratamiento(s) a pagar</label
        >
      </div>
    </div>

    <div v-show="selected_treatments.length" class="form-group row no-gutters">
      <div class="col-sm-12">
        <a
          role="button"
          @click="removeTreatment(treatment.id)"
          class="
            btn
            wabident-badge wabident-badge__clear wabident-badge__ellipsis
          "
          v-for="treatment in selected_treatments"
          style="margin: 4px"
          :key="treatment.id"
          >{{ treatment.product.name }}
          <span v-if="treatment.quoted_price">
            - {{ getQuotedPrice(treatment) }}
          </span>
        </a>
      </div>
    </div>

    <div
      class="form-group row"
      v-if="!is_editing && selected_treatments.length"
    >
      <legend>Tras guardar, marcar los tratamientos como:</legend>
      <div class="col-sm-12">
        <div class="form-check form-check-inline">
          <input
            v-model="is_paid"
            class="form-check-input"
            type="checkbox"
            id="treatment_is_paid"
            :value="true"
          />
          <label class="form-check-label" for="treatment_is_paid"
            >Pagados</label
          >
        </div>
        <div class="form-check form-check-inline">
          <input
            v-model="is_done"
            class="form-check-input"
            type="checkbox"
            id="treatment_is_done"
            :value="true"
          />
          <label class="form-check-label" for="treatment_is_done"
            >Realizados</label
          >
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-12">
        <input
          type="text"
          v-model="notes"
          class="form-control"
          id="customer-show-payment-notes"
          :readonly="restrict_editing"
        />
        <label for="customer-show-payment-notes" class="wabident-info"
          >Concepto</label
        >
      </div>
    </div>
    <div class="row form-group">
      <div class="col buttons-column text-center">
        <button
          :disabled="is_loading"
          @click="deletePayment"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>

        <button
          @click="submit"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_editing">Modificar</span>
          <span v-else>Crear </span>
          <i
            v-if="is_loading"
            class="fa fa-spin fa-circle-notch float-right"
          ></i>
        </button>

        <button
          @click="returnPayment"
          v-if="is_editing"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident float-right"
        >
          Devolver&nbsp;<i class="fas fa-undo"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    customer: { required: true },
  },
  data() {
    return {
      payment_id: 0,
      customer_id: 0,
      is_editing: false,
      is_loading: false,
      invoice_id: null,
      created_at: new Date().toISODate(),
      amount: 0,
      type: "cash",
      notes: "",
      vat: null,
      is_paid: false,
      is_done: false,
      unpaid_treatments: [],
      selected_treatments: [],
      selected_treatment: null,
      available_invoices: null,
    };
  },
  computed: {
    is_dental() {
      if (!this.clinic) return true;
      return this.clinic.type == "dental";
    },
    restrict_editing() {
      false;
    },
    available_treatments() {
      let selected = this.selected_treatments.map((x) => {
        return x.id;
      });
      return this.unpaid_treatments.filter((x) => {
        return !selected.includes(x.id);
      });
    },
    dialog_title() {
      if (!this.clinic || this.clinic.type == "dental") {
        return this.is_editing ? "Editar Pago" : "Añadir Pago";
      }

      return this.is_editing ? "Editar Cobro" : "Añadir Cobro";
    },
    invoice_names() {
      if (!this.available_invoices) return [];
      return this.available_invoices.map((x) => {
        return {
          value: x.id,
          name: x.code + " - " + x.notes,
        };
      });
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    getQuotedPrice(treatment) {
      if (!treatment.quoted_price) {
        return "";
      }
      return window.WabiDent.fmtMoney(treatment.quoted_price);
    },
    showNew() {
      this.customer_id = this.customer ? this.customer.id : 0;
      this.payment_id = 0;
      this.is_editing = false;
      this.amount = 0;
      this.type = null;
      this.notes = "";
      this.invoice_id = null;
      this.$refs.modal.show();
      this.created_at = new Date().toISODate();
      this.vat = null;
      this.is_paid = false;
      this.is_done = false;
      this.updateTreatments();
      this.updateInvoices();
    },
    showNewTreatment(treatment, price, invoices = null) {
      this.customer_id = this.customer ? this.customer.id : 0;
      this.payment_id = 0;
      this.is_editing = false;
      this.amount = price;
      this.type = null;

      if (invoices && invoices.length == 1) {
        this.invoice_id = invoices[0].id;
      } else {
        this.invoice_id = null;
      }

      this.notes = "Pago tratamiento: " + treatment.product.name;
      this.vat = Number.parseFloat(treatment.product.vat) || null;
      this.is_paid = true;
      this.is_done = true;
      this.created_at = new Date().toISODate();

      this.notes = "Pago de tratamientos: " + treatment.product.name;

      if (treatment.teeth && treatment.teeth.length > 0) {
        this.notes += " (" + treatment.teeth.join(",") + ")";
      }

      treatment.quoted_price = price;
      this.selected_treatments = [treatment];
      this.$refs.modal.show();
      this.updateTreatments();
      this.updateInvoices();
    },
    showNewTreatments(treatments, prices, invoices = null) {
      let price = prices.reduce((acc, x) => {
        return acc + x;
      }, 0);

      treatments.forEach((t, index) => {
        t.quoted_price = prices[index];
      });

      this.payment_id = 0;
      this.customer_id = this.customer ? this.customer.id : 0;
      this.is_editing = false;
      this.amount = price;
      this.type = null;

      if (invoices && invoices.length == 1) {
        this.invoice_id = invoices[0].id;
      } else {
        this.invoice_id = null;
      }

      let names = treatments.map((t) => {
        let r = t.product.name;
        if (t.teeth && t.teeth.length > 0) {
          this.notes += " (" + t.teeth.join(",") + ")";
        }
        return r;
      });

      this.notes = "Pago tratamientos: " + names.join(", ");
      this.vat = null;

      this.is_paid = true;
      this.is_done = true;
      this.created_at = new Date().toISODate();
      this.selected_treatments = treatments;

      this.$refs.modal.show();
      this.updateTreatments();
      this.updateInvoices();
    },

    showEdit(payment) {
      this.payment_id = payment.id;
      this.customer_id = this.customer ? this.customer.id : payment.customer_id;
      this.is_editing = true;
      this.invoice_id = payment.invoice_id;
      this.amount = payment.amount;
      this.notes = payment.notes;
      this.vat = Number.parseFloat(payment.vat) || null;
      this.created_at = moment.utc(payment.created_at).format("YYYY-MM-DD");
      this.type = payment.type;

      let is_paid = true;
      let is_done = true;

      payment.treatments.forEach((t) => {
        is_paid = is_paid && t.paid_at != null;
        is_done = is_done && t.done_at != null;
      });

      this.is_paid = is_paid;
      this.is_done = is_done;
      this.$refs.modal.show();
      this.selected_treatments = payment.treatments;
      this.updateTreatments();
      this.updateInvoices();
    },
    updateTreatments() {
      let params = {
        treatments: this.selected_treatments.map((x) => {
          return x.id;
        }),
      };

      axios
        .get(`/customer/${this.customer_id}/unpaid`, { params: params })
        .then((response) => {
          this.unpaid_treatments = response.data;
        })
        .catch((err) => {
          this.msgwindow.error("Error obteniendo tratamientos sin pagar");
        });
    },
    updateInvoices() {
      axios
        .get(`/customer/${this.customer_id}/invoices_payment`)
        .then((response) => {
          this.available_invoices = response.data;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.msgwindow.error("Error obteniendo facturas");
        });
    },
    selectedTreatment(treatment_id) {
      if (treatment_id) {
        let id = Number(treatment_id);
        let to_add = this.unpaid_treatments.filter((x) => {
          return x.id == id;
        });
        this.selected_treatments = this.selected_treatments.concat(to_add);
        this.selected_treatment = null;
      }
    },
    removeTreatment(treatment_id) {
      if (this.is_editing) return;
      this.selected_treatments = this.selected_treatments.filter((x) => {
        return x.id != treatment_id;
      });
    },
    submit() {
      if (!this.validatePayment()) return;

      if (!this.is_editing) {
        let message = "¿Confirmar creación?";
        message += "<br>No podrás deshacer esta operación";

        this.errorwindow.show(
          "Confirmación",
          message,
          "Crear",
          "Cancelar",
          () => {
            this.addPayment();
          }
        );
      } else {
        this.updatePayment();
      }
    },
    validatePayment() {
      let errors = [];

      if (!this.type) {
        errors.push(
          "<li>Debes seleccionar un Tipo: Efectivo, Tarjeta, etc ..."
        );
      }

      if (!this.amount) {
        errors.push("<li>Debes indicar una Cantidad mínima");
      }

      if (!this.notes) {
        errors.push("<li>Debes indicar el Concepto");
      }

      if (this.type == "cash" && this.amount > 1000) {
        errors.push("<li>No puedes ingresar Efectivo superior a 1000€");
      }

      if (
        this.clinic.type != "dental" &&
        this.vat == null &&
        this.selected_treatments.length == 0
      ) {
        errors.push(
          "<li>Debes elegir al menos un tratamiento o un tipo de IVA a aplicar"
        );
      }

      if (errors.length) {
        let message = "<ul>" + errors.join() + "</ul>";
        this.errorwindow.show("Error", "No se puede crear el cobro:" + message);
        return false;
      }

      return true;
    },
    returnPayment() {
      this.errorwindow.show(
        "Confirmación",
        "¿Quieres crear una devolución?",
        "Aceptar",
        "Cancelar",
        () => {
          this.doReturnPayment();
        }
      );
    },
    deletePayment() {
      this.errorwindow.show(
        "Confirmación",
        "¿Quieres borrar el pago? Esta operación no es reversible",
        "Aceptar",
        "Cancelar",
        () => {
          this.doDeletePayment();
        }
      );
    },
    doDeletePayment() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/customer/${this.customer.id}/payment/${this.payment_id}`, {
            _method: "delete",
          })
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Pago borrado");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error borrando pago !");
            this.$refs.modal.hide();
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    doReturnPayment() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/payment/${this.payment_id}/return`
          )
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Devolución creada");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error en la devolución !");
            this.$refs.modal.hide();
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    updatePayment() {
      if (!this.is_loading) {
        this.is_loading = true;

        var params = {
          amount: this.amount,
          notes: this.notes,
          type: this.type,
          clinic_id: this.clinic ? this.clinic.id : null,
          invoice_id: this.invoice_id,
          created_at: this.created_at,
          treatments: this.selected_treatments.map((x) => {
            return x.id;
          }),
          is_paid: this.is_paid,
          is_done: this.is_done,
          vat: this.vat,
        };

        axios
          .post(
            `/customer/${this.customer.id}/payment/${this.payment_id}`,
            params
          )
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Modificado con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error modificando !");
            this.$refs.modal.hide();
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    addPayment() {
      if (!this.is_loading) {
        this.is_loading = true;

        var params = {
          amount: this.amount,
          notes: this.notes,
          type: this.type,
          clinic_id: this.clinic ? this.clinic.id : null,
          invoice_id: this.invoice_id,
          created_at: this.created_at,
          treatments: this.selected_treatments.map((x) => {
            return x.id;
          }),
          is_paid: this.is_paid,
          is_done: this.is_done,
          vat: this.vat,
        };

        axios
          .post(`/customer/${this.customer.id}/payment`, params)
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Añadido con éxito");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error añadiendo!");
            this.$refs.modal.hide();
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
  mounted() {},
};
</script>