<template>
  <div class="row" style="padding: 43px 45px 0 45px">
    <div class="col-sm-3 customer-edit-info">
      <!-- Photo and Info -->
      <div class="row" style="margin-bottom: 30px">
        <div class="col-sm-4">
          <div
            class="customer-photo"
            :style="{ 'background-image': 'url(' + customer.photo + ')' }"
          ></div>
        </div>
        <div
          class="
            col-sm-8
            d-flex
            flex-column
            align-items-start
            justify-content-center
          "
          style="padding-left: 25px"
        >
          <h2 class="customer-name">{{ customer.name }}</h2>
          <h3 class="customer-name-data">
            {{ customer_birth_date }} -
            <strong>{{ customer_age }} años</strong>
          </h3>
          <h3 class="customer-name-data">
            Nº historial -
            <strong>{{ customer.history_number }}</strong>
          </h3>
        </div>
      </div>

      <div v-if="!is_dental" class="customer-notes row">
        <div class="col">
          <label>Notas</label>
          <p>{{ customer.notes }}</p>
        </div>
      </div>

      <!-- Datapoint: Phone -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>Clínica de Referencia</label>
          <p>{{ showClinic(customer) }}</p>
        </div>
      </div>

      <!-- Child Info -->
      <template v-if="!is_adult">
        <div class="customer-datapoint row">
          <div class="col">
            <label>Adulto a cargo</label>
            <p>{{ adult_type }} - {{ customer.adult_name }}</p>
          </div>
        </div>
      </template>

      <!-- Datapoint: Phone -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>Teléfono de contacto</label>
          <p>{{ customer.phone }}</p>
        </div>
      </div>

      <!-- Datapoint: Email -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>Email de de contato</label>
          <p>{{ customer.email }}</p>
        </div>
      </div>

      <!-- Datapoint: NIF -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>DNI/Pasaporte</label>
          <p>{{ customer.nif }}</p>
        </div>
      </div>

      <!-- Datapoint: Address -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>Dirección</label>
          <p>
            {{ customer.address }}, {{ customer.zipcode }}, {{ customer.city }}
          </p>
        </div>
      </div>

      <!-- Datapoint: Address -->
      <div class="customer-datapoint row">
        <div class="col">
          <label>Ley de protección de datos:</label>
          <p
            class="customer-consent"
            :class="{ signed: customer.is_consent_signed }"
          >
            <span
              v-if="
                customer.is_consent_signed &&
                customer.is_consent_signed != 'false'
              "
              >FIRMADA</span
            >
            <span v-else>SIN FIRMAR</span>
          </p>
        </div>
      </div>

      <!-- Datapoint: Notes -->
      <div v-if="is_dental" class="customer-notes row">
        <div class="col">
          <label>Notas</label>
          <p>{{ customer.notes }}</p>
        </div>
      </div>

      <!-- Datapoint: History -->
      <div class="customer-notes row">
        <div class="col">
          <label>Información importante</label>
          <p>
            <template v-for="(section, sectionkey) in customer.history">
              <template v-for="(history, historykey) in section">
                <div
                  class="customer-history-item"
                  :key="sectionkey + '_' + historykey"
                  v-if="history_items[historykey] && history"
                >
                  <img
                    v-if="history_items[historykey].icon"
                    :src="history_items[historykey].icon"
                    :alt="history_items[historykey].text"
                  />
                  {{ history_items[historykey].text }}
                </div>
              </template>
            </template>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col text-center my-3">
          <button
            @click="tab_index = 0"
            :disabled="tab_index == 0"
            type="button"
            class="btn btn-wabident-round big"
          >
            Editar ficha cliente
          </button>
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <b-tabs v-model="tab_index" justified lazy>
        <b-tab
          key="edit"
          title="Editar ficha"
          :active="customerInitialTab() == 'edit'"
        >
          <customer-edit-data
            @customerUpdated="customerUpdated"
            :customer="customer"
          ></customer-edit-data>
        </b-tab>

        <b-tab
          key="appointments"
          title="Citas"
          :active="customerInitialTab() == 'appointments'"
        >
          <customer-edit-appointments
            :customer="customer"
          ></customer-edit-appointments>
        </b-tab>

        <b-tab
          key="history"
          title="Anamnesis"
          :active="customerInitialTab() == 'history'"
        >
          <customer-edit-history
            @historyUpdated="historyUpdated"
            :customer="customer"
          ></customer-edit-history>
        </b-tab>

        <b-tab
          key="odontogram"
          :title="treatments_title"
          :active="customerInitialTab() == 'odontogram'"
        >
          <customer-edit-treatments
            @treatmentsUpdated="treatmentsUpdated"
            :customer="customer"
            :age="customer_age"
          ></customer-edit-treatments>
        </b-tab>

        <b-tab
          key="invoices"
          title="Facturación"
          :active="customerInitialTab() == 'invoices'"
        >
          <customer-invoices :customer="customer"></customer-invoices>
        </b-tab>

        <b-tab
          key="documents"
          title="Documentos"
          :active="customerInitialTab() == 'documents'"
        >
          <customer-documents :customer="customer"></customer-documents>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      customer: {},
      tab_index: 0,
      history_items: {
        penicillin: {
          text: "Alergia a la penicilina",
          icon: "/images/penicilina.svg",
        },
        latex: {
          text: "Alergia al látex",
          icon: "/images/latex.svg",
        },
        hepatitis: {
          text: "Hepatitis",
          icon: "/images/hepatitis.svg",
        },
        diabetes: {
          text: "Diabetes",
          icon: "/images/diabetes.svg",
        },
        heart_condition: {
          text: "Problemas cardíacos",
          icon: "/images/cardiacos.svg",
        },
        hiv: {
          text: "VIH",
          icon: "/images/vih.svg",
        },
        anticoagulants: {
          text: "Anticoagulantes",
        },
        inmunosuppressants: {
          text: "Inmunosupresores",
        },
        headache: {
          text: "Cefáleas",
        },
        neuralgia: {
          text: "Neuralgia",
        },
        bruxism: {
          text: "Bruxismo",
        },
        tobacco: {
          text: "Tabaco",
        },
        drugs: {
          text: "Drogas",
        },
      },
    };
  },
  props: {
    initCustomer: { required: true },
    clinicId: { required: true },
  },
  computed: {
    is_dental() {
      if (!this.clinic) return false;
      if (!this.clinic.type) return false;
      if (this.clinic.type == "dental") return true;
      return false;
    },
    treatments_title() {
      return this.is_dental ? "Odontograma" : "Tratamientos";
    },
    customer_birth_date() {
      return moment(this.customer.birth_date).toDate().toLocaleDateString();
    },
    customer_age() {
      let birth = moment(this.customer.birth_date);
      let now = moment();
      return now.diff(birth, "years");
    },
    is_adult() {
      return this.customer_age >= 12;
    },
    adult_type() {
      return WabiDent.AdultType[this.customer.adult_type];
    },
    ...mapGetters(["clinic"]),
  },
  methods: {
    customerInitialTab() {
      if (!this.clinic) return null;
      if (!this.clinic.config) return null;
      return this.clinic.config.customer_initial_tab || null;
    },
    showClinic(customer) {
      if (!this.clinic) return "";
      return this.clinic.clinic_names[customer.clinic_id] || "";
    },
    customerUpdated(customer) {
      this.customer = JSON.parse(JSON.stringify(customer));
    },
    historyUpdated(history) {
      this.customer.history = JSON.parse(JSON.stringify(history));
    },
    treatmentsUpdated(treatments) {
      this.customer.treatments = JSON.parse(JSON.stringify(treatments));
    },
    loadClinicData() {
      if (!this.clinic) {
        axios
          .get("/agenda/clinic_info", {
            params: {
              clinic_id: this.clinicId,
            },
          })
          .then((response) => {
            let data = response.data;
            if (data.error) {
              console.log("[ERR] loadClinicData:", data.error);
            } else {
              this.setClinic(data);
            }
          })
          .catch((error) => {
            console.log("[ERR] loadClinicData:", error);
          });
      }
    },
    ...mapMutations(["setClinic"]),
  },
  created() {
    this.customer = JSON.parse(JSON.stringify(this.initCustomer));
  },
  mounted() {
    this.loadClinicData();
  },
};
</script>

<style lang="scss" scoped>
.customer-edit-info {
  .customer-photo {
    background-color: var(--theme-grey);
    width: 150px;
    height: 150px;
    border-radius: 75px;
    background-position: center center;
    background-size: cover;
  }

  .customer-name {
    font-size: 18px;
    color: black;
    font-weight: 500;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .customer-name-data {
    font-size: 15px;
    color: var(--theme-line-color);
    letter-spacing: 0.3px;
  }

  .customer-datapoint,
  .customer-notes {
    label {
      color: var(--theme-line-color);
      font-weight: 500;
      font-size: 14px;
    }

    p {
      color: var(--theme-text-color);
      font-size: 16px;
      line-height: 1.25;
    }

    .customer-history-item {
      color: var(--theme-color-absent);
      img {
        color: var(--theme-color-absent);
        fill: currentColor;
      }

      & + .customer-history-item {
        margin-top: 5px;
      }
    }
  }

  .customer-notes + .customer-notes {
    margin-top: 12px;
  }

  .customer-notes {
    background-color: var(--theme-grey-bg);
  }

  .customer-consent {
    color: var(--theme-color-absent);
    &.signed {
      color: var(--theme-text-color);
    }
  }
}
</style>
