<template>
  <tr class="clinic-payment" style="vertical-align:middle">
    <td class="clinic-payment__date ">{{ pretty_date }}</td>
    <td class="clinic-payment__type ">{{ pretty_type }}</td>
    <td class="clinic-payment__customer ">
      <a class="btn-link" target="_blank" :href="customer_link">{{
        customer.name
      }}</a>
    </td>
    <td class="clinic-payment__clinic ">{{ clinic_name }}</td>
    <td class="clinic-payment__invoice ">
      <a
        v-if="payment.invoice"
        class="btn-link"
        target="_blank"
        :href="invoice_link"
        >{{ invoice_code }}</a
      >
      <button @click="emitInvoice" v-else class="btn btn-wabident-round">
        Facturar
      </button>
    </td>
    <td class="clinic-payment__amount text-right ">{{ pretty_amount }} 
      <button @click="updatePayment" class="btn btn-wabident-round ml-2" style="font-size: 10px; padding: 5px 15px">
        <i class="fa fa-edit"></i>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    payment: { required: true },
    clinics: { default: null },
  },
  computed: {
    customer_link() {
      return `/customer/${this.customer.id}/edit`;
    },
    customer() {
      return this.payment.customer;
    },
    pretty_date() {
      return moment(this.payment.created_at).format("DD/MM/YYYY");
    },
    pretty_type() {
      return (
        {
          cash: "Efectivo",
          credit_card: "Tarjeta",
          transfer: "Transferencia",
          mobile: "Bizum/Móvil",
          direct_debit: "Financiación",
          other: "Otros",
        }[this.payment.type] || "Otros"
      );
    },
    pretty_amount() {
      return WabiDent.fmtMoney(this.payment.amount, 0);
    },
    clinic_name() {
      if (!this.clinics) return "";
      return this.clinics[this.payment.clinic_id] || "";
    },
    invoice_code() {
      if (!this.payment.invoice) return "";
      return this.payment.invoice.code;
    },
    invoice_link() {
      if (!this.payment.invoice) return "";
      return `/invoice/${this.payment.invoice.id}/pdf`;
    },
  },
  methods: {
    emitInvoice() {
      this.$emit("invoice", this.payment);
    },
    updatePayment() {
      this.$emit("payment",this.payment);
    }
  },
};
</script>

<style lang="scss">
.clinic-payment {
  td {
    vertical-align: middle;;
  }
}
</style>
