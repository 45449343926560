<template>
  <b-modal
    id="customer-upload-multiple"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col-sm-12">
        <customer-search
          id="show_document_customer"
          ref="search"
          :locked="locked"
          @customerSelected="customerSelected"
          @customerDeselected="customerDeselected"
        ></customer-search>
      </div>
    </div>
    <div class="row form-group">
      <div class="col">
        <input
          class="form-control"
          type="date"
          id="show_document_date"
          v-model="created_at"
        />
        <label for="show_document_date" class="wabident-info">Fecha</label>
      </div>

      <div class="col">
        <select
          v-model="hour"
          id="show_document_hour"
          class="custom-select form-control"
        >
          <template v-if="clinic">
            <optgroup label="Mañana">
              <option
                v-for="(slot, i) in day_slots.morning.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
            <optgroup label="Tarde">
              <option
                v-for="(slot, i) in day_slots.afternoon.start"
                :key="i"
                :value="slot"
              >
                {{ slot }}
              </option>
            </optgroup>
          </template>
        </select>
        <label for="show_document_hour" class="wabident-info">Hora</label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <select
          v-model="doctor_id"
          id="show_document_doctor"
          class="custom-select form-control"
        >
          <option :value="null">Selecciona Doctor</option>
          <option
            v-for="(doctor, index) in doctors"
            :key="index"
            :value="doctor.doctor.id"
          >
            {{ doctor.name }}
          </option>
        </select>
        <label for="show_document_doctor" class="wabident-info">Doctor</label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <input
          id="show_document_title"
          type="text"
          class="form-control"
          v-model="title"
        />
        <label for="show_document_title" class="wabident-info"
          >Cabecera del documento - ( Se añadirá un número despues por cada
          archivo )</label
        >
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <file-selector
          :multiple="true"
          accept-extensions=".jpg,.svg,.jpeg,.gif,.png,.pdf"
          @validated="handleFilesValidated"
          @changed="handleFilesChanged"
          :height="75"
        >
          <h4 class="customer-upload-multiple__upload-btn">
            Suelta archivos o pulsa aquí para añadir
          </h4>
        </file-selector>
      </div>
    </div>

    <div v-if="files.length" class="row form-group mb-3">
      <div class="col-md-12 text-left">
        <h4>Archivos</h4>
      </div>
      <div v-for="(file, index) in files" class="col-md-6" :key="index">
        <b>{{ index + 1 }}</b> - {{ file.name }}
        <button
          class="customer-upload-multiple__delete-file"
          type="button"
          @click="deleteFile(index)"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
    </div>

    <div class="form-group row">
      <div class="col text-center">
        <button
          @click="submitDocument"
          class="btn btn-primary btn-wabident vertical-align-middle"
          :disabled="!is_valid || is_loading"
        >
          <span v-if="is_loading">
            Subiendo
            <i class="fas fa-spin fa-circle-notch"></i>
          </span>
          <span v-else>Subir Documentos</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    locked: { default: true },
  },
  data() {
    return {
      is_loading: false,
      hour: null,
      selected_customer: null,
      created_at: new Date().toISODate(),
      doctor_id: null,
      template_id: null,
      title: "",
      content: "",
      type: "document",
      files: [],
      templates: [],
    };
  },
  computed: {
    is_valid() {
      if (this.is_loading) return false;
      if (!this.doctor_id) return false;
      if (!this.type) return false;
      if (this.type == "document" && !this.files.length) return false;
      return true;
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    dialog_title() {
      return "Subir Múltiples";
    },
    day_slots() {
      if (!this.clinic) return [];
      return this.clinic.schedule.daily_timeslots[this.dayindex];
    },
    dayindex() {
      let d = moment(this.created_at).toDate().getDay();
      if (d == 0) return 6;
      return d - 1;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    handleFilesValidated(result, files) {},
    handleFilesChanged(files) {
      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i]);
      }
    },
    deleteFile(index) {
      this.files.splice(index, 1);
    },
    customerSelected(customer) {
      if (customer) {
        this.selected_customer = customer;
      }
    },
    customerDeselected() {
      this.selected_customer = null;
    },
    show() {
      this.$refs.modal.show();
    },
    hide() {
      this.$refs.modal.hide();
    },
    showNew(customer) {
      this.document = null;
      this.type = "document";
      this.created_at = new Date().toISODate();
      this.doctor_id = null;
      this.title = "";
      this.hour = this.getFirstHour();
      this.files = [];

      this.selected_customer = customer;
      setTimeout(() => {
        this.$refs.search.select(customer);
      }, 100);

      this.show();
    },
    getFirstHour() {
      return this.day_slots.morning.start[0];
    },
    getHour(date) {
      return moment(date).format("HH:mm");
    },
    getFormData() {
      let ret = new FormData();

      ret.append("created_at", this.created_at);
      ret.append("doctor_id", this.doctor_id);
      ret.append("customer_id", this.selected_customer.id);
      ret.append("type", this.type);
      ret.append("hour", this.hour);

      if (this.clinic) {
        ret.append("clinic_id", this.clinic.id);
      }

      return ret;
    },
    submitDocument() {
      this.createDocument();
    },
    async createDocument() {
      if (!this.is_loading) {
        this.is_loading = true;

        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          let formData = this.getFormData();

          formData.append("file", file);
          formData.append("title", `${this.title} - ${i}`);

          let response = await axios
            .post("/document", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response) => {
              this.msgwindow.success("Documento creado con éxito");
            })
            .catch((err) => {
              console.log("[ERR] createDocument", err);
              this.errorwindow.show("Error", "Error creando documento");
            });
        }

        this.$emit("submit");
        this.hide();
        this.is_loading(false);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#customer-upload-multiple {
  .modal-dialog {
    max-width: 50vw;
  }
}

.customer-upload-multiple {
  &__upload-btn {
    cursor: pointer;
    color: var(--theme-color);
    border: 1px var(--theme-color) solid;
    border-radius: 5px;
    padding: 10px 50px;
    width: 100%;
    text-align: center;
  }

  &__delete-file {
    border: none;
    border-radius: 50%;
    color: white;
    background: var(--theme-color-absent);
    display: inline-flex;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }
}
</style>
