<template>
  <div class="container customer-sign-document">
    <div v-if="!is_signed" class="row form-group">
      <div class="col text-center">
        <h1>Firma Documento</h1>
        <VueSignaturePad
          class="customer-sign-document__pad"
          width="300px"
          height="300px"
          ref="signaturepad"
          saveType="image/png"
        />
      </div>
    </div>

    <div v-if="is_signed" class="row form-group">
      <div class="col text-center">
        <h2>DOCUMENTO FIRMADO</h2>
        <h3>Puede cerrar esta pestaña</h3>
      </div>
    </div>

    <div v-else class="row form-group">
      <div class="col text-center customer-sign-document__buttons">
        <button
          :disabled="is_loading"
          @click="clearSignature"
          class="btn btn-primary btn-wabident"
        >
          <i class="wabident icon-wabident-trash"></i> Borrar
        </button>
        <button
          :disabled="is_loading"
          @click="saveSignature"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_loading">
            Firmando
            <i class="fas fa-circle-notch"></i>
          </span>
          <span v-else>Firmar</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["document", "documentkey"],
  data() {
    return {
      is_loading: false,
      is_signed: false,
    };
  },
  computed: {
    ...mapGetters(["msgwindow"]),
  },
  methods: {
    clearSignature() {
      this.$refs.signaturepad.clearSignature();
    },
    getInputs() {
      let ret = {};
      let input_list = document.querySelectorAll("#document_content input");
      input_list.forEach((input) => {
        let type = input.getAttribute("type");
        let id = input.getAttribute("id");

        if (type == "checkbox" || type == "radio") {
          let checked = input.checked;
          ret[id] = checked ? 1 : 0;
        } else {
          ret[id] = input.value;
        }
      });

      return ret;
    },
    saveSignature() {
      let signature = this.$refs.signaturepad.saveSignature();
      if (!signature.isEmpty) {
        if (!this.is_loading) {
          this.is_loading = true;
          let data = {
            key: this.documentkey,
            signature: signature.data,
            inputs: this.getInputs(),
          };
          axios
            .post(`/signature`, data)
            .then((response) => {
              this.msgwindow.success("Firma correcta");
              this.is_signed = true;
            })
            .catch((error) => {
              this.msgwindow.error("Error firmando !");
              console.log("[ERR] saveSignature: ", error);
            })
            .then(() => {
              this.is_loading = false;
            });
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.customer-sign-document {
  &__pad {
    margin: 30px auto;
    border: 2px var(--theme-grey-disabled) solid;
    border-radius: 2px;
  }

  &__buttons {
    button {
      margin: auto 15px;
    }
  }
}
</style>
