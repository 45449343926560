<template>
  <b-modal ref="modal" title="Nuevo paciente" hide-footer>
    <b-form @submit="onSubmit">
      <div class="form-group row">
        <div class="col-sm-9">
          <input
            v-model="form.name"
            type="text"
            class="form-control"
            name="name"
            id="new_customer_name"
            required
          />
          <label for="new_customer_name" class="wabident-info"
            >Nombre y apellidos</label
          >
        </div>
        <div class="col-sm-3">
          <input
            :disabled="is_loading_history_number"
            v-model="form.history_number"
            type="text"
            class="form-control"
            name="history_number"
            id="new_customer_history_number"
            required
          />
          <label for="new_customer_history_number" class="wabident-info"
            >Nº historial</label
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6">
          <input
            v-model="form.created_at"
            type="date"
            name="created_at"
            id="customer_new_created_at"
            class="form-control"
            required
            readonly
          />
          <label for="customer_new_created_at" class="wabident-info"
            >Fecha de alta</label
          >
        </div>
        <div class="col-sm-6">
          <input
            type="date"
            v-model="form.birth_date"
            name="birth_date"
            id="customer_new_birth_date"
            class="form-control"
          />
          <label for="customer_new_birth_date" class="wabident-info"
            >Fecha de nacimiento</label
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-5">
          <input
            v-model="form.phone"
            type="tel"
            name="phone"
            id="customer_new_phone"
            class="form-control"
            required
          />
          <label for="customer_new_phone" class="wabident-info"
            >Teléfono móvil</label
          >
        </div>
        <div class="col-sm-7">
          <input
            v-model="form.email"
            type="email"
            name="email"
            id="customer_new_email"
            class="form-control"
          />
          <label for="customer_new_email" class="wabident-info">Email</label>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-5">
          <input
            v-model="form.nif"
            type="text"
            name="nif"
            id="customer_new_nif"
            class="form-control"
          />
          <label for="customer_new_nif" class="wabident-info"
            >DNI / Pasaporte</label
          >
        </div>
        <div class="col-sm-7">
          <select
            v-model="form.country"
            name="country"
            id="customer_new_country"
            class="form-control custom-select"
            required
          >
            <option value="AF">Afganistán</option>
            <option value="AL">Albania</option>
            <option value="DE">Alemania</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antártida</option>
            <option value="AG">Antigua y Barbuda</option>
            <option value="AN">Antillas Holandesas</option>
            <option value="SA">Arabia Saudí</option>
            <option value="DZ">Argelia</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaiyán</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrein</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BE">Bélgica</option>
            <option value="BZ">Belice</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermudas</option>
            <option value="BY">Bielorrusia</option>
            <option value="MM">Birmania</option>
            <option value="BO">Bolivia</option>
            <option value="BA">Bosnia y Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BR">Brasil</option>
            <option value="BN">Brunei</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="BT">Bután</option>
            <option value="CV">Cabo Verde</option>
            <option value="KH">Camboya</option>
            <option value="CM">Camerún</option>
            <option value="CA">Canadá</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CY">Chipre</option>
            <option value="VA">Ciudad del Vaticano (Santa Sede)</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comores</option>
            <option value="CG">Congo</option>
            <option value="CD">Congo, República Democrática del</option>
            <option value="KR">Corea</option>
            <option value="KP">Corea del Norte</option>
            <option value="CI">Costa de Marfíl</option>
            <option value="CR">Costa Rica</option>
            <option value="HR">Croacia (Hrvatska)</option>
            <option value="CU">Cuba</option>
            <option value="DK">Dinamarca</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egipto</option>
            <option value="SV">El Salvador</option>
            <option value="AE">Emiratos Árabes Unidos</option>
            <option value="ER">Eritrea</option>
            <option value="SI">Eslovenia</option>
            <option value="ES" selected>España</option>
            <option value="US">Estados Unidos</option>
            <option value="EE">Estonia</option>
            <option value="ET">Etiopía</option>
            <option value="FJ">Fiji</option>
            <option value="PH">Filipinas</option>
            <option value="FI">Finlandia</option>
            <option value="FR">Francia</option>
            <option value="GA">Gabón</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GD">Granada</option>
            <option value="GR">Grecia</option>
            <option value="GL">Groenlandia</option>
            <option value="GP">Guadalupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GY">Guayana</option>
            <option value="GF">Guayana Francesa</option>
            <option value="GN">Guinea</option>
            <option value="GQ">Guinea Ecuatorial</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="HT">Haití</option>
            <option value="HN">Honduras</option>
            <option value="HU">Hungría</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IQ">Irak</option>
            <option value="IR">Irán</option>
            <option value="IE">Irlanda</option>
            <option value="BV">Isla Bouvet</option>
            <option value="CX">Isla de Christmas</option>
            <option value="IS">Islandia</option>
            <option value="KY">Islas Caimán</option>
            <option value="CK">Islas Cook</option>
            <option value="CC">Islas de Cocos o Keeling</option>
            <option value="FO">Islas Faroe</option>
            <option value="HM">Islas Heard y McDonald</option>
            <option value="FK">Islas Malvinas</option>
            <option value="MP">Islas Marianas del Norte</option>
            <option value="MH">Islas Marshall</option>
            <option value="UM">Islas menores de Estados Unidos</option>
            <option value="PW">Islas Palau</option>
            <option value="SB">Islas Salomón</option>
            <option value="SJ">Islas Svalbard y Jan Mayen</option>
            <option value="TK">Islas Tokelau</option>
            <option value="TC">Islas Turks y Caicos</option>
            <option value="VI">Islas Vírgenes (EEUU)</option>
            <option value="VG">Islas Vírgenes (Reino Unido)</option>
            <option value="WF">Islas Wallis y Futuna</option>
            <option value="IL">Israel</option>
            <option value="IT">Italia</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japón</option>
            <option value="JO">Jordania</option>
            <option value="KZ">Kazajistán</option>
            <option value="KE">Kenia</option>
            <option value="KG">Kirguizistán</option>
            <option value="KI">Kiribati</option>
            <option value="KW">Kuwait</option>
            <option value="LA">Laos</option>
            <option value="LS">Lesotho</option>
            <option value="LV">Letonia</option>
            <option value="LB">Líbano</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libia</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lituania</option>
            <option value="LU">Luxemburgo</option>
            <option value="MK">Macedonia, Ex-República Yugoslava de</option>
            <option value="MG">Madagascar</option>
            <option value="MY">Malasia</option>
            <option value="MW">Malawi</option>
            <option value="MV">Maldivas</option>
            <option value="ML">Malí</option>
            <option value="MT">Malta</option>
            <option value="MA">Marruecos</option>
            <option value="MQ">Martinica</option>
            <option value="MU">Mauricio</option>
            <option value="MR">Mauritania</option>
            <option value="YT">Mayotte</option>
            <option value="MX">México</option>
            <option value="FM">Micronesia</option>
            <option value="MD">Moldavia</option>
            <option value="MC">Mónaco</option>
            <option value="MN">Mongolia</option>
            <option value="MS">Montserrat</option>
            <option value="MZ">Mozambique</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Níger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="NF">Norfolk</option>
            <option value="NO">Noruega</option>
            <option value="NC">Nueva Caledonia</option>
            <option value="NZ">Nueva Zelanda</option>
            <option value="OM">Omán</option>
            <option value="NL">Países Bajos</option>
            <option value="PA">Panamá</option>
            <option value="PG">Papúa Nueva Guinea</option>
            <option value="PK">Paquistán</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Perú</option>
            <option value="PN">Pitcairn</option>
            <option value="PF">Polinesia Francesa</option>
            <option value="PL">Polonia</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="QA">Qatar</option>
            <option value="UK">Reino Unido</option>
            <option value="CF">República Centroafricana</option>
            <option value="CZ">República Checa</option>
            <option value="ZA">República de Sudáfrica</option>
            <option value="DO">República Dominicana</option>
            <option value="SK">República Eslovaca</option>
            <option value="RE">Reunión</option>
            <option value="RW">Ruanda</option>
            <option value="RO">Rumania</option>
            <option value="RU">Rusia</option>
            <option value="EH">Sahara Occidental</option>
            <option value="KN">Saint Kitts y Nevis</option>
            <option value="WS">Samoa</option>
            <option value="AS">Samoa Americana</option>
            <option value="SM">San Marino</option>
            <option value="VC">San Vicente y Granadinas</option>
            <option value="SH">Santa Helena</option>
            <option value="LC">Santa Lucía</option>
            <option value="ST">Santo Tomé y Príncipe</option>
            <option value="SN">Senegal</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leona</option>
            <option value="SG">Singapur</option>
            <option value="SY">Siria</option>
            <option value="SO">Somalia</option>
            <option value="LK">Sri Lanka</option>
            <option value="PM">St Pierre y Miquelon</option>
            <option value="SZ">Suazilandia</option>
            <option value="SD">Sudán</option>
            <option value="SE">Suecia</option>
            <option value="CH">Suiza</option>
            <option value="SR">Surinam</option>
            <option value="TH">Tailandia</option>
            <option value="TW">Taiwán</option>
            <option value="TZ">Tanzania</option>
            <option value="TJ">Tayikistán</option>
            <option value="TF">Territorios franceses del Sur</option>
            <option value="TP">Timor Oriental</option>
            <option value="TG">Togo</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad y Tobago</option>
            <option value="TN">Túnez</option>
            <option value="TM">Turkmenistán</option>
            <option value="TR">Turquía</option>
            <option value="TV">Tuvalu</option>
            <option value="UA">Ucrania</option>
            <option value="UG">Uganda</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistán</option>
            <option value="VU">Vanuatu</option>
            <option value="VE">Venezuela</option>
            <option value="VN">Vietnam</option>
            <option value="YE">Yemen</option>
            <option value="YU">Yugoslavia</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabue</option>
          </select>
          <label for="customer_new_country" class="wabident-info"
            >Nacionalidad</label
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-6">
          <input
            v-model="form.address"
            type="text"
            name="address"
            id="customer_new_address"
            class="form-control"
          />
          <label for="customer_new_address" class="wabident-info"
            >Dirección</label
          >
        </div>
        <div class="col-sm-3">
          <input
            v-model="form.city"
            type="text"
            name="city"
            id="customer_new_city"
            class="form-control"
          />
          <label for="customer_new_city" class="wabident-info">Población</label>
        </div>
        <div class="col-sm-3">
          <input
            v-model="form.zipcode"
            type="text"
            name="zipcode"
            id="customer_new_zipcode"
            class="form-control"
          />
          <label for="customer_new_zipcode" class="wabident-info"
            >Código postal</label
          >
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-12">
          <select
            v-model="form.reason"
            name="reason"
            id="customer_new_reason"
            class="form-control custom-select"
            required
          >
            <option
              v-for="(name, value) in customer_reasons"
              :value="value"
              :key="value"
            >
              {{ name }}
            </option>
          </select>
          <label for="customer_new_reason" class="wabident-info"
            >¿Cómo nos conociste?</label
          >
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-center">
          <button
            :disabled="is_loading"
            type="submit"
            class="btn btn-primary btn-wabident mx-3"
            style="flex-basis: 316px"
          >
            Crear nueva ficha
            <i
              style="margin-top: 5px"
              v-if="is_loading"
              class="fas fa-circle-notch fa-spin float-right"
            ></i>
          </button>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      is_loading: false,
      is_loading_history_number: false,
      form: {
        name: "",
        nif: "",
        address: "",
        city: "",
        zipcode: "",
        email: "",
        phone: "",
        history_number: "",
        clinic_id: null,
        created_at: new Date().toISODate(),
        birth_date: "",
        country: "ES",
        reason: "other",
      },
    };
  },
  computed: {
    customer_reasons() {
      if (!this.clinic) return {};
      return this.clinic.customer_reasons;
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  methods: {
    show() {
      this.form.name = "";
      this.form.nif = "";
      this.form.address = "";
      this.form.city = "";
      this.form.zipcode = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.history_number = "";
      this.form.created_at = new Date().toISODate();
      this.form.birth_date = "";
      this.form.country = "ES";
      this.form.reason = "other";
      this.form.clinic_id = this.clinic ? this.clinic.id : null;
      this.getHistoryNumber();
      this.$refs.modal.show();
    },
    getHistoryNumber() {
      this.is_loading_history_number = true;
      axios
        .get("/customer/history_number")
        .then((response) => {
          this.form.history_number = response.data.history_number;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          this.is_loading_history_number = false;
        });
    },
    hide() {
      this.$refs.modal.hide();
    },
    onSubmit(ev) {
      ev.preventDefault();
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post("/customer", this.form)
          .then((response) => {
            this.$emit("customerCreated", response.data.customer);
            this.msgwindow.addMessage("success", "Paciente creado con éxito");
            this.hide();
          })
          .catch((error) => {
            console.log(error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
};
</script>
