<template>
  <div class="container clinic-templates">
    <clinic-show-template ref="showtemplate" @submit="onSubmit"></clinic-show-template>
    <!-- HEADER -->
    <div class="row">
      <div class="col-sm-9">
        <h1 class="page-title">PLANTILLAS DE DOCUMENTOS</h1>
      </div>
      <div class="col-sm-3 text-center">
        <button
          @click="addNewTemplate"
          type="button"
          class="btn btn-wabident-round btn-block"
        >Crear nueva plantilla</button>
      </div>
    </div>
    <!-- RESULTS -->
    <div class="row">
      <div class="col-sm-12">
        <div v-show="!has_results_available" class="text-center">
          <h2 class="template-search-empty-message my-4">{{empty_message}}</h2>
        </div>
        <div v-show="has_results_available" class="template-search-wrapper">
          <table class="table template-search-results">
            <thead>
              <tr>
                <th colspan="7">TITULO</th>
                <th colspan="2">FORMATO</th>
                <th colspan="2">TIPO</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="template-search-results__row"
                v-for="(template,index) in search_results"
                :key="index"
                @click="templateClicked(template)"
              >
                <td colspan="7">{{template.title}}</td>
                <td colspan="2">{{formatName(template.format)}}</td>
                <td colspan="2">{{typeName(template.type)}}</td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      search_results: [],
      is_searching: false,
      selected_template: null
    };
  },
  computed: {
    has_results_available() {
      return this.search_results && this.search_results.length != 0;
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningún templateo con ese nombre";
      }
    },
    ...mapGetters(["msgwindow"])
  },
  watch: {
    search(val) {
      this.searchTemplates(val);
    }
  },
  methods: {
    formatName(format) {
      return (
        {
          html: "HTML5",
          rich: "HTML Visual",
          markdown: "Markdown",
        }[format] || "Otro"
      );
    },
    typeName(type) {
      return (
        {
          proof: "Justificante",
          consent: "Consentimiento",
          report: "Informe"
        }[type] || "Otro"
      );
    },
    addNewTemplate() {
      this.$refs.showtemplate.showNew();
    },
    templateClicked(prod) {
      this.$refs.showtemplate.showEdit(prod);
    },
    onSubmit() {
      this.searchTemplates();
    },
    searchTemplates() {
      if (!this.is_searching) {
        this.is_searching = true;
        axios
          .get("/document_templates")
          .then(response => {
            this.search_results = response.data;
          })
          .catch(err => {
            console.log("[ERR] searchTemplates", err);
            this.msgwindow.error("Error buscando plantillas");
          })
          .then(() => {
            this.is_searching = false;
          });
      }
    }
  },
  mounted() {
    this.searchTemplates();
  }
};
</script>

<style lang="scss">
.clinic-templates {
  margin-top: 54px;
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.template-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
}

.template-search-wrapper {
  margin-top: 30px;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
}

.template-search-results {
  width: 100%;
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
    position: sticky;
    background-color: white;
    top: 0;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);

      &.template-code,
      &.template-icon {
        color: var(--theme-grey);
        text-transform: uppercase;
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
