<template>
  <div class="container clinic-availability">
    <div class="row">
      <clinic-show-availability
        ref="showavail"
        @submit="onSubmit"
        :doctor="selected_doctor"
        :selectedclinic="clinic_id"
      ></clinic-show-availability>
      <div class="col-md-12">
        <h2>DISPONIBILIDAD DE DOCTORES</h2>
        <!-- Search -->
        <div class="row form-group mt-4">
          <div class="col-md-6">
            <select
              id="select_doctor"
              v-model="doctor_id"
              class="custom-select"
            >
              <option :value="null">Seleccione Doctor/a ...</option>
              <option
                v-for="(doctor, index) in doctors"
                :key="index"
                :value="doctor.doctor.id"
              >
                {{ doctor.name }}
              </option>
            </select>
            <label for="select_doctor" class="wabident-info">Doctor</label>
          </div>
          <div v-if="selected_doctor" class="col-md-3">
            <div>Por defecto:</div>
            <div v-if="default_available"><b>Disponible</b></div>
            <div v-else><b>No disponible</b></div>
          </div>
          <div v-if="selected_doctor" class="col-md-3 text-center">
            <button
              @click="addNewEntry"
              type="button"
              class="btn btn-wabident-round btn-block"
            >
              Añadir disponibilidad
            </button>
          </div>
        </div>
        <!-- Calendar -->
        <div class="row form-group mt-4">
          <div v-if="show_calendar" class="col">
            <vue-cal
              :disable-views="['years', 'day']"
              :time-from="calendar_time_from"
              :time-to="calendar_time_to"
              :time-step="calendar_time_step"
              :events="calendar_events"
              :hide-weekdays="calendar_hide_weekdays"
              :on-event-click="onEventClick"
              @cell-click="onEmptyCellClick"
              locale="es"
            />
          </div>
          <div class="col text-center" v-else>
            <h2>Por favor, seleccione primero Doctor/a</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClinicShowAvailability from "./ClinicShowAvailability.vue";
export default {
  components: { ClinicShowAvailability },
  data() {
    return {
      clinic_id: null,
      doctor_id: null,
      is_loading: false,
      availabilities: [],
    };
  },
  watch: {
    clinic_id() {
      this.getDoctorAvailability();
    },
    doctor_id() {
      this.getDoctorAvailability();
    },
  },
  computed: {
    calendar_events() {
      if (!this.availabilities) return [];
      let ret = this.availabilities.map((value) => {
        return {
          start: moment(value.date_from).toDate(),
          end: moment(value.date_to).toDate(),
          title: value.is_available ? "Disponible" : "No Disponible",
          class: value.is_available ? "available" : "not-available",
          availability: value,
        };
      });

      this.clinic.schedule.vacations.forEach((day) => {
        ret.push({
          start: day,
          end: day,
          allDay: true,
          title: "Festivo/Vacaciones",
          class: "vacation",
          availability: null,
        });
      });

      return ret;
    },
    calendar_hide_weekdays() {
      if (!this.clinic) return [6, 7];
      let ret = [];
      for (let i = 0; i < 7; i++) {
        if (!this.clinic.schedule.daily_timeslots[i].checked) {
          ret.push(i + 1);
        }
      }
      return ret;
    },
    calendar_time_from() {
      if (!this.clinic) return 7 * 60;
      let ret = Number.MAX_SAFE_INTEGER;

      this.clinic.schedule.daily_timeslots.forEach((day) => {
        if (day.checked) {
          day.morning.start.forEach((slot) => {
            let m = slot.split(":");
            let hours = Number(m[0]);
            let minutes = Number(m[1]);
            let v = hours * 60 + minutes;
            ret = Math.min(ret, v);
          });

          day.afternoon.start.forEach((slot) => {
            let m = slot.split(":");
            let hours = Number(m[0]);
            let minutes = Number(m[1]);
            let v = hours * 60 + minutes;
            ret = Math.min(ret, v);
          });
        }
      });

      return ret || 7 * 60;
    },
    calendar_time_to() {
      if (!this.clinic) return 21 * 60;
      let ret = 0;

      this.clinic.schedule.daily_timeslots.forEach((day) => {
        if (day.checked) {
          day.morning.end.forEach((slot) => {
            let m = slot.split(":");
            let hours = Number(m[0]);
            let minutes = Number(m[1]);
            let v = hours * 60 + minutes;
            ret = Math.max(ret, v);
          });

          day.afternoon.end.forEach((slot) => {
            let m = slot.split(":");
            let hours = Number(m[0]);
            let minutes = Number(m[1]);
            let v = hours * 60 + minutes;
            ret = Math.max(ret, v);
          });
        }
      });

      return ret || 21 * 60;
    },
    calendar_time_step() {
      if (!this.clinic) return 0;
      return Number(this.clinic.schedule.slot_minutes);
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names || [];
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    show_calendar() {
      return this.clinic_id && this.doctor_id;
    },
    selected_doctor() {
      if (!this.doctor_id) return null;

      for (const i in this.clinic.doctors) {
        const doctor = this.clinic.doctors[i];

        if (doctor.doctor.id == this.doctor_id) {
          return doctor;
        }
      }

      return null;
    },
    default_available() {
      return this.selected_doctor.doctor.default_available;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    getDoctorAvailability() {
      if (!this.clinic_id) return;
      if (!this.doctor_id) return;
      if (this.is_loading) return;
      this.is_loading = true;

      let params = {
        clinic_id: this.clinic_id,
        doctor_id: this.doctor_id,
      };

      axios
        .get("/availability", { params })
        .then((response) => {
          this.availabilities = response.data;
        })
        .catch(() => {
          this.msgwindow.error("Error cargando disponibilidad doctores");
        })
        .then(() => {
          this.is_loading = false;
        });
    },
    addNewEntry() {
      this.$refs.showavail.showNew();
    },
    onSubmit() {
      this.getDoctorAvailability();
    },
    onEventClick(event, e) {
      if (event.availability) {
        this.$refs.showavail.showEdit(event.availability);
      }
      e.stopPropagation();
    },
    onEmptyCellClick(date) {
      this.$refs.showavail.showNewDate(date);
    },
  },
  mounted() {
    this.clinic_id = this.clinic.id;
  },
};
</script>

<style lang="scss">
.clinic-availability {
  margin-top: 54px;

  .vuecal__event.available {
    background: var(--theme-color-arrived);
    color: white;
    cursor: pointer;
  }

  .vuecal__event.not-available {
    background: var(--theme-color-absent);
    color: white;
    cursor: pointer;
  }

  .vuecal__event.vacation {
    background: var(--theme-color);
    color: white;
    cursor: not-allowed;
  }

  .vuecal__now-line {
    display: none;
  }
}
</style>
