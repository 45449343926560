<template>
  <b-modal
    id="customer-show-quotation"
    class="customer-show-quotation"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col-sm-3">
        <input
          type="date"
          class="form-control wabident-small"
          readonly
          :value="pretty_quotation_date"
        />
      </div>
      <div class="col-sm-9">
        <input
          type="text"
          class="form-control wabident-small"
          readonly
          :value="pretty_quotation_code"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <div class="customer-info">
          <div
            class="customer-photo"
            :style="{ 'background-image': 'url(' + customer.photo + ')' }"
          ></div>
          <div class="customer-data">
            {{ customer_name }}
            <br />
            {{ customer.phone || customer.phone_alt }} | {{ customer_nif }}
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <input
          type="text"
          class="form-control wabident-small"
          v-model="notes"
          placeholder="Notas adicionales / Nombre presupuesto"
        />
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <table class="table treatments-table">
          <thead>
            <tr>
              <th colspan="7">TRATAMIENTO</th>
              <th colspan="1">P.UNIT</th>
              <th colspan="1">CANT.</th>
              <th colspan="1">BASE</th>
              <th colspan="1">DESCUENTO</th>
              <th colspan="1">TOTAL</th>
              <th colspan="1"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in quotation_lines" :key="index">
              <td colspan="7">{{ prettyLineName(line) }}</td>
              <td colspan="1">
                <input
                  type="number"
                  v-model.number="line.price"
                  class="form-control wabident-xsmall"
                />
              </td>
              <td colspan="1">
                <input
                  type="number"
                  v-model.number="line.quantity"
                  min="1"
                  class="form-control wabident-xsmall"
                />
              </td>
              <td colspan="1">{{ prettyPrice(line) }}</td>
              <td colspan="1">
                <input
                  type="number"
                  v-model.number="line.discount"
                  min="0"
                  max="100"
                  class="form-control wabident-xsmall"
                />
              </td>
              <td colspan="1">{{ prettyNetPrice(line) }}</td>
              <td
                colspan="1"
                @click="removeQuotationLine(index)"
                class="trash-icon"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 0 0-1 1v1h6V4a1 1 0 0 0-1-1h-4zm7 2V4a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3v1H5a1 1 0 0 0-1 1v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V6a1 1 0 0 0-1-1h-2zM6 7v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7H6z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zM14 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </td>
            </tr>
            <tr>
              <td colspan="14">
                <select
                  v-model="selected_add_teeth"
                  @change="addTreatment"
                  class="custom-select form-control"
                >
                  <option value>Añadir un tratamiento</option>
                  <option
                    v-for="t in addable_treatments"
                    :key="t.id"
                    :value="t"
                  >
                    {{ t.product.name }} - {{ prettyTeeth(t) }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td colspan="10"></td>
              <td colspan="2">
                {{ pretty_total }}
                <label class="total">BASE</label>
              </td>
              <td colspan="2">
                {{ pretty_net_total }}
                <label class="total">TOTAL</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row form-group">
      <div class="col buttons-column align-items-center">
        <button
          :disabled="is_loading"
          @click="onDelete"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          @click="submitQuotation()"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident float-left"
        >
          Guardar presupuesto
        </button>
        <button
          @click="submitApproval()"
          v-if="!is_editing"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident float-left"
        >
          Aprobar presupuesto
        </button>

        <div @click="sendMail" class="btn-sendmail float-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="17"
            viewBox="0 0 24 17"
          >
            <path
              d="M2.341 0A2.35 2.35 0 0 0 0 2.341V14.05a2.35 2.35 0 0 0 2.341 2.341h19.317A2.35 2.35 0 0 0 24 14.05V2.34A2.35 2.35 0 0 0 21.659 0H2.34zm0 1.17h19.317c.17 0 .323.04.467.101L12.732 9.66c-.444.396-1 .396-1.445 0L1.875 1.27c.144-.061.297-.1.466-.1zM1.18 2.224l6.732 6-6.613 6.356a1.21 1.21 0 0 1-.128-.53V2.34c0-.04.005-.079.009-.118zm21.64 0c.004.04.01.077.01.118V14.05c0 .19-.051.373-.129.53l-6.594-6.366 6.713-5.99zM15.23 9l6.457 6.22H2.314L8.79 9.01l1.719 1.527a2.25 2.25 0 0 0 3 0L15.229 9z"
            />
          </svg>
        </div>
        <div @click="print" role="button" class="btn-print float-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23"
            viewBox="0 0 24 23"
          >
            <path
              d="M5.053 3.79h13.894V1.262H5.053V3.79zm-1.264 0V.631c0-.35.283-.632.632-.632H19.58c.349 0 .631.283.631.632v3.157h3.158c.35 0 .632.283.632.632v8.842a.632.632 0 0 1-.632.632h-3.157v3.158a.631.631 0 0 1-.185.446l-5.053 5.053a.632.632 0 0 1-.447.185H4.421a.632.632 0 0 1-.632-.632v-8.21H.632A.632.632 0 0 1 0 13.263V4.421c0-.349.283-.632.632-.632h3.157zm0 8.842V12c0-.349.283-.632.632-.632H19.58c.349 0 .631.283.631.632v.632h2.527v-7.58H1.263v7.58H3.79zm11.369 7.949l2.896-2.897h-2.896v2.896zm-1.263.893v-4.421c0-.35.283-.632.631-.632h4.421v-3.79H5.053v8.843h8.842zm-6.948-6.316a.632.632 0 0 1 0-1.263h8.843a.632.632 0 0 1 0 1.263H6.947zm0 2.526a.632.632 0 0 1 0-1.263h3.79a.631.631 0 1 1 0 1.263h-3.79zm0 2.526a.632.632 0 1 1 0-1.263h3.79a.631.631 0 1 1 0 1.264h-3.79zM4.421 7.58a.632.632 0 0 1 0-1.263h2.526a.632.632 0 0 1 0 1.263H4.421z"
            />
          </svg>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    customer: { required: true },
    clinic: { default: null },
  },
  data() {
    return {
      quotation_id: 0,
      quotation_code: "",
      notes: "",
      quotation_date: new Date(),
      is_editing: false,
      is_loading: false,
      quotation_lines: [],
      selected_treatments: [],
      available_treatments: [],
      selected_add_teeth: "",
    };
  },
  computed: {
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    is_minor() {
      if (this.customer.is_incapacitated) return true;
      let birthday = moment(this.customer.birth_date);
      let date = moment(this.quotation_date.toISODate());
      let years = date.diff(birthday, "years");
      return years < 18;
    },
    customer_name() {
      return this.is_minor
        ? "Rep: " + this.customer.adult_name
        : this.customer.name;
    },
    customer_nif() {
      return this.is_minor ? this.customer.adult_nif : this.customer.nif;
    },
    addable_treatments() {
      return this.available_treatments.filter((x) => {
        return (
          this.selected_treatments.filter((y) => {
            return x.id == y.id;
          }).length <= 0
        );
      });
    },
    total() {
      return this.quotation_lines.reduce((cur, val) => {
        return cur + Number(val.price) * Number(val.quantity);
      }, 0);
    },
    net_total() {
      return this.quotation_lines.reduce((cur, val) => {
        return (
          cur +
          Number(val.price) *
            Number(val.quantity) *
            (1 - Number(val.discount) / 100)
        );
      }, 0);
    },
    pretty_quotation_date() {
      return this.quotation_date.toISODate();
    },
    pretty_quotation_code() {
      return `Presupuesto #${this.quotation_code}`;
    },
    pretty_net_total() {
      return WabiDent.fmtMoney(this.net_total);
    },
    pretty_total() {
      return WabiDent.fmtMoney(this.total);
    },
    dialog_title() {
      return this.is_editing ? "Modificar Presupuesto" : "Crear Presupuesto";
    },
    ...mapGetters(["msgwindow", "errorwindow"]),
  },
  methods: {
    prettyLineName(line) {
      if (this.clinic_type == "dental") {
        return line.product.name + " (" + this.prettyTeeth(line) + ")";
      }

      return line.product.name;
    },
    prettyTeeth(l) {
      return l.teeth.reduce((r, x) => {
        return r + " " + x;
      }, "");
    },
    prettyUnitPrice(line) {
      return WabiDent.fmtMoney(line.price);
    },
    prettyPrice(line) {
      return WabiDent.fmtMoney(line.price * line.quantity);
    },
    prettyNetPrice(line) {
      let price =
        Number(line.price) *
        Number(line.quantity) *
        (1 - Number(line.discount) / 100);
      return WabiDent.fmtMoney(price);
    },
    updateQuotationLines() {
      let lines = {};
      this.selected_treatments.forEach((treatment) => {
        let id = treatment.product.id;
        lines[id] = lines[id] || {
          product: treatment.product,
          quantity: 0,
          treatments: [],
          teeth: [],
          discount: 0,
          price: treatment.product.price,
        };

        lines[id].teeth = lines[id].teeth.concat(treatment.teeth);
        lines[id].quantity += 1;
        lines[id].treatments.push(treatment);
      });

      this.$set(this, "quotation_lines", Object.values(lines));
    },

    updateQuotationLinesForEdit() {
      this.quotation_lines.forEach((line) => {
        if (line.teeth == null) {
          line.teeth = [];

          let treatments_matching = this.selected_treatments.filter(
            (treatment) => {
              return treatment.product_id == line.product_id;
            }
          );

          treatments_matching.forEach((treatment) => {
            line.teeth = line.teeth.concat(treatment.teeth);
          });
        }
      });
    },

    updateQuotationTreatments() {
      this.selected_treatments.forEach((t) => {
        let quotation_line = this.quotation_lines.find((line) => {
          return t.product.id == line.product.id;
        });

        if (quotation_line) {
          quotation_line.treatments = quotation_line.treatments || [];
          quotation_line.treatmets.push(t);
        }
      });
    },
    showNew(selected_treatments, available_treatments) {
      this.quotation_id = 0;
      this.notes = "";
      this.selected_treatments = WabiDent.cloneObj(
        selected_treatments.length > 0
          ? selected_treatments
          : available_treatments
      );
      this.available_treatments = WabiDent.cloneObj(available_treatments);
      this.updateQuotationLines();

      this.is_editing = false;
      this.quotation_date = new Date();
      this.getQuotationCode();
      this.$refs.modal.show();
    },
    showEdit(quotation, available_treatments) {
      this.quotation_id = quotation.id;
      this.notes = quotation.notes;
      this.is_editing = true;
      this.quotation_date = moment(quotation.created_at).toDate();

      this.quotation_lines = WabiDent.cloneObj(quotation.quotationlines);
      this.selected_treatments = WabiDent.cloneObj(quotation.treatments);

      this.available_treatments = WabiDent.cloneObj(
        available_treatments
      ).concat(this.selected_treatments);

      this.updateQuotationLinesForEdit();
      this.$refs.modal.show();
    },
    addTreatment(ev) {
      let treatment = this.selected_add_teeth;
      if (treatment) {
        this.selected_treatments.push(treatment);

        let line = this.quotation_lines.find((line) => {
          return line.product.id == treatment.product.id;
        });

        if (line) {
          line.quantity += 1;
          line.teeth = line.teeth.concat(treatment.teeth);
          line.treatments.push(treatment);
        } else {
          this.quotation_lines.push({
            product: treatment.product,
            quantity: 1,
            treatments: [treatment],
            price: treatment.product.price,
            discount: 0,
            teeth: treatment.teeth,
          });
        }
      }
      this.selected_add_teeth = "";
    },
    removeQuotationLine(index) {
      let line = this.quotation_lines[index];
      let removed_product_id = line.product.id;

      this.selected_treatments = this.selected_treatments.filter((t) => {
        return t.product.id != removed_product_id;
      });
      this.quotation_lines.splice(index, 1);
    },
    print() {
      this.submitQuotation((data) => {
        this.$refs.modal.hide();
        window.open(`/quotation/${data.id}/pdf`, "_blank");
      });
    },
    sendMail() {
      this.submitQuotation((data) => {
        this.$refs.modal.hide();
        this.msgwindow.info("Enviando presupuesto ...");
        axios
          .get(`/quotation/${data.id}/email`)
          .then((response) => {
            let data = response.data;
            if (data.error) {
              this.msgwindow.error(data.message);
            } else {
              this.msgwindow.success(
                "Presupuesto enviado a " + this.customer.email
              );
            }
          })
          .catch((error) => {
            console.log("[ERR] sendQuotationEmail ", error);
            this.msgwindow.error("Error enviando presupuesto !");
          });
      }, "mail");
    },
    saveInvoice() {
      this.errorwindow.show(
        "Confirmación",
        "Al generar factura se aprobará el presupuesto. ¿Seguro?",
        "Sí, Aprobar",
        "Cancelar",
        () => {
          this.submitQuotation((data) => {
            this.msgwindow.success("Factura creada ");
            this.$refs.modal.hide();
          }, "invoice");
        }
      );
    },
    submitQuotation(callback, action = null) {
      if (this.selected_treatments.length <= 0) {
        this.errorwindow.show("Error", "Debe añadir al menos un tratamiento");
        return;
      }

      if (this.is_editing) {
        this.modifyQuotation(callback, action);
      } else {
        this.saveQuotation(callback, action);
      }
    },
    submitApproval() {
      this.errorwindow.show(
        "Confirmación",
        "¿Quieres aprobar directamente el presupuesto?<br>Puedes volver a abrirlo en la pestaña 'Facturación'",
        "Aprobar",
        "Cancelar",
        () => {
          this.submitQuotation(null, "approve");
        }
      );
    },
    onDelete() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/quotation/${this.quotation_id}`,
            { _method: "delete" }
          )
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Presupuesto borrado");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            console.log("[ERR] deleteQuotation ", err);
            this.msgwindow.error("Error borrando presupuesto!");
            this.$refs.modal.hide();
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    modifyQuotation(callback, action = null) {
      if (!this.is_loading) {
        this.is_loading = true;
        var params = {
          quotation_id: this.quotation_id,
          lines: this.quotation_lines.map((x) => {
            return {
              product: x.product.id,
              quantity: x.quantity,
              price: x.price,
              discount: x.discount,
              teeth: x.teeth,
            };
          }),
          treatments: this.selected_treatments.map((x) => x.id),
          code: this.quotation_code,
          action: action,
          clinic_id: this.clinic ? this.clinic.id : null,
          notes: this.notes,
        };

        axios
          .post(
            `/customer/${this.customer.id}/quotation/${this.quotation_id}`,
            params
          )
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Presupuesto modificado con éxito");
            if (callback) {
              callback(response.data);
            } else {
              this.$refs.modal.hide();
            }
          })
          .catch((error) => {
            console.log("[ERR] modifyQuotation", error);
            this.msgwindow.error("Error modificando presupuesto !");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    saveQuotation(callback, action = null) {
      if (!this.is_loading) {
        this.is_loading = true;
        var params = {
          lines: this.quotation_lines.map((x) => {
            return {
              product: x.product.id,
              quantity: x.quantity,
              price: x.price,
              discount: x.discount,
              teeth: x.teeth,
            };
          }),
          treatments: this.selected_treatments.map((x) => x.id),
          code: this.quotation_code,
          action: action,
          clinic_id: this.clinic ? this.clinic.id : null,
          notes: this.notes,
        };

        axios
          .post(`/customer/${this.customer.id}/quotation`, params)
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Presupuesto creado con éxito");
            if (callback) {
              callback(response.data);
            } else {
              this.$refs.modal.hide();
            }
          })
          .catch((error) => {
            console.log("[ERR] saveQuotation", error);
            this.msgwindow.error("Error creando presupuesto !");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    getQuotationCode() {
      axios
        .get("/quotation/code")
        .then((response) => {
          this.quotation_code = response.data;
        })
        .catch((error) => {
          this.quotation_code = "";
        });
    },
  },
  mounted() {
    this.getQuotationCode();
  },
};
</script>

<style lang="scss">
#customer-show-quotation {
  .modal-dialog {
    max-width: 50%;
  }

  .treatments-table {
    thead {
      th {
        border-top: none;
        border-bottom: 1px var(--theme-grey) solid;
        color: var(--theme-grey);
        font-size: 11px;
        letter-spacing: 0.16px;
        font-weight: 300;
        line-height: 1.82;
      }
    }
    tbody {
      td {
        font-size: 16px;
        color: var(--theme-text-color);
        border: none;
        vertical-align: middle;

        input {
          margin: 0;
          width: 80px;
        }

        .total {
          display: block;
          font-size: 11px;
          line-height: 1.82;
          font-weight: 300;
          color: var(--theme-grey);
          letter-spacing: 0.16px;
          margin-top: 9px;
          border-top: 1px var(--theme-grey) solid;
          width: 100%;
          padding: 7px;
        }
      }

      .trash-icon {
        cursor: pointer;
        color: var(--theme-grey);
        transition: all 0.2s;
        &:hover {
          color: var(--theme-color-absent);
        }
        svg {
          fill: currentColor;
        }
      }
    }
  }

  .customer-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--theme-grey-bg);
    border-bottom: 1px var(--theme-line-color) solid;
    padding: 15px;

    .customer-photo {
      width: 62px;
      height: 62px;
      background-size: cover;
      background-position: center center;
      border-radius: 31px;
      margin-left: 4px;
    }

    .customer-data {
      padding: 11px 18px;
      font-size: 20px;
      line-height: 1.25;
      color: var(--theme-text-color);
    }
  }

  .buttons-column {
    button {
      flex: 1 0 180px;
    }
    button + button {
      margin-left: 10px;
    }

    .btn-link + .btn-link {
      margin-left: 10px;
    }

    .btn-sendmail,
    .btn-print {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 13px;
      height: 100%;
      cursor: pointer;
      display: block;
      width: 24px;
      color: var(--theme-grey) !important;
      transition: all 0.2s;
      fill: currentColor;
      &:hover {
        color: var(--theme-color) !important;
      }
      svg {
        transition: all 0.2s;
        fill: currentColor;
      }
    }
  }
}
</style>

