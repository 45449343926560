<template>
  <div class="container-fluid clinic-payments" style="margin-top: 40px">
    <customer-show-invoice
      ref="showinvoice"
      :customer="selected_customer"
      :clinic="clinic"
      @submit="onSubmit"
    ></customer-show-invoice>

    <customer-show-payment
      ref="showpayment"
      :customer="selected_customer"
      @submit="onSubmit"
    ></customer-show-payment>

    <div class="row">
      <!-- LEFT COLUMN -->
      <div class="col-md-2">
        <h1 class="clinic-payments__title">RESUMEN</h1>
        <h5>
          TOTAL: <strong>{{ total }}</strong>
        </h5>
      </div>
      <!-- CENTER COLUMN -->
      <div class="col-md-8">
        <div class="row">
          <div class="col">
            <h1 class="clinic-payments__title">
              <span v-if="clinic.type == 'dental'">PAGOS</span>
              <span v-else>COBROS</span>
              </h1>
          </div>
        </div>
        <div class="clinic-payments__inputs row form-group">
          <div class="col-sm-2">
            <select
              id="clinic_payments_period"
              v-model="period"
              class="custom-select"
            >
              <option value="day">Hoy</option>
              <option value="day_prior">Ayer</option>
              <option value="week">Esta semana</option>
              <option value="week_prior">Semana pasada</option>
              <option value="month">Este mes</option>
              <option value="month_prior">Mes pasado</option>
              <option value="trimester">Este trimestre</option>
              <option value="trimester_prior">Trimestre pasado</option>
              <option value="year">Este año</option>
              <option value="past_year">Año pasado</option>
              <option value="custom">Personalizado</option>
            </select>
            <label for="clinic_payments_period" class="wabident-info"
              >Periodo</label
            >
          </div>
          <transition name="fade">
            <div class="col-sm-2" v-if="period == 'custom'">
              <input
                v-model="date_from"
                id="clinic_payments_date_from"
                type="date"
                class="form-control"
              />
              <label class="wabident-info" for="clinic_payments_date_from"
                >Desde:</label
              >
            </div>
          </transition>
          <transition name="fade">
            <div class="col-sm-2" v-if="period == 'custom'">
              <input
                v-model="date_to"
                id="clinic_payments_date_to"
                type="date"
                class="form-control"
              />
              <label class="wabident-info" for="clinic_payments_date_to"
                >Hasta:</label
              >
            </div>
          </transition>
          <div class="col-sm-2">
            <select
              id="clinic_payments_type"
              v-model="type"
              class="custom-select"
            >
              <option :value="null">Todos</option>
              <option value="cash">Efectivo</option>
              <option value="credit_card">Tarjeta</option>
              <option value="transfer">Transferencia</option>
              <option value="mobile">Bizum/Móvil</option>
              <option value="direct_debit">Financiación</option>
              <option value="other">Otros</option>
            </select>
            <label for="clinic_payments_type" class="wabident-info"
              >Tipo de pago</label
            >
          </div>

          <div class="col-sm-2">
            <select
              id="clinic_payments_is_invoiced"
              v-model="is_invoiced"
              class="custom-select"
            >
              <option :value="null">Todos</option>
              <option :value="'invoiced'">Facturados</option>
              <option :value="'uninvoiced'">Sin facturar</option>
            </select>
            <label for="clinic_payments_is_invoiced" class="wabident-info"
              >¿Facturado?</label
            >
          </div>

          <div class="col-sm-2">
            <select
              id="clinic_payments_clinic_id"
              v-model="clinic_id"
              class="custom-select"
            >
              <option :value="null">Todas</option>
              <option v-for="(name, id) in clinics" :key="id" :value="id">
                {{ name }}
              </option>
            </select>
            <label for="clinic_payments_clinic_id" class="wabident-info"
              >Clínica</label
            >
          </div>
        </div>

        <div v-if="!is_loading" class="clinic-payments__list">
          <table class="table">
            <thead>
              <tr>
                <th>FECHA</th>
                <th>TIPO</th>
                <th>PACIENTE</th>
                <th>CLÍNICA</th>
                <th>FACTURA</th>
                <th>IMPORTE</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(payment, index) in search_payments">
                <clinic-payment
                  class="clinic-payments__row"
                  :payment="payment"
                  :key="index"
                  :clinics="clinics"
                  @invoice="emitInvoice"
                  @payment="updatePayment"
                ></clinic-payment>
              </template>
            </tbody>
          </table>
        </div>
        <div v-else class="clinic-payments__loading text-center">
          <h2>
            Buscando
            <i class="fas fa-spin fa-circle-notch"></i>
          </h2>
        </div>

        <!-- PAGINATION -->
        <div v-if="has_results_available" class="row">
          <div class="col text-center customer-search-pagination my-2">
            <button
              :disabled="is_first_page"
              @click="goFirst"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-left"></i>
            </button>
            <button
              :disabled="is_first_page"
              @click="goPrev"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-left"></i>
            </button>
            <label class="customer-search-pagination-locator mx-2">
              Página
              {{ current_page }} de {{ total_pages }}
            </label>
            <button
              :disabled="is_last_page"
              @click="goNext"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-right"></i>
            </button>
            <button
              :disabled="is_last_page"
              @click="goLast"
              type="button"
              class="btn btn-primary btn-wabident-round mx-1"
            >
              <i class="fas fa-angle-double-right"></i>
            </button>
          </div>
        </div>

      </div>

      <!-- RIGHT COLUMN -->
      <div class="col-md-2">
        <h1 class="clinic-payments__title">INFORMES</h1>
        <div class="clinic-payments__inputs row form-group">
          <div
            class="
              col
              clinic-payments__summary
              d-flex
              flex-column
              justify-content-start
              align-items-center
            "
          >
            <button
              :disabled="is_loading"
              @click="generateReport('csv')"
              class="
                btn btn-primary btn-wabident btn-block
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <i
                style="font-size: 24px; margin-right: 20px"
                class="fas fa-download"
              ></i>
              Descargar Excel
            </button>
            <button
              :disabled="is_loading"
              @click="generateReport('pdf')"
              class="
                btn btn-primary btn-wabident btn-block
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <i
                style="font-size: 24px; margin-right: 20px"
                class="wabident icon-wabident-print"
              ></i>
              Imprimir
            </button>
            <button
              :disabled="is_loading"
              @click="sendPayments"
              class="
                btn btn-primary btn-wabident btn-block
                d-flex
                align-items-center
                justify-content-center
              "
            >
              <i
                style="font-size: 24px; margin-right: 20px"
                class="wabident icon-wabident-mail"
              ></i>
              Enviar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 0,
      is_loading: false,
      period: "day",
      date_from: new Date().toISODate(),
      date_to: new Date().toISODate(),
      type: null,
      clinic_id: null,
      payments: null,
      is_invoiced: null,
      summary: [],
      selected_customer: null,
      selected_invoices: [],
    };
  },
  computed: {
    total() {
      if (!this.summary.total) return "";
      return WabiDent.fmtMoney(this.summary.total, 2);
    },
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.payments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.payments.current_page == this.payments.last_page;
    },
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.payments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.payments.current_page == this.payments.last_page;
    },
    search_payments() {
      if (this.is_searching) return [];
      if (!this.payments) return [];
      if (!this.payments.data) return [];
      return this.payments.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.payments.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.payments.last_page;
    },
    has_results_available() {
      if (this.is_loading) {
        return false;
      }

      if (!this.payments) {
        return false;
      }

      return this.payments.data.length > 0;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  watch: {
    type(val) {
      this.page = 1;
      this.loadPayments();
    },
    period(val) {
      this.page = 1;
      this.loadPayments();
    },
    date_from(val) {
      this.page = 1;
      this.loadPayments();
    },
    date_to(val) {
      this.page = 1;
      this.loadPayments();
    },
    clinic_id(val) {
      this.page = 1;
      this.loadPayments();
    },
    is_invoiced(val) {
      this.page = 1;
      this.loadPayments();
    },
  },
  methods: {
    onSubmit() {
      this.loadPayments();
    },
    emitInvoice(payment) {
      this.selected_customer = payment.customer;
      this.$refs.showinvoice.$forceUpdate();
      this.$refs.showinvoice.showNewPayment(payment);
    },
    updatePayment(payment) {
      this.selected_customer = payment.customer;
      this.$refs.showpayment.$forceUpdate();
      this.$refs.showpayment.showEdit(payment);
    },
    userCan(cap) {
      if (!this.clinic) return false;
      if (!this.clinic.capabilities) return false;
      return this.clinic.capabilities[cap] || false;
    },
    generateReport(type) {
      let params = {
        period: this.period,
        date_from: this.date_from,
        date_to: this.date_to,
        type: this.type,
        clinic_id: this.clinic_id,
        is_invoiced: this.is_invoiced,
      };

      params[type] = true;

      let query_params = [];
      for (let field in params) {
        let val = params[field];
        if (val) {
          query_params.push(`${field}=${params[field]}`);
        }
      }

      window.open("/clinic/payments?" + query_params.join("&"), "_blank");
    },
    loadPayments() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          period: this.period,
          date_from: this.date_from,
          date_to: this.date_to,
          type: this.type,
          clinic_id: this.clinic_id,
          page: this.page,
          is_invoiced: this.is_invoiced,
        };
        axios
          .get("/clinic/payments", { params })
          .then((response) => {
            this.payments = response.data.payments;
            this.page = response.data.payments.current_page;
            this.summary = response.data.summary;
            this.date_from = response.data.params.date_from;
            this.date_to = response.data.params.date_to;
          })
          .catch((error) => {
            console.log("[ERR] loadPayments ", error);
            this.msgwindow.error("Error cargando pagos");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    sendPayments() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          period: this.period,
          date_from: this.date_from,
          date_to: this.date_to,
          type: this.type,
          clinic_id: this.clinic_id,
          email: true,
          is_invoiced: this.is_invoiced,
        };
        this.msgwindow.info("Enviando correo ...");
        axios
          .get("/clinic/payments", { params })
          .then((response) => {
            if (response.data.error) {
              this.msgwindow.error(
                "Error enviando correo :" + response.data.error
              );
            } else {
              this.msgwindow.success("Correo enviado");
            }
          })
          .catch((error) => {
            console.log("[ERR] loadPayments ", error);
            this.msgwindow.error("Error enviando correo");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    goFirst() {
      this.page = 1;
      this.loadPayments();
    },
    goPrev() {
      this.page -= 1;
      this.loadPayments();
    },
    goNext() {
      this.page += 1;
      this.loadPayments();
    },
    goLast() {
      this.page = this.total_pages;
      this.loadPayments();
    },
  },
  mounted() {
    this.loadPayments();
  },
};
</script>

<style lang="scss">
.clinic-payments {
  &__title {
    font-size: 32px;
    color: var(--theme-text-color);
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 1.31;
    margin-bottom: 25px;
  }

  &__list {
    width: 100%;
    table {
      th {
        font-size: 15px;
        letter-spacing: 0.3px;
        color: var(--theme-grey);
        border: none;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        border-bottom: 1px #e0e0e0 solid;
      }

      &__row {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.2s;
        border-bottom: 1px #e0e0e0 solid;
        border-top: 1px #e0e0e0 solid;

        &:hover {
          background-color: var(--theme-highlight);
        }

        td {
          font-size: 16px;
          line-height: 20px;
          color: var(--theme-text-color);
        }
      }
    }
  }
}
</style>
