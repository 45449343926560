<template>
  <div class="container clinic-config">
    <div class="row mt-4">
      <div class="col">
        <h1 class="page-title">CONFIGURACION AVANZADA</h1>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="clinic-config__list">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2">CLAVE</th>
                <th colspan="10">CLAVE</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="clinic-config__row"
                v-for="(value, k) in clinic_config"
                :key="k"
              >
                <td colspan="2">{{ k }}</td>
                <td colspan="10">
                  <input
                    class="form-control"
                    type="text"
                    v-model="clinic_config[k]"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["clinic"],
  data() {
    return {
      clinic_config: {},
    };
  },
  mounted() {
    this.clinic_config = window.WabiDent.cloneObj(this.clinic.config);
  },
};
</script>

<style lang="scss">
.clinic-config {
  &__list {
    table {
      th {
        font-size: 15px;
        letter-spacing: 0.3px;
        color: var(--theme-grey);
        border: none;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        border-bottom: 1px #e0e0e0 solid;
      }

      &__row {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.2s;
        border-bottom: 1px #e0e0e0 solid;
        border-top: 1px #e0e0e0 solid;

        &:hover {
          background-color: var(--theme-highlight);
        }

        td {
          font-size: 16px;
          line-height: 20px;
          color: var(--theme-text-color);
        }
      }
    }
  }
}
</style>
