/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import "./bootstrap";

window.moment = require("moment");
moment.locale("es");

import Vue from "vue";
import Vuex from "vuex";
import {
    mapGetters,
    mapMutations
} from "vuex";
import VCalendar from "v-calendar";
import BootstrapVue from "bootstrap-vue";
import vSelect from "vue-select";
import VueQRCodeComponent from "vue-qrcode-component";
import VueSignaturePad from "vue-signature-pad";
import "prismjs";
import "prismjs/themes/prism.css";
import VuePrismEditor from "vue-prism-editor";
import "vue-prism-editor/dist/VuePrismEditor.css";
import {
    Compact
} from "vue-color";
import VueCal from "vue-cal";
import "vue-cal/dist/i18n/es.js";
import "vue-cal/dist/vuecal.css";
import FileSelector from 'vue-file-selector';

// Rich-editor
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

Vue.use(VueQuillEditor);
Vue.use(FileSelector);
Vue.component("prism-editor", VuePrismEditor);
Vue.component("compact-picker", Compact);
Vue.component("vue-cal", VueCal);

window.mapGetters = mapGetters;
window.mapMutations = mapMutations;

// VSelect
Vue.component("v-select", vSelect);

// Vuex store
Vue.use(Vuex);

// Bootstrap vue widgets
Vue.use(BootstrapVue);

// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: "vc",
});

// QR Code
Vue.component("qr-code", VueQRCodeComponent);

// Vue Signature Pad
Vue.use(VueSignaturePad);

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context("./", true, /\.vue$/i);
files
    .keys()
    .map((key) =>
        Vue.component(key.split("/").pop().split(".")[0], files(key).default)
    );

const store = new Vuex.Store({
    state: {
        clinic: null,
        msgwindow: null,
        errorwindow: null,
    },
    getters: {
        clinic(state) {
            return state.clinic;
        },
        msgwindow(state) {
            return state.msgwindow;
        },
        errorwindow(state) {
            return state.errorwindow;
        },
    },
    mutations: {
        setClinic(state, clinic) {
            state.clinic = clinic;
        },
        setMsgWindow(state, msgwindow) {
            state.msgwindow = msgwindow;
        },
        setErrorWindow(state, errorwindow) {
            state.errorwindow = errorwindow;
        },
    },
});

// Attach CSRF-TOKEN to web for POSTs
axios.defaults.headers.common = {
    "X-Requested-With": "XMLHttpRequest",
    "X-CSRF-TOKEN": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
};

// Print only dates in ISO Format
window.pad = function(n, width, z) {
    z = z || "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

Date.prototype.toISODate = function() {
    return (
        this.getUTCFullYear() +
        "-" +
        pad(this.getUTCMonth() + 1, 2) +
        "-" +
        pad(this.getUTCDate(), 2)
    );
};

// Limit string with ellipsis
String.prototype.limit = function(len, tail = "…") {
    if (this.length > len) {
        return this.substr(0, len - 1) + tail;
    }
};

window.WabiDent = {
    ColorPalette: [
        "#E57373",
        "#F06292",
        "#BA68C8",
        "#9575CD",
        "#7986CB",
        "#64B5F6",
        "#4FC3F7",
        "#4DD0E1",
        "#4DB6AC",
        "#81C784",
        "#AED581",
        "#DCE775",
        "#FFF176",
        "#FFD54F",
        "#FFB74D",
        "#FF8A65",
        "#A1887F",
        "#E0E0E0",
        "#90A4AE",
    ],
    AdultType: {
        mother: "Madre",
        father: "Padre",
        brother: "Hermano",
        sister: "Hermana",
        grandmother: "Abuela",
        grandfather: "Abuelo",
        other: "Otros",
    },
    AppointmentStatus: {
        pending: "Pendiente",
        arrived: "Se presentó",
        attended: "Atendido",
        cancel: "Cancelada",
        absent: "No acude / No avisa",
    },
    cloneObj(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    fmtMoney(value, min = 2, max = 2) {
        let v = Number(value) || 0;
        return v.toLocaleString(undefined, {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: min,
            maximumFractionDigits: max,
        });
    },
    getIconColor(treatment) {
        if (!treatment) return "black";
        switch (treatment.status) {
            case "done":
                return "var(--theme-color-attended)";
            case "done_prior":
                return "black";
            default:
                if (treatment.is_priority) return "var(--theme-color-absent)";
                else return "var(--theme-color-urgent)";
        }
    },
    hexToRGBA(hex, alpha = "1") {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
            c = hex.substring(1).split("");
            if (c.length == 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c = "0x" + c.join("");
            return (
                "rgba(" + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
                "," +
                alpha +
                ")"
            );
        }
        throw new Error("Bad Hex");
    },
};

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const app = new Vue({
    el: "#app",
    store,
});