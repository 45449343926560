<template>
  <b-modal
    id="clinic-show-user"
    class="clinic-show-user"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="form-group row">
      <div class="col-sm-12">
        <input
          v-model="is_active"
          class="form-check-input"
          type="checkbox"
          id="user_is_active"
          value="1"
        />
        <label class="form-check-label" for="user_is_active"
          >Usuario Activo - ( Desmarca para desactivar )</label
        >
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-4">
        <input
          placeholder="Nombre"
          v-model="name"
          model="name"
          type="text"
          id="show_user_name"
          class="form-control"
          required
        />
        <label class="wabident-info" for="show_user_name">Nombre</label>
      </div>

      <div class="col-sm-4">
        <input
          placeholder="Email"
          v-model="email"
          name="email"
          type="email"
          id="show_user_email"
          class="form-control"
          required
        />
        <label class="wabident-info" for="show_template_title"
          >Email ( Se usa para entrar en la aplicación )</label
        >
      </div>

      <div class="col-sm-4">
        <input
          placeholder="Contraseña"
          v-model="password"
          name="password"
          type="text"
          id="show_user_password"
          class="form-control"
          :required="is_editing"
        />
        <label class="wabident-info" for="show_user_password"
          >Contraseña
          <span v-if="is_editing">( Vacía para no cambiar)</span></label
        >
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-4">
        <select
          v-model="clinic_id"
          name="clinic_id"
          id="show_user_clinic_id"
          class="custom-select"
          required
        >
          <option :value="null">Elige clínica</option>
          <option :value="id" :key="id" v-for="(name, id) in clinic_list">
            {{ name }}
          </option>
        </select>
        <label for="show_user_clinic_id" class="wabident-info"
          >Clínica de Referencia</label
        >
      </div>

      <div class="col-sm-4">
        <select
          v-model="role"
          name="role"
          id="show_user_role"
          class="custom-select"
          required
        >
          <option :value="null">Elige rol</option>
          <option value="reception">Recepción</option>
          <option value="doctor">Doctor/a</option>
          <option value="nurse">Enfermero/a</option>
          <option value="auxiliary">Auxiliar</option>
          <option value="admin">Administrador/a</option>
        </select>
        <label for="show_user_role" class="wabident-info">Rol</label>
      </div>
    </div>

    <transition name="fade">
      <div v-if="is_role_doctor" class="form-group row">
        <div class="col-sm-12">
          <legend>Datos Doctor/a</legend>
        </div>

        <div class="col-sm-4">
          <input
            placeholder="Nº Colegiado/a"
            v-model="college_number"
            model="college_number"
            type="text"
            id="show_user_college_number"
            class="form-control"
            required
          />
          <label class="wabident-info" for="show_user_college_number"
            >Nº de Colegiado/a</label
          >
        </div>

        <div class="col-sm-8">
          <div class="form-check form-check form-check-inline">
            <input
              v-model="use_custom_color"
              class="form-check-input"
              type="checkbox"
              id="app_new_color"
              value="true"
            />
            <label class="form-check-label" for="app_new_color"
              >Color Personalizado Agenda</label
            >
          </div>
          <compact-picker
            v-if="use_custom_color"
            v-model="colors"
            :palette="palette"
          >
          </compact-picker>
        </div>

        <div class="col-sm-12">
          <legend>Firma</legend>
        </div>

        <div class="col-sm-11">
          <b-form-file
            v-model="signature_file"
            :state="Boolean(signature_file)"
            accept=".jpg, .png, .gif, .jpeg"
            placeholder="Elige un archivo de firma. Permitidos JPG, GIF y PNG"
            class="wabident-file-input"
            :disabled="is_loading"
          ></b-form-file>
        </div>

        <div v-if="this.current_signature" class="col-sm-1">
          <img :src="this.current_signature" style="max-height: 39px" />
        </div>
      </div>
    </transition>

    <div class="form-group row">
      <div class="col text-center">
        <button
          :disabled="is_loading"
          type="button"
          @click="onSubmit"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_loading">Guardando ...</span>
          <span v-else-if="is_editing">Modificar Usuario</span>
          <span v-else>Crear Usuario</span>
          <i
            v-if="is_loading"
            class="fas fa-spin fa-circle-notch float-right"
          ></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      is_active: true,
      is_editing: false,
      is_loading: false,
      name: null,
      email: null,
      role: null,
      password: null,
      clinic_id: null,
      college_number: null,
      signature_file: null,
      current_signature: null,
      use_custom_color: false,
      colors: {
        hex: "#000000",
      },
      palette: [
        "#E57373",
        "#F06292",
        "#BA68C8",
        "#9575CD",
        "#7986CB",
        "#64B5F6",
        "#4FC3F7",
        "#4DD0E1",
        "#4DB6AC",
        "#81C784",
        "#AED581",
        "#DCE775",
        "#FFF176",
        "#FFD54F",
        "#FFB74D",
        "#FF8A65",
        "#A1887F",
        "#E0E0E0",
        "#90A4AE",
      ],
    };
  },
  computed: {
    is_role_doctor() {
      return (
        this.role != null &&
        (this.role == "doctor" ||
          this.role == "nurse" ||
          this.role == "auxiliary")
      );
    },
    clinic_list() {
      if (!this.clinic) return;
      return this.clinic.clinic_names;
    },
    dialog_title() {
      return this.is_editing ? "Modificar Usuario" : "Nuevo Usuario";
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"]),
  },
  methods: {
    showNew() {
      this.is_active = true;
      this.user = null;
      this.is_editing = false;
      this.is_loading = false;
      this.name = null;
      this.email = null;
      this.role = null;
      this.clinic_id = null;
      this.college_number = null;
      this.password = null;
      this.signature_file = null;
      this.current_signature = null;
      this.use_custom_color = false;
      this.colors = { hex: "#000000" };
      this.$refs.modal.show();
    },
    showEdit(user) {
      this.user = user;
      this.is_active = user.is_active;
      this.name = user.name;
      this.email = user.email;

      this.role = user.role;
      if (this.role == "doctor") {
        this.role = user.doctor.type;
      }

      this.clinic_id = user.clinic_id;
      this.password = null;
      this.signature_file = null;
      this.current_signature = null;

      if (
        (user.role == "doctor" ||
          user.role == "nurse" ||
          user.role == "auxiliary") &&
        this.user.doctor
      ) {
        this.college_number = this.user.doctor.college_number;

        this.use_custom_color = !(
          this.user.doctor.color == null ||
          this.user.doctor.color == "#000000" ||
          this.user.doctor.color == "null"
        );

        if (this.use_custom_color) {
          this.colors = { hex: this.user.doctor.color };
        } else {
          this.colors = { hex: "#000000" };
        }
      } else {
        this.college_number = null;
      }

      this.is_editing = true;
      this.is_loading = false;

      this.getCurrentSignature();
      this.$refs.modal.show();
    },
    getCurrentSignature() {
      if (!this.is_role_doctor) return;

      axios.get(`/user/${this.user.id}/signature`).then((request) => {
        this.current_signature = request.data.signature;
      });
    },
    getFormData() {
      let ret = new FormData();

      ret.append("name", this.name);
      ret.append("email", this.email);
      ret.append("role", this.role);
      ret.append("password", this.password);
      ret.append("clinic_id", this.clinic_id);
      ret.append("college_number", this.college_number);
      ret.append("signature", this.signature_file);
      ret.append("is_active", this.is_active);

      if (this.use_custom_color) {
        ret.append("color", this.colors.hex);
      } else {
        ret.append("color", null);
      }

      return ret;
    },
    onSubmit() {
      let formData = this.getFormData();

      if (this.is_editing) {
        this.modifyUser(formData);
      } else {
        this.createNewUser(formData);
      }
    },
    signatureChanged() {},
    modifyUser(params) {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/user/${this.user.id}`, params, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error);
            } else {
              this.$emit("submit", response.data);
              this.msgwindow.success("Usuario modificado con éxito");
              this.$refs.modal.hide();
            }
          })
          .catch((err) => {
            console.log("[ERR] modifyUser ", err);
            this.msgwindow.error("Error modificando usuario!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    createNewUser(params) {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post("/users", params)
          .then((response) => {
            this.$emit("submit", response.data);
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error, "Aceptar");
            } else {
              this.msgwindow.success("Usuario creado con éxito");
              this.$refs.modal.hide();
            }
          })
          .catch((err) => {
            console.log("[ERR] createNewUser ", err);
            this.msgwindow.error("Error creando usuario!");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
#clinic-show-user {
  .modal-dialog {
    max-width: 50%;
  }
}

.clinic-show-user {
  &__signature {
    height: 50px;
  }
}
</style>
