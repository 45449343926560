<style lang="scss" scoped>
.appointments {
  overflow: auto;
  height: calc(100vh - 130px);
  width: 100%;

  &--title {
    height: 50px;
    font-size: 18px;
    padding: 15px;
    font-weight: 500;
    background-color: #d6d9e4;
    margin: 0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    vertical-align: middle;
    z-index: 1;
  }

  table {
    border-collapse: collapse;
    thead {
      position: sticky;
      position: -webkit-sticky;
      border-collapse: collapse;
      top: 50px;
      z-index: 1;

      th {
        box-sizing: content-box;
        background-color: #f0f1f4;
        color: #a6aab4;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border: 1px #d6d9e4 solid;
      }

      th.nav-prev,
      th.nav-next {
        transition: all 0.2s;
        i {
          font-size: 25px;
        }

        &.nav-enabled {
          cursor: pointer;
          &:hover {
            background-color: var(--theme-color);
            color: white;
          }
        }
      }
    }
    tbody td {
      font-size: 11px;
      border: 1px #d6d9e4 solid;
      color: #a0a4b0;
      font-weight: 500;

      &.nav-cell {
        width: 50px;
      }

      &.inactive {
        cursor: not-allowed;
        background-color: #a0a4b0;
        color: white;
      }
    }
  }
}

@media (max-width: 1024px) {
  .appointments {
    overflow-y: auto;
    overflow-x: scroll;
    height: auto;
  }
}
</style>

<template>
  <div v-if="appointments" class="text-center appointments mx-xl-3 mx-lg-0">
    <h2 class="appointments--title">{{ date.toLocaleDateString() }}</h2>
    <table class="table table-sm">
      <thead>
        <tr class="appointments--navigation">
          <th
            class="nav-prev nav-cell"
            :class="{ 'nav-enabled': can_prev_box }"
            @click="prevBox"
          >
            <i v-show="can_prev_box" class="fas fa-angle-left"></i>
          </th>
          <th v-for="(box, index) in displayed_boxes" v-bind:key="index">
            {{ box.name }}
          </th>
          <th
            class="nav-next nav-cell"
            :class="{ 'nav-enabled': can_next_box }"
            @click="nextBox"
          >
            <i v-show="can_next_box" class="fas fa-angle-right"></i>
          </th>
        </tr>
      </thead>
      <tbody v-if="is_vacation">
        <tr>
          <td :colspan="num_boxes + 2">
            <h1>FESTIVO / VACACIONES</h1>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <template v-for="(timeslot, index) in timeslots">
          <tr v-bind:key="index">
            <template v-if="timeslot.inactive">
              <td class="inactive nav-cell">{{ timeslot.name }}</td>
              <td
                class="inactive"
                v-for="box in displayed_boxes"
                v-bind:key="box.id"
              ></td>
              <td class="inactive nav-cell">{{ timeslot.name }}</td>
            </template>
            <template v-else>
              <td class="nav-cell">{{ timeslot.name }}</td>
              <template v-for="box in displayed_boxes">
                <agenda-time-slot
                  @cellClick="cellClicked"
                  :key="box.id"
                  :appointment="getAppForTimeSlot(timeslot, box)"
                  :timeslot="timeslot"
                  :tsindex="index"
                  :num_boxes="num_boxes"
                  :box="box"
                  :doctors="doctors"
                ></agenda-time-slot>
              </template>
              <td class="nav-cell">{{ timeslot.name }}</td>
            </template>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    date: { required: true },
    appointments: { required: true },
    clinic: { required: true },
    num_boxes: { default: 3 },
  },
  data() {
    return {
      selected_box: 0,
      timeslot_mappings: {},
      doctors: {},
    };
  },
  computed: {
    displayed_boxes() {
      let ret = [];
      if (this.appointments && this.appointments.length > 0) {
        for (let i = 0; i < this.num_boxes; i++) {
          let box = this.appointments[this.selected_box + i];
          if (box) {
            ret.push(box);
          }
        }
      }
      return ret;
    },
    can_prev_box() {
      return this.selected_box > 0;
    },
    can_next_box() {
      let total_boxes = this.appointments ? this.appointments.length : 0;
      return this.selected_box + this.num_boxes < total_boxes;
    },
    dayindex() {
      let d = this.date.getDay();
      if (d == 0) return 6;
      return d - 1;
    },
    is_vacation() {
      if (!this.clinic) return true;

      let slots = this.clinic.schedule.daily_timeslots[this.dayindex];
      if (!slots.checked) return true;

      let found = this.clinic.schedule.vacations.find((vacation) => {
        return vacation == this.date.toISODate();
      });

      if (found) return true;

      return false;
    },
    timeslots() {
      let ret = [];
      if (this.clinic) {
        let slots = this.clinic.schedule.daily_timeslots[this.dayindex];
        let slot_minutes = Number(this.clinic.schedule.slot_minutes);
        if (slots.checked) {
          slots.morning.start.forEach((slot) => {
            let d = moment(slot, "HH:mm");
            let from = moment(this.date).set({
              hour: d.hours(),
              minute: d.minutes(),
              second: 0,
            });

            let to = ret.push({
              hour: d.hours(),
              minute: d.minutes(),
              name: slot,
              from: from.toDate(),
              to: from.add(slot_minutes - 1, "m").toDate(),
            });
          });

          ret.push({
            inactive: true,
            name: "Desc",
          });

          slots.afternoon.start.forEach((slot) => {
            let d = moment(slot, "HH:mm");
            let from = moment(this.date).set({
              hour: d.hours(),
              minute: d.minutes(),
              second: 0,
            });

            let to = ret.push({
              hour: d.hours(),
              minute: d.minutes(),
              name: slot,
              from: from.toDate(),
              to: from.add(slot_minutes - 1, "m").toDate(),
            });
          });
        }
      }
      return ret;
    },
  },
  watch: {
    appointments(val) {
      this.updateTimeSlotMappings();
    },
  },
  methods: {
    formatTimeSlot(timeslot) {
      return (
        timeslot.hour.toString().padStart(2, "0") +
        ":" +
        timeslot.minute.toString().padStart(2, "0")
      );
    },
    updateTimeSlotRanges() {
      this.timeslots.forEach((timeslot) => {
        if (!timeslot.inactive) {
          let from = moment(this.date).set({
            hour: timeslot.hour,
            minute: timeslot.minute,
            second: 0,
          });

          timeslot.from = from.toDate();
          timeslot.to = from.add(14, "m").toDate();
        }
      });
    },
    updateTimeSlotMappings() {
      let mappings = {};

      // Organize by box
      this.appointments.forEach((box) => {
        let box_mappings = {};

        // Assign appointments to timeslots
        box.appointments.forEach((appointment) => {
          let app_from = moment(appointment.date_from).toDate();
          let app_to = moment(appointment.date_to).toDate();

          this.timeslots.forEach((timeslot) => {
            if (!timeslot.inactive) {
              if (timeslot.from >= app_from && timeslot.to <= app_to) {
                box_mappings[timeslot.name] = appointment;
              }
            }
          });
        });

        // Count assigned slots for every mapping, doing it here
        // accounts for possible overlaps.
        for (const name in box_mappings) {
          let app = box_mappings[name];
          if (app) {
            if (app.num_slots) {
              app.num_slots += 1;
            } else {
              app.num_slots = 1;
            }
          }
        }

        // Asign the final map to the box
        mappings[box.id] = box_mappings;
      });

      this.timeslot_mappings = mappings;
      this.doctors = {};
      if (this.clinic) {
        this.clinic.doctors.forEach((doctor) => {
          this.doctors[doctor.doctor.id] = doctor.doctor;
        });
      }
    },
    getAppForTimeSlot(timeslot, box) {
      let box_mappings = this.timeslot_mappings[box.id];
      if (!box_mappings) return null;
      return box_mappings[timeslot.name] || null;
    },
    getDoctorForTimeSlot(timeslot, box) {
      let app = this.getAppForTimeSlot(timeslot, box);
      if (!app) return null;

      return this.doctors[app.doctor_id] || null;
    },
    cellClicked(appointment, timeslot, box) {
      this.$emit("appSelected", appointment, timeslot, box);
    },
    prevBox() {
      if (this.can_prev_box) {
        this.selected_box -= 1;
      }
    },
    nextBox() {
      if (this.can_next_box) {
        this.selected_box += 1;
      }
    },
  },
  mounted() {
    this.updateTimeSlotMappings();
  },
};
</script>
