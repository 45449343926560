<style lang="scss">
.customer__photo {
  width: 32px;
  height: 3;
  border: none;
  border-radius: 16px;
  margin: 0 12px 0 0;
  vertical-align: middle;
  background-color: var(--theme-grey);
  padding: 0;

  &_selected {
    width: 62px;
    height: 62px;
    border-radius: 31px;
    float: left;
    margin: 0;
  }
}

.customer__box_selected {
  vertical-align: middle;
  display: inline-block;
  padding-left: 18px;

  a {
    text-decoration: none;
    color: var(--theme-color);
    transition: all 0.2s;
    &:hover {
      color: var(--theme-color);
    }
  }
}

.customer__name,
.customer__phone {
  font-size: 16px;
  color: var(--theme-text-color);
  vertical-align: middle;
  &_selected {
    display: block;
    line-height: 1.25;
  }
}

.customer__phone {
  text-align: right;
  float: right;
  vertical-align: middle;
}

.customer__indicator {
  width: 24px;
  height: 24px;
  color: #a6aab4;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none !important;
  transform: none !important;
}

.wabident-selector-customer.selected .customer__indicator {
  display: none;
}

.wabident-selector-customer.locked {
  pointer-events: none;
  .customer__clear {
    display: none;
  }
}

.customer__clear {
  width: 24px;
  height: 24px;
  background-color: #a6aab4;
  color: #f0f1f4;
  text-align: center;
  margin: 0;
  border-radius: 12px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
</style>

<template>
  <v-select
    class="wabident-selector wabident-selector-customer"
    :class="{ selected: selected != null, locked: locked }"
    @search="searchCustomers"
    :options="customers"
    placeholder="Escribe el nombre del paciente para buscar"
    v-model="selected"
    :components="{ Deselect, OpenIndicator }"
  >
    <template slot="option" slot-scope="option">
      <img class="customer__photo" :src="option.customer.photo" />
      <span class="customer__name">
        {{ option.label }} - {{ option.customer.history_number }}
        <span v-if="!option.customer.has_paid">
          - <i class="fas fa-money-bill-alt"></i>
        </span>
      </span>
      <span class="customer__phone">
        {{ option.customer.phone }}
        {{ showClinic(option.customer, " - ") }}
      </span>
    </template>

    <template slot="selected-option" slot-scope="option">
      <img class="customer__photo_selected" :src="option.customer.photo" />
      <div class="customer__box_selected">
        <span class="customer__name_selected">
          {{ option.label }} - Nº {{ option.customer.history_number }}
          <span v-if="!option.customer.has_paid">
            - <i class="fas fa-money-bill-alt"></i>
          </span>
        </span>
        <span class="customer__phone_selected"
          >{{ option.customer.phone }}
          {{ showClinic(option.customer, " - ") }}</span
        >
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    locked: { default: false },
    clinics: { default: null },
  },
  data() {
    return {
      selected: null,
      customers: [],
      Deselect: {
        render: (createElement) =>
          createElement("span", { class: { customer__clear: true } }, [
            createElement("i", { class: { fas: true, "fa-times": true } }),
          ]),
      },
      OpenIndicator: {
        render: (createElement) =>
          createElement("span", { class: { customer__indicator: true } }, [
            createElement("i", { class: { fas: true, "fa-search": true } }),
          ]),
      },
    };
  },
  computed: {
    customer_link() {
      if (!this.selected) return "";
      return `/customer/${this.selected.customer.id}/edit`;
    },
  },
  watch: {
    selected(option) {
      if (option) {
        this.$emit("customerSelected", option.customer);
      } else {
        this.$emit("customerDeselected");
      }
    },
  },
  methods: {
    showClinic(customer, prefix = "") {
      if (!this.clinics) return "";
      let ret = this.clinics[customer.clinic_id] || "";
      return ret ? prefix + ret : "";
    },
    select(customer) {
      this.selected = {
        label: customer.name,
        customer: customer,
      };
    },
    searchCustomers(search, loading) {
      loading(true);
      axios
        .get("/customer/search", {
          params: {
            search: search,
          },
        })
        .then((response) => {
          this.fillCustomerData(response.data);
        })
        .catch((error) => {
          console.log("[ERR] searchCustomers:", error);
        })
        .then(() => {
          loading(false);
        });
    },
    fillCustomerData(customers) {
      this.customers = customers.map((customer) => {
        return {
          label: customer.name,
          customer: customer,
        };
      });
    },
  },
};
</script>
