<template>
  <div class="customer-document-gallery" :style="bg_style">
    <div class="customer-document-gallery__overlay" @click="$emit('view')">
      <div class="customer-document-gallery__date">{{ date_time }}</div>
      <div class="customer-document-gallery__title">{{ document.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer", "document", "clinic"],
  data() {
    return {};
  },
  computed: {
    bg_style() {
      let bg = `url('/customer/${this.customer.id}/document/${this.document.id}/view')`;
      return {
        "background-image": bg,
      };
    },
    date_time() {
      return moment(this.document.created_at).format("DD/MM/YYYY HH:mm");
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.customer-document-gallery {
  height: 310px;
  background-color: var(--theme-shadow);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  margin-bottom: 30px;

  &__overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    padding: 10px;
    transition: background-color 0.3s;
    background-color: transparent;
    color: white;
  }

  &__date,
  &__title {
    font-weight: 500;
    font-size: 18px;
    text-shadow: 1px 1px 3px var(--theme-transparent);
  }

  &:hover {
    .customer-document-gallery__overlay {
      background-color: var(--theme-shadow);
    }
  }
}
</style>
