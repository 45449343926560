<template>
  <b-modal
    id="customer-show-invoice"
    class="customer-show-invoice"
    ref="modal"
    :title="dialog_title"
    hide-footer
  >
    <div class="row form-group">
      <div class="col-sm-6">
        <input
          type="date"
          class="form-control wabident-xsmall"
          v-model="data.date"
          readonly
        />
        <label class="wabident-info">FECHA</label>
      </div>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="data.code"
          readonly
        />
        <label class="wabident-info">CÓDIGO</label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-4">
        <div class="form-check form-check-inline">
          <input
            v-model="is_other_customer"
            class="form-check-input"
            type="checkbox"
            id="is_other_customer"
            value="true"
          />
          <label class="form-check-label" for="is_other_customer"
            >Facturar a otro nombre</label
          >
        </div>
      </div>
      <div v-if="!is_editing" class="col-sm-4">
        <div class="form-check form-check-inline">
          <input
            v-model="is_revocation"
            class="form-check-input"
            type="checkbox"
            id="is_revocation"
            value="true"
            :readonly="is_editing"
          />
          <label class="form-check-label" for="is_revocation"
            >Factura rectificativa</label
          >
        </div>
      </div>

      <div v-if="is_revocation && !is_editing" class="col-sm-4">
        <select
          v-model="revocation_invoice_id"
          id="revocation_invoice_id"
          class="form-control custom-select"
          :disabled="is_editing"
        >
          <option :value="null">Sin factura</option>
          <template v-for="(invoice, i) in unrevoked_invoices">
            <option :key="i" :value="invoice.id">{{ invoice.code }}</option>
          </template>
        </select>
        <label for="revoication_invoice_id-show" class="wabident-info">
          Factura a rectificar
        </label>
      </div>
    </div>

    <div v-if="!is_other_customer" class="row form-group">
      <div class="col" v-if="customer">
        <div class="customer-info">
          <div
            class="customer-photo"
            :style="{ 'background-image': 'url(' + customer.photo + ')' }"
          ></div>
          <div class="customer-data">
            {{ customer_real_name }}
            <br />
            {{ customer.phone || customer.phone_alt }} | {{ customer_real_nif }}
          </div>
        </div>
      </div>
    </div>

    <div v-else class="row form-group">
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_name"
        />
        <label class="wabident-info">Nombre completo</label>
      </div>
      <div class="col-sm-6">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_nif"
        />
        <label class="wabident-info">DNI/NIF</label>
      </div>
      <div class="col-sm-6">
        <input
          type="tel"
          class="form-control wabident-xsmall"
          v-model="customer_phone"
        />
        <label class="wabident-info">Teléfono</label>
      </div>
      <div class="col-sm-6">
        <input
          type="email"
          class="form-control wabident-xsmall"
          v-model="customer_email"
        />
        <label class="wabident-info">Email</label>
      </div>
      <div class="col-sm-12">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_address"
        />
        <label class="wabident-info">Dirección</label>
      </div>
      <div class="col-sm-4">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_zipcode"
        />
        <label class="wabident-info">CP</label>
      </div>
      <div class="col-sm-4">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_city"
        />
        <label class="wabident-info">Ciudad</label>
      </div>
      <div class="col-sm-4">
        <input
          type="text"
          class="form-control wabident-xsmall"
          v-model="customer_province"
        />
        <label class="wabident-info">Provincia</label>
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <table class="table lines-table">
          <colgroup>
            <col class="line-desc-col" style="width: 40%" />
            <col class="line-price-col" />
            <col class="line-vat-col" />
            <col class="line-discount-col" />
            <col class="line-total-col" />
            <col class="line-actions-col" />
          </colgroup>
          <thead>
            <tr>
              <th>CONCEPTO</th>
              <th>PRECIO (IVA INC)</th>
              <th>IVA%</th>
              <th>CANTIDAD</th>
              <th>PRECIO(€)</th>
              <th>DESCUENTO</th>
              <th>TOTAL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(line, index) in data.lines" :key="index">
              <td class="input">
                <input
                  type="text"
                  class="form-control wabident-xsmall"
                  v-model="line.desc"
                  placeholder="Escribe aquí el concepto"
                />
              </td>
              <td class="input">
                <input
                  type="number"
                  class="form-control wabident-xsmall"
                  step="0.01"
                  placeholder="0.00"
                  v-model.number="line.price"
                />
              </td>
              <td class="input">
                <input
                  type="number"
                  class="form-control wabident-xsmall"
                  step="0.01"
                  placeholder="0.00"
                  v-model.number="line.vat"
                />
              </td>
              <td class="input">
                <input
                  type="number"
                  class="form-control wabident-xsmall"
                  min="1"
                  step="1"
                  placeholder="1"
                  v-model.number="line.quantity"
                />
              </td>
              <td>{{ getLinePrice(line) }}</td>
              <td class="input">
                <input
                  type="number"
                  class="form-control wabident-xsmall"
                  step="0.01"
                  placeholder="0.00"
                  v-model.number="line.discount"
                />
              </td>
              <td>{{ getLineNetPrice(line) }}</td>
              <td @click="removeLine(line, index)" class="trash-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2 6a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M10 3a1 1 0 0 0-1 1v1h6V4a1 1 0 0 0-1-1h-4zm7 2V4a3 3 0 0 0-3-3h-4a3 3 0 0 0-3 3v1H5a1 1 0 0 0-1 1v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V6a1 1 0 0 0-1-1h-2zM6 7v13a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V7H6z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill-rule="evenodd"
                    d="M10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zM14 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z"
                    clip-rule="evenodd"
                  />
                </svg>
              </td>
            </tr>

            <tr>
              <td>
                <select
                  v-model="selected_line"
                  class="custom-select wabident-xsmall"
                >
                  <option :value="null">Añadir linea de presupuesto ...</option>
                  <option
                    v-for="(line, i) in available_lines"
                    :key="i"
                    :value="line"
                  >
                    {{ getLineDesc(line) }}
                  </option>
                </select>
              </td>
              <td colspan="3">
                <span class="add-line" @click="addLine">
                  <i class="fas fa-plus"></i>
                  Añadir concepto
                </span>
              </td>
              <td>
                {{ pretty_total }}
                <label class="total">NETO</label>
              </td>
              <td></td>
              <td>
                {{ pretty_net_total }}
                <label class="total">TOTAL</label>
              </td>
              <td></td>
            </tr>

            <tr>
              <td colspan="8" class="input">
                <input
                  type="text"
                  class="form-control wabident-small"
                  v-model.number="data.notes"
                  placeholder="Escribe aquí notas adicionales"
                />
                <label class="wabident-info">NOTAS</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row form-group">
      <div class="col buttons-column text-center">
        <button
          @click="submit"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_editing">Modificar</span>
          <span v-else>Crear</span>
          <i
            v-if="is_loading"
            class="fa fa-spin fa-circle-notch float-right"
          ></i>
        </button>

        <button
          @click="onRevoke"
          :disabled="is_loading"
          v-if="
            is_editing &&
            !(invoice.is_revocation || invoice.revocation_invoice_id)
          "
          type="button"
          class="btn btn-primary btn-wabident float-right"
        >
          Rectificar&nbsp;<i class="fas fa-undo"></i>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    customer: { required: true },
    clinic: { default: null },
  },
  data() {
    return {
      data: {
        date: new Date(),
        code: "",
        lines: [],
        notes: "",
      },
      uninvoiced_lines: [],
      selected_line: null,
      invoice: null,
      is_editing: false,
      is_loading: false,
      is_other_customer: false,
      customer_phone: "",
      customer_email: "",
      customer_address: "",
      customer_city: "",
      customer_zipcode: "",
      customer_province: "",
      is_revocation: false,
      revocation_invoice_id: null,
      unrevoked_invoices: null,
      payment_id: null,
    };
  },
  watch: {
    selected_line(val) {
      if (val) {
        this.addUninvoicedLine(val);
        this.selected_line = null;
      }
    },
    is_revocation(val) {
      if (!this.is_editing) {
        this.getInvoiceCode();
      }
    },
  },
  computed: {
    is_minor() {
      if (!this.customer) return false;
      if (this.customer.is_incapacitated) return true;
      let birthday = moment(this.customer.birth_date);
      let date = moment(this.data.date);
      let years = date.diff(birthday, "years");

      return years < 18;
    },
    customer_real_name() {
      if (!this.customer) return "";

      if (this.is_minor && this.customer.adult_name) {
        return "Rep: " + this.customer.adult_name;
      }
      return this.customer.name;
    },
    customer_real_nif() {
      if (!this.customer) return "";

      if (this.is_minor && this.customer.adult_nif) {
        return this.customer.adult_nif;
      }
      return this.customer.nif;
    },
    pretty_total() {
      let total = this.data.lines.reduce((cur, val) => {
        return cur + Number(val.price) * Number(val.quantity);
      }, 0);
      return WabiDent.fmtMoney(total);
    },
    pretty_net_total() {
      let total = this.data.lines.reduce((cur, val) => {
        return (
          cur +
          Number(val.price) *
            Number(val.quantity) *
            (1 - Number(val.discount) / 100)
        );
      }, 0);
      return WabiDent.fmtMoney(total);
    },
    pretty_invoice_code() {
      return `Factura #${this.data.code}`;
    },
    dialog_title() {
      return this.is_editing ? "Ver Factura" : "Crear Factura";
    },
    available_lines() {
      return this.uninvoiced_lines.filter((quotation_line) => {
        let line = this.data.lines.find((invoice_line) => {
          return quotation_line.id == invoice_line.quotation_line_id;
        });
        return !Boolean(line);
      });
    },
    ...mapGetters(["msgwindow", "errorwindow"]),
  },
  methods: {
    getLinePrice(line) {
      return WabiDent.fmtMoney(Number(line.price) * Number(line.quantity));
    },
    getLineNetPrice(line) {
      return WabiDent.fmtMoney(
        Number(line.price) *
          Number(line.quantity) *
          (1 - Number(line.discount) / 100.0)
      );
    },
    getLineDesc(line) {
      if (line.product) {
        return line.product.name + this.prettyTeeth(line);
      } else {
        return "(PRODUCTO BORRADO) " + this.prettyTeeth(line);
      }
    },
    showNew() {
      this.payment_id = null;
      this.is_editing = false;
      this.invoice = null;
      this.data.lines = [];
      this.data.code = "";
      this.data.date = new Date().toISODate();
      this.data.notes = "";
      this.uninvoiced_lines = [];
      this.selected_line = null;
      this.is_revocation = false;
      this.revocation_invoice_id = null;

      this.is_other_customer = false;
      this.customer_phone = "";
      this.customer_email = "";
      this.customer_address = "";
      this.customer_city = "";
      this.customer_zipcode = "";
      this.customer_province = "";

      this.getInvoiceCode();
      this.getUninvoicedLines();
      this.getUnrevokedInvoices();
      this.$refs.modal.show();
    },
    showNewPayment(payment) {
      this.payment_id = payment.id;
      this.is_editing = false;
      this.invoice = null;
      this.data.lines = [];
      this.data.code = "";
      this.data.date = new Date().toISODate();
      this.data.notes = "";
      this.uninvoiced_lines = [];
      this.selected_line = null;
      this.is_revocation = false;
      this.revocation_invoice_id = null;
      this.is_other_customer = false;
      this.customer_phone = "";
      this.customer_email = "";
      this.customer_address = "";
      this.customer_city = "";
      this.customer_zipcode = "";
      this.customer_province = "";
      this.getInvoiceCode();
      this.fillFromPayment(payment);
      this.$refs.modal.show();
    },
    showEdit(invoice) {
      this.payment_id = null;
      this.is_editing = true;
      this.fillFrom(invoice);
      this.uninvoiced_lines = [];
      this.selected_line = null;
      this.getUninvoicedLines();
      this.getUnrevokedInvoices();
      this.$refs.modal.show();
    },
    submit() {
      let title = this.is_editing
        ? "¿Desea Modificar la Factura?"
        : "¿Desea Emitir la Factura?";

      this.errorwindow.show(
        "Confirmación",
        title + "<br>Esta operación no se puede deshacer",
        "Emitir",
        "Cancelar",
        () => {
          if (this.is_editing) {
            this.updateInvoice();
          } else {
            this.addInvoice();
          }
        }
      );
    },
    onRevoke() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea generar una Factura Rectificativa?",
        "Aceptar",
        "Cancelar",
        () => {
          if (!this.is_loading) {
            this.is_loading = true;
            axios
              .post(`/invoice/${this.invoice.id}/revoke`)
              .then((response) => {
                this.$emit("submit");
                this.msgwindow.success("Factura Rectificada");
                this.$refs.modal.hide();
              })
              .catch((err) => {
                console.log("[ERR] revokeInvoice", err);
                this.msgwindow.error("Error rectificando factura!");
                this.$refs.modal.hide();
              })
              .then(() => {
                this.is_loading = false;
              });
          }
        }
      );
    },
    updateInvoice() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          customer_id: this.customer.id,
          lines: this.data.lines,
          code: this.data.code,
          notes: this.data.notes,
          created_at: this.data.date,
          clinic_id: this.clinic ? this.clinic.id : null,
          is_other_customer: this.is_other_customer,
          customer_name: this.customer_name,
          customer_nif: this.customer_nif,
          customer_phone: this.customer_phone,
          customer_email: this.customer_email,
          customer_address: this.customer_address,
          customer_city: this.customer_city,
          customer_zipcode: this.customer_zipcode,
          customer_province: this.customer_province,
        };
        axios
          .post(`/invoice/${this.invoice.id}/update`, params)
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Factura modificada");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error modificando factura");
            console.log("[ERR] updateInvoice", err);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    addInvoice() {
      if (!this.is_loading) {
        this.is_loading = true;
        let params = {
          customer_id: this.customer.id,
          lines: this.data.lines,
          code: this.data.code,
          notes: this.data.notes,
          created_at: this.data.date,
          clinic_id: this.clinic ? this.clinic.id : null,
          is_other_customer: this.is_other_customer,
          customer_name: this.customer_name,
          customer_nif: this.customer_nif,
          customer_phone: this.customer_phone,
          customer_email: this.customer_email,
          customer_address: this.customer_address,
          customer_city: this.customer_city,
          customer_zipcode: this.customer_zipcode,
          customer_province: this.customer_province,
          is_revocation: this.is_revocation,
          revocation_invoice_id: this.revocation_invoice_id,
          payment_id: this.payment_id,
        };
        axios
          .post(`/invoice`, params)
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Factura creada");
            this.$refs.modal.hide();
          })
          .catch((err) => {
            this.msgwindow.error("Error creando factura");
            console.log("[ERR] addInvoice", err);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    fillFromPayment(payment) {
      this.data.lines.push({
        desc: payment.notes,
        price: payment.amount,
        discount: 0,
        quantity: 1,
        vat: payment.vat,
        quotation_line_id: null,
        treatment_id: null,
      });
    },
    fillFrom(invoice) {
      this.invoice = invoice;
      this.data.code = invoice.code;
      this.data.lines = WabiDent.cloneObj(invoice.invoicelines);
      this.data.date = moment.utc(invoice.created_at).toDate().toISODate();
      this.data.notes = invoice.notes;
      this.is_other_customer = invoice.is_other_customer;
      this.customer_name = invoice.customer_name;
      this.customer_nif = invoice.customer_nif;
      this.customer_phone = invoice.customer_phone;
      this.customer_email = invoice.customer_email;
      this.customer_address = invoice.customer_address;
      this.customer_city = invoice.customer_city;
      this.customer_zipcode = invoice.customer_zipcode;
      this.customer_province = invoice.customer_province;
      this.is_revocation = invoice.is_revocation;
      this.revocation_invoice_id = invoice.revocation_invoice_id;
    },
    getInvoiceCode() {
      let params = {
        clinic_id: this.clinic ? this.clinic.id : null,
        is_revocation: this.is_revocation,
      };

      axios
        .get(`/invoice/code`, { params })
        .then((response) => {
          this.data.code = response.data;
        })
        .catch((err) => {
          this.msgwindow.error("Error obteniendo el código de factura");
        });
    },
    removeLine(line, index) {
      if (this.is_editing) return;
      this.data.lines = this.data.lines.filter((v, i) => {
        return i != index;
      });
    },
    addLine() {
      this.data.lines.push({
        desc: "",
        price: 0,
        discount: 0,
        quantity: 1,
        vat: 0,
        quotation_line_id: null,
        treatment_id: null,
      });
    },
    addUninvoicedLine(line) {
      this.data.lines.push({
        desc: this.getLineDesc(line),
        price: line.price,
        vat: line.product.vat,
        discount: line.discount,
        quantity: line.quantity,
        quotation_line_id: line.id,
        treatment_id: null,
      });
    },
    prettyTeeth(line) {
      if (!line.teeth || !line.teeth.length) return "";

      return (
        "(" +
        line.teeth.reduce((r, x) => {
          return r + " " + x;
        }, "") +
        ")"
      );
    },
    getUnrevokedInvoices() {
      if (!this.customer) return;

      axios
        .get(`/customer/${this.customer.id}/unrevoked_invoices`)
        .then((response) => {
          this.unrevoked_invoices = response.data;
          console.log(this.unrevoked_invoices);
        })
        .catch((err) => {
          console.log("[ERR] getUninvoicedLines ", err);
          this.msgwindow.error("Error obteniendo tratamientos sin facturar");
        });
    },
    getUninvoicedLines() {
      if (!this.customer) return;

      axios
        .get(`/customer/${this.customer.id}/uninvoiced_lines`)
        .then((response) => {
          this.uninvoiced_lines = response.data;
        })
        .catch((err) => {
          console.log("[ERR] getUninvoicedLines ", err);
          this.msgwindow.error("Error obteniendo tratamientos sin facturar");
        });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
#customer-show-invoice {
  .modal-dialog {
    max-width: 75%;
  }

  .customer-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--theme-grey-bg);
    border-bottom: 1px var(--theme-line-color) solid;
    padding: 15px;

    .customer-photo {
      width: 62px;
      height: 62px;
      background-size: cover;
      background-position: center center;
      border-radius: 31px;
      margin-left: 4px;
    }

    .customer-data {
      padding: 11px 18px;
      font-size: 20px;
      line-height: 1.25;
      color: var(--theme-text-color);
    }
  }

  .lines-table {
    thead {
      th {
        border-top: none;
        border-bottom: 1px var(--theme-grey) solid;
        color: var(--theme-grey);
        font-size: 11px;
        letter-spacing: 0.16px;
        font-weight: 300;
        line-height: 1.82;
      }
    }
    tbody {
      td {
        font-size: 16px;
        color: var(--theme-text-color);
        border: none;

        .total {
          display: block;
          font-size: 11px;
          line-height: 1.82;
          font-weight: 300;
          color: var(--theme-grey);
          letter-spacing: 0.16px;
          margin-top: 9px;
          border-top: 1px var(--theme-grey) solid;
          padding: 14px 17px 0 0;
        }

        &.input {
          padding: 7px;
        }
      }

      .trash-icon {
        cursor: pointer;
        color: var(--theme-grey);
        transition: all 0.2s;
        vertical-align: middle;
        text-align: center;
        &:hover {
          color: var(--theme-color-absent);
        }
        svg {
          fill: currentColor;
        }
      }
    }
  }

  .add-line {
    font-size: 13px;
    font-weight: bold;
    color: var(--theme-line-color);
    transition: all 0.2s;
    cursor: pointer;

    i {
      font-size: 13px;
    }

    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
