<style lang="scss">
$day-border-color: #d6d9e4;
$grey-bg: #f0f1f4;

.vc-container {
  border: none;

  .vc-header {
    height: 50px;
    margin-bottom: 15px;
    .vc-title {
      font-size: 21px;
      color: var(--theme-color);
      font-weight: 600;
      letter-spacing: 2.1px;
      text-transform: uppercase;
    }
  }

  .vc-arrows-container {
    .vc-cursor-pointer {
      background-color: var(--theme-color) !important;
      color: white !important;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2),
        0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
    }
  }

  // First row: Days
  .vc-weeks {
    .vc-weekday {
      color: var(--theme-color) !important;
      font-size: 14px;
      font-weight: normal;
    }

    div:not(.vc-grid-cell-row-1).vc-grid-cell {
      border: 1px $day-border-color solid;
      .in-prev-month,
      .in-next-month {
        background-color: $grey-bg;
      }
    }

    .vc-day {
      .vc-h-full {
        width: 100%;
      }

      .vc-highlight {
        background-color: var(--theme-color) !important;
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
      }

      .vc-day-content:focus,
      .vc-day-content:hover {
        background-color: transparent;
        width: 100%;
        border: 4px $day-border-color solid;
        border-radius: 0;
      }
    }
  }
}

.agenda-doctor-selector {
  position: absolute;
  left: 60px;
  top: 9px;
  z-index: 10;
  width: 20%;
}
</style>

<template>
  <div class="container-fluid">
    <agenda-show-appointment
      ref="appdialog"
      :clinics="clinics"
      @onSubmit="onAppSubmit"
    ></agenda-show-appointment>

    <div class="row mx-3">
      <div class="col-xl-3 col-lg-12 order-xl-first order-lg-last">
        <!-- Calendar widget -->
        <vc-calendar
          ref="vcalendar"
          :disabled-dates="{ weekdays: [1, 7] }"
          :first-day-of-week="2"
          :attributes="cal_attrs"
          :is-expanded="true"
          @dayclick="daySelected"
          @update:fromPage="calendarPageUpdated"
        ></vc-calendar>
        <!-- Month / Year Selector -->
        <div class="form-group row no-gutters">
          <div class="col-sm-6">
            <div class="mx-1">
              <select
                v-model.number="show_month"
                id="month_selector"
                class="form-control custom-select"
              >
                <option value="1">Enero</option>
                <option value="2">Febrero</option>
                <option value="3">Marzo</option>
                <option value="4">Abril</option>
                <option value="5">Mayo</option>
                <option value="6">Junio</option>
                <option value="7">Julio</option>
                <option value="8">Agosto</option>
                <option value="9">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mx-1">
              <select
                v-model.number="show_year"
                id="year_selector"
                class="form-control custom-select"
              >
                <option
                  v-for="(n, index) in 10"
                  :key="index"
                  :value="n + from_year"
                >
                  {{ n + from_year }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <!-- Attended Customer List -->
        <agenda-attended-customers
          @appointmentChecked="appointmentChecked"
          @appointmentGone="appointmentGone"
          :appointments="arrived_appointments"
        ></agenda-attended-customers>
      </div>
      <!-- Appointments view -->
      <div
        class="col-xl-9 col-lg-12 order-xl-last order-lg-first px-xl-2 px-lg-0"
      >
        <!-- Doctor Filters -->
        <div v-if="enable_doctor_availability" class="agenda-doctor-selector">
          <select
            id="select_doctor"
            v-model="doctor_id"
            class="custom-select wabident-xsmall"
          >
            <option :value="null">Tod@s</option>
            <option
              v-for="(doctor, index) in doctors"
              :key="index"
              :value="doctor.doctor.id"
            >
              {{ doctor.name }}
            </option>
          </select>
        </div>

        <!-- Main Appointments View -->
        <agenda-appointments
          ref="appointments"
          @appSelected="appointmentSelected"
          :date="selected_date"
          :appointments="appointments"
          :clinic="clinic"
          :num_boxes="num_boxes"
        ></agenda-appointments>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["clinic_id"],
  data: function () {
    return {
      selected_date: new Date(),
      doctor_id: null,
      appointments: [],
      availability: [],
      show_month: new Date().getMonth() + 1,
      show_year: new Date().getFullYear(),
      from_year: new Date().getFullYear() - 5,
    };
  },
  watch: {
    show_month() {
      this.updateCalendarFromSelectors();
    },
    show_year() {
      this.updateCalendarFromSelectors();
    },
    doctor_id() {
      this.loadAppointments();
    },
  },
  computed: {
    available_dates() {
      let ret = [];

      this.availability.forEach((av) => {
        if (av.is_available) {
          ret.push(moment(av.date_from).toDate());
        }
      });

      return ret;
    },
    unavailable_dates() {
      let ret = [];

      this.availability.forEach((av) => {
        if (!av.is_available) {
          ret.push(moment(av.date_from).toDate());
        }
      });

      return ret;
    },
    cal_attrs() {
      return [
        {
          dates: new Date(),
          highlight: true,
          key: "today",
        },
        {
          dot: 'green',
          dates: this.available_dates,
        },
        {
          dot: 'red',
          dates: this.unavailable_dates,
        },
      ];
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    enable_doctor_availability() {
      if (!this.clinic) return false;
      if (!this.clinic.config) return false;
      return this.clinic.config.enable_doctor_availability || false;
    },
    num_boxes() {
      if (!this.clinic) return 3;
      if (!this.clinic.config) return 3;
      return Number(this.clinic.config.agenda_num_boxes) || 3;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    arrived_appointments() {
      if (!this.appointments || this.appointments.lenght == 0) return [];

      let ret = [];

      this.appointments.forEach((box) => {
        box.appointments.forEach((appointment) => {
          if (appointment.arrival_time && appointment.status != "gone") {
            appointment.box = box.name;
            ret.push(appointment);
          }
        });
      });

      ret.sort((a, b) => {
        return a.arrival_time > b.arrival_time;
      });

      return ret;
    },
    ...mapGetters(["clinic", "msgwindow"]),
  },
  methods: {
    updateCalendarFromSelectors() {
      if (this.$refs.vcalendar) {
        this.$refs.vcalendar.showPageRange({
          month: this.show_month,
          year: this.show_year,
        });
      }
    },

    daySelected(day) {
      let new_date = new Date(day.id);
      this.selected_date = new_date;
      this.loadAppointments();
    },
    loadAppointments() {
      let params = {
        params: {
          date: this.selected_date,
          clinic_id: this.clinic_id,
          doctor_id: this.doctor_id,
        },
      };
      axios
        .get(`/clinic/${this.clinic_id}/appointments`, params)
        .then((response) => {
          let data = response.data;
          if (data.appointments) {
            this.appointments = data.appointments;
          }
          if (data.availability) {
            this.availability = data.availability;
          } else {
            this.availability = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onAppSubmit() {
      this.loadAppointments();
    },
    appointmentSelected(appointment, timeslot, box) {
      if (appointment) {
        this.$refs.appdialog.showEdit(
          appointment,
          this.selected_date,
          timeslot,
          box
        );
      } else {
        this.$refs.appdialog.show(this.selected_date, timeslot, box);
      }
    },
    appointmentChecked(checked, appointment) {
      if (appointment) {
        axios
          .post(
            `/clinic/${this.clinic.id}/appointments/${appointment.id}/check_in`,
            {
              checkin: checked,
            }
          )
          .then((response) => {
            if (checked) {
              this.msgwindow.success("Paciente atendido");
            } else {
              this.msgwindow.success("Paciente esperando ...");
            }
          })
          .catch((error) => {
            console.log("[ERR] appointmentChecked ", error);
          })
          .then(() => {
            this.onAppSubmit();
          });
      }
    },
    appointmentGone(appointment) {
      if (appointment) {
        axios
          .post(`/clinic/${this.clinic.id}/appointments/${appointment.id}/gone`)
          .then((response) => {
            this.msgwindow.success("Salida realizada");
          })
          .catch((error) => {
            console.log("[ERR] appointmentGone ", error);
          })
          .then(() => {
            this.onAppSubmit();
          });
      }
    },
    calendarPageUpdated(page) {
      this.show_month = page.month;
      this.show_year = page.year;
    },
    loadClinicData() {
      if (!this.clinic) {
        axios
          .get("/agenda/clinic_info", {
            params: {
              clinic_id: this.clinic_id,
            },
          })
          .then((response) => {
            let data = response.data;
            if (data.error) {
              console.log("[ERR] loadClinicData:", data.error);
            } else {
              this.setClinic(data);
              this.loadAppointments();
            }
          })
          .catch((error) => {
            console.log("[ERR] loadClinicData:", error);
          });
      }
    },
    ...mapMutations(["setClinic"]),
  },
  mounted() {
    this.loadClinicData();
  },
};
</script>
