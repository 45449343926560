t<template>
  <div>
    <!-- CUSTOM HISTORY -->
    <div
      v-if="custom_history_enabled"
      class="customer-edit-history container-fluid"
    >
      <!-- MEDICINE ALLERGIES -->
      <div class="row">
        <div class="col customer-edit-history-section">
          <legend>Alergias</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.allergies['medicines']"
                class="form-check-input"
                type="checkbox"
                id="customer_edit_history_medicine_allergies"
              />
              <label
                for="customer_edit_history_medicine_allergies"
                class="form-check-label"
              >
                ¿Tiene alergias a algún medicamento?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.allergies['medicines']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.allergies['other_medicines']"
              />
            </div>
          </transition>
          <!-- SUBSTANCE ALLERGIES -->
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.allergies['substances']"
                class="form-check-input"
                type="checkbox"
                id="customer_edit_history_substance_allergies"
              />
              <label
                for="customer_edit_history_substance_allergies"
                class="form-check-label"
              >
                ¿Tiene alergias a alguna sustancia?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.allergies['substances']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.allergies['other_substances']"
              />
            </div>
          </transition>
        </div>
      </div>
      <!-- DISEASES CUSTOM -->
      <div class="row">
        <div class="col customer-edit-history-section">
          <legend>Enfermedades</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['diabetes']"
                class="form-check-input"
                type="checkbox"
                name="diseases[diabetes]"
                id="customer_edit_history_diabetes"
              />
              <label
                for="customer_edit_history_diabetes"
                class="form-check-label"
              >
                <img src="/images/diabetes.svg" alt="Diabetes" />
                Diabetes
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['hypertension']"
                class="form-check-input"
                type="checkbox"
                name="diseases[hypertension]"
                id="customer_edit_history_hypertension"
              />
              <label
                for="customer_edit_history_hypertension"
                class="form-check-label"
              >
                Hipertensión
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['autoinmune']"
                class="form-check-input"
                type="checkbox"
                name="diseases[autoinmune]"
                id="customer_edit_history_autoinmune"
              />
              <label
                for="customer_edit_history_autoinmune"
                class="form-check-label"
              >
                Enf. Autoinmune
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['herpes']"
                class="form-check-input"
                type="checkbox"
                name="diseases[herpes]"
                id="customer_edit_history_herpes"
              />
              <label
                for="customer_edit_history_herpes"
                class="form-check-label"
              >
                Herpes simple
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['other_diseases']"
                class="form-check-input"
                type="checkbox"
                name="diseases[other_diseases]"
                id="customer_edit_history_other_diseases"
              />
              <label
                for="customer_edit_history_other_diseases"
                class="form-check-label"
              >
                ¿Tiene otras enfermedades cardíacas/hepáticas/digestivas/óseas?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.diseases['other_diseases']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.diseases['other_diseases_content']"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- MEDICINES  -->
      <div class="row">
        <div class="col customer-edit-history-section">
          <legend>Medicación</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.medicines['other_medicines']"
                class="form-check-input"
                type="checkbox"
                id="customer_edit_history_other_medicines"
              />
              <label
                for="customer_edit_history_other_medicines"
                class="form-check-label"
              >
                ¿Toma algún tipo de medicación?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.medicines['other_medicines']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.medicines['other_medicines_content']"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- MEDICAL INTERVENTIONS -->
      <div class="row">
        <div class="col customer-edit-history-section">
          <legend>Intervenciones quirúrgicas</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.background['surgical_interventions']"
                class="form-check-input"
                type="checkbox"
                id="customer_edit_history_surgical_interventions"
              />
              <label
                for="customer_edit_history_surgical_interventions"
                class="form-check-label"
              >
                ¿Se ha realizado algún tipo de intervención quirúrgica?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.background['surgical_interventions']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.background['surgical_interventions_content']"
              />
            </div>
          </transition>
        </div>
      </div>

      <!-- MEDICAL INTERVENTIONS -->
      <div class="row">
        <div class="col customer-edit-history-section">
          <legend>Tratamientos estéticos previos</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.background['previous_treatments']"
                class="form-check-input"
                type="checkbox"
                id="customer_edit_history_previous_treatments"
              />
              <label
                for="customer_edit_history_previous_treatments"
                class="form-check-label"
              >
                ¿Se ha realizado algún tratamiento estético en otra clínica?
              </label>
            </div>
          </div>
          <transition name="fade">
            <div v-if="history.background['previous_treatments']">
              <legend>Indique cuales:</legend>
              <input
                type="text"
                class="form-control"
                v-model="history.background['previous_treatments_content']"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>
    <!-- GENERIC HISTORY -->
    <div v-else class="customer-edit-history container-fluid">
      <div class="row">
        <div class="col customer-edit-history-section">
          <button
            type="button"
            @click="addOthers('allergies')"
            class="btn btn-primary btn-wabident btn-plus"
          >
            <i class="fas fa-plus"></i>
          </button>
          <legend>Alergias</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.allergies['penicillin']"
                class="form-check-input"
                type="checkbox"
                name="allergies[penicillin]"
                id="customer_edit_history_penicillin"
              />
              <label
                for="customer_edit_history_penicillin"
                class="form-check-label"
              >
                <img src="/images/penicilina.svg" alt="Penicilina" />
                Alergia a la Penicilina
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.allergies['latex']"
                class="form-check-input"
                type="checkbox"
                name="allergies[latex]"
                id="customer_edit_history_latex"
              />
              <label for="customer_edit_history_latex" class="form-check-label">
                <img src="/images/latex.svg" alt="Látex" />
                Alergia al látex
              </label>
            </div>
          </div>
          <div class="customer-edit-history-others">
            <div
              class="other-entry"
              v-for="(other, index) in history.allergies.others"
              :key="index"
            >
              <input
                v-model="history.allergies.others[index]"
                type="text"
                placeholder="Introduce el nombre"
              />
              <button type="button" @click="removeOther('allergies', index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col customer-edit-history-section">
          <button
            type="button"
            @click="addOthers('diseases')"
            class="btn btn-primary btn-wabident btn-plus"
          >
            <i class="fas fa-plus"></i>
          </button>

          <legend>Enfermedades</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['hiv']"
                class="form-check-input"
                type="checkbox"
                name="diseases[hiv]"
                id="customer_edit_history_hiv"
              />
              <label for="customer_edit_history_hiv" class="form-check-label">
                <img src="/images/vih.svg" alt="VIH" />
                VIH
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['hepatitis']"
                class="form-check-input"
                type="checkbox"
                name="diseases[hepatitis]"
                id="customer_edit_history_hepatitis"
              />
              <label
                for="customer_edit_history_hepatitis"
                class="form-check-label"
              >
                <img src="/images/hepatitis.svg" alt="Hepatitis" />
                Hepatitis
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['diabetes']"
                class="form-check-input"
                type="checkbox"
                name="diseases[diabetes]"
                id="customer_edit_history_diabetes"
              />
              <label
                for="customer_edit_history_diabetes"
                class="form-check-label"
              >
                <img src="/images/diabetes.svg" alt="Diabetes" />
                Diabetes
              </label>
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.diseases['heart_condition']"
                class="form-check-input"
                type="checkbox"
                name="diseases[heart_condition]"
                id="customer_edit_history_heart_condition"
              />
              <label
                for="customer_edit_history_heart_condition"
                class="form-check-label"
              >
                <img src="/images/cardiacos.svg" alt="Corazón" />
                Problemas Cardíacos
              </label>
            </div>
          </div>
          <div class="customer-edit-history-others">
            <div
              class="other-entry"
              v-for="(other, index) in history.diseases.others"
              :key="index"
            >
              <input
                v-model="history.diseases.others[index]"
                type="text"
                placeholder="Introduce el nombre"
              />
              <button type="button" @click="removeOther('diseases', index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col customer-edit-history-section">
          <button
            type="button"
            @click="addOthers('medicines')"
            class="btn btn-primary btn-wabident btn-plus"
          >
            <i class="fas fa-plus"></i>
          </button>
          <legend>Medicamentos</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.medicines['anticoagulants']"
                class="form-check-input"
                type="checkbox"
                name="medicines[anticoagulants]"
                id="customer_edit_history_anticoagulants"
              />
              <label
                for="customer_edit_history_anticoagulants"
                class="form-check-label"
                >Anticoagulantes</label
              >
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.medicines['inmunosuppressants']"
                class="form-check-input"
                type="checkbox"
                name="medicines[inmunosuppressants]"
                id="customer_edit_history_inmunosuppressants"
              />
              <label
                for="customer_edit_history_inmunosuppressants"
                class="form-check-label"
                >Inmunosupresores</label
              >
            </div>
          </div>
          <div class="customer-edit-history-others">
            <div
              class="other-entry"
              v-for="(other, index) in history.medicines.others"
              :key="index"
            >
              <input
                v-model="history.medicines.others[index]"
                type="text"
                placeholder="Introduce el nombre"
              />
              <button type="button" @click="removeOther('medicines', index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col customer-edit-history-section">
          <button
            type="button"
            @click="addOthers('background')"
            class="btn btn-primary btn-wabident btn-plus"
          >
            <i class="fas fa-plus"></i>
          </button>
          <legend>Antecedentes</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.background['headache']"
                class="form-check-input"
                type="checkbox"
                name="background[headache]"
                id="customer_edit_history_headache"
              />
              <label
                for="customer_edit_history_headache"
                class="form-check-label"
                >Cefáleas</label
              >
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.background['neuralgia']"
                class="form-check-input"
                type="checkbox"
                name="background[neuralgia]"
                id="customer_edit_history_neuralgia"
              />
              <label
                for="customer_edit_history_neuralgia"
                class="form-check-label"
                >Neuralgia</label
              >
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.background['bruxism']"
                class="form-check-input"
                type="checkbox"
                name="background[bruxism]"
                id="customer_edit_history_bruxism"
              />
              <label
                for="customer_edit_history_bruxism"
                class="form-check-label"
                >Bruxismo</label
              >
            </div>
          </div>
          <div class="customer-edit-history-others">
            <div
              class="other-entry"
              v-for="(other, index) in history.background.others"
              :key="index"
            >
              <input
                v-model="history.background.others[index]"
                type="text"
                placeholder="Introduce el nombre"
              />
              <button type="button" @click="removeOther('background', index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col customer-edit-history-section">
          <button
            type="button"
            @click="addOthers('habits')"
            class="btn btn-primary btn-wabident btn-plus"
          >
            <i class="fas fa-plus"></i>
          </button>
          <legend>Hábitos</legend>
          <div class="customer-edit-history-checks">
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.habits['tobacco']"
                class="form-check-input"
                type="checkbox"
                name="habits[tobacco]"
                id="customer_edit_history_tobacco"
              />
              <label
                for="customer_edit_history_tobacco"
                class="form-check-label"
                >Tabaco</label
              >
            </div>
            <div class="form-check form-check-inline form-check-history">
              <input
                v-model="history.habits['drugs']"
                class="form-check-input"
                type="checkbox"
                name="habits[drugs]"
                id="customer_edit_history_drugs"
              />
              <label for="customer_edit_history_drugs" class="form-check-label"
                >Drogas</label
              >
            </div>
          </div>
          <div class="customer-edit-history-others">
            <div
              class="other-entry"
              v-for="(other, index) in history.habits.others"
              :key="index"
            >
              <input
                v-model="history.habits.others[index]"
                type="text"
                placeholder="Introduce el nombre"
              />
              <button type="button" @click="removeOther('habits', index)">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- BUTTONS FINAL ROW -->
    <div>
      <div class="row">
        <div class="col text-center">
          <button
            type="button"
            role="button"
            class="btn btn-primary btn-wabident-round big"
            style="min-width: 316px; margin: 30px 0 30px 0"
            :disabled="is_loading"
            @click="updateHistory"
          >
            <div class="btn-icon float-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 19 19"
              >
                <path
                  fill="#ffffff"
                  d="M14.343.3a1.027 1.027 0 0 0-.726-.3h-1.489v5.873a.41.41 0 0 1-.41.41H3.058a.408.408 0 0 1-.408-.41V0H1.024A1.023 1.023 0 0 0 0 1.024v16.95A1.025 1.025 0 0 0 1.024 19h16.95A1.028 1.028 0 0 0 19 17.974V5.381a1.025 1.025 0 0 0-.302-.724L14.343.3zm1.967 16.342a.53.53 0 0 1-.532.53H3.22a.53.53 0 0 1-.532-.53V9.557a.53.53 0 0 1 .532-.53h12.56a.53.53 0 0 1 .531.53v7.085z"
                />
                <path fill="#ffffff" d="M11.073 0H3.705v5.227h7.368V0z" />
                <path
                  fill="#ffffff"
                  d="M13.31 13.875H5.69a.528.528 0 1 0 0 1.056h7.62a.528.528 0 1 0 0-1.056z"
                />
              </svg>
            </div>
            <span v-if="is_loading">Guardando ...</span>
            <span v-else>Guardar cambios</span>
            <i
              v-if="is_loading"
              class="fas fa-circle-notch fa-spin float-right"
              style="margin-top: 5px"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer"],
  data() {
    return {
      is_loading: false,
      history: {
        allergies: {
          penicillin: false,
          latex: false,
          others: [],
          medicines: false,
          other_medicines: "",
          substances: false,
          other_substances: "",
        },
        diseases: {
          hepatitis: false,
          diabetes: false,
          heart_condition: false,
          hiv: false,
          others: [],
          hypertension: false,
          autoinmune: false,
          other_diseases: false,
          other_diseases_content: "",
        },
        medicines: {
          anticoagulants: false,
          inmunosuppresasnts: false,
          others: [],
          other_medicines: false,
          other_medicines_content: "",
        },
        background: {
          headache: false,
          neuralgia: false,
          bruxism: false,
          others: [],
          surgical_interventions: false,
          surgical_interventions_content: "",
          previous_treatments: false,
          previous_treatments_content: "",
        },
        habits: {
          tobacco: false,
          drugs: false,
          others: [],
        },
      },
    };
  },
  computed: {
    custom_history_enabled() {
      console.log(this.clinic.config);
      return this.clinic && this.clinic.config["custom_customer_history"];
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  methods: {
    onSubmit(ev) {
      ev.preventDefault();
    },
    loadHistory() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .get(`/customer/${this.customer.id}/history`)
          .then((response) => {
            this.history = response.data;
          })
          .catch((error) => {
            console.log("[ERR] loadHistory", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    updateHistory() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(`/customer/${this.customer.id}/history`, this.history)
          .then((response) => {
            this.msgwindow.addMessage("success", "Anamnésis actualizada");
            this.history = response.data;
            this.$emit("historyUpdated", this.history);
          })
          .catch((error) => {
            this.msgwindow.addMessage(
              "danger",
              "Error actualizando Anamnésis!"
            );
            console.log("[ERR] updateHistory", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    addOthers(section) {
      let others = this.history[section].others || [];
      others.push("");
      this.$set(this.history[section], "others", others);
    },
    removeOther(section, index) {
      let others = this.history[section].others || [];
      others.splice(index, 1);
      this.$set(this.history[section], "others", others);
    },
  },
  mounted() {
    this.loadHistory();
  },
};
</script>

<style lang="scss" scoped>
.customer-edit-history {
  .customer-edit-history-section {
    .btn-plus {
      float: right;
    }

    margin-top: 30px;
    &::after {
      margin-top: 26px;
      width: 100%;
      height: 1px;
      background-color: var(--theme-grey-disabled);
      content: "";
      display: block;
    }

    legend {
      color: var(--theme-text-color);
      font-size: 18px;
      letter-spacing: 0.36px;
      font-weight: 500;
    }

    .customer-edit-history-checks {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin: 23px 0 18px 0;
      .form-check + .form-check {
        margin-left: 30px;
      }
    }

    .customer-edit-history-others {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;

      margin: -5px;

      .other-entry {
        margin: 5px;
        display: flex;
        flex-direction: row nowrap;
        align-items: center;
        border: 1px var(--theme-color-absent) solid;
        border-radius: 70px;
        height: 32px;
        padding: 7px 11px;
        input {
          border: none !important;
          color: var(--theme-color-absent);
          font-size: 13px;
          line-height: 1.54;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          appearance: none;
          width: 20px;
          height: 20px;
          color: white;
          border-radius: 10px;
          background-color: var(--theme-color-absent);
          border: none;
          i {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
