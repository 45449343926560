<template>
  <div class="customer-payment" @click="$emit('click', payment)">
    <div class="customer-payment__date">{{ pretty_date }}</div>
    <div class="customer-payment__type">{{ pretty_type }}</div>
    <div class="customer-payment__notes">
      {{ payment.notes }}
      <span v-if="payment.invoice_id"> - Fact: {{ invoice_code }}</span>
    </div>
    <div class="flex-fill"></div>
    <div class="customer-payment__amount">{{ pretty_amount }}</div>
    <div class="customer-payment__print">
      <a :href="payment_pdf_link" target="_blank">
        <i class="wabident icon-wabident-print"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["payment", "customer"],
  data() {
    return {};
  },
  computed: {
    payment_pdf_link() {
      return `/payment/${this.payment.id}/pdf`;
    },
    pretty_date() {
      return moment(this.payment.created_at).toDate().toLocaleDateString();
    },
    invoice_code() {
      if (this.payment.invoice_id && this.payment.invoice) {
        return this.payment.invoice.code;
      }
      return "";
    },
    pretty_type() {
      return (
        {
          cash: "Efectivo",
          credit_card: "Tarjeta",
          transfer: "Transferencia",
          mobile: "Bizum/Móvil",
          direct_debit: "Financiación",
          other: "Otros",
        }[this.payment.type] || "Otros"
      );
    },
    pretty_amount() {
      return WabiDent.fmtMoney(this.payment.amount, 0);
    },
  },
};
</script>

<style lang="scss">
.customer-payment {
  cursor: pointer;
  padding: 9px;
  background-color: var(--theme-grey-bg);
  border-bottom: 1px var(--theme-grey) solid;
  margin-bottom: 9px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  &__date {
    font-size: 14px;
    font-weight: 500;
    color: #a0a4b0;
    margin-right: 10px;
  }

  &__type {
    font-size: 16px;
    margin-right: 15px;
  }

  &__notes {
    font-size: 12px;
    font-weight: 500;
  }

  &__amount {
    font-size: 18px;
    color: var(--theme-color-attended);
    letter-spacing: 0.36px;
    margin-left: 30px;
  }

  &__print {
    a {
      color: var(--theme-text-color);
      transition: color 0.2s;
      text-decoration: none;
      &:hover {
        color: var(--theme-color);
      }
    }
    padding-left: 10px;
  }
}
</style>
