<template>
  <div class="odontogram-history-entry" @click="$emit('edit', history)">
    <div class="odontogram-history-entry__info">
      <div class="odontogram-history-entry__date">
        {{ date }} - {{ clinic }}
      </div>
      {{ doctor }}
    </div>
    <div class="odontogram-history-entry__summary">{{ summary }}<br>Ttos: {{ treatments_summary }}</div>
  </div>
</template>

<script>
export default {
  props: {
    history: { required: true },
    clinics: { required: true },
  },
  computed: {
    clinic() {
      let name = this.clinics[this.history.clinic_id] || null;
      return name ? name : "";
    },
    date() {
      return moment(this.history.created_at).format("DD/MM/YYYY");
    },
    doctor() {
      if (!this.history.doctor) return "";
      return this.history.doctor.user.name;
    },
    summary() {
      let tmp = document.createElement("DIV");
      tmp.innerHTML = this.history.notes;
      let notes = tmp.textContent || tmp.innerText || "";

      let ret = notes;

      return ret;
    },
    treatments_summary() {
      let ret = "";

      if (this.history.treatments && this.history.treatments.length > 0) {
        this.history.treatments.forEach((treatment) => {
          let teeth = "";
          if (treatment.teeth && treatment.teeth.length > 0) {
            teeth = " (" + treatment.teeth.join(",") + ") ";
          }
          ret += treatment.product.name + teeth;
        });
      }
      return ret;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.odontogram-history-entry {
  cursor: pointer;
  padding: 9px;
  background-color: var(--theme-grey-bg);
  border-bottom: 1px var(--theme-grey) solid;
  margin-bottom: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &__info {
    font-size: 16px;
    line-height: 20px;
    color: var(--theme-text-color);
    flex: 0 0 275px;
  }

  &__date {
    font-size: 14px;
    font-weight: 500;
    color: var(--theme-line-color);
  }

  &__summary {
    font-size: 12px;
    font-weight: 500;
    height: 100%;
    color: var(--theme-text-color);
  }
}
</style>

