<template>
  <div class="wabident-treatment-icon" :style="icon_style">
    <i class="wabident" :class="icon_class"></i>
  </div>
</template>

<script>
export default {
  props: {
    icon: { default: "default" },
    color: { default: "currentColor" }
  },
  data() {
    return {
      icon_mappings: {
        brackets: "icon-wabident-brackets",
        cavity: "icon-wabident-caries",
        carilles: "icon-wabident-carillas",
        corona: "icon-wabident-corona",
        endodoncy: "icon-wabident-endodoncia",
        extraction: "icon-wabident-extraccion",
        fracture: "icon-wabident-fractura",
        implant: "icon-wabident-implante",
        prothesis: "icon-wabident-protesis",
        restore: "icon-wabident-restauracion",
        multiple: "icon-wabident-multiple",
        absent: "icon-wabident-absent"
      }
    };
  },
  computed: {
    icon_class() {
      var ret = {};
      let name = this.icon_mappings[this.icon];
      if (!name) name = "icon-wabident-multiple";
      ret[name] = true;
      return ret;
    },
    icon_style() {
      return {
        color: this.color + " !important"
      };
    }
  }
};
</script>

<style lang="scss">
.wabident-treatment-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  i {
    color: inherit;
    font-size: inherit;
  }
}
</style>
