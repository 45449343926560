<template>
  <div class="container clinic-treatment-customers">
    <div class="row" style="margin-top:40px">
      <div class="col">
        <h1 class="clinic-treatment-customers__title">TRATAMIENTOS POR PACIENTES</h1>
      </div>
    </div>

    <!-- Treatment selector Selector -->
    <div class="clinic-treatment-customers__inputs row form-group">
      <div class="col-sm-12">
        <v-select
          class="wabident-selector"
          @search="searchProducts"
          :options="products"
          placeholder="Escribe del tratamiento a seleccionar"
          v-model="selected_product"
        ></v-select>
      </div>
    </div>

    <!-- Period Selector -->
    <div class="clinic-treatments-customers__inputs row form-group">
      <div class="col-sm-2">
        <select id="clinic_treatment_customers_period" v-model="period" class="custom-select">
          <option :value="null">Todas</option>
          <option value="day">Hoy</option>
          <option value="day_prior">Ayer</option>
          <option value="week">Esta semana</option>
          <option value="week_prior">Semana pasada</option>
          <option value="month">Este mes</option>
          <option value="month_prior">Mes pasado</option>
          <option value="trimester">Este trimestre</option>
          <option value="tri_prior">Trimestre pasado</option>
          <option value="year">Este año</option>
          <option value="year_prior">Año pasado</option>
          <option value="custom">Personalizado</option>
        </select>
        <label for="clinic_treatment_customers_period" class="wabident-info">Periodo</label>
      </div>
      <!-- Custom Dates Selector -->
      <transition name="fade">
        <div class="col-sm-2" v-if="period == 'custom'">
          <input
            v-model="date_from"
            id="clinic_treatment_customers_date_from"
            type="date"
            class="form-control"
          />
          <label class="wabident-info" for="clinic_treatment_customers_date_from">Desde:</label>
        </div>
      </transition>
      <transition name="fade">
        <div class="col-sm-2" v-if="period == 'custom'">
          <input
            v-model="date_to"
            id="clinic_treatment_customers_date_to"
            type="date"
            class="form-control"
          />
          <label class="wabident-info" for="clinic_treatment_customers_date_to">Hasta:</label>
        </div>
      </transition>
      <!-- ClinicSelector -->
      <div class="col-sm-2">
        <select id="clinic_treatment_customers_clinic_id" v-model="clinic_id" class="custom-select">
          <option :value="null">Todas</option>
          <option v-for="(name,id) in clinics" :key="id" :value="id">{{name}}</option>
        </select>
        <label for="clinic_treatment_customers_clinic_id" class="wabident-info">Clínica</label>
      </div>
      <!-- DoctorSelector -->
      <div class="col-sm-2">
        <select id="clinic_treatment_customers_doctor_id" v-model="doctor_id" class="custom-select">
          <option :value="null">Todos</option>
          <option
            v-for="(doctor, index) in doctors"
            :key="index"
            :value="doctor.doctor.id"
          >{{doctor.name}}</option>
        </select>
        <label for="clinic_treatment_customers_doctor_id" class="wabident-info">Doctor</label>
      </div>
    </div>

    <!-- CONTENT ROWS -->
    <div v-if="!selected_product" class="clinic-treatment-customers__loading text-center">
      <h2 class="my-5">Seleccione un tratamiento de la lista</h2>
    </div>
    <div v-else-if="is_loading" class="clinic-treatment-customers__loading text-center">
      <h2 class="my-5">
        Buscando
        <i class="fas fa-spin fa-circle-notch"></i>
      </h2>
    </div>
    <div v-else-if="has_results_available" class="clinic-treatment-customers__list text-center">
      <table class="table">
        <thead>
          <tr>
            <th>FECHA</th>
            <th>PACIENTE</th>
            <th>CLINICA</th>
            <th>DOCTOR</th>
            <th>REALIZADO</th>
            <th>PAGADO</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(treatment,index) in search_treatments">
            <clinic-treatment-customer
              class="clinic-treatment-customers__row"
              :treatment="treatment"
              :key="index"
              :clinics="clinics"
              :doctors="doctors"
            ></clinic-treatment-customer>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else class="clinic-treatment-customers__loading text-center">
      <h2 class="my-5">Lo siento, no pude encontrar pacientes con ese tratamiento.</h2>
    </div>
    <!-- PAGINATION -->
    <div v-if="has_results_available" class="row">
      <div class="col text-center customer-search-pagination my-2">
        <button
          :disabled="is_first_page"
          @click="goFirst"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-double-left"></i>
        </button>
        <button
          :disabled="is_first_page"
          @click="goPrev"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-left"></i>
        </button>
        <label class="customer-search-pagination-locator mx-2">
          Página
          {{current_page}} de {{total_pages}}
        </label>
        <button
          :disabled="is_last_page"
          @click="goNext"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-right"></i>
        </button>
        <button
          :disabled="is_last_page"
          @click="goLast"
          type="button"
          class="btn btn-primary btn-wabident-round mx-1"
        >
          <i class="fas fa-angle-double-right"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 0,
      is_loading: false,
      selected_product: null,
      treatments: null,
      period: null,
      date_from: new Date().toISODate(),
      date_to: new Date().toISODate(),
      doctor_id: null,
      clinic_id: null,
      products: []
    };
  },
  computed: {
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == this.treatments.last_page;
    },
    is_first_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == 1;
    },
    is_last_page() {
      if (!this.has_results_available) return true;
      return this.treatments.current_page == this.treatments.last_page;
    },
    search_treatments() {
      if (this.is_searching) return [];
      if (!this.treatments.data) return [];
      return this.treatments.data;
    },
    current_page() {
      if (!this.has_results_available) return 0;
      return this.treatments.current_page;
    },
    total_pages() {
      if (!this.has_results_available) return 0;
      return this.treatments.last_page;
    },
    has_results_available() {
      if (this.is_loading) {
        return false;
      }

      if (!this.treatments) {
        return false;
      }

      return this.treatments.data.length > 0;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    ...mapGetters(["msgwindow", "clinic", "errorwindow"])
  },
  watch: {
    selected_product(val) {
      this.page = 1;
      if (val) {
        this.loadTreatments();
      } else {
        this.resetTreatments();
      }
    },
    doctor_id(val) {
      this.loadTreatments();
    },
    period(val) {
      this.loadTreatments();
    },
    date_from(val) {
      this.loadTreatments();
    },
    date_to(val) {
      this.loadTreatments();
    },
    clinic_id(val) {
      this.loadTreatments();
    }
  },
  methods: {
    userCan(cap) {
      if (!this.clinic) return false;
      if (!this.clinic.capabilities) return false;
      return this.clinic.capabilities[cap] || false;
    },
    fillSearchedProducts(products) {
      this.searched_products = products;
      this.products = products.map(p => {
        return {
          label: p.name,
          value: p.id
        };
      });
    },
    searchProducts(search, loading) {
      loading(true);
      axios
        .get("/product", {
          params: {
            search: search
          }
        })
        .then(response => {
          this.fillSearchedProducts(response.data);
       })
        .catch(error => {
          console.log("[ERR] searchProducts:", error);
        })
        .then(() => {
          loading(false);
        });
    },
    loadTreatments() {
      if (!this.is_loading && this.selected_product) {
        this.is_loading = true;
        axios
          .get("/clinic/treatment_customers", {
            params: {
              product_id: this.selected_product.value,
              period: this.period,
              clinic_id: this.clinic_id,
              doctor_id: this.doctor_id,
              date_from: this.date_from,
              date_to: this.date_to,
              page: this.page
            }
          })
          .then(response => {
            console.log(response.data);
            this.treatments = response.data.treatments;
            this.page = this.treatments.current_page;
            this.date_from = response.data.date_from;
            this.date_to = resonse.data.date_to;

          })
          .catch(error => {
            console.log("[ERR] loadTreatments ", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    resetTreatments() {
      this.selected_product = null;
      this.treatments = null;
    },
    goFirst() {
      this.page = 1;
      this.loadTreatments();
    },
    goPrev() {
      this.page -= 1;
      this.loadTreatments();
    },
    goNext() {
      this.page += 1;
      this.loadTreatments();
    },
    goLast() {
      this.page = this.total_pages;
      this.loadTreatments();
    }
  },
  mounted() {
    this.resetTreatments();
  }
};
</script>

<style lang="scss">
.clinic-treatment-customers {
  &__title {
    font-size: 32px;
    color: var(--theme-text-color);
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 1.31;
    margin-bottom: 25px;
  }

  &__list {
    width: 100%;
    height: 60vh;
    overflow: scroll;
    table {
      th {
        font-size: 15px;
        letter-spacing: 0.3px;
        color: var(--theme-grey);
        border: none;
        position: sticky;
        top: 0;
        z-index: 1;
        background: white;
        border-bottom: 1px #e0e0e0 solid;
      }

      &__row {
        cursor: pointer;
        background-color: transparent;
        transition: all 0.2s;
        border-bottom: 1px #e0e0e0 solid;
        border-top: 1px #e0e0e0 solid;

        &:hover {
          background-color: var(--theme-highlight);
        }

        td {
          font-size: 16px;
          line-height: 20px;
          color: var(--theme-text-color);
        }
      }
    }
  }
}
</style>
