<template>
  <aside class="wabident-msg-window">
    <template v-for="(message,index) in messages">
      <b-alert
        fade
        v-model="message.timeout"
        :key="index"
        :variant="message.type"
        @dismissed="message.timeout = 0"
        dismissible
      >{{message.message}}</b-alert>
    </template>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      messages: []
    };
  },
  computed: {
    ...mapGetters(["msgwindow"])
  },
  mounted() {
    if (!this.msgwindow) {
      this.setMsgWindow(this);
    }
  },
  methods: {
    addMessage(type, msg, timeout = 5) {
      this.messages = this.messages.filter(msg => {
        return msg.timeout > 0;
      });

      this.messages.push({
        type: type,
        message: msg,
        timeout: timeout
      });
    },
    info(msg, timeout = 5) {
      this.addMessage("info", msg);
    },
    error(msg, timeout = 5) {
      this.addMessage("danger", msg);
    },
    success(msg, timeout = 5) {
      this.addMessage("success", msg);
    },
    warning(msg, timeout = 5) {
      this.addMessage("warning", msg);
    },
    ...mapMutations(["setMsgWindow"])
  }
};
</script>

<style lang="scss" scoped>
.wabident-msg-window {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 15px;
  width: 25vw;

  .alert {
    border: none;
    border-radius: 4px;
    font-size: 15px;
    color: white;
    background-color: var(--theme-color);

    &.alert-warning {
      background-color: var(--theme-color-urgent);
    }

    &.alert-success {
      background-color: var(--theme-color-attended);
    }

    &.alert-danger {
      background-color: var(--theme-color-absent);
    }
  }
}
</style>
