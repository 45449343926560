<template>
  <div
    class="treatment-item"
    :class="{ done: is_done && is_paid }"
    :style="treatment_style"
  >
    <div v-if="!total" class="treatment-selected-icon">
      <div class="form-check form-check-inline">
        <input
          v-if="is_selectable"
          @change="toggleSelected"
          class="form-check-input"
          type="checkbox"
          :id="checkbox_id"
          :checked="selected"
        />
        <label class="form-check-label" :for="checkbox_id"></label>
      </div>
    </div>
    <div
      v-if="!total && clinic_type == 'dental'"
      @click="toggleEdit"
      class="treatment-kind-icon"
    >
      <treatment-icon
        :icon="product_icon"
        :color="getIconColor(treatment)"
      ></treatment-icon>
    </div>
    <div @click="toggleEdit" class="treatment-body">
      <span class="treatment-date"
        >{{ treatment_date }} - {{ clinic_name }} -</span
      >
      <span class="treatment-name">
        <span v-if="clinic_type == 'dental'">( {{ treatment_teeth }} ) -</span>
        {{ treatment.product ? treatment.product.name : "" }}</span
      >
      <div class="treatment-notes">{{ treatment_notes }}</div>
    </div>
    <div v-if="total" class="treatment-total mx-3">
      {{ pretty_total }}
    </div>
    <div
      v-if="show_done"
      @click="markDone"
      data-toggle="tooltip"
      data-placement="top"
      title="Completar Tratamiento"
      class="treatment-done-icon"
      :class="{ done: is_done, doneprior: is_done_prior }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          fill-rule="evenodd"
          d="M22.707 3.293a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L12 12.586l9.293-9.293a1 1 0 0 1 1.414 0z"
          clip-rule="evenodd"
        />
        <path
          fill-rule="evenodd"
          d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-7a1 1 0 1 1 2 0v7a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h11a1 1 0 1 1 0 2H5z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
    <div
      @click="markPaid"
      class="treatment-paid-icon"
      data-toggle="tooltip"
      data-placement="top"
      title="Pagar Tratamiento"
      :class="{ paid: is_paid, notpaid: is_not_paid }"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 17 20"
      >
        <path
          d="M15.9 16.4c-.3-.5-.9-.6-1.4-.4-3.4 2-8 .7-9.8-3.1H9c.6 0 1-.4 1-1s-.4-1-1-1H4.1C4 10.3 4 9.7 4.1 9H9c.6 0 1-.4 1-1s-.4-1-1-1H4.7c1.8-3.8 6.4-5.1 9.8-3.1.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C10.7-.6 4.4 1.6 2.5 7H1c-.6 0-1 .4-1 1s.4 1 1 1h1.1c-.1.7-.1 1.3 0 2H1c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.9 5.4 8.2 7.6 13 4.8.5-.3.6-.9.4-1.4z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    clinic_type: { required: true },
    treatment: { required: true },
    clinics: { required: true },
    selected: { default: false },
    total: { default: 0 },
    show_done: { default: true },
  },
  computed: {
    treatment_notes() {
      let lines = [];
      if (this.treatment.quotation_id) {
        lines.push("Presupuestado");
        if (this.treatment.is_approved) {
          lines.push("Aprobado");
        }
      }

      if(this.treatment.notes && this.treatment.notes != '') {
        lines.push(this.treatment.notes);
      }
      return lines.join(" - ");
    },
    product_icon() {
      if (this.treatment.product) {
        return this.treatment.product.icon;
      }

      return null;
    },
    clinic_name() {
      let name = this.clinics[this.treatment.clinic_id] || null;
      return name ? name : "";
    },
    pretty_total() {
      return WabiDent.fmtMoney(this.total);
    },
    treatment_date() {
      return moment(this.treatment.created_at).toDate().toLocaleDateString();
    },
    treatment_teeth() {
      return this.treatment.teeth.reduce((accum, value) => {
        return accum + ` ${value}`;
      }, "");
    },
    checkbox_id() {
      return `treatment_check_${this.treatment.id}`;
    },
    is_done() {
      return this.treatment.status == "done";
    },
    is_done_prior() {
      return this.treatment.status == "done_prior";
    },
    is_quoted() {
      return Boolean(this.treatment.quotation_id);
    },
    is_paid() {
      return this.is_quoted && this.treatment.is_paid;
    },
    is_not_paid() {
      return this.is_quoted && !this.treatment.is_paid;
    },
    is_selectable() {
      return !(this.is_quoted || this.is_done_prior);
    },
    treatment_style() {
      let ret = {};

      if (this.treatment && this.treatment.color) {
        ret["background-color"] = window.WabiDent.hexToRGBA(
          this.treatment.color,
          0.125
        );
      }

      return ret;
    },
  },
  methods: {
    getIconColor(treatment) {
      return WabiDent.getIconColor(treatment);
    },
    toggleSelected(ev) {
      this.$emit("select", this.treatment, ev.target.checked);
    },
    toggleEdit() {
      this.$emit("edit", this.treatment);
    },
    markDone() {
      this.$emit("done", this.treatment);
    },
    markPaid() {
      this.$emit("paid", this.treatment);
    },
  },
};
</script>

<style lang="scss">
.treatment-item {
  cursor: pointer;
  padding: 9px;
  background-color: var(--theme-grey-bg);
  border-bottom: 1px var(--theme-grey) solid;
  margin-bottom: 7px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  &.done {
    background-color: rgba(84, 211, 173, 0.1);
  }

  .treatment-kind-icon,
  .treatment-selected-icon,
  .treatment-done-icon,
  .treatment-paid-icon {
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--theme-grey);
    svg {
      fill: currentColor;
    }

    .form-check {
      margin: 1px 0 0 0;
    }

    .wabident-treatment-icon {
      width: 20px;
      height: 20px;
      padding: 5px 0;
      font-size: 18px;
    }
  }

  .treatment-done-icon.done {
    color: var(--theme-color-attended);
  }

  .treatment-done-icon.doneprior {
    color: var(--theme-color-absent);
  }

  .treatment-paid-icon.paid {
    color: var(--theme-color-attended);
  }

  .treatment-paid-icon.notpaid {
    color: var(--theme-color-absent);
  }

  .treatment-body {
    flex: 1 1 auto;

    .treatment-date {
      color: var(--theme-line-color);
      font-size: 14px;
      font-weight: 500;
      vertical-align: middle;
    }

    .treatment-name {
      color: var(--theme-text-color);
      vertical-align: middle;
      font-size: 16px;
      font-weight: normal;
    }

    .treatment-notes {
      font-size: 12px;
      color: var(--theme-grey);
      font-weight: 500;
    }
  }

  .treatment-total {
    font-size: 18px;
    color: var(--theme-color-attended);
    letter-spacing: 0.36px;
    flex: 0 0 120px;
    text-align: right;
  }
}
</style>

