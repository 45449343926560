<template>
  <div class="customer-documents">
    <customer-show-document
      @submit="getDocuments"
      ref="showdocument"
      :customer="customer"
    ></customer-show-document>

    <customer-upload-multiple
      @submit="getDocuments"
      ref="uploadmultiple"
      :customer="customer"
    ></customer-upload-multiple>

    <customer-show-gallery-document
      ref="showgallery"
      @edit="onEditGalleryDocument"
      @email="onMailGalleryDocument"
    ></customer-show-gallery-document>

    <show-qr-code
      @close="onCloseSignature"
      ref="signdocument"
      id="customer-document-sign"
      title="Firmar Documento"
      :url="signature_url"
    ></show-qr-code>

    <div
      class="
        customer-documents__header
        d-flex
        flex-row
        justify-content-center
        align-items-center
      "
    >
      <h2>Documentos</h2>
      <div class="flex-spacer"></div>

      <button
        type="button"
        @click="addDocument"
        class="btn btn-primary btn-wabident-round"
      >
        <i class="fas fa-plus"></i>
        &nbsp; Crear
      </button>

      <button
        type="button"
        @click="addDocumentMultiple"
        class="btn btn-primary btn-wabident-round ml-3"
      >
        <i class="fa fa-files-o"></i>
        &nbsp; Múltiple
      </button>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="form-inline mb-3">
          <label class="mr-3">Filtro:</label>
          <select v-model="type_filter" class="custom-select wabident-xsmall">
            <option :value="null">Todos</option>
            <option value="consent">Consentimiento</option>
            <option value="proof">Justificante</option>
            <option value="report">Informe</option>
            <option value="prescription">Receta</option>
            <option value="document">Archivo</option>
            <option value="gallery">Galería</option>
          </select>
        </div>
      </div>
    </div>

    <div v-if="customer_documents_empty" class="customer-documents__list">
      <h3 v-if="!type_filter">El Paciente no tiene documentos todavía</h3>
      <h3 v-else>No hay documentos disponibles de este tipo</h3>
    </div>
    <div v-else class="customer-documents__list">
      <div v-if="type_filter == 'gallery'" class="row">
        <template v-for="(document, index) in documents">
          <div :key="index" class="col-sm-3">
            <customer-document-gallery
              :customer="customer"
              :document="document"
              :clinic="clinic"
              @view="viewGalleryDocument(document)"
            ></customer-document-gallery>
          </div>
        </template>
      </div>
      <template v-else v-for="(document, index) in documents">
        <customer-document
          :key="index"
          :customer="customer"
          :document="document"
          :clinic="clinic"
          @sign="signDocument(document)"
          @resign="resignDocument(document)"
          @edit="editDocument(document)"
          @email="mailDocument(document)"
        ></customer-document>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: { required: true },
  },
  data() {
    return {
      documents: [],
      type_filter: null,
      signature_url: "",
      is_loading: false,
    };
  },
  watch: {
    type_filter() {
      this.getDocuments();
    },
  },
  computed: {
    customer_documents_empty() {
      return !this.documents || !this.documents.length;
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    getDocuments() {
      let params = {};

      if (this.type_filter) {
        params.type = this.type_filter;
      }

      axios
        .get(`/customer/${this.customer.id}/document`, { params })
        .then((response) => {
          this.documents = response.data;
        })
        .catch((err) => {
          console.log("[ERR] getDocuments", err);
          this.msgwindow.error("Error cargando documentos");
        });
    },
    addDocument() {
      this.$refs.showdocument.showNew(this.customer);
    },
    addDocumentMultiple() {
      this.$refs.uploadmultiple.showNew(this.customer);
    },
    editDocument(document) {
      this.$refs.showdocument.showEdit(this.customer, document);
    },
    viewGalleryDocument(document) {
      this.$refs.showgallery.showEdit(this.customer, document);
    },
    resignDocument(document) {
      this.errorwindow.show(
        "Confirmación",
        "¿Deseas volver a firmar el Documento?. Esto invalidará firma anterior",
        "Firmar",
        "Cancelar",
        () => {
          this.signDocument(document, true);
        }
      );
    },
    signDocument(document, resign = false) {
      if (!this.is_loading) {
        this.is_loading = true;

        let params = {};
        if (resign) {
          params.resign = true;
        }

        axios
          .get(
            `/customer/${this.customer.id}/document/${document.id}/signature`,
            { params }
          )
          .then((response) => {
            let url = response.data.url;
            if (url) {
              this.signature_url = url;
              this.$refs.signdocument.show();
            } else {
              this.msgwindow.error(
                "Error firmando documento: " + (response.data.error || "")
              );
            }
          })
          .catch((error) => {
            console.log("[ERR] signDocument", error);
            this.msgwindow.error("Error firmando documento !");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    onCloseSignature() {
      this.signature_url = "";
      this.getDocuments();
    },
    onMailGalleryDocument(document) {
      this.mailDocument(document);
    },
    onEditGalleryDocument(document) {
      this.editDocument(document);
    },
    mailDocument(document) {
      if (!this.is_loading && document) {
        this.is_loading = true;
        this.msgwindow.info("Enviando correo ...");
        axios
          .post(`/document/${document.id}/email`)
          .then((response) => {
            let data = response.data;
            if (data.error) {
              this.msgwindow.error(data.error);
            } else {
              this.msgwindow.success(
                "Documento enviado a: " + this.customer.email
              );
            }
          })
          .catch((err) => {
            console.log("[ERR] mailDocument", err);
            this.msgwindow.error("Error enviando correo !");
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
  },
  mounted() {
    this.is_loading = false;
    this.getDocuments();
  },
};
</script>

<style lang="scss">
.customer-documents {
  &__list {
    h3 {
      text-align: center;
      font-size: 22px;
      font-weight: 500;
    }
  }

  &__header {
    padding: 30px 0 5px 0;
    h2 {
      font-size: 18px;
      letter-spacing: 0.36px;
      font-weight: 500;
    }
  }
}
</style>
