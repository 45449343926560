<template>
  <b-modal
    id="customer-show-history-entry"
    class="customer-show-history-entry"
    ref="modal"
    :title="dialog_title"
    @hidden="onClose"
    hide-footer
  >
    <div class="row form-group">
      <div class="col">
        <div class="customer-info">
          <div
            class="customer-photo"
            :style="{ 'background-image': 'url(' + customer.photo + ')' }"
          ></div>
          <div class="customer-data">
            {{ customer.name }}
            <br />
            {{ customer.phone || customer.phone_alt }} | {{ customer.nif }}
          </div>
        </div>
      </div>
    </div>

    <div class="row form-group">
      <div class="col-sm-4">
        <input
          type="date"
          id="entry_show_date"
          class="form-control"
          v-model="date"
          :readonly="readonly"
        />
        <label for="entry_show_date" class="wabident-info">Fecha</label>
      </div>
      <div class="col-sm-8">
        <select
          id="entry_select_doctor"
          v-model="doctor_id"
          class="custom-select"
          :disabled="readonly || default_doctor_id"
        >
          <option :value="null">Selecciona un Doctor</option>
          <option
            v-for="(doctor, i) in doctors"
            :key="i"
            :value="doctor.doctor.id"
          >
            {{ doctor.name }}
          </option>
        </select>
        <label for="entry_select_doctor" class="wabident-info">Doctor</label>
      </div>
    </div>

    <!-- Customer Treatment Selector -->
    <div
      v-if="!readonly"
      class="form-group row"
      :class="{ mb4px: selected_treatment_ids }"
    >
      <div class="col-sm-12">
        <select
          v-model="selected_treatment"
          class="custom-select wabident-small"
          :readonly="readonly"
        >
          <option value="0">Selecciona tratamiento</option>
          <option
            v-for="treatment in customer_treatments"
            :key="treatment.id"
            :value="treatment.id"
          >
            {{ getTreatmentName(treatment) }}
          </option>
        </select>
      </div>
    </div>
    <!-- Treatment List -->
    <div v-show="selected_treatment_ids" class="form-group row no-gutters">
      <div class="col-sm-12">
        <a
          @click="removeTreatment(treatment.id)"
          role="button"
          class="
            btn
            wabident-badge wabident-badge__clear wabident-badge__ellipsis
          "
          v-for="treatment in selected_customer_treatments"
          style="margin: 4px"
          :key="treatment.id"
          >{{ getTreatmentName(treatment) }}</a
        >
      </div>
    </div>

    <div class="row form-group">
      <div class="col">
        <label for="entry_select_notes" class="wabident-info">
          Información historia clínica
        </label>
        <quill-editor
          id="entry_select_notes"
          :options="options"
          v-model="notes"
        ></quill-editor>
      </div>
    </div>

    <div v-if="!readonly" class="row form-group">
      <div class="col buttons-column text-center">
        <button
          :disabled="is_loading"
          @click="deleteEntry"
          v-show="is_editing"
          class="btn btn-wabident-delete float-left"
          style="width: 53.6px; height: 53.6px"
        ></button>
        <button
          @click="submitEntry"
          :disabled="is_loading"
          type="button"
          class="btn btn-primary btn-wabident"
        >
          <span v-if="is_editing">Modificar Historia</span>
          <span v-else>Crear Historia</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    customer: { required: true },
    readonly: { default: false },
  },
  data() {
    return {
      is_editing: false,
      is_loading: false,
      date: new Date().toISODate(),
      doctor_id: null,
      selected_treatment_ids: [],
      customer_treatments: [],
      selected_treatment: 0,
      history_id: 0,
      notes: "",
      options: {
        placeholder: "Introduce texto ...",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ indent: "-1" }, { indent: "+1" }],
            ["blockquote", "code-block", "link"],
            ["clean"],
          ],
        },
      },
    };
  },
  watch: {
    selected_treatment(value) {
      if (value) {
        if (this.selected_treatment_ids.indexOf(value) < 0) {
          this.selected_treatment_ids.push(value);
        }
        this.selected_treatment = 0;
      }
    },
  },
  computed: {
    clinic_type() {
      if (!this.clinic) return null;
      return this.clinic.type;
    },
    default_doctor_id() {
      if (!this.clinic) return null;
      if (this.clinic.role == "doctor") return this.clinic.doctor_id;
      return null;
    },
    dialog_title() {
      if (this.readonly) return "Ver Historia";
      return this.is_editing ? "Editar Historia" : "Añadir Historia";
    },
    doctors() {
      if (!this.clinic) return [];
      return this.clinic.doctors;
    },
    selected_customer_treatments() {
      if (!this.customer_treatments) return [];
      return Object.values(this.customer_treatments).filter((treatment) => {
        return this.selected_treatment_ids.indexOf(treatment.id) >= 0;
      });
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    onClose() {},
    showNew() {
      this.is_editing = false;
      this.is_loading = false;
      this.date = new Date().toISODate();
      this.doctor_id = this.default_doctor_id;
      this.notes = "";
      this.history_id = 0;
      this.selected_treatment_ids = [];
      this.selected_treatment = 0;
      this.getCustomerTreatments();
      this.$refs.modal.show();
    },
    showEdit(entry) {
      this.is_editing = true;
      this.is_loading = false;
      this.date = moment(entry.created_at).format("YYYY-MM-DD");
      this.doctor_id = entry.doctor_id;
      this.notes = entry.notes;
      this.history_id = entry.id;
      this.selected_treatment = 0;
      this.getCustomerTreatments(entry);
      this.$refs.modal.show();
    },
    deleteEntry() {
      this.errorwindow.show(
        "Confirmación",
        "¿Desea borrar esta historia?",
        "Borrar",
        "Cancelar",
        this.onDeleteConfirm,
        null
      );
    },
    onDeleteConfirm() {
      if (this.is_editing && !this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/history_entry/${this.history_id}`,
            { _method: "delete" }
          )
          .then((response) => {
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error);
            } else {
              this.msgwindow.success("Historia borrada con éxito");
            }

            this.$emit("submit");
            this.$refs.modal.hide();
            this.is_loading = false;
          })
          .catch((error) => {
            console.log("[ERR] deleteHistoryEntry:", error.response.data);
            this.is_loading = false;
          });
      }
    },
    submitEntry() {
      if (this.is_editing) {
        this.modifyEntry();
      } else {
        this.createEntry();
      }
    },
    getTreatmentName(treatment) {
      let ret = treatment.product ? treatment.product.name : treatment.notes;
      if (this.clinic_type == "dental") {
        if (treatment.teeth && treatment.teeth.length > 0) {
          ret += "(" + treatment.teeth.join(",") + ")";
        }
      }
      return ret;
    },
    modifyEntry() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/history_entry/${this.history_id}`,
            this.getParameters()
          )
          .then((response) => {
            if (response.data.error) {
              this.errorwindow.show("Error", response.data.error);
            } else {
              this.msgwindow.success("Historia modificada con éxito");
            }

            this.$emit("submit");
            this.$refs.modal.hide();
          })
          .catch((error) => {
            console.log("[ERR] editEntry", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    createEntry() {
      if (!this.is_loading) {
        this.is_loading = true;
        axios
          .post(
            `/customer/${this.customer.id}/history_entry`,
            this.getParameters()
          )
          .then((response) => {
            this.$emit("submit");
            this.msgwindow.success("Historia creada con exito");
            this.$refs.modal.hide();
          })
          .catch((error) => {
            console.log("[ERR] createEntry", error);
          })
          .then(() => {
            this.is_loading = false;
          });
      }
    },
    getParameters() {
      return {
        clinic_id: this.clinic ? this.clinic.id : null,
        customer_id: this.customer.id,
        doctor_id: this.doctor_id,
        created_at: this.date,
        notes: this.notes,
        treatment_ids: this.selected_treatment_ids
          ? this.selected_treatment_ids
          : [],
      };
    },
    getCustomerTreatments(entry = null) {
      this.customer_treatments = [];
      axios
        .get(`/customer/${this.customer.id}/treatment`)
        .then((response) => {
          let ret = {};
          response.data.forEach((treatment) => {
            ret[treatment.id] = treatment;
          });
          this.customer_treatments = ret;
          if (this.is_editing && entry) {
            this.selected_treatment_ids = entry.treatments.map((t) => t.id);
          }
        })
        .catch((error) => {
          console.log("[ERR] getCustomerTreatments", error);
        });
    },
    removeTreatment(id) {
      if (this.readonly) return;

      this.selected_treatment_ids = this.selected_treatment_ids.filter(
        (treatment) => {
          return id != treatment;
        }
      );
    },
  },
};
</script>

<style lang="scss">
#customer-show-history-entry {
  .mb4px {
    margin-bottom: 4px !important;
  }

  .customer-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--theme-grey-bg);
    border-bottom: 1px var(--theme-line-color) solid;
    padding: 15px;

    .customer-photo {
      width: 62px;
      height: 62px;
      background-size: cover;
      background-position: center center;
      border-radius: 31px;
      margin-left: 4px;
    }

    .customer-data {
      padding: 11px 18px;
      font-size: 20px;
      line-height: 1.25;
      color: var(--theme-text-color);
    }
  }

  #entry_select_notes .ql-container {
    height: 250px;
    overflow-y: scroll;
  }
}
</style>

