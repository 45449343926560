<template>
  <div class="customer-edit-invoices">
    <customer-show-quotation
      ref="showquotation"
      :customer="customer"
      :clinic="clinic"
      @submit="onSubmit"
    ></customer-show-quotation>
    <customer-show-payment
      ref="showpayment"
      :customer="customer"
      @submit="onSubmit"
    ></customer-show-payment>
    <customer-show-invoice
      ref="showinvoice"
      :customer="customer"
      :clinic="clinic"
      @submit="onSubmit"
    ></customer-show-invoice>
    <div class="customer-edit-invoices__quotations">
      <div class="row mt-4">
        <div class="col">
          <button
            type="button"
            @click="addQuotation"
            class="btn btn-primary btn-wabident btn-plus float-right"
          >
            <i class="fas fa-plus"></i>
          </button>

          <legend>Presupuestos</legend>
          <div class="quotation-list">
            <customer-quotation
              v-for="q in filtered_quotations"
              :key="q.id"
              :quotation="q"
              :customer="customer"
              @edit="editQuotation"
              @approve="approveQuotation"
            ></customer-quotation>
          </div>
        </div>
      </div>
    </div>

    <div class="customer-edit-invoices__pending">
      <div class="row">
        <div
          class="col d-flex flex-row align-items-center"
          style="margin-bottom: 15px"
        >
          <div class="flex-fill">
            <div class="summary-header">PENDIENTE DE PAGO</div>
            <div class="total-quoted">
              Total presupuestado: {{ pretty_quoted }}
            </div>
          </div>
          <div>
            <a
              role="button"
              target="_blank"
              :href="customer_payment_report_link"
              class="btn btn-primary btn-wabident mx-3"
              >Informe <i class="fas fa-print"></i>
            </a>
          </div>
          <div class="total-pending">{{ pretty_pending }}</div>
        </div>
      </div>

      <div class="row treatments my-2">
        <div class="col">
          <legend class="mb-4">Tratamientos pendientes</legend>
          <button
            v-if="has_pending_treatments && is_dental"
            type="button"
            class="btn btn-primary btn-wabident-round float-right"
            @click="payAllTreatments"
          >
            Pagar Todos

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="20"
              viewBox="0 0 17 20"
            >
              <path
                d="M15.9 16.4c-.3-.5-.9-.6-1.4-.4-3.4 2-8 .7-9.8-3.1H9c.6 0 1-.4 1-1s-.4-1-1-1H4.1C4 10.3 4 9.7 4.1 9H9c.6 0 1-.4 1-1s-.4-1-1-1H4.7c1.8-3.8 6.4-5.1 9.8-3.1.5.3 1.1.1 1.4-.4.3-.5.1-1.1-.4-1.4C10.7-.6 4.4 1.6 2.5 7H1c-.6 0-1 .4-1 1s.4 1 1 1h1.1c-.1.7-.1 1.3 0 2H1c-.6 0-1 .4-1 1s.4 1 1 1h1.5c1.9 5.4 8.2 7.6 13 4.8.5-.3.6-.9.4-1.4z"
              />
            </svg>
          </button>
          <div class="treatment-list">
            <template v-for="(treatment, index) in data.pending_treatments">
              <treatment-item
                :total="getTreatmentTotal(treatment)"
                :key="index"
                :clinics="clinics"
                :treatment="treatment"
                :clinic_type="clinic.type"
                :show_done="clinic.type != 'dental'"
                @paid="paidTreatment"
                @done="doneTreatment"
              ></treatment-item>
            </template>
          </div>
        </div>
      </div>

      <div class="row invoices my-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-wabident-round float-right mb-2"
            @click="addPayment"
          >
            Crear <span v-if="is_dental">Pago</span
            ><span v-else>Cobro</span>&nbsp;
            <i class="fas fa-plus"></i>
          </button>

          <legend class="my-2">Entregas a cuenta</legend>
          <div class="invoice-list">
            <customer-payment
              v-for="p in data.payments"
              :customer="customer"
              :payment="p"
              :key="p.id"
              @click="editPayment"
            ></customer-payment>
          </div>
        </div>
      </div>

      <div v-if="can_invoice" class="row invoices my-2">
        <div class="col">
          <button
            type="button"
            class="btn btn-primary btn-wabident-round float-right mb-2"
            @click="addInvoice"
          >
            Crear Factura&nbsp;
            <i class="fas fa-plus"></i>
          </button>

          <legend>Facturas</legend>
          <div class="invoice-list">
            <customer-invoice
              v-for="i in data.invoices"
              :customer="customer"
              :invoice="i"
              :key="i.id"
              @edit="editInvoice"
            ></customer-invoice>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["customer"],
  data() {
    return {
      is_loading: false,
      data: {
        quotations: [],
        invoices: [],
        payments: [],
        available_treatments: [],
        pending_treatments: [],
        total_quoted: 0,
        total_pending: 0,
      },
    };
  },
  computed: {
    is_dental() {
      if(!this.clinic) return true;
      return this.clinic.type == 'dental';
    },
    has_pending_treatments() {
      if (this.is_loading) return false;
      if (!this.data.pending_treatments) return false;
      return this.data.pending_treatments.length > 0;
    },
    customer_payment_report_link() {
      if (!this.customer) return "";

      return `/customer/${this.customer.id}/payments_report`;
    },
    pretty_quoted() {
      return WabiDent.fmtMoney(this.data.total_quoted, 0);
    },
    pretty_pending() {
      return WabiDent.fmtMoney(this.data.total_pending, 0);
    },
    filtered_quotations() {
      return this.data.quotations;
    },
    clinics() {
      if (!this.clinic) return [];
      return this.clinic.clinic_names;
    },
    can_invoice() {
      if (!this.clinic) return false;

      return this.userCan("admin") || this.clinic.type == "dental";
    },
    done_warning() {
      if (this.clinic && this.clinic.type == "dental") {
        return "¡El tratamiento viene hecho de otra clínica!";
      } else return "¡El tratamiento es una revisión!";
    },
    ...mapGetters(["msgwindow", "errorwindow", "clinic"]),
  },
  methods: {
    userCan(capability) {
      if (!this.clinic) return false;
      return this.clinic.capabilities[capability] || false;
    },
    addQuotation() {
      if (this.data.available_treatments.length <= 0) {
        this.errorwindow.show(
          "Error",
          'No hay tratamientos sin presupuestar. Debe añadir al menos uno desde la pestaña "Odontograma"'
        );
        return;
      }
      this.$refs.showquotation.showNew([], this.data.available_treatments);
    },
    editQuotation(q) {
      this.$refs.showquotation.showEdit(q, this.data.available_treatments);
    },
    approveQuotation(q) {
      if (!q.is_approved) {
        this.errorwindow.show(
          "Confirmación",
          "Una vez aprobado el presupuesto, no podrá modificarlo si no lo desbloquea primero.",
          "Sí, Aprobar",
          "Cancelar",
          () => {
            this.doApproveQuotation(q);
          }
        );
      } else {
        this.errorwindow.show(
          "Confirmación",
          "¿Desbloquear presupuesto para editar?",
          "Sí, desbloquear",
          "Cancelar",
          () => {
            this.doUnlockQuotation(q);
          }
        );
      }
    },
    addPayment() {
      this.$refs.showpayment.showNew();
    },
    editPayment(payment) {
      this.$refs.showpayment.showEdit(payment);
    },
    addInvoice() {
      this.$refs.showinvoice.showNew();
    },
    editInvoice(invoice) {
      this.$refs.showinvoice.showEdit(invoice);
    },
    onSubmit() {
      this.loadData();
    },
    doApproveQuotation(quotation) {
      axios
        .post(
          `/customer/${this.customer.id}/quotation/${quotation.id}/approve`,
          {}
        )
        .then((response) => {
          this.msgwindow.success("Presupuesto aprobado");
        })
        .catch((err) => {
          console.log("[ERR] doApproveQuotation ", err);
          this.msgwindow.error("Error aprobando presupuesto!");
        })
        .then(() => {
          this.onSubmit();
        });
    },
    doUnlockQuotation(quotation) {
      axios
        .post(
          `/customer/${this.customer.id}/quotation/${quotation.id}/unlock`,
          {}
        )
        .then((response) => {
          this.msgwindow.success("Presupuesto desbloqueado");
        })
        .catch((err) => {
          console.log("[ERR] doUnlockquotation ", err);
          this.msgwindow.error("Error aprobando presupuesto!");
        })
        .then(() => {
          this.onSubmit();
        });
    },
    loadData() {
      let params = {
        clinic_id: this.clinic.id,
      };
      axios
        .get(`/customer/${this.customer.id}/invoices`, { params })
        .then((response) => {
          this.data = response.data;
        })
        .catch((err) => {
          this.msgwindow.error("Error leyendo facturas de cliente");
          console.log("[ERR] loadInvoiceData: ", err);
        });
    },
    nullOrEmpty(array) {
      return !array || array.length == 0;
    },
    getTreatmentTotal(treatment) {
      let quotation = this.filtered_quotations.find((q) => {
        return q.id == treatment.quotation_id;
      });

      if (!quotation) return 0;

      let line = quotation.quotationlines.find((l) => {
        // No product match -> fail
        if (l.product_id != treatment.product_id) return false;
        // Both empty -> ok
        if (this.nullOrEmpty(l.teeth) && this.nullOrEmpty(treatment.teeth))
          return true;
        // Any empty -> fail
        if (this.nullOrEmpty(l.teeth)) return false;
        if (this.nullOrEmpty(treatment.teeth)) return false;

        return (
          l.teeth.filter((x) => {
            return treatment.teeth.indexOf(x) !== -1;
          }).length > 0
        );
      });

      if (!line) return 0;

      return Number(line.price) * (1 - Number(line.discount / 100));
    },
    confirmPaidTreatment(treatment) {
      this.msgwindow.info("Buscando precio del tratamiento ...");
      axios
        .get(`/customer/${this.customer.id}/treatment/${treatment.id}/price`)
        .then((response) => {
          let price = response.data.price;
          this.invoices = response.data.invoices;
          this.$refs.showpayment.showNewTreatment(
            treatment,
            price,
            response.data.invoices
          );
        })
        .catch((error) => {
          console.log("[ERR] confirmPaidTreatment ", error);
          this.msgwindow.error("Error actualizando tratamiento ");
        })
        .then(() => {
          this.is_loading = false;
        });
    },
    confirmPaidTreatmens(treatments) {
      let prices = treatments.map((t) => {
        return this.getTreatmentTotal(t);
      });

      let quotation_ids = treatments.map((t) => {
        return t.quotation_id;
      });

      let invoices = this.data.invoices.filter((i) => {
        return quotation_ids.includes(i.quotation_id);
      });

      console.log(invoices);
      this.$refs.showpayment.showNewTreatments(treatments, prices, invoices);
    },
    payAllTreatments() {
      if (this.is_loading) return;
      this.confirmPaidTreatmens(this.data.pending_treatments);
    },
    paidTreatment(treatment) {
      if (this.is_loading) return;

      if (treatment.status == "done_prior") {
        this.msgwindow.warning(this.done_warning);
        return;
      }

      if (!treatment.quotation_id) {
        this.msgwindow.warning("El tratamiento no se ha presupuestado !");
        return;
      }

      this.is_loading = true;
      this.confirmPaidTreatment(treatment);
    },
    doneTreatment(treatment) {
      if (this.is_loading) return;

      if (treatment.status == "done_prior" && this.clinic.type == "dental") {
        this.msgwindow.warning(this.done_warning);
        return;
      }

      this.is_loading = true;

      axios
        .post(`/customer/${this.customer.id}/treatment/${treatment.id}/done`)
        .then((response) => {
          this.msgwindow.success("Tratamiento actualizado");
        })
        .catch((error) => {
          console.log("[ERR] doneTreatment ", error);
          this.msgwindow.error("Error actualizando tratamiento ");
        })
        .then(() => {
          this.is_loading = false;
          this.loadData();
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.quotation-list,
.invoice-list,
.treatment-list,
.customer-edit-invoices {
  margin-top: 0 15px;
}

.customer-edit-invoices {
  legend {
    font-size: 18px;
    color: var(--theme-text-color);
    font-weight: 500;
    display: inline-block;
    vertical-align: middle;
    width: auto;

    &::before {
      content: "―";
      margin-left: 15px;
      margin-right: 15px;
    }

    & ~ .btn-wabident-round {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      margin-left: 15px;

      svg {
        fill: currentColor;
        width: 15px;
        height: 15px;
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  &__quotations {
    margin-bottom: 30px;
    .quotation-list {
      margin-top: 30px;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__pending {
    background-color: #fafafa;
    padding: 27px 19px;

    .summary-header {
      font-size: 16px;
      line-height: 1.25;
      color: #3b414b;
    }

    .total-quoted {
      font-size: 14px;
      color: var(--theme-grey);
      font-weight: 500;
    }

    .total-pending {
      font-size: 32px;
      color: var(--theme-text-color);
      font-weight: 800;
      letter-spacing: 0.35px;
    }
  }
}
</style>
