import { render, staticRenderFns } from "./ClinicLog.vue?vue&type=template&id=2aa699f5&scoped=true&"
import script from "./ClinicLog.vue?vue&type=script&lang=js&"
export * from "./ClinicLog.vue?vue&type=script&lang=js&"
import style0 from "./ClinicLog.vue?vue&type=style&index=0&id=2aa699f5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2aa699f5",
  null
  
)

export default component.exports