<template>
  <div class="container-fluid wabident-clinic-main">
    <b-tabs class="clinic-main-tabs" justified lazy>
      <b-tab
        v-if="userCan('admin-treatments')"
        index="treatments"
        title="T. Realizados"
      >
        <clinic-treatments></clinic-treatments>
      </b-tab>
      <b-tab v-if="userCan('admin-payments')" index="payments" :title="payments_title">
        <clinic-payments></clinic-payments>
      </b-tab>
      <b-tab v-if="userCan('admin-invoices')" index="invoices" title="Facturas">
        <clinic-invoices @clinicUpdated="loadClinicData"></clinic-invoices>
      </b-tab>
      <b-tab
        v-if="userCan('admin-invoices')"
        index="invoices-data"
        title="Datos Fiscales"
      >
        <clinic-invoices-data
          @clinicUpdated="loadClinicData"
        ></clinic-invoices-data>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  props: ["clinic_id"],
  mounted() {
    this.loadClinicData();
  },
  computed: {
    payments_title() {
      if(this.clinic && this.clinic.type == 'aesthetic') {
        return 'Cobros';
      }
      return 'Pagos';
    },
    ...mapGetters(["clinic"]),
  },
  methods: {
    userCan(capability) {
      if (!this.clinic) return false;
      return this.clinic.capabilities[capability] || false;
    },
    loadClinicData(clinic_id) {
      axios
        .get("/agenda/clinic_info", {
          params: {
            clinic_id: clinic_id,
          },
        })
        .then((response) => {
          let data = response.data;
          if (data.error) {
            console.log("[ERR] loadClinicData:", data.error);
          } else {
            this.setClinic(data);
          }
        })
        .catch((error) => {
          console.log("[ERR] loadClinicData:", error);
        });
    },
    ...mapMutations(["setClinic"]),
  },
};
</script>

<style lang="scss">
.clinic-main-tabs {
  position: relative;
  height: 53px;

  & > div:not(.tab-content) {
    background-color: var(--theme-grey-bg);
  }

  .nav-tabs {
    max-width: 1140px;
    margin: auto;

    .nav-link {
      font-size: 14px;
    }

    &::before,
    &::after {
      position: absolute;
      background-color: var(--theme-grey-bg);
      top: 0;
      left: -99em;
      width: 99em;
      height: 52px;
      display: block;
      content: "";
    }

    &::before {
      left: -99em;
    }

    &::after {
      left: 100%;
    }
  }
}
</style>
