<template>
  <div class="customer-invoice">
    <div class="customer-invoice__date">{{ pretty_date }}</div>
    <div class="customer-invoice__name">{{ pretty_name }}</div>
    <div class="customer-invoice__notes">{{ invoice.notes }}</div>
    <div class="flex-fill"></div>
    <div class="customer-invoice__edit fcenter">
      <button
        type="button"
        class="btn btn-wabident-round transparent"
        @click="$emit('edit', invoice)"
      >
        Ver
      </button>
    </div>
    <div class="customer-invoice__print fcenter">
      <a
        :href="invoice_pdf_link"
        target="_blank"
        data-toggle="tooltip"
        data-placement="top"
        title="Imprimir"
      >
        <i class="wabident icon-wabident-print"></i>
      </a>
    </div>
    <div v-if="enable_simple_invoices" class="customer-invoice__print fcenter">
      <a
        :href="invoice_simple_pdf_link"
        target="_blank"
        data-toggle="tooltip"
        data-placement="top"
        title="Imprimir Simplificada"
      >
        <i class="far fa-file"></i>
      </a>
    </div>
    <div
      @click="sendMail"
      class="customer-invoice__email fcenter"
      data-toggle="tooltip"
      data-placement="top"
      title="Enviar por correo"
    >
      <i class="wabident icon-wabident-mail"></i>
    </div>
    <div class="customer-invoice__total">{{ pretty_total }}</div>
  </div>
</template>

<script>
export default {
  props: ["invoice", "customer"],
  data() {
    return {
      sending: false,
    };
  },
  computed: {
    enable_simple_invoices() {
      return this.clinic && this.clinic.config["enable_simple_invoices"];
    },
    invoice_pdf_link() {
      return `/invoice/${this.invoice.id}/pdf`;
    },
    invoice_simple_pdf_link() {
      return `/invoice/${this.invoice.id}/pdf?type=simple`;
    },
    pretty_date() {
      return moment(this.invoice.created_at).toDate().toLocaleDateString();
    },
    pretty_name() {
      let ret = `Factura #${this.invoice.code}`;

      if (this.invoice.is_revocation) {
        ret = ret + "- Rectificación";
      } else {
        if (this.invoice.revocation_invoice_id) {
          ret = ret + "- Rectificada";
        } else if (this.invoice.is_paid) {
          if (this.invoice.paid_amount - this.invoice.total_amount > 0.5) {
            ret = ret + "- ¡Sobrepago!";
          } else {
            ret = ret + "- Pagada";
          }
        } else {
          ret = ret + "- Sin pagar";
        }
      }

      return ret;
    },
    pretty_total() {
      let value = this.invoice.invoicelines.reduce((total, line) => {
        return (
          total +
          Number(line.price) *
            Number(line.quantity) *
            (1 - Number(line.discount) / 100)
        );
      }, 0);
      return WabiDent.fmtMoney(value, 0);
    },
    ...mapGetters(["msgwindow", "clinic"]),
  },
  methods: {
    sendMail() {
      if (this.sending) return;
      this.sending = true;
      this.msgwindow.info("Enviando factura ...");
      axios
        .get(`/invoice/${this.invoice.id}/email`)
        .then((response) => {
          let data = response.data;
          if (data.error) {
            this.msgwindow.error(data.message);
          } else {
            this.msgwindow.success(data.message);
          }
        })
        .catch((error) => {
          console.log("[ERR] sendInvoiceEmail ", error);
          this.msgwindow.error("Error enviando factura !");
        })
        .then(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style lang="scss">
.customer-invoice {
  padding: 9px;
  background-color: var(--theme-grey-bg);
  border-bottom: 1px var(--theme-grey) solid;
  margin-bottom: 9px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  .fcenter {
    justify-content: center;
  }

  &__date {
    font-size: 14px;
    font-weight: 500;
    color: #a0a4b0;
    margin-right: 10px;
  }

  &__name {
    font-size: 16px;
    margin-right: 15px;
  }

  &__notes {
    font-size: 12px;
    font-weight: 500;
  }

  &__total {
    font-size: 18px;
    color: var(--theme-color-attended);
    letter-spacing: 0.36px;
    margin-left: 30px;
  }

  &__email,
  &__print {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    flex: 0 0 35px;
    cursor: pointer;
    transition: all 0.2s;

    a {
      color: inherit;
      text-decoration: none;
    }

    i {
      font-size: 24px;
    }

    &.done {
      color: var(--theme-color-attended);
    }

    &:hover {
      color: var(--theme-color);
    }
  }
}
</style>
