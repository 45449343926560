<template>
  <div class="container clinic-products">
    <clinic-show-product
      ref="showproduct"
      @submit="onSubmit"
    ></clinic-show-product>
    <!-- HEADER -->
    <div class="row">
      <div class="col-sm-7">
        <h1 class="page-title">LISTA DE TRATAMIENTOS</h1>
      </div>
      <div class="col-sm-2 text-center">
        <button
          @click="downloadProducts"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          <i class="fas fa-download mr-2"></i>Descargar
        </button>
      </div>
      <div class="col-sm-3 text-center">
        <button
          @click="addNewProduct"
          type="button"
          class="btn btn-wabident-round btn-block"
        >
          <i class="fas fa-plus mr-2"></i> Nuevo tratamiento
        </button>
      </div>
    </div>
    <!-- INPUT -->
    <div class="row">
      <div class="col-sm-10">
        <input
          v-model="search"
          class="form-control icon"
          type="search"
          name="search"
          id="product_index_search"
          placeholder="Escribe el nombre del tratamiento"
        />
      </div>
      <div class="col-sm-2">
        <select
          v-model="show_disabled"
          id="product_show_disabled"
          class="custom-select"
        >
          <option :value="false">No</option>
          <option :value="true">Sí</option>
        </select>
        <label for="product_show_disabled" class="wabident-info"
          >Mostrar Inactivos</label
        >
      </div>
    </div>
    <!-- RESULTS -->
    <div class="row">
      <div class="col-sm-12">
        <div v-show="!has_results_available" class="text-center">
          <h2 class="product-search-empty-message my-4">{{ empty_message }}</h2>
        </div>
        <div v-show="has_results_available" class="product-search-wrapper">
          <table class="table product-search-results">
            <thead>
              <tr>
                <th colspan="7">TRATAMIENTO</th>
                <th colspan="2">TIPO</th>
                <th colspan="2">UNIDAD</th>
                <th colspan="2">PRECIO</th>
                <th colspan="2">ESTADO</th>
                <th colspan="2">CODIGO</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="product-search-results__row"
                v-for="(product, index) in search_results"
                :key="index"
                @click="productClicked(product)"
              >
                <td colspan="7">{{ product.name }}</td>
                <td colspan="2">
                  {{ product.type == "treatment" ? "Tratamiento" : "Producto" }}
                </td>
                <td colspan="2">
                  {{ product.category ? product.category.name : "" }}
                </td>
                <td colspan="2">{{ product.price }}</td>
                <td colspan="2">
                  {{ product.disabled ? "Inactivo" : "Activo" }}
                </td>
                <td class="product-code" colspan="2">{{ product.code }}</td>
              </tr>
              <tr></tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      search_results: [],
      show_disabled: false,
      is_searching: false,
      selected_product: null,
    };
  },
  computed: {
    has_results_available() {
      return this.search_results && this.search_results.length != 0;
    },
    empty_message() {
      if (this.search == "") {
        return "";
      } else {
        return "Lo siento, no pude encontrar ningún producto con ese nombre";
      }
    },
    ...mapGetters(["msgwindow"]),
  },
  watch: {
    search(val) {
      this.searchProducts(val);
    },
    show_disabled(val) {
      this.searchProducts(this.search);
    },
  },
  methods: {
    downloadProducts() {
      window.open("/product/csv", "_blank");
    },
    addNewProduct() {
      this.$refs.showproduct.showNew();
    },
    productClicked(prod) {
      this.$refs.showproduct.showEdit(prod);
    },
    onSubmit() {
      this.searchProducts();
    },
    searchProducts() {
      if (!this.is_searching) {
        this.is_searching = true;
        let params = {
          search: this.search,
        };

        if (this.show_disabled) {
          params.show_disabled = true;
        }

        axios
          .get("/product", { params })
          .then((response) => {
            this.search_results = response.data;
          })
          .catch((err) => {
            console.log("[ERR] searchProducts", err);
            this.msgwindow.error("Error buscando productos");
          })
          .then(() => {
            this.is_searching = false;
          });
      }
    },
  },
  mounted() {
    this.searchProducts();
  },
};
</script>

<style lang="scss">
.clinic-products {
  margin-top: 54px;
}

.page-title {
  font-size: 32px;
  color: var(--theme-text-color);
  letter-spacing: 0.5px;
  line-height: 1.31;
  font-weight: 800;
  margin-bottom: 27px;
}

.product-search-empty-message {
  color: var(--theme-grey);
  font-size: 24px;
}

.product-search-wrapper {
  margin-top: 30px;
  position: relative;
  overflow-y: scroll;
  width: 100%;
  height: 60vh;
}

.product-search-results {
  width: 100%;
  th {
    font-size: 15px;
    letter-spacing: 0.3px;
    color: var(--theme-grey);
    border: none;
    position: sticky;
    background-color: white;
    top: 0;
  }

  &__row {
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    border: none;
    border-bottom: 1px #e0e0e0 solid;

    &:hover {
      background-color: var(--theme-highlight);
    }

    td {
      font-size: 16px;
      line-height: 20px;
      color: var(--theme-text-color);

      &.product-code,
      &.product-icon {
        color: var(--theme-grey);
        text-transform: uppercase;
        svg {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
