<style lang="scss">
// Timeslot
.app-timeslot {
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: var(--theme-shadow) !important;
  }
}

.timeslot-filled {
  background-color: var(--theme-grey-bg);
  .timeslot-wrapper {
    // Photo Column
    .photo-col {
      flex: 0 0 30px;
      padding: 5px;

      .customer-photo {
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
    }

    .col.content-col {
      padding: 5px;
      h6 {
        font-size: 13px;
        font-weight: bold;
        color: var(--theme-text-color);
      }

      p {
        font-size: 12px;
        line-height: 12.7px;
        color: var(--theme-text-color);
      }
    }

    .icons-col {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 0 0 58px;
      justify-content: flex-end;
      align-items: flex-start;
      & > * {
        flex: 0 0 22px;
        margin: 2px;
      }

      .icon-gone {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 22px;
        height: 22px;
        border-radius: 11px;
        border: 1px var(--theme-text-color) solid;
        background-color: white;

        i {
          font-size: 10px;
          color: var(--theme-text-color);
        }
      }
    }
  }

  &.arrived {
    background-color: var(--theme-color-arrived);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.attended {
    background-color: var(--theme-color-attended);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.cancel {
    background-color: var(--theme-color-canceled);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.absent {
    background-color: var(--theme-color-absent);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.treated {
    background-color: var(--theme-color-treated);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.gone {
    background-color: var(--theme-color-gone);
    .timeslot-wrapper {
      opacity: 0.5;
    }
  }

  &.revision {
    background-color: var(--theme-color-revision);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.urgent {
    background-color: var(--theme-color-urgent);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.insurance {
    background-color: var(--theme-color-insurance);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }

  &.marked {
    background-color: var(--theme-color-marked);
    .col.content-col {
      h6,
      p {
        color: white;
      }
    }
  }
}
</style>

<template>
  <td
    @click="cellClick"
    :style="cell_style"
    class="app-timeslot timeslot-empty"
    :class="cell_class"
    v-if="!isFilled()"
  ></td>
  <td
    @click="cellClick"
    :style="cell_style"
    class="app-timeslot timeslot-filled"
    :class="cell_class"
    v-else-if="isFirst()"
    :rowspan="appointment.num_slots"
  >
    <div class="timeslot-wrapper text-left row no-gutters">
      <div v-if="has_photo" class="col photo-col">
        <img class="customer-photo" :src="appointment.customer.photo" />
      </div>
      <div class="col content-col">
        <h6>{{ appointment.customer ? appointment.customer.name : "" }}</h6>
        <p>{{ appointment.notes }}</p>
      </div>
      <div class="col icons-col">
        <img
          v-if="appointment.status == 'attended'"
          src="/images/atendido.svg"
        />
        <div class="icon-gone" v-if="appointment.status == 'gone'">
          <i class="fas fa-door-open"></i>
        </div>
        <div
          class="icon-gone"
          v-if="appointment.customer && !appointment.customer.has_paid"
        >
          <i class="fas fa-money-bill-alt"></i>
        </div>
        <template v-for="(section, i) in customer_history">
          <template v-for="(item, j) in section">
            <img
              v-if="history_icons[j] && section[j]"
              :key="i + '_' + j"
              alt="j"
              :src="history_icons[j]"
            />
          </template>
        </template>
      </div>
    </div>
  </td>
  <span style="display: none" v-else> </span>
</template>

<script>
export default {
  props: ["appointment", "timeslot", "tsindex", "num_boxes", "box", "doctors"],
  data() {
    return {
      history_icons: {
        penicillin: "/images/penicilina.svg",
        latex: "/images/latex.svg",
        hiv: "/images/vih.svg",
        hepatitis: "/images/hepatitis.svg",
        heart_condition: "/images/cardiacos.svg",
        diabetes: "/images/diabetes.svg",
      },
    };
  },
  computed: {
    doctor() {
      if (!this.doctors || !this.appointment) return null;
      return this.doctors[this.appointment.doctor_id] || null;
    },
    customer_history() {
      if (!this.appointment.customer) return [];
      return this.appointment.customer.history;
    },
    has_photo() {
      return !(
        this.appointment.customer == null ||
        this.appointment.customer.photo == null ||
        this.appointment.customer.photo == ""
      );
    },
    app_color() {
      if(!this.appointment) {
        return  null;
      }

      if (
        !this.appointment.color ||
        this.appointment.color == "null" ||
        this.appointment.color == "#000000"
      ) {
        return null;
      }

      return this.appointment.color;
    },

    doc_color() {
      if(!this.doctor) {
        return null;
      }

      if (
        !this.doctor.color ||
        this.doctor.color == "null" ||
        this.doctor.color == "#000000"
      ) {
        return null;
      }

      return this.doctor.color;
    },

    cell_style() {
      let width = "100%;";
      if (this.num_boxes > 0) {
        let w = 100 / this.num_boxes;
        width = `${w}%`;
      }

      let ret = {
        width: width,
      };

      if (this.isWaiting()) {
        if (this.app_color) {
          ret["background-color"] = this.app_color;
        } else if (this.doc_color) {
          ret["background-color"] = this.doc_color;
        }
      }

      return ret;
    },
    cell_class() {
      let ret = {};
      if (this.isFilled()) {
        let classname = null;

        if (this.appointment.is_marked) {
          classname = "marked";
        } else if (this.appointment.is_urgent) {
          classname = "urgent";
        } else if (this.appointment.is_revision) {
          classname = "revision";
        } else if (this.appointment.is_insurance) {
          classname = "insurance";
        }

        if (this.appointment.status != "pending") {
          classname = this.appointment.status;
        }

        if (classname) {
          ret[classname] = true;
        }
      }

      return ret;
    },
  },
  methods: {
    isFilled() {
      return this.appointment != null;
    },
    isWaiting() {
      return this.appointment != null && this.appointment.status == "pending";
    },
    isFirst() {
      if (this.tsindex == 0) {
        return true;
      }

      let d = moment(this.appointment.date_from);
      return (
        d.minutes() == this.timeslot.minute && d.hours() == this.timeslot.hour
      );
    },
    cellClick() {
      this.$emit("cellClick", this.appointment, this.timeslot, this.box);
    },
  },
  mounted() {},
};
</script>
